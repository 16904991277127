<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-5 wrapper-content">

    <div class="row justify-content-center">
        <div class="form-group has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="text" class="form-control" [(ngModel)]="serviceName" placeholder="Search by product name"
                (input)="getServiceList();currentPage=1" (input)="!serviceName && getServiceList()">
        </div>
    </div>
    <sub-category-filter (onSelectCategory)="getsubCatId($event)"></sub-category-filter>

    <div class="row  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3">
        <ng-container *ngIf="productDetails?.length">
            <div class="col newCardWidth mb-3"
                *ngFor="let item of productDetails | paginate : {itemsPerPage: pageSize, currentPage: currentPage,totalItems: totalItems};let i = index">
                <div class="innerDiv">
                    <product-card [data]="item" (on_Click)="navigateToViewProduct(item?._id)" [showHeart]="true"
                        (on_Add_Wishlist)="addWishlist($event)"></product-card>
                </div>
            </div>
        </ng-container>
        <!-- replaced !apiLoaded with !isLoading -->
        <ng-container *ngIf="!productDetails?.length && !isLoading">
            <div class="col newCardWidth mb-3"
                *ngFor="let item of length(12)"> 
                <div class="innerDiv">
                    <product-card [data]="item"  [showHeart]="true"
                        (on_Add_Wishlist)="addWishlist($event)"></product-card>
                </div>
            </div>

        </ng-container>
        <div *ngIf="apiLoaded" class="no-data">
            <p style="text-align: center; font-size: 20px; font-weight: 500;">No Data Found</p>
        </div>

    </div>
    <div class="custom-pagination mt-2 text-align-right" style="text-align: right;" *ngIf="totalItems > 15 && !apiLoaded">
        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
    </div>
</div>
