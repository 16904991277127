<div class="modal fade" id="paymentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content ggg" >
      <div class="d-flex justify-content-center mt-3 mb-3" id="book">
        <img class="for-img1" style="height: auto;width: 20%;" [src]="paymentImg" alt="login form"
          class="img-fluid" />
      </div>
      <div class="text-center mb-2">
       <h2>{{heading}}</h2>
       <p>{{description}}</p>
      </div>
     
      <div class="modal-body d-flex justify-content-center p-0">
        <button type="button" *ngIf="paymentList == 'product'" class="btn btn-theme"  (click)="closeProduct()">
          Continue Shopping
        </button>
        <button type="button"  *ngIf="paymentList != 'product'" class="btn btn-theme"  (click)="navigatecart()">
          My Cart
        </button>
        
      </div>
    </div>
  </div>
</div>
