<div class="container mb-5 wrapper-content">
    <span (click)="commonService.backLocation()" class="circle-arrow text-start">
        <img class="arrow-image-add-new" src="assets/svg/ar.svg" alt="">
        </span>
    <h1 class="text-center mt-3">Payment Failed</h1>
    <div class="right-side" style="width: 100%;">
        <div class="row mt-3">
           <div class="top-success">
            <div class="row">
                <div class="col-md-6">
<div class="datas">
    <img class="right-img" src="assets/img/er.png" alt="">
    <p class="new-text">Payment Failed</p>
</div>
                </div>
                <div class="col-md-6">
                    <img class="left-img" src="assets/img/faildpayment.png" alt="">

                </div>
            </div>
           </div>
        </div>
    </div>


</div>