<div class="container fw-b">
  <app-linker-route
    [headingName]="headingName"
    [linkerRouteItems]="linkerRouteItems"
  ></app-linker-route>
</div>
<div class="container mb-5 wrapper-content">
  <div class="row justify-content-center searchBox">
    <div class="form-group has-search">
      <span class="fa fa-search form-control-feedback"></span>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="serviceName"
        placeholder="Search by product name"
        (input)="getServiceList(); currentPage = 1"
      />
    </div>
  </div>
  <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 mt-3">
    <div
      class="col newCardWidth mb-3 newCardWidth1"
      *ngFor="
        let item of productDetails
          | paginate
            : {
                itemsPerPage: pageSize,
                currentPage: currentPage,
                totalItems: totalItems
              };
        let i = index
      "
    >
      <div class="innerDiv">
        <product-card
          [data]="item"
          (on_Click)="navigateToViewProduct(item?._id)"
          (addWishlist)="addWishlist($event)"
        ></product-card>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="no-data">
    <p style="text-align: center; font-size: 16px; font-weight: 500">
      No Data Found
    </p>
  </div>
  <div
    class="custom-pagination mt-2 text-align-right"
    style="text-align: right"
    *ngIf="totalItems > 15"
  >
    <pagination-controls
      (pageChange)="pagination($event)"
    ></pagination-controls>
  </div>
</div>
