import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-customer-request-list',
  templateUrl: './customer-request-list.component.html',
  styleUrls: ['./customer-request-list.component.scss']
})
export class CustomerRequestListComponent implements OnInit {
  btn: any = "Pending"
  headingName:any =' Bookings'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Bookings',route:'/customer-service-request-list-table'}
  ]
  orderList: any =[]
  count: any
  catName: any
  currentPage: any = 1;
  pageSize: any = 10;
  serviceStatus: any = 'PENDING'
  searchForm: FormGroup | any;
  today = new Date().toISOString().split('T')[0]
  notFoundData:boolean =false
  constructor(public commonService: CommonService, public router: Router) { }

  ngOnInit(): void {
    this.searchFormValidation()
    this.serviceOrderList();
  }
  isDate(date){
  return date.length==10 ? true : false  
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {
    this.notFoundData =false
    if (this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.serviceOrderList()
    }
  }
  searchFormReset() {
    this.notFoundData =false
    if (this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        fromDate: '',
        toDate: ''
      });
      this.serviceOrderList()
    }
    // else{
    //   this.getCustomerList()
    // }
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  isLoading:boolean =false
  serviceOrderList() {
    this.orderList =[]
    this.count = 0
      this.isLoading =true
    let data = {
      page: this.currentPage,
      limit: this.pageSize,
      fromDate: this.searchForm.value.fromDate ? new Date(this.searchForm.value.fromDate).toISOString() : '',
      toDate: this.searchForm.value.toDate ? new Date(this.searchForm.value.toDate).toISOString() : ''
      // orderType: 'SERVICE',
      // statusType: this.serviceStatus
    }
    data = this.commonService.removeEmptyKey(data)
    this.commonService.postApi('order/orderServiceList', data, 1).subscribe((res: any) => {
      this.isLoading =false
     
      if (res['responseCode'] == 200 && res.result.docs.length>0) {
        this.commonService.hideSpinner()
        this.orderList = res.result.docs;
        this.count = res.result.total
        if(this.orderList.length>0){
          this.notFoundData =false
        }
      }else{
        this.notFoundData = true
         this.orderList =[]
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }

    }, (err: any) => {
      this.notFoundData = true
      this.orderList =[]
      this.count =0
      this.isLoading =false
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  isPageCall:boolean =false 
  changePageNumber(page) {
    this.isPageCall=true
    this.currentPage = page;
    this.serviceOrderList();
  }
  viewBookingHistoryDetails(item){
    this.router.navigate(['/customer-booking-details-view'],{ queryParams: { data: JSON.stringify(item) } })
  }
}
