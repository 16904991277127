import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-error-customer-payment',
  templateUrl: './error-customer-payment.component.html',
  styleUrls: ['./error-customer-payment.component.scss']
})
export class ErrorCustomerPaymentComponent implements OnInit {
  constructor(public commonService:CommonService) { }

  ngOnInit(): void {
  }

}
