import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-otp-verification-customer',
  templateUrl: './otp-verification-customer.component.html',
  styleUrls: ['./otp-verification-customer.component.scss']
})
export class OtpVerificationCustomerComponent implements OnInit, OnDestroy {
  // otp: any;
  otpForm!: FormGroup
  otp: any;
  myEmail: any;
  countDowon: any = 2.59





  resend_otp_active: boolean = false
  time!: string

  constructor(private http: HttpClient, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.otpForm = new FormGroup({
      otp: new FormControl("", [
        Validators.required,

      ]),
    });
    let timerd = localStorage.getItem('remainingTime')
    this.timer(timerd|| 180);
    this.myEmail = localStorage.getItem('email')

  }
  timerOn = true;

  onOtpChange(otp: any) {
    this.otp = otp;
  }

  navigateToVerifyCustomer() {
    let userData = {
      email: this.myEmail,
      otp: this.otp,
      userType: 'CUSTOMER',
    }
    // this.commonService.showSpinner()
    if (!this.otp) {
      return
    }
    this.loading = true
    this.commonService.postApi('user/verifyOTP', userData, 0).subscribe((res: any) => {

      if (res.responseCode == 200) {
        this.loading = false
        this.commonService.successToast(res['responseMessage'])
        this.router.navigate(['/login'])
      }
    }, err => {
      this.loading = false
      this.commonService.errorToast(err['responseMessage'])
      if (err['responseCode'] == 400) {
        this.commonService.errorToast(err['responseMessage'])
      }

    })

  }


  // resend otp 
  resendOtp() {
    if (!this.isExpire) {
      return
    }
    // this.commonService.showSpinner()
    this.myEmail = localStorage.getItem('email');
    var email = {
      'email': this.myEmail,
      userType: 'CUSTOMER',
    }
    this.commonService.postApi('user/resendOTP', email, 0).subscribe(res => {
      console.log(res,"rrrr")
      if (res['responseCode'] == 200) {
        // this.commonService.hideSpinner()
        this.timer(180);
        this.isExpire=false;
        this.commonService.successToast(res['responseMessage'])
      }

    }, err => {
      // this.commonService.hideSpinner();
      this.commonService.errorToast(err['responseMessage'])

    })
  }

  remainingTime: any
  isExpire: boolean = false
  timeOutId : any
  timer(remaining) {
    let m: any = Math.floor(remaining / 60);
    let s: any = remaining % 60;

    m = m < 10 ? '' + m : m;
    s = s < 10 ? '0' + s : s;
    this.remainingTime = m + ':' + s

    // document.getElementById('timer').innerHTML = m + ':' + s;
    remaining -= 1;
    let me = this
    localStorage.setItem('remainingTime', remaining)
    if (remaining >= 0 && this.timerOn) {
      this.timeOutId =  setTimeout(function () {
        me.timer(remaining);
      }, 1000);
      return;
    }

    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    localStorage.removeItem('remainingTime')
    this.isExpire = true
  }
  loading = false;

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;

    }, 60000); // 1 minute
  }
  ngOnDestroy(): void {
    // This method will be called just before the component is destroyed
    // alert(5)
    this.timer(0);
    clearTimeout(this.timeOutId)
    localStorage.removeItem('remainingTime');
  }
}
