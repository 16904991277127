import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {
  pageName: any;
  description: any;
  type: any;
  result: any;
  staticContentDetails: any;
  termsData: any;
  

  constructor(private fb: FormBuilder, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getPrivacy();
  }
  getPrivacy() {
    this.commonService.getApi('static/viewStaticContent?type=termsConditions', 0).subscribe((res: any) => {
      this.commonService.showSpinner()
      if (res['responseCode'] == 200) {
        this.termsData = res['result']
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {

      this.commonService.warningToast(err['error']['responseMessage'])
    }
    )
  }
}
