import { ChangePasswordServiceProviderComponent } from './pages/service-provider/auth/change-password-service-provider/change-password-service-provider.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BusinessDetailCustomerComponent } from './pages/customer/business-detail-customer/business-detail-customer.component';
import { ChangePasswordCustomerComponent } from './pages/customer/change-password-customer/change-password-customer.component';
import { CustomerEditProfileComponent } from './pages/customer/customer-edit-profile/customer-edit-profile.component';
import { CustomerProfileComponent } from './pages/customer/customer-profile/customer-profile.component';
import { ForgotCustomerComponent } from './pages/customer/forgot-customer/forgot-customer.component';
import { LoginCustomerComponent } from './pages/customer/login-customer/login-customer.component';
import { OtpVerificationCustomerComponent } from './pages/customer/otp-verification-customer/otp-verification-customer.component';
import { OtpVerificationResetPasswordCustomerComponent } from './pages/customer/otp-verification-reset-password-customer/otp-verification-reset-password-customer.component';
import { ResetCustomerComponent } from './pages/customer/reset-customer/reset-customer.component';
import { SignupCustomerComponent } from './pages/customer/signup-customer/signup-customer.component';
import { UploadDocumentCustomerComponent } from './pages/customer/upload-document-customer/upload-document-customer.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { SearchProductComponent } from './pages/search-product/search-product.component';
import { ForgotServiceProviderComponent } from './pages/service-provider/auth/forgot-service-provider/forgot-service-provider.component';
import { LoginServiceProviderComponent } from './pages/service-provider/auth/login-service-provider/login-service-provider.component';
import { SignupServiceProviderComponent } from './pages/service-provider/auth/signup-service-provider/signup-service-provider.component';
import { AboutusComponent } from './pages/static-content-management/aboutus/aboutus.component';
import { ContactusComponent } from './pages/static-content-management/contactus/contactus.component';
import { FaqComponent } from './pages/static-content-management/faq/faq.component';
import { PrivacyPolicyComponent } from './pages/static-content-management/privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './pages/static-content-management/terms-and-condition/terms-and-condition.component';
import { OtpVerificationServiceProviderComponent } from './pages/service-provider/auth/otp-verification-service-provider/otp-verification-service-provider.component';
import { ResetServiceProviderComponent } from './pages/service-provider/auth/reset-service-provider/reset-service-provider.component';
import { BusinessDetailsServiceProviderComponent } from './pages/service-provider/auth/business-details-service-provider/business-details-service-provider.component';
import { UploadDocumentServiceProviderComponent } from './pages/service-provider/auth/upload-document-service-provider/upload-document-service-provider.component';
import { ProductViewCustomerComponent } from './pages/customer/product-view-customer/product-view-customer.component';
import { ServiceProviderServiceComponent } from './pages/service-provider/service-provider-service/service-provider-service.component';
import { ServiceOrderDetailComponent } from './pages/service-provider/service-order-detail/service-order-detail.component';
import { ServiceOrderDetailViewComponent } from './pages/service-provider/service-order-detail-view/service-order-detail-view.component';
import { CustomerWishlistComponent } from './pages/customer/customer-wishlist/customer-wishlist.component';
import { CustomerAddToCartComponent } from './pages/customer/customer-add-to-cart/customer-add-to-cart.component';
import { LoginRetailerComponent } from './pages/retailer/auth/login-retailer/login-retailer.component';
import { SignupRetailerComponent } from './pages/retailer/auth/signup-retailer/signup-retailer.component';
import { ForgotPasswordRetailerComponent } from './pages/retailer/auth/forgot-password-retailer/forgot-password-retailer.component';
import { OtpVerificationRetailerComponent } from './pages/retailer/auth/otp-verification-retailer/otp-verification-retailer.component';
import { ResetPasswordRetailerComponent } from './pages/retailer/auth/reset-password-retailer/reset-password-retailer.component';
import { BusinessDetailsRetailerComponent } from './pages/retailer/auth/business-details-retailer/business-details-retailer.component';
import { UploadDocumentRetailerComponent } from './pages/retailer/auth/upload-document-retailer/upload-document-retailer.component';
import { CustomerProductAddressComponent } from './pages/customer/customer-product-address/customer-product-address.component';
import { AddCustomerAddressComponent } from './pages/customer/add-customer-address/add-customer-address.component';
import { EditCustomerAddressComponent } from './pages/customer/edit-customer-address/edit-customer-address.component';
import { CustomerOrderOverviewComponent } from './pages/customer/customer-order-overview/customer-order-overview.component';
import { SuccesCustomerPaymentComponent } from './pages/customer/succes-customer-payment/succes-customer-payment.component';
import { CancelCustomerPaymentComponent } from './pages/customer/cancel-customer-payment/cancel-customer-payment.component';
import { ErrorCustomerPaymentComponent } from './pages/customer/error-customer-payment/error-customer-payment.component';
import { CustomerServiceListComponent } from './pages/customer/customer-service-list/customer-service-list.component';
import { ServiceOrderOtpVerificationComponent } from './pages/service-provider/service-order-otp-verification/service-order-otp-verification.component';
import { ServiceProviderRequestServicesComponent } from './pages/service-provider/service-provider-request-services/service-provider-request-services.component';
import { ServiceProviderRequestServicesViewComponent } from './pages/service-provider/service-provider-request-services-view/service-provider-request-services-view.component';
import { ServiceProviderPaymentFromCustomersComponent } from './pages/service-provider/service-provider-payment-from-customers/service-provider-payment-from-customers.component';
import { CustomerServiceAvailableComponent } from './pages/customer/customer-service-available/customer-service-available.component';
import { CustomerServiceAvailableAddComponent } from './pages/customer/customer-service-available-add/customer-service-available-add.component';
import { CustomerServiceAddQuantityComponent } from './pages/customer/customer-service-add-quantity/customer-service-add-quantity.component';
import { ServiceProviderDealsToCustomerComponent } from './pages/service-provider/service-provider-deals-to-customer/service-provider-deals-to-customer.component';
import { ServiceProviderAvailableDealsComponent } from './pages/service-provider/service-provider-available-deals/service-provider-available-deals.component';
import { ServiceProviderAddDealsComponent } from './pages/service-provider/service-provider-add-deals/service-provider-add-deals.component';
import { CustomerCategoryListComponent } from './pages/customer/customer-category-list/customer-category-list.component';
import { CustomerCategoryViewListComponent } from './pages/customer/customer-category-view-list/customer-category-view-list.component';
import { CustomerCategoryParticularViewComponent } from './pages/customer/customer-category-particular-view/customer-category-particular-view.component';
import { CustomerDealsOnProductListComponent } from './pages/customer/customer-deals/customer-deals-on-product-list/customer-deals-on-product-list.component';
import { ServiceProviderListDealsComponent } from './pages/service-provider/service-provider-list-deals/service-provider-list-deals.component';
import { CustomerProductListComponent } from './pages/customer/customer-product-list/customer-product-list.component';
import { CustomerDealsProductViewComponent } from './pages/customer/customer-deals/customer-deals-product-view/customer-deals-product-view.component';
import { CustomerDealsServiceListComponent } from './pages/customer/customer-deals/customer-deals-service-list/customer-deals-service-list.component';
import { CustomerDealsServiceViewComponent } from './pages/customer/customer-deals/customer-deals-service-view/customer-deals-service-view.component';
import { CustomerDealsServiceViewParticularComponent } from './pages/customer/customer-deals/customer-deals-service-view-particular/customer-deals-service-view-particular.component';
import { CustomerRequestListComponent } from './pages/customer/customer-deals/customer-request-list/customer-request-list.component';
import { HomepageRetailerComponent } from './pages/retailer/homepage-retailer/homepage-retailer.component';
import { ServiceProviderViewDealsComponent } from './pages/service-provider/service-provider-view-deals/service-provider-view-deals.component';
import { ServiceProviderEditDealsComponent } from './pages/service-provider/service-provider-edit-deals/service-provider-edit-deals.component';
import { OrderHistoryCustomerComponent } from './pages/customer/customer-history/order-history-customer/order-history-customer.component';
import { CustomerProductEnquiryComponent } from './pages/customer/customer-history/customer-product-enquiry/customer-product-enquiry.component';
import { OrderHistoryViewCustomerComponent } from './pages/customer/customer-history/order-history-view-customer/order-history-view-customer.component';
import { CustomerAddressListComponent } from './pages/customer/customer-history/customer-address-list/customer-address-list.component';
import { ReatilerOrderListComponent } from './pages/retailer/order-management-retailer/reatiler-order-list/reatiler-order-list.component';
import { ReatilerCategoryViewListComponent } from './pages/retailer/order-management-retailer/reatiler-category-view-list/reatiler-category-view-list.component';
import { RetailerOrderListViewComponent } from './pages/retailer/order-management-retailer/retailer-order-list-view/retailer-order-list-view.component';
import { RetailerOrderOtpVerificationComponent } from './pages/retailer/order-management-retailer/retailer-order-otp-verification/retailer-order-otp-verification.component';
import { RetailerProductListComponent } from './pages/retailer/product-management-retailer/retailer-product-list/retailer-product-list.component';
import { RetailerPaymentFromCustomerComponent } from './pages/retailer/order-management-retailer/retailer-payment-from-customer/retailer-payment-from-customer.component';
import { RetailerPaymentFromWholesalerComponent } from './pages/retailer/order-management-retailer/retailer-payment-from-wholesaler/retailer-payment-from-wholesaler.component';
import { RetailerResetPasswordOtpVerificationComponent } from './pages/retailer/auth/retailer-reset-password-otp-verification/retailer-reset-password-otp-verification.component';
import { RetailerAddProductComponent } from './pages/retailer/product-management-retailer/retailer-add-product/retailer-add-product.component';
import { RetailerEditProductComponent } from './pages/retailer/product-management-retailer/retailer-edit-product/retailer-edit-product.component';
import { RetailerViewProductComponent } from './pages/retailer/product-management-retailer/retailer-view-product/retailer-view-product.component';
import { RetailerDealListComponent } from './pages/retailer/deal-management-retailer/retailer-deal-list/retailer-deal-list.component';
import { RetailerAddDealsComponent } from './pages/retailer/deal-management-retailer/retailer-add-deals/retailer-add-deals.component';
import { RetailerEditDealsComponent } from './pages/retailer/deal-management-retailer/retailer-edit-deals/retailer-edit-deals.component';
import { RetailerViewDealsComponent } from './pages/retailer/deal-management-retailer/retailer-view-deals/retailer-view-deals.component';
import { EditBusinessDetailsRetailerComponent } from './pages/retailer/edit-business-details-retailer/edit-business-details-retailer.component';
import { EditUploadDocumentRetailerComponent } from './pages/retailer/edit-upload-document-retailer/edit-upload-document-retailer.component';
import { EditBusinessDetailServiceComponent } from './pages/service-provider/auth/edit-business-detail-service/edit-business-detail-service.component';
import { EditUploadDocumentServiceComponent } from './pages/service-provider/auth/edit-upload-document-service/edit-upload-document-service.component';
import { AuthGuard } from './guard/auth.guard';
import { BeforeLoginGuard } from './guard/before-login.guard';
import { BookingDataViewComponent } from './pages/customer/booking-data-view/booking-data-view.component';
import { PaymentLayoutComponent } from './components/payment-layout/payment-layout.component';

import {LoginComponent} from './auth/login-customer/login-customer.component';
import { SelectLoginCategoryComponent } from './pages/service-provider/select-login-category/select-login-category.component';
import { ProductReviewComponent } from './pages/service-provider/product-review/product-review.component';
import { SetFeeConfigurationComponent } from './pages/service-provider/set-fee-configuration/set-fee-configuration.component';
import { TransactionHistoryComponent } from './pages/sharing/transaction-history/transaction-history.component';
import { SelectServiceComponent } from './pages/service-provider/auth/select-service/select-service.component';
import { CustomerWalletComponent } from './pages/customer/customer-wallet/customer-wallet.component';
import { RequestedPriceRetailerComponent } from './pages/retailer/requested-price-retailer/requested-price-retailer.component';
import { RetailerRequestedPriceViewComponent } from './pages/retailer/retailer-requested-price-view/retailer-requested-price-view.component';
import { IntrestedPriceComponent } from './pages/sharing/intrested-price/intrested-price.component';
import { ServiceCampaignListComponent } from './pages/service-provider/service-campaign-list/service-campaign-list.component';



// import { PreloadAllModules } from '@angular/router';
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomepageComponent },

  //New Auth
  { path: 'login',component:LoginComponent},

  //auth 
  { path: 'signup-customer', component: SignupCustomerComponent },
  { path: 'login-customer', component: LoginCustomerComponent, canActivate: [BeforeLoginGuard] },// need to remove later
  { path: 'forgot-customer', component: ForgotCustomerComponent },
  { path: 'reset-customer', component: ResetCustomerComponent },
  { path: 'change-password-customer', component: ChangePasswordCustomerComponent },
  { path: 'otp-verification-customer', component: OtpVerificationCustomerComponent },

  { path: 'reset-otp-customer', component: OtpVerificationResetPasswordCustomerComponent },
  
  
  { path: 'login-service-provider', component: LoginComponent, canActivate: [BeforeLoginGuard] },// need to remove later
  { path: 'signUp-service-provider', component: SignupServiceProviderComponent },
  { path: 'forget-password-service-provider', component: ForgotServiceProviderComponent },
  { path: 'change-password-service-provider', component: ChangePasswordServiceProviderComponent },
  { path: 'otp-verification-service-provider', component: OtpVerificationServiceProviderComponent },
  { path: 'reset-password-service-provider', component: ResetServiceProviderComponent },

  { path: 'login-retailer', component: LoginComponent, canActivate: [BeforeLoginGuard] }, // need to remove later
  { path: 'signup-retailer', component: SignupRetailerComponent },
  { path: 'forgot-password-retailer', component: ForgotPasswordRetailerComponent },
  { path: 'otp-verification-retailer', component: OtpVerificationRetailerComponent },
  { path: 'reset-password-retailer', component: ResetPasswordRetailerComponent },


 // end Auth screen

  { path: 'business-detail-customer', component: BusinessDetailCustomerComponent },
  { path: 'upload-document-customer', component: UploadDocumentCustomerComponent },
  { path: 'product-view-customer', component: CustomerCategoryParticularViewComponent },
  { path: 'wishlist-customer', component: CustomerWishlistComponent, canActivate: [AuthGuard] },
  { path: 'addtoCart-customer', component: CustomerAddToCartComponent, canActivate: [AuthGuard] },
  { path: 'list-address-customer', component: CustomerAddressListComponent, canActivate: [AuthGuard] },
  { path: 'add-address-customer', component: AddCustomerAddressComponent, canActivate: [AuthGuard] },
  { path: 'edit-address-customer', component: EditCustomerAddressComponent, canActivate: [AuthGuard] },
  { path: 'order-overview-customer', component: CustomerOrderOverviewComponent, canActivate: [AuthGuard] },
  { path: 'search-product', component: SearchProductComponent },
  { path: 'customer-profile', component: CustomerProfileComponent },
  { path: 'edit-customer-profile', component: CustomerEditProfileComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'aboutUs', component: AboutusComponent },
  { path: 'contactUs', component: ContactusComponent },
  { path: 'terms-and-condition', component: TermsAndConditionComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'customer-payment-success', component: SuccesCustomerPaymentComponent },
  { path: 'customer-payment-cancel', component: CancelCustomerPaymentComponent },
  { path: 'customer-payment-error', component: ErrorCustomerPaymentComponent },
  { path: 'customer-service-list', component: CustomerServiceListComponent },
  { path: 'customer-service-available', component: CustomerServiceAvailableComponent },
  { path: 'customer-service-available-add', component: CustomerServiceAvailableAddComponent },
  { path: 'customer-service-quantity-add', component: CustomerServiceAddQuantityComponent },
  { path: 'customer-category-list', component: CustomerCategoryListComponent },
  { path: 'customer-category-list-view', component: ReatilerCategoryViewListComponent },
  { path: 'customer-category-particular-view', component: CustomerCategoryParticularViewComponent },
  { path: 'customer-product-deal-list', component: CustomerDealsOnProductListComponent },
  { path: 'customer-product-list', component: CustomerProductListComponent },
  { path: 'customer-product-view', component: CustomerDealsProductViewComponent },
  { path: 'customer-service-request-list', component: CustomerDealsProductViewComponent },
  { path: 'customer-service-deal-list', component: CustomerDealsServiceListComponent },
  { path: 'customer-service-deal-view', component: CustomerDealsServiceViewComponent },
  { path: 'customer-service-deal-view-particular', component: CustomerDealsServiceViewParticularComponent },
  { path: 'customer-service-request-list-table', component: CustomerRequestListComponent },
  { path: 'customer-order-history', component: OrderHistoryCustomerComponent, canActivate: [AuthGuard] },
  { path: 'customer-product-enquiry', component: CustomerProductEnquiryComponent, canActivate: [AuthGuard] },
  { path: 'customer-order-history-view', component: OrderHistoryViewCustomerComponent, canActivate: [AuthGuard] },
  { path: 'customer-address-list', component: CustomerAddressListComponent, canActivate: [AuthGuard] },

  // service provider routing

  { path: 'business-details-service-provider', component: BusinessDetailsServiceProviderComponent },
  { path: 'edit-business-detail-service', component: EditBusinessDetailServiceComponent },
  { path: 'edit-upload-document-service', component: EditUploadDocumentServiceComponent },
  { path: 'upload-document-service-provider', component: UploadDocumentServiceProviderComponent },
  { path: 'services', component: ServiceProviderServiceComponent },
  { path: 'services-provider', component: ServiceProviderServiceComponent },
  { path: 'service-order-detail', component: ServiceOrderDetailComponent },
  { path: 'assigned-service-pickup', component: ServiceOrderDetailComponent },
  { path: 'assigned-service-delivery', component: ServiceOrderDetailComponent },
  { path: 'assigned-service-field', component: ServiceOrderDetailComponent },
  { path: 'service-order-rejected-detail', component: ServiceOrderDetailComponent },
  { path: 'service-order-detail-view', component: ServiceOrderDetailViewComponent },
  { path: 'service-order-otp-verification', component: ServiceOrderOtpVerificationComponent },
  { path: 'service-provider-request-services', component: ServiceProviderRequestServicesComponent },
  { path: 'service-provider-request-services-view', component: ServiceProviderRequestServicesViewComponent },
  { path: 'service-provider-payment-from-customers', component: ServiceProviderPaymentFromCustomersComponent },
  { path: 'service-provider-deals-to-customer', component: ServiceProviderDealsToCustomerComponent },
  { path: 'service-provider-available-deals', component: ServiceProviderAvailableDealsComponent },
  { path: 'service-provider-add-deals', component: ServiceProviderAddDealsComponent },
  { path: 'service-provider-list-deals', component: ServiceProviderListDealsComponent },
  { path: 'service-provider-view-deals', component: ServiceProviderViewDealsComponent },
  { path: 'service-provider-edit-deals', component: ServiceProviderEditDealsComponent },
  { path: 'service-provider-select-role', component: SelectLoginCategoryComponent },
  { path: 'service-provider-update-role', component: SelectLoginCategoryComponent },
  { path: 'set-fee-pickup-partner', component: SetFeeConfigurationComponent },
  { path: 'update-fee-pickup-partner', component: SetFeeConfigurationComponent },
  { path: 'select-service', component: SelectServiceComponent },

  // retailer routing

  { path: 'business-details-retailer', component: BusinessDetailsRetailerComponent },
  { path: 'edit-business-form', component: EditBusinessDetailsRetailerComponent },
  
  { path: 'upload-document-retailer', component: UploadDocumentRetailerComponent },
  { path: 'edit-upload-document-retailer', component: EditUploadDocumentRetailerComponent },
  { path: 'retailer-order-history-view', component: RetailerOrderListViewComponent },
  { path: 'retailer-order-otp-verify', component: RetailerOrderOtpVerificationComponent },
  { path: 'retailer-home', component: HomepageComponent },
  { path: 'retailer-order-list', component: ReatilerOrderListComponent },
  { path: 'retailer-category-list-view', component: ReatilerCategoryViewListComponent },
  { path: 'retailer-payment-from-customer', component: RetailerPaymentFromCustomerComponent },
  { path: 'retailer-payment-from-wholesaler', component: RetailerPaymentFromWholesalerComponent },
  { path: 'reset-otp-verification', component: RetailerResetPasswordOtpVerificationComponent },
  { path: 'retailer-product-list', component: RetailerProductListComponent },
  { path: 'retailer-add-product', component: RetailerAddProductComponent },
  { path: 'retailer-edit-product', component: RetailerEditProductComponent },
  { path: 'retailer-view-product', component: RetailerViewProductComponent },
  { path: 'retailer-deal-list', component: RetailerDealListComponent },
  { path: 'retailer-add-deals', component: RetailerAddDealsComponent },
  { path: 'retailer-edit-deals', component: RetailerEditDealsComponent },
  { path: 'retailer-view-deals', component: RetailerViewDealsComponent },
  { path: 'customer-booking-details-view', component: BookingDataViewComponent },
  { path: 'payment-layout', component: PaymentLayoutComponent },
  { path: 'product-review', component: ProductReviewComponent },
  { path: 'transaction-history', component: TransactionHistoryComponent },
  { path: 'intrested-price', component: IntrestedPriceComponent },
  { path: 'customer-wallet', component: CustomerWalletComponent },
  {path:'campaign-list',component:RequestedPriceRetailerComponent},
  {path:'service-campaign-list' ,component:ServiceCampaignListComponent},
  {path:'retailer-requested-price-view' ,component:RetailerRequestedPriceViewComponent},
  { path: '**', component: HomepageComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
