<div class="container mb-5 wrapper-content">
  <app-linker-route
    [headingName]="headingName"
    [linkerRouteItems]="linkerRouteItems"
  ></app-linker-route>
  <div>
    <span class="dateset"
      >Ordered on {{ data?.createdAt | date : "mediumDate" }}
    </span>
    <span class="line"> </span>
    <span class="orderSet">Order {{ data?.orderId }}</span>
  </div>
  <div class="right-side" style="width: 100%">
    <div class="row mt-3">
      <div class="col-md-5">
        <div class="row">
          <div class="container">
            <p class="titlem">Product</p>
            <div class="mt-4" *ngFor="let item of data.serviceDetails">
              <div class="row">
                <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                  <div
                    class="bg-image hover-overlay hover-zoom ripple rounded"
                    data-ripple-color="light"
                  >
                    <img
                      src="{{
                        item?.serviceId?.userId?.profilePic ||
                          'assets/latest/pc.png'
                      }}"
                      class="productImageNew"
                      alt=""
                    />
                    <a>
                      <div
                        class="mask"
                        style="background-color: rgba(251, 251, 251, 0.2)"
                      ></div>
                    </a>
                  </div>
                </div>
                <div class="col-lg-9 col-md-12 mb-4 mb-lg-0">
                  <p style="margin: 1px;font-size: 18px;font-weight: 600;" class="product">
                    {{ item?.serviceId?.userId?.firstName  }}
                    {{ item?.serviceId?.userId?.lastName }}
                  </p>
                  <p style="margin: 1px" class="product">
                    {{ item?.serviceId?.categoryId?.categoryName }}
                  </p>
                  <p class="new-pr" style="margin: 1px">
                    {{ getPrice(item?.price | currency : "ZAR" : "R") }}
                  </p>
                  <p style="margin: 1px" class="product">
                    {{ item?.serviceId?.serviceName }}
                  </p>
                  <p style="margin: 1px" class="product">
                    {{ data?.duration | date : "dd-MM-yyyy" }}
                    {{ data?.slots[0] }}
                  </p>
                  <p style="margin: 1px" class="product">
                    Service Status: {{ data?.orderStatus }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="container">
            <p class="titlem">Booking Address</p>
            <div class="row">
              <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                <p
                  style="margin: 1px; margin-top: 5px"
                  class="text-sty text-del"
                >
                  {{ data?.shippingAddress?.firstName }}
                  {{ data?.shippingAddress?.lastName }}
                </p>
                <p
                  style="margin: 1px; margin-top: 3px"
                  class="text-sty text-del"
                >
                  {{ data?.shippingAddress?.email }}
                </p>
                <p
                  style="margin: 1px; margin-top: 3px"
                  class="text-sty text-del"
                >
                  <!-- {{ data?.shippingAddress?.address }} -->
                  {{ data?.shippingAddress?.addressLine1 }}
                  {{ data?.shippingAddress?.addressLine2 }},
                </p>

                <p
                  style="margin: 1px; margin-top: 3px"
                  class="text-sty text-del"
                >
                  {{ data?.shippingAddress?.city }},
                  {{ data?.shippingAddress?.state }},{{
                    data?.shippingAddress?.country
                  }},
                  {{ data?.shippingAddress?.zipCode }}
                </p>
                <div class="mt-1">
                  <span class="phoen"
                    >{{ data?.shippingAddress?.countryCode }}
                    {{ data?.shippingAddress?.mobileNumber }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <div class="container">
            <p class="titlemPay">Payment Details</p>
            <div class="">
              <div
                class="justify-content-between text-center"
                style="align-items: center; margin-left: 8px"
              >
                <ul>
                  <li>
                    <span>Subtotal </span>
                    <span>{{
                      data?.actualPrice &&
                        getPrice(data?.actualPrice | currency : "ZAR" : "R")
                    }}</span>
                  </li>
                  <li style="margin-top: 5px">
                    <span>VAT@15% </span>
                    <span>
                      {{
                        data?.actualPrice &&
                          getPrice(
                            (data?.actualPrice / 100) * 15
                              | currency : "ZAR" : "R"
                          )
                      }}</span
                    >
                  </li>
                  <li style="margin-top: 5px">
                    <span>Grand Amount </span>
                    <span>
                      {{
                        data?.actualPrice + (data?.actualPrice / 100) * 15 &&
                          getPrice(
                            data?.actualPrice + (data?.actualPrice / 100) * 15
                              | currency : "ZAR" : "R"
                          )
                      }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <service-order-tracker [orderDataDetials]="orderDataDetials"></service-order-tracker>
  <!-- <star-rating *ngIf="feedBackData" [feedBackData]="feedBackData" [isFeedbackDataSubmited]="isFeedbackDataSubmited" (submitFeedBack)="submitFeedBack($event)"></star-rating> -->

  <div
    class="ui-footer row justify-content-between mt-2 text-center"
    style="align-items: center"
  >
    <span class="m-auto">
      <button
        class="butCh"
        *ngIf="loggedInUserType == 'CUSTOMER'"
        routerLink="/home"
      >
        Continue Shopping
      </button>
      <button
        class="butCh"
        *ngIf="loggedInUserType == 'RETAILER'"
        routerLink="/retailer-home"
      >
        Continue Shopping
      </button>
    </span>
  </div>
</div>

<!-- <div class="container mb-5">
>>>>>>> a9cedbc93eee1498955042b42d94ba094d0d150e
    <span (click)="commonService.backLocation()" class="circle-arrow text-start">
        <img class="arrow-image-add-new" src="assets/svg/ar.svg" alt="">
    </span>
    <h1 class="text-center mt-3">Booking Detail</h1>
    <div class="right-side" style="width: 100%;">
        <div class="row mt-3">
            <div class="col-md-5">
                <div class="row">
                    <div class="container  card card-data">
                        <p>Booking Address:</p>
                        <div class="row">

                            <div class="col-lg-8 col-md-8 mb-4 mb-lg-0">
                                <p style="margin: 1px;margin-top: 5px;"><strong
                                        class="text-sty">{{data?.shippingAddress?.firstName}}
                                        {{data?.shippingAddress?.lastName}}
                                    </strong>
                                </p>
                                <p style="margin: 1px;margin-top: 5px;"><strong
                                        class="text-sty">{{data?.shippingAddress?.email}}

                                    </strong>
                                </p>
                                <p style="margin: 1px;margin-top: 5px;"><strong
                                        class="text-sty">{{data?.shippingAddress?.address}}
                                        {{data?.shippingAddress?.addressLine1}}
                                        {{data?.shippingAddress?.addressLine2}}, </strong>
                                </p>

                                <p style="margin: 1px;margin-top: 5px;"><strong
                                        class="text-sty">{{data?.shippingAddress?.city}},
                                        {{data?.shippingAddress?.state}},{{data?.shippingAddress?.country}},
                                        {{data?.shippingAddress?.zipCode}}</strong>
                                </p>
                                <div class="mt-1">
                                    <span><em class="fa fa-phone" aria-hidden="true" style="color: #c01e2f;"></em>
                                    </span> &nbsp;&nbsp; <span
                                        class="phoen">{{data?.shippingAddress?.countryCode}}
                                        {{data?.shippingAddress?.mobileNumber}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-7">
                <div class="right-side">
                    <h1 class=" right-side-text">Booking Id: {{data?.orderId}}</h1>
                    <div class=" mt-4 card card-data" *ngFor="let item of data.serviceDetails">
                        <div class="row">
                            <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                                <div class="bg-image hover-overlay hover-zoom ripple rounded" data-ripple-color="light">
                                    <img [src]="item?.serviceId?.userId?.profilePic" class="productImageNew" alt="" />
                                    <a>
                                        <div class="mask" style="background-color: rgba(251, 251, 251, 0.2)"></div>
                                    </a>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-6 mb-4 mb-lg-0">
   
                                <p style="margin: 1px;margin-top: 5px;">
                                    <strong>{{item?.serviceId?.userId?.firstName}} {{item?.serviceId?.userId?.lastName}}</strong>
                                </p>
                                <p style="margin: 1px;margin-top: 5px;">
                                    <strong>{{item?.serviceId?.categoryId?.categoryName}}</strong>
                                </p>
                                <p class="mb-0">{{item?.serviceId?.serviceName}}</p>
                                <p class="mb-1">
                                    {{data?.duration | date:'dd-MM-yyyy'}} {{data?.slots[0]}}
                                </p>
                                <p class="mb-1">Service Status:
                                    {{data?.orderStatus}}</p>
                               
                            </div>

                            <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                                <p class="text-right new-pr m-0">
                                    <strong> {{getPrice((item?.price) |
                                        currency:"ZAR":"R")}}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                   
                  
                    <div class="row justify-content-between mt-4 text-center" style="align-items: center;">
                        <h1 class="amount-details-text">Amount Details</h1>
                    </div>
                    <div class="row justify-content-between mt-4 text-center" style="align-items: center;">
                        <div class="totalamout">
                            <span class="amnt-total-details">Actual Amount</span>
                        </div>
                        <div class="totalamout">
                            <span class="total-price-sum">{{data?.actualPrice &&
                                getPrice(data?.actualPrice | currency:"ZAR":"R")}}</span>
                        </div>
                    </div>
                    <div class="row justify-content-between mt-1 text-center" style="align-items: center;">

                        <div class="totalamout">
                            <span class="amnt-total-details">Tax</span>
                        </div>
                        <div class="totalamout">
                            <span class="total-price-sum">{{data?.actualPrice &&
                                getPrice((data?.actualPrice/100 * 15) | currency:"ZAR":"R")}}</span>
                        </div>
                    </div>
                    <div class="row justify-content-between mt-1 text-center" style="align-items: center;">

                        <div class="totalamout">
                            <span class="amnt-total-details">Total Amount</span>
                        </div>
                        <div class="totalamout">
                            <span class="total-price-sum">{{data?.actualPrice +
                                (data?.actualPrice/100 * 15)&& getPrice((data?.actualPrice +
                                (data?.actualPrice/100 * 15)) | currency:"ZAR":"R")}}</span>
                        </div>
                    </div>
                    <div class="row justify-content-between mt-2 text-center" style="align-items: center;">
                        <span class="m-auto">
                            <button class="butCh" *ngIf="loggedInUserType == 'CUSTOMER'" routerLink="/home">Continue
                                Shopping</button>
                            <button class="butCh" *ngIf="loggedInUserType == 'RETAILER'"
                                routerLink="/retailer-home">Continue Shopping</button>
                        </span>
                    </div>
                </div>


            </div>
        </div>
    </div>


</div> -->
