import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
declare var $;
@Component({
  selector: 'app-customer-order-overview',
  templateUrl: './customer-order-overview.component.html',
  styleUrls: ['./customer-order-overview.component.scss'],
})
export class CustomerOrderOverviewComponent implements OnInit {
  _id: any;
  qtyA: any;
  serviceData: any;
  headingName: any = 'Order Review';
  linkerRouteItems: any = [
    { name: 'Home', route: '/home' },
    { name: 'Cart', route: '/addtoCart-customer' },
    { name: 'Address', route: '/list-address-customer' },
    { name: 'Order', route: '/order-overview-customer' },
  ];
  flag: boolean = true;
  idOfAddress: any;
  constructor(
    private activatedroute: ActivatedRoute,
    public commonService: CommonService,
    private router: Router,
    public sanatizer: DomSanitizer
  ) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.idOfAddress = res.idOfAddress;
    });
  }

  address: any;

  ngOnInit(): void {
    // this.getSimiliarProduct();
    this.getuserCartList();
    this.getProfile();
  }
  getSimiliarProduct() {
    let apiReqUrl: any = `product/productMightLike`;
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.serviceData = res.result.docs;
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }
  getPrice(e: any) {
    if (typeof e != 'string') {
      e = String(e);
    }
    let a = e.replace(/,/g, ' ');
    return a.replace(/R/g, 'R ');
  }
  cartListDetails: any = new Array(1);
  cartId: any;
  getID: any = [];
  payOutDetail: any;
  dropDownData: any = [];
  getuserCartList() {
    let apiReqUrl = `user/cartList`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.cartListDetails = res.result.cartList;
          this.payOutDetail = res.result.payOutAmount;
          const walletBalance = parseFloat(
            this.payOutDetail.walletAmount
          ).toFixed(2);
          this.cartListDetails.forEach((obj) => {
            this.cartId = obj._id;
            this.getID.push(this.cartId);
          });
          this.dropDownData = [
            {
              name: 'Proceed to Checkout',
              value: '',
            },
            {
              name: 'OZOW Payment',
              value: 'OZOW',
            },
            {
              name: 'Payfast Payment',
              value: 'PAYFAST',
            },
            {
              name: `Wallet (R ${walletBalance})`,
              value: 'WALLET',
            },
          ];

          this.commonService.hideSpinner();
          this.getTotalProductPrice();
        } else {
          this.cartListDetails = [];
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
          this.getTotalProductPrice();
        }
      },
      (err) => {
        this.cartListDetails = [];
      }
    );
  }
  itemDeleteModal(id) {
    this.itemSelectedId = id;
    $('#itemDeleteModal').modal('show');
  }
  itemSelectedId: any;
  removeCartList() {
    let url = `user/removeItemFromCart?_id=${this.itemSelectedId}`;
    this.commonService.showSpinner();
    this.commonService.deleteApi(url, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.getuserCartList();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }
  updateCartValue(id, prizeSize, avlQty) {
    if (avlQty == 0) {
      return this.commonService.infoToast('Quantity can not be 0.');
    }
    let apiReqUrl = `user/updateCartItem?_id=${id}`;
    let apiReqData = {
      quantity: avlQty,
      priceSizeDetails: {
        value: prizeSize.value,
        price: Number(prizeSize.price),
        unit: prizeSize.unit,
        id: prizeSize.id,
      },
    };

    this.commonService.showSpinner();
    this.commonService.putApi(apiReqUrl, apiReqData, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          // this.cartListDetails = res.result;
          // this.qtyA = this.cartListDetails.quantity
          this.commonService.successToast(res.responseMessage);
          this.commonService.hideSpinner();
          this.getuserCartList();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },
      (err) => {
        this.commonService.hideSpinner();
        return this.commonService.errorToast(err.responseMessage);
      }
    );
  }
  date: any = Date;

  getAddedDate(createdAt: string, days: number) {
    let date = new Date(createdAt).setDate(new Date(createdAt).getDate() + 7);
    return date;
  }
  subtotal: any = 0;
  total: any = 0;
  sum: any = 0;
  vatValue = 0;
  sumToatal = 0;
  totalPayAmount = 0;
  getTotalProductPrice() {
    if (this.cartListDetails.length) {
      this.sum = 0;
      this.total = 0;
      this.vatValue = 0;
      this.subtotal = 0;
      this.sumToatal = 0;
      this.totalPayAmount = 0;
      this.cartListDetails.forEach((element) => {
        // this.subtotal = this.subtotal + element.totalMrp * element.enteredQuantity
        this.total = element.totalPrice * element.quantity;
        this.sum += this.total;
        this.subtotal = this.sum;
        this.vatValue = 15 / 100;
        this.sumToatal = this.vatValue * this.sum;
        this.totalPayAmount = this.sumToatal + this.sum;
        // this.total = this.subtotal + this.shippingCharge
      });
    } else {
      this.sum = 0;
    }
    // this.commonService.totalItemsInShoppingBag.next(this.totalItems)
  }

  profileData: any = [];
  getProfile() {
    let url = `user/viewAddress?addressId=${this.idOfAddress}`;
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe(
      (res: any) => {
        if (res['responseCode'] == 200) {
          this.commonService.hideSpinner();
          this.profileData = res['result'];

          this.address = `${this.profileData.addressLine1}, ${this.profileData.addressLine2}, ${this.profileData.city}, ${this.profileData.state}, ${this.profileData.country}, ${this.profileData.countryCode} ${this.profileData.mobileNumber}`;
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res['responseMessage']);
        }
      },
      (err) => {
        this.commonService.hideSpinner();
        this.commonService.errorToast('Server error');
      }
    );
  }
  navigateToEdit(id) {
    this.router.navigate(['/edit-address-customer'], {
      queryParams: { id: id },
    });
  }
  buyOrderData: any;
  buyOrder(flag: Number) {
    let url = `order/buyProduct`;
    let data = {
      actualPrice: this.payOutDetail.totalAmount,
      orderPrice: this.payOutDetail.totalAmountWithVat,
      address: this.profileData._id,
      cartId: this.getID,
      deliveryFee: this.payOutDetail.deliveryFee,
      deliveryType: this.payOutDetail.deliveryMode,
      weight: this.cartListDetails[0].weight,
      volume: this.cartListDetails[0].volume,
    };
    this.flag = false;
    // alert(flag)
    this.commonService.postApi(url, data, 1).subscribe(
      (res: any) => {
        if (res['responseCode'] == 200) {
          this.buyOrderData = res['result'];
          localStorage.setItem('paymentList', 'product');
          if (flag == 1) {
            this.ozhoPayment();
          } else if (flag == 2) {
            this.walletPayment();
          } else {
            this.payfastPayment();
          }
        } else {
          this.flag = true;
          this.commonService.errorToast(res['responseMessage']);
        }
      },
      (err) => {
        this.flag = true;
        this.commonService.errorToast('Server error');
      }
    );
  }
  OzhoUrl: any;
  ozhoPayment() {
    let url = `order/ozowCheckOut?orderId=${this.buyOrderData._id}`;
    let data = {
      webFailure:
        this.commonService.webSiteUrl +
        'list-address-customer?paymentStatus=cancel',
      webError:
        this.commonService.webSiteUrl +
        'list-address-customer?paymentStatus=error',
      webSuccess:
        this.commonService.webSiteUrl +
        'list-address-customer?paymentStatus=success',
      cancelUrl: this.commonService.ozhoBaseUrl,
      errorUrl: this.commonService.ozhoBaseUrl,
      SuccessUrl: this.commonService.ozhoBaseUrl,
      testMode: true,
      trxFrom: 'WEB',
    };
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe(
      (res: any) => {
        if (res['responseCode'] == 200) {
          this.commonService.hideSpinner();
          this.OzhoUrl = res.result;

          this.ozhoRedirect();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res['responseMessage']);
        }
      },
      (err) => {
        this.commonService.hideSpinner();
        this.commonService.errorToast('Server error');
      }
    );
  }
  walletPayment() {
    if (this.payOutDetail.walletAmount < this.payOutDetail.totalAmountWithVat) {
      this.flag = true;
      this.commonService.errorToast('Insufficient Balance');
      return;
    }

    let url = `order/checkOutWalletOrder?orderId=${this.buyOrderData._id}`;
    let data = {
      webFailure: this.commonService.webSiteUrl + 'customer-payment-cancel',
      webError: this.commonService.webSiteUrl + 'customer-payment-error',
      webSuccess: this.commonService.webSiteUrl + 'customer-payment-success',
      cancelUrl: this.commonService.ozhoBaseUrl,
      errorUrl: this.commonService.ozhoBaseUrl,
      SuccessUrl: this.commonService.ozhoBaseUrl,
      testMode: true,
      trxFrom: 'WEB',
    };
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe(
      (res: any) => {
        if (res['responseCode'] == 200) {
          this.commonService.hideSpinner();
          this.OzhoUrl = res.result;
          this.router.navigate(['/customer-payment-success']);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res['responseMessage']);
        }
      },
      (err) => {
        this.commonService.hideSpinner();
        this.commonService.errorToast('Server error');
      }
    );
  }
  htmlData: any;
  payfastPayment() {
    console.log('before');
    let url = `payment/checkoutPayfast?orderId=${this.buyOrderData._id}`;
    let data = {
      webFailure: this.commonService.webSiteUrl + 'customer-payment-cancel',
      webError: this.commonService.webSiteUrl + 'customer-payment-error',
      webSuccess: this.commonService.webSiteUrl + 'customer-payment-success',
      cancelUrl: this.commonService.ozhoBaseUrl,
      errorUrl: this.commonService.ozhoBaseUrl,
      SuccessUrl: this.commonService.ozhoBaseUrl,
      testMode: true,
      trxFrom: 'WEB',
    };
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, true).subscribe(
      (res: any) => {
        console.log(res);
        console.log('asaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');

        if (res['responseCode'] == 200) {
          this.commonService.hideSpinner();
          this.htmlData = this.sanatizer.bypassSecurityTrustHtml(
            res.result.file
          );
          console.log('after');
          console.log(res);
          // debugger;
          // this.ozhoRedirect()
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res['responseMessage']);
        }
      },
      (err) => {
        console.log(err);
        this.commonService.hideSpinner();
        this.commonService.errorToast('Server error');
      }
    );
  }
  ozhoRedirect() {
    window.location.href = this.OzhoUrl;
  }
  paymentMethod: any;
  getPayamnetMethod(e) {
    this.paymentMethod = e;
  }
  onContinuue() {
    if (this.paymentMethod == 'pay-fast') {
      this.buyOrder(1);
    } else if (this.paymentMethod == 'wallet') {
      this.buyOrder(2);
    } else {
      this.buyOrder(3);
    }
  }
  openChoosePaymentModal() {
    $('#choosePaymentModal').modal('show');
  }

  getDeliveryName(e) {
    let deliveryOption =
      e == 'SUNDAY'
        ? 'Sunday'
        : e == 'SAMEDAY'
        ? 'Sameday'
        : e == 'BUSINESSDAY'
        ? 'Next business day'
        : e == 'SATURDAY'
        ? 'Saturday'
        : 'Standard';

    return deliveryOption;
  }
  back() {
    this.commonService.backLocation();
  }
  selectPayment(e) {
    if (e == 'OZOW') {
      this.buyOrder(1);
    } else if (e == 'WALLET') {
      this.buyOrder(2);
    } else {
      this.buyOrder(3);
    }
  }
}
