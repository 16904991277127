import { CommonService } from './../../../../provide/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-service-provider',
  templateUrl: './forgot-service-provider.component.html',
  styleUrls: ['./forgot-service-provider.component.scss']
})
export class ForgotServiceProviderComponent implements OnInit {

  forgotPassword!: FormGroup;

  userType: any = 'SERVICE_PROVIDER';
flag= false;

  constructor(private fb: FormBuilder, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.forgot();
  }

  forgot() {
    this.forgotPassword = this.fb.group({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
    })
  }



  add() {
    var data = {
      email: this.forgotPassword.value.email,
      userType: this.userType
    }
    localStorage.setItem('email', data['email'])
  this.flag = true
    this.commonService.postApi('user/forgotPassword', data, 0).subscribe((res: any) => {
      
      this.commonService.hideSpinner()
      if (res['responseCode'] == 200) {
        localStorage.setItem('email', this.forgotPassword.value.email)
        this.flag = false
        this.commonService.successToast(res['responseMessage'])
        // set current url to localstorage 
        this.router.navigate(['/otp-verification-service-provider']);
      } else {
        this.flag = false
        this.commonService.errorToast(res['responseMessage'])
      }
    }, err => {
      this.flag = false
      if (err['responseCode'] == 404) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err['responseMessage'])
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err['responseMessage'])
      }
    })
  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

}
