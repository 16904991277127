import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-requested-price-retailer',
  templateUrl: './requested-price-retailer.component.html',
  styleUrls: ['./requested-price-retailer.component.scss'],
})
export class RequestedPriceRetailerComponent implements OnInit {
  headingName: any = 'Campaign Issued';
  linkerRouteItems: any = [
    { name: 'Home', route: '/home' },
    { name: 'Campaign Issued', route: '/campaign-list' },
  ];
  count: any;
  catName: any;
  currentPage: any = 1;
  pageSize: any = 10;
  isLoading: boolean = false;
  notFoundData: boolean = false;
  campaign: string = 'PRODUCT';
  constructor(public commonService: CommonService, public router: Router) {}

  ngOnInit(): void {
    this.campaignOrderList('PRODUCT');
  }

  campaignList: any = [];
  campaignOrderList(e) {
    this.isLoading = true;
    this.campaignList = [];
    this.count = 0;
    const data = {
      page: String(this.currentPage),
      limit: String(this.pageSize),
      campaignOn: 'PRODUCT',
    };
    // const baseUrl="http://172.16.6.130:3030/api/v1/campaign/listCampaign?"
    const params = `?page=${data.page}&limit=${data.limit}&campaignOn=${data.campaignOn}`;
    // const apiUrl=baseUrl + params
    // console.log(apiUrl,"ssssssss")
    this.commonService.getApi('campaign/listCampaign' + params, 1).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res['responseCode'] == 200 && res.result.docs.length > 0) {
          this.commonService.hideSpinner();
          this.campaignList = res.result.docs;
          console.log(this.campaignList, 'campaign');
          this.count = res.result.total;
          if (this.campaignList.length > 0) {
            this.notFoundData = false;
          } else {
            this.notFoundData = true;
          }
        } else {
          this.commonService.hideSpinner();
          this.notFoundData = true;
          this.commonService.errorToast(res.responseMessage);
        }
      },
      (err: any) => {
        this.notFoundData = true;
        this.isLoading = false;
        if (err.responseCode == 409) {
          this.commonService.hideSpinner();
          this.commonService.errorToast(err.responseMessage);
        }
      }
    );
  }

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ');
    return a.replace(/R/g, 'R ');
  }
  changePageNumber(page) {
    this.currentPage = page;
    this.campaignOrderList(this.campaign);
  }

  tabChange(e) {
    this.currentPage = 1;
    this.campaignOrderList(this.campaign);
  }
}
