import { flatten } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-customer-service-available',
  templateUrl: './customer-service-available.component.html',
  styleUrls: ['./customer-service-available.component.scss']
})
export class CustomerServiceAvailableComponent implements OnInit {
  _id: any
  currentPage = 1;
  pageSize = 12;
  serviceName: any
  latitude: any
  longitude: any
  catName: any
  headingName:any ='BOOKING PROVIDERS'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Booking',route:'/customer-service-list'},
    {name:'Booking Providers',route:'/customer-service-list'}

  ]
  desc:any
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.catName = res.catName
      this.desc = res.desc
    })
  }

  ngOnInit(): void {
    // this.getProfile()
    navigator.geolocation.getCurrentPosition(function(position) {  
      // alert('allow');  
  }, function() {
      alert('You need to enable geolocation services in your browser to use this feature.');
  });
  this.getPosition().then(pos=>
    
    {
    localStorage.setItem('location',JSON.stringify(pos))
  });
  this.getLocation();
  
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getServiceList()
        }
      },
        (error) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }


  userId: any;
  productDetails: any = [];
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any
  totalItems: any
  isLoading: boolean = false;
  // get retailer detail by id
  getServiceList(id?) {
    let apiReqUrl: any = `v2/service/listServiceProviderNearMe`
    let apiReqData = {
      categoryId: this._id,
      search: this.serviceName,
      page: this.currentPage,
      limit: this.pageSize,
      lat: this.latitude,
      lng: this.longitude,
      subCategoryId : id ? id :'',
    }
    this.commonService.removeEmptyKey(apiReqData)
    this.productDetails = []
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    // this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.productDetails = res.result.docs;
        this.totalItems = res.result.total
        if (!this.productDetails.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        this.commonService.hideSpinner();
      } else {
        this.isLoading = true;
        this.productDetails = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.isLoading = true;
      this.productDetails = []
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }

  navigateToViewProduct(_id) {
    this.router.navigate(['/product-view-customer'], { queryParams: { _id: _id } })
  }
  pagination(event) {
    this.currentPage = event;
    this.getServiceList();
  }
  profileData: any
  getProfile() {
    // this.commonService.showSpinner();
    this.commonService.getApi("user/myProfile", 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.profileData = res["result"]['storeLocation']['coordinates'];
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
  navigateToserviceAvailableAdd(id) {
    this.router.navigate(['/customer-service-available-add'], { queryParams: { categoryId: this._id, userId: id, categoryName: this.catName } })
  }
  length(n){
    return new Array(n)
  }
}
