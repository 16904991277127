<auth-layout title="Reset Password" subTitile="You can reset your password here." sideImage ="assets/latest/forgot.svg">


    <form [formGroup]="resetPassword">
        <div class="form-group">
            <label for="username">New Password<span class="redis">*</span></label>
            <div class="input-control">
                <input  class="form-control" placeholder="Enter your new password"
                    [type]="showPassword?'password':'text'" maxlength="60" (keypress)="preventSpace($event)"
                    onselectstart="return false;" onpaste="return false;" oncopy="return false;" oncut="return false"
                    ondrag="return false" ondrop="return false" autocomplete="off" formControlName="password">
                    <!-- <i class="eye-icon-posistion" [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"
                    (click)="changePasswordIcon()"></i> -->


                <span class="eyeicon" (click)="changetype = !changetype">
                    <!-- <img src="assets/latest/pass.svg" alt=""> -->
                    <!-- <img src="assets/latest/eye2.svg" alt="" *ngIf="!changetype">
                    <img src="assets/latest/eye1.svg" alt="" *ngIf="changetype"> -->
                    <i class="eye-icon-posistion" [ngClass]="changetype ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"
                    ></i>
                </span>
            </div>
            <div class="for-validation mb-4">
                <div class="for-validation text-danger"
                    *ngIf="resetPassword.get('password')?.hasError('required') && resetPassword.get('password')?.touched">
                    *Password is
                    required.
                </div>

            </div>
        </div>
        <div class="form-group">
            <label for="username">Confirm Password<span class="redis">*</span></label>
            <div class="input-control">
                <input [type]="changetypes?'password':'text'" placeholder="Enter your confirm password"
                    class="form-control" id="password" maxlength="60" (keypress)="preventSpace($event)"
                    onselectstart="return false;" onpaste="return false;" oncopy="return false;" oncut="return false"
                    ondrag="return false" ondrop="return false" autocomplete="off" formControlName="confirmPassword">
                <span class="eyeicon" (click)="changetypes = !changetypes">
                    <!-- <img src="assets/latest/pass.svg" alt=""> -->
                    <!-- <img src="assets/latest/eye2.svg" alt="" *ngIf="!changetypes">
                    <img src="assets/latest/eye1.svg" alt="" *ngIf="changetypes"> -->
                    <i class="eye-icon-posistion" [ngClass]="changetypes ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"
                    ></i>
                </span>
            </div>
            <div class="for-validation  mb-3 ">
                <div class="for-validation  text-danger"
                    *ngIf="resetPassword.get ('confirmPassword')?.hasError('required') && resetPassword.get('confirmPassword')?.dirty">
                    *Confirm Password is required.</div>


                <div class="for-validation  text-danger"
                    *ngIf="resetPassword.get('password')?.value !=resetPassword.get('confirmPassword')?.value && resetPassword.get('confirmPassword')?.dirty && resetPassword.get('confirmPassword')?.value">
                    *password and confirm password must be match.
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between" style="align-items: center;">
            <div class="left-wrap">
                <button class="for-login btn text-white btn-block btn-primary"  (click)="navigateToResetPassword()"
                    [disabled]="!resetPassword.valid" type="submit">Reset Password</button>
            </div>


        </div>
    </form>


</auth-layout>