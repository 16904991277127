import { CommonService } from 'src/app/provide/common.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
declare const gapi: any; 
@Component({
  selector: 'app-login-service-provider',
  templateUrl: './login-service-provider.component.html',
  styleUrls: ['./login-service-provider.component.scss']
})
export class LoginServiceProviderComponent implements OnInit {

  loginForm!: FormGroup;
  user: any;

  flag = false

  // foricon eye
  visible: boolean = false;
  changetype: boolean = true;
  route: any;
  userType: string;
  serviceRole: string;


  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }

  signupTitle  = "Signup As Pickup Driver"

  constructor(private fb: FormBuilder, private router: Router, private http: HttpClient, public commonService: CommonService, public authService: AuthService) { 

    this.serviceRole = localStorage.getItem('serviceRole')
    if(this.serviceRole == "PICKUP"){
      this.signupTitle  = "Pickup Partner Login"
      this.userType = "PICKUP_PARTNER"
    }
    else if(this.serviceRole == "DELIVERY"){
      
      this.signupTitle  = "Delivery Partner Login"
      this.userType = "DELIVERY_PARTNER"
    }
    else if(this.serviceRole == "FIELD"){
      this.signupTitle  = "Fulfilment Login"
      this.userType = "FIELD_ENTITY"
    }
    else{
      this.signupTitle  = "Service Provider Login"
      this.userType = "SERVICE_PROVIDER"
    }
  }

  ngOnInit(): void {


    window.scrollTo(0, 0);

    this.loginForm = this.fb.group({
      'email': new FormControl('', [
        Validators.required,
        // Validators.pattern('[a-z.-]{1,}@[a-z.-]{2,}[.]{1}[a-z]{2,5}')
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]
      ),
      'password': new FormControl('', [
        Validators.required,
        // Validators.pattern("(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$"),
      ]
      ),

      checkbox: new FormControl(false)

    })


    // remember me
    let checkbox = localStorage.getItem('serviceLogin') ? JSON.parse(localStorage.getItem('serviceLogin')!) : '';
    if (checkbox) {
      this.loginForm.patchValue({
        "email": atob(checkbox.email),
        "password": atob(checkbox.password),
        "checkbox": checkbox.checkbox
      })
    }

  }




  // ================================================



  /**
    * login api
    */
  login() {
    let apiReqData = {
      userType: this.userType,
      emailNumberId: this.loginForm.value.email,
      password: this.loginForm.value.password,
    }
 this.flag = true
    this.commonService.postApi('user/usersLogin', apiReqData, 0).subscribe((res: any) => {
      
      if (res['responseCode'] == 200) {
        this.flag = false
        this.commonService.hideSpinner()
        // localStorage.setItem('token', res.result.token);
        localStorage.setItem('userType', this.userType);
        localStorage.setItem('userId', res.result._id);
        localStorage.setItem('email', this.loginForm.value.email)
        localStorage.setItem('completeProfile', res.result.completeProfile)
        this.authService.SharingData.next(res.result.completeProfile);
        // this.authService.isLoggedIn.next(true);
        this.authService.loggedInUserType.next(this.userType);
        localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
        this.authService.userRequestStatus.next(res.result.userRequestStatus);

        if (res.result.completeProfile && res.result.userRequestStatus == 'APPROVED') {
          localStorage.setItem('token', res['result']['token'])
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          localStorage.setItem('myId', res['result']['_id'])
          this.router.navigate(['/service-order-detail'])
          this.authService.isLoggedIn.next(true);
        }
        else if (!res.result.completeProfile && res.result.userRequestStatus ==
          
          'PENDING' && res.result.flag == 0) {
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          localStorage.setItem('token-auth', res['result']['token'])
          this.router.navigate(['/business-details-service-provider']);
        }
        else if (!res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 1) {
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          localStorage.setItem('token-auth', res['result']['token'])
          this.router.navigate(['/service-provider-select-role']);
        
        }
        else if (res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 3) {
          this.commonService.errorToast('Thanks For Connecting With Us. We will verify your account and will send your approval soon.')
        
        }
       
        else if (!res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 2) {
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          // localStorage.setItem('token', res['result']['token'])
          localStorage.setItem('token-auth', res['result']['token'])
          this.router.navigate(['/upload-document-service-provider']);
        }
   
        else if (res.result.completeProfile && res.result.userRequestStatus == 'REJECTED') {
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          // localStorage.setItem('token', res['result']['token'])
          localStorage.setItem('token-auth', res['result']['token'])
          this.router.navigate(['/upload-document-service-provider']);
          if (res.responseCode == 200) {
            this.commonService.errorToast(res.result.rejectReason)
          } else {
            this.commonService.errorToast('Your application has been rejected. Plesae complete the application and then proceed')
          }
        }
        else if (!res.result.completeProfile && res.result.userRequestStatus == 'REJECTED') {
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          // localStorage.setItem('token', res['result']['token'])
          localStorage.setItem('token-auth', res['result']['token'])
          this.router.navigate(['/business-details-service-provider']);
          if (res.responseCode == 200) {
            this.commonService.errorToast(res.result.rejectReason)
          } else {
            this.commonService.errorToast('Your application has been rejected. Plesae complete the application and then proceed')
          }
        }
        
        if (this.loginForm.value.checkbox == true) {
          let remData = {
            "email": btoa(this.loginForm.value.email),
            "password": btoa(this.loginForm.value.password),
            "checkbox": this.loginForm.value.checkbox
          }
          localStorage.setItem('serviceLogin', JSON.stringify(remData))


        } else {
          localStorage.removeItem('serviceLogin')
        }
       

      } else {
        this.flag = false
        this.commonService.errorToast(res['responseMessage'])
      }
    }, (err: any) => {
      this.flag = false
      if (err.responseCode == 402) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
      else if (err.responseCode == 404) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }





  preventSpace(event: any) {
    if ((event.charCode == 32) && !event.target.value) {
      event.preventDefault();
    }
  }
  changePasswordIcon(){
    this.changetype=!this.changetype
  }
  signInWithGoogle() {
    gapi.auth2.getAuthInstance().signIn();
  }
}
