<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-4 mt-4 wrapper-content">
    <!-- <div class="d-flex mt-1" >
        <div class="radio-group">
            <input type="radio"  name="del"  id="Product" (change)="tabChange('PRODUCT')"  [(ngModel)]="campaign" value="PRODUCT">
            <label for="Product" class="ml-2">Product</label>
        </div>
        <div class="radio-group">
            <input type="radio"  name="del"  id="Services" (change)="tabChange('SERVICE')"   [(ngModel)]="campaign" value="SERVICE">
            <label for="Services" class="ml-2"> Services</label>
        </div>
        
    </div> -->
    <div class="row mt-3">
        <!-- <div class="col-12 table-responsive" *ngIf="campaign == 'SERVICE' " >
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Product Image</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Unit/Size</th>
                        <th scope="col">Campaign Price</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Start Date & Time </th>
                        <th scope="col">End Date & Time</th>
                  
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of campaignList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                        <td scope="row">{{10 * (currentPage - 1) + i+1}}</td>
                        <td scope="row">
                            <img class="imagtag" src="{{item?.productId?.thumbnail}}"
                                 alt="">
                        </td>
                        <td>
                            {{item?.productId?.productName}}
                        </td>
                        <td> {{ item?.campaignDetail[0]?.value }} {{ item?.campaignDetail[0]?.unit !== 'other' ? '' + item?.campaignDetail[0]?.unit : '' }}</td>
                        <td>{{item?.campaignDetail[0]?.discountedPrice ? getPrice(item?.campaignDetail[0]?.discountedPrice | currency:"ZAR":"R") : item?.campaignDetail[0]?.discountedPrice}}</td>
                        <td>{{item?.campaignDetail[0]?.releaseQuantity}}</td>
                        <td>{{item?.createdAt | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        <td>{{item?.endDate | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        
                   
                    </tr>
                </tbody>
            </table>
            <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'80px'" ></app-table-loader>
            <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%;"
                (pageChange)="changePageNumber($event)"></pagination-controls>
        </div> -->
        <div class="table-responsive" >
            <table class="table table-hover table-striped" aria-describedby="">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Service Image</th>
                        <th scope="col">Service Category</th>
                        <th scope="col">Service Name</th>
                        <th scope="col">Campaign Price</th>
                        <th scope="col">Start Date </th>
                        <th scope="col">End Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of campaignList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                        <td scope="row">{{10 * (currentPage - 1) + i+1}}</td>
                        <td scope="row">
                            <img class="imagtag" src="{{item?.serviceId?.userId?.profilePic}}"
                                 alt="">
                        </td>
                        <td>
                            {{item?.serviceId?.categoryId?.categoryName}}
                        </td>
                        <td> {{ item?.serviceId?.serviceName }}</td>
                        <td>{{item?.serviceDiscountedPrice ? getPrice(item?.serviceDiscountedPrice | currency:"ZAR":"R") : item?.serviceDiscountedPrice}}</td>
                        <td>{{item?.createdAt | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        <td>{{item?.endDate | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        
                   
                    </tr>
                </tbody>
            </table>
            <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'80px'" ></app-table-loader>
            <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%;"
                (pageChange)="changePageNumber($event)"></pagination-controls>
        </div>
    </div>



</div>