<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
  </div>
<div class="container">
<body>
  <table>
    <thead>
      <tr>
        <th>Sr</th>
        <th>Customer Name</th>
        <th>Order ID</th>
        <th>Date & Time</th>
        <th>Total Receive Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>John Doe</td>
        <td>12345</td>
        <td >2023-07-15 10:30 AM</td>
        <td style="color: #0BB412;">$100.00</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Jane Smith</td>
        <td>54321</td>
        <td >2023-07-16 02:45 PM</td>
        <td style="color: #0BB412;">$75.50</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Michael Johnson</td>
        <td>98765</td>
        <td >2023-07-16 09:15 AM</td>
        <td style="color: #0BB412;">$250.20</td>
      </tr>
      <tr>
        <td>4</td>
        <td>Sarah Williams</td>
        <td>67890</td>
        <td >2023-07-17 11:00 AM</td>
        <td style="color: #0BB412;">$50.75</td>
      </tr>
      <tr>
        <td>5</td>
        <td>Robert Davis</td>
        <td>13579</td>
        <td >2023-07-17 03:30 PM</td>
        <td style="color: #0BB412;">$150.90</td>
      </tr>
    </tbody>
  </table>
</body>
</div>
