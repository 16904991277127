import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-detail-customer',
  templateUrl: './business-detail-customer.component.html',
  styleUrls: ['./business-detail-customer.component.scss']
})
export class BusinessDetailCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
