import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-customer-product-enquiry',
  templateUrl: './customer-product-enquiry.component.html',
  styleUrls: ['./customer-product-enquiry.component.scss']
})
export class CustomerProductEnquiryComponent implements OnInit {
  headingName:any ='Product Enquiry'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Product Enquiry',route:'/customer-product-enquiry'}
  ]
  helpForm: FormGroup;
  profileData: any = []
  status: boolean = false
  email: string
  name: string
  emailCall:any
  loggedInUserType: any;

  constructor(private router: Router, public dialog: MatDialog,public authService:AuthService, public service: CommonService) { }

  ngOnInit(): void {
    this.helpFormValidation()
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res

    })
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn()
    this.emailCall = localStorage.getItem('email')
  }
  helpFormValidation() {
    this.helpForm = new FormGroup({
      // name: new FormControl("", [
      //   Validators.required,

      // ]),
      // email: new FormControl("", [
      //   Validators.required,
      //   Validators.pattern(
      //     /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/
      //   )
      // ]),
      subject: new FormControl("", [
        Validators.required,
      ]),

      description: new FormControl("", [
        Validators.required
      ])
    });

  }

  helpApi() {
    let url = `user/productInquiry`
    let data = {
      subject : this.helpForm.value.subject,
      description :this.helpForm.value.description,
      email : this.emailCall
    }
    this.service.showSpinner()
    this.service.putApi(url, data, 1).subscribe((res) => {
      if (res["responseCode"] == 200) {
        this.service.hideSpinner()
        this.service.successToastr("Thank You for contact. Our team will contact you shortly")

        // if (this.service.isLoggedIn()) {

        //   this.helpForm.patchValue({
        //     subject: "",
        //     description: ""
        //   })

        // }

        // else {
          this.helpForm.reset()
        // }
        this.router.navigate(['/homepage'])
      }
      else {
        this.service.hideSpinner()
        // this.service.successToast(res["responseMessage"])
      }
    })
  }
  navigateToTermsOfServices() {
    this.router.navigate(['/terms-of-service']),
      this.dialog.closeAll()
  }
  cancel(){
    this.router.navigate(['/homepage'])
  }
}
