<div class="container fw-b">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-5 wrapper-content">
  <div class="row justify-content-center bookingsearchBox">
    <div class="form-group has-search">
      <span class="fa fa-search form-control-feedback"></span>
      <input type="text" class="form-control" [(ngModel)]="serviceName" placeholder="Search by booking category"
        (input)="getServiceList(); currentPage = 1" />
    </div>
  </div>
  <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3">
    <ng-container *ngIf="productDetails?.length">
      <div class="col newCardWidth newCardWidth1 mb-3" *ngFor="
      let item of productDetails
        | paginate
          : {
              itemsPerPage: pageSize,
              currentPage: currentPage,
              totalItems: totalItems
            };
      let i = index
    ">
        <div class="innerDiv" (click)="
        navigateToViewProduct(
          item?._id,
          item?.categoryName,
          item?.description
        )
      ">
          <category-layout-home [data]="item" [catName]="item?.categoryName"></category-layout-home>
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="!productDetails?.length && !isLoading">
      <div class="col newCardWidth newCardWidth1 mb-3" *ngFor="
      let item of length(10)">
        <div class="innerDiv">
          <category-layout-home [data]="item" [catName]="item?.categoryName"></category-layout-home>
        </div>
      </div>
    </ng-container>

    <div *ngIf="isLoading" class="no-data">
      <p style="text-align: center; font-size: 16px; font-weight: 500">
        No Data Found
      </p>
    </div>
  </div>
  <div class="custom-pagination mt-2 text-align-right" style="text-align: right" *ngIf="totalItems > 12">
    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
  </div>
</div>