import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss']
})
export class SearchProductComponent implements OnInit {
  @HostListener('window:scroll', ['$event'])
  loadAtFist: boolean = false;

  activeButton = 'product_wholesale'

  panelOpenState = false;

  categoryListArray: any = [];
  itemPerPage = 20;
  currentPage = 1;
  totalItems: any;
  categoryId: any;
  images: any;
  subCategoryListArray: any = [];
  productBySubCategoryArray: any = [];
  subCategoryId: any;


  // parentElement: any;
  // classList: any;





  _id: any



  productIds:any

  constructor(private activatedroute: ActivatedRoute, private _formBuilder: FormBuilder, private router: Router, public commonService: CommonService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })
if (this.commonService.subsVar == undefined) {
  this.commonService.subsVar =
    this.commonService.invokeFirstComponentFunction.subscribe((res) => {
    });
}

  }

  // ===========================

  ngOnInit(): void {
    window.scrollTo(0, 0);
    // this.getCategoryList();
    this.searchCategorybyHome()
    this.getCategoryLists();

    this.getBannerProductList();
    //  this.getSubCategoryList(event);
    // this.productBySubCategory(event);
   

  }

  showActivebutton(title: any) {
    this.activeButton = title
  }

  tab:any
  select(tab:any){
   this.tab = tab
  }
  categoryListArrayNew: any

  datalist:any
  // -------------------- get category list --------------------- //
  // -------------------- get category list --------------------- //
  getCategoryList() {
    let apiReqUrl: any = `product/productOfOwn`
    this.commonService.showSpinner();
    let apiReqData: any = {
      page: String(this.currentPage),
      limit: String(this.itemPerPage),

    }
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.categoryListArrayNew = res.result.docs ? res.result.docs : '';
        this.datalist = res.result.docs[0].thumbnail
        this.totalItems = res.result.total
        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);

      } else {
        this.categoryListArrayNew = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.categoryListArrayNew = []
        this.totalItems = 0
        // this.commonService.errorToast(err.responseMessage);

      }else{
      this.router.navigate(['/login'])

      }
    }  )
  }
  searchCategory(id:any) {
    let apiReqUrl: any = `product/productOfOwn`
    this.commonService.showSpinner();
    let apiReqData: any = {
      page: String(this.currentPage),
      limit: String(this.itemPerPage),
      categoryId: id

    }
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.categoryListArrayNew = res.result.docs ? res.result.docs : '';
        this.datalist = res.result.docs[0].thumbnail

        this.totalItems = res.result.total
        
        
        
        // thumbnail

        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);

      } else {
        this.categoryListArrayNew = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.categoryListArrayNew = []
        this.totalItems = 0
        // this.router.navigate(['/login'])
        // this.commonService.errorToast(err.responseMessage);

      }else{
        this.router.navigate(['/login'])
      }
    }  )
  }
  searchCategorybyHome() {
    this.productIds = [localStorage.getItem('category')];
    let apiReqUrl: any = `product/productOfOwn`
    this.commonService.showSpinner();
    let apiReqData: any = {
      page: String(this.currentPage),
      limit: String(this.itemPerPage),
      categoryId: this.productIds

    }
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.categoryListArrayNew = res.result.docs ? res.result.docs : '';
        this.datalist = res.result.docs[0].thumbnail

        this.totalItems = res.result.total
        
        
        
        // thumbnail

        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);

      } else {
        this.categoryListArrayNew = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        // this.router.navigate(['/login'])
        // this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.categoryListArrayNew = []
        this.totalItems = 0
        // this.router.navigate(['/login'])

        // this.commonService.errorToast(err.responseMessage);

      }else{
      this.router.navigate(['/login'])

      }
    }  )
  }



  pagination(event: number) {
    
    this.currentPage = event;
    this.getCategoryList()
  }










  // ===========================================================================      ================================





  getCategoryLists() {
    let apiReqUrl: any = `admin/listCategory`
    // this.commonService.showSpinner();
    let apiReqData: any = {
      // page: String(this.currentPage),
      // limit: String(this.itemPerPage)
    }
    // remove empty keys from request body
    // apiReqData = this.commonService['removeEmptyKey'](apiReqData)
    apiReqData = this.commonService.removeEmptyKey(apiReqData)

    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.categoryListArray = res.result.docs ? res.result.docs : '';
        

        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  getCategoryId(id: any) {
    this.categoryId = id.target.value
    
  }



  getSubCategoryList(id: any) {
    let apiReqUrl = `${'admin/subcategoryListWithCategory' + '?categoryId=' + id}`
    // this.commonService.showSpinner();

    // remove empty keys from request body
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.subCategoryListArray = res.result.subCategory ? res.result.subCategory : '';
        this.totalItems = res.result
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    })
  }


  getSubCategoryId(id: any) {
    this.subCategoryId = id.target.value
  }






  // ==========================================


  productBySubCategory(id: any) {
    // let apiReqUrl = `${'product/productListByCategory'}`
    let apiReqUrl = `${'product/productOfOwn'}`
    // this.commonService.showSpinner();
    let apiReqData: any = {
      page: String(this.currentPage),
      limit: String(this.itemPerPage),
      subCategoryId: id
    }
    // remove empty keys from request body
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.categoryListArrayNew = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        
        this.commonService.hideSpinner();

      } else {
        this.categoryListArrayNew = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
        // this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.categoryListArrayNew = []
        this.totalItems = 0
        // this.commonService.errorToast(err.responseMessage);

      }else{
      this.router.navigate(['/login'])
      }
    })
  }


  productBySubCategoryId(id: any) {
    this.subCategoryId = id.target.value
  }

  productId: any;

  forID(_id: any) {
    this.router.navigate(['/product-details'], { queryParams: { _id: _id } })
  }

  forEdit(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } })
  }


  productDetails: any;
  imgurl: any


// =======================bannerApi=========================
BannerListArray:any

getBannerProductList() {
  let apiReqUrl: any = `user/listBanner`  
  let apiReqData: any = {
    // page: String(this.currentPage),
    limit: String(this.itemPerPage),
  } 
  apiReqData = this.commonService.removeEmptyKey(apiReqData)
  this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
    
    if (res.responseCode == 200) {
      this.BannerListArray = res.result.docs ? res.result.docs : '';
      // this.datalist = res.result.docs[0].thumbnail
      this.totalItems = res.result.total
               
      
      // this.commonService.successToast(res['responseMessage'])
      this.commonService.hideSpinner();
      // this.commonService.successToast(res.responseMessage);
    } else {
      this.BannerListArray = []
      this.totalItems = 0
      this.commonService.hideSpinner();
      // this.router.navigate(['/login'])
      // this.commonService.errorToast(res.responseMessage)
    }
  }, (err: any) => {
    if (err.responseCode == 404) {
      this.BannerListArray = []
      this.totalItems = 0
      // this.router.navigate(['/login'])
      // this.commonService.errorToast(err.responseMessage);

    }else{
      this.router.navigate(['/login'])

    }
  }  )
}

ngOnDestroy() {
  localStorage.removeItem('category');
}
getPrice(e : any){
  
  let a = e.replace(/,/g,' ')
  return a.replace(/R/g,'R ')
}
reset(){
  this. getCategoryList()
}
}

