<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="container mt-5 mb-5">
  <div class="">
    <div class="row mt-3 mb-5">
      <div class="col-md-7">
        <div class="row">
          <span>
            <!-- <img class="image-custom qwqwqw" [src]="imgurl || '/assets/img/no-img.png'" alt=""> -->
            <img class="image-custom qwqwqw" [hidden]="!isLoaded" (load)="onImageLoad()" [src]="imgurl" alt="">

            <ngx-skeleton-loader *ngIf="!isLoaded" [theme]="{width: '670px',
              height: '405px',
              position: 'relative'}" count="1"></ngx-skeleton-loader>
          </span>
        </div>
        <div class="row mt-4" *ngIf="productDetails?.productImage?.length">
          <div *ngFor="let item of productDetails?.productImage" class="col-4 p-0 col-md-4">
            <img class="custom-img h-100 change-img" style=" cursor: pointer;" [src]="item"
              (click)="changeProductimage(item)">
          </div>
        </div>
      </div>
      <div class="col-md-5 p-0">
        <div class="row">

          <div class="col-12">
            <p class="prdct-home">{{productDetails?.productName}}</p>
          </div>
          <div class="col-12">
            <p class="para-text-common mb-0">
              Product ID : {{productDetails?._id | slice:0:7}}
            </p>
          </div>
          <div class="col-6">
            <div class="row  justify-content-between">
              <span [ngClass]="{'priceTestP':productDetails?.isDealActive}">
                {{ mrp ? getPrice(mrp | currency:"ZAR":"R") :
                productDetails?.priceSizeDetails[0]?.price && getPrice(productDetails?.priceSizeDetails[0]?.price |
                currency:"ZAR":"R")}}
              </span>
              <!-- <span *ngIf="productDetails?.isDealActive" class="priceTestPColor">
                  {{ mrp ? getPrice(mrp | currency:"ZAR":"R") :
                  productDetails?.priceSizeDetails[0]?.price && getPrice(productDetails?.priceSizeDetails[0]?.price |
                  currency:"ZAR":"R")}}
                </span> -->
              <span *ngIf="productDetails?.isDealActive" class="priceTestPColor">
                {{ afterDiscountPrice ? getPrice(afterDiscountPrice | currency:"ZAR":"R") :
                getPrice(afterDiscountPrice | currency:"ZAR":"R") }}
              </span>
              <span *ngIf="productDetails?.isDealActive" class="priceTestPColorD">
                {{productDetails?.dealDiscount}}% off
              </span>
            </div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <img src="assets/latest/veriticalline.svg" style="height: 2px;width: 100%;" alt="">
          </div>
        </div>

        <div class="" style="margin-top: 1em;">

          <div class="row">

            <div class="col-4 pr-0">
              <p class="pid">Product Name</p>
            </div>
            <div class="">:</div>
            <div class="col-7">
              <p class="pid-1">{{productDetails?.productName }}</p>
            </div>
          </div>
          <div class="row">

            <div class="col-4 pr-0">
              <p class="pid">Category Name</p>
            </div>
            <div class="">:</div>
            <div class="col-7">
              <p class="pid-1">{{productDetails?.categoryId.categoryName }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4 pr-0">
              <p class="pid">Quantity Available</p>
            </div>
            <div class="">:</div>
            <div class="col-7">
              <p class="pid-1">{{ quantity ? quantity : productDetails?.priceSizeDetails[0]?.quantity}}</p>
            </div>
          </div>
          <!-- <div class="row">
              <div class="col-4 pr-0">
                <p class="pid">Unit</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">{{ productDetails?.unit}}</p>
              </div>
            </div> -->
          <div class="row">
            <div class="col-4 pr-0">
              <p class="pid">Size/Value</p>
            </div>
            <div class="">:</div>
            <div class="col-7 flex-data">
              <ng-container *ngFor="let item of productDetails?.priceSizeDetails">
                <p class="pid-1 new-loop-para"
                  (click)="price(item?.price, item?.quantity, item?.value, item?.id, item?.unit)"
                  [ngClass]="{ active: value == item?.value }">
                  {{ item?.value }} {{ item?.unit !== 'other' ? '' + item?.unit : '' }}
                </p>
              </ng-container>
            </div>
          </div>

        </div>

        <div class="mt-4 pt-3">
          <span class="d-flex justify-content-between">
            <h1 class="text-center desc">Description</h1>
          </span>
          <p style="word-break: break-word;" [innerHTML]="getRawText( productDetails?.description)"
            class=" text-left descrip ml-3">
          </p>

        </div>
        <div class="w-100 text-center mt-5">

          <button class="btn btn-theme" (click)="openCampinModal()">Start Campaign</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="campainModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="margin-top: 230px;">
      <div class="modal-header" style="height: 90px; background: #BF1E2E;">
        <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img
            style="height: 100%; width:100%; object-fit: cover;" src="assets/img/whiteLogo.png" alt=""></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <!-- <span aria-hidden="true" style="color: white;">&times;</span> -->
        </button>
      </div>
      <div class="modal-body">
        <div class="form" *ngIf="selectedIndex == 0 || selectedIndex">

          <div class="row justify-content-between">
            <div class="col-md-6 text-center">
              <div class="form-group">
                <label for="exampleInputEmail1">From Date</label>
                <input type="datetime-local" class="form-control" [(ngModel)]="fromDate" aria-describedby="fromDate"
                  placeholder="Enter From Date">

              </div>
            </div>
            <div class="col-md-6 text-center">
              <div class="form-group">
                <label for="exampleInputEmail1">To Date</label>
                <input type="datetime-local" class="form-control" [disabled]="!fromDate" [min]="fromDate"
                  [(ngModel)]="toDate" aria-describedby="toDate" placeholder="Enter To Date">

              </div>
            </div>
            <div class="col-md-12 text-center">
              <div class="form-group d-flex">
                <label for="size">Size/Value :- </label>
                <ng-container *ngFor="let item of productDetails?.priceSizeDetails;let i = index">
                  <p class="pid-1 new-loop-para" id="size" (click)="onPrice(item, i)"
                    [ngClass]="{ active: i == selectedIndex}">
                    {{ item?.value }} {{ item?.unit !== 'other' ? '' + item?.unit : '' }}
                  </p>
                </ng-container>
              </div>
            </div>

            <div class="col-md-6 text-center">
              <div class="form-group">
                <label for="exampleInputEmail1">Actual Price</label>
                <input type="text" class="form-control" [value]="priceOfSize.price" readonly aria-describedby="toDate"
                  placeholder="Enter Discount Percentage">

              </div>
            </div>
            <div class="col-md-6 text-center">
              <div class="form-group">
                <label for="exampleInputEmail1">Discount percentage</label>
                <input type="text" class="form-control" aria-describedby="toDate"
                  placeholder="Enter Discount Percentage" [value]="campaignModel[selectedIndex].discountedPercentage"
                  (input)="calculateDiscountedPrice($event.target.value)" (keypress)="commonService.numberOnly($event)"
                  (keypress)="commonService.preventSpace($event);commonService.numberOnly($event)"
                  onkeyup="if(this.value > 99) this.value = 99; if(this.value < 0) this.value = 0;">
              </div>
            </div>
            <div class="col-md-6 text-center">
              <div class="form-group">
                <label for="exampleInputEmail1">Discounted Price</label>
                <input type="number" class="form-control" aria-describedby="toDate"
                  [value]="campaignModel[selectedIndex].discountedPrice" readonly
                  placeholder="Enter Discount Percentage">

              </div>
            </div>
            <!-- changes -->
            <div class="col-md-6 text-center">
              <div class="form-group">
                <label for="exampleInputEmail1">Released Stock</label>
                <input type="text" class="form-control" aria-describedby="toDate"
                  placeholder="Enter Discount Percentage" [value]="campaignModel[selectedIndex].releaseQuantity"
                  (input)="calculateQuantity($event.target.value)" (keypress)="commonService.numberOnly($event)"
                  (keypress)="commonService.preventSpace($event);commonService.numberOnly($event)"
                  onkeyup="if(this.value > 5) this.value = 5; if(this.value < 0) this.value = 0;">
              </div>
            </div>
            <!-- <div class="col-md-6 text-center">
              <div class="form-group">
                <label for="exampleInputEmail1">Released Stock</label>
                <input type="text" class="form-control" (input)="calculateQuantity($event.target.value)"
                  aria-describedby="toDate" placeholder="Enter Discount Percentage" formControlName="stock"
                  [value]="campaignModel[selectedIndex].releaseQuantity" min="1"> 
              </div>
                 
            </div> -->
            <div class="col-md-12 text-center">
              <div class="form-group">
                <label for="exampleInputEmail1">Remaining Stock {{
                  campaignModel[selectedIndex].quantity - campaignModel[selectedIndex].releaseQuantity >= 0
                  ? campaignModel[selectedIndex].quantity - campaignModel[selectedIndex].releaseQuantity
                  : 0
                  }}</label>


              </div>
            </div>

            <div class="col-md-12 text-center">
              <button class="btn btn-theme" (click)="startCampaign()">Confirm and Start Compaign</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>