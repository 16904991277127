import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
import { AuthService } from 'src/app/provide/auth.service';

@Component({
  selector: 'app-login-customer',
  templateUrl: './login-customer.component.html',
  styleUrls: ['./login-customer.component.scss']
})
export class LoginCustomerComponent implements OnInit {
  loginForm!: FormGroup;
  user: any;
  visible: boolean = false;
  changetype: boolean = true;
  route: any;
  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  constructor(private fb: FormBuilder, private router: Router, private http: HttpClient, public commonService: CommonService, public authService: AuthService) { }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loginForm = this.fb.group({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]
      ),
      'password': new FormControl('', [Validators.required,]),
      checkbox: new FormControl(false)
    })
    let checkbox = localStorage.getItem('customerLogin') ? JSON.parse(localStorage.getItem('customerLogin')!) : '';
    if (checkbox) {
      this.loginForm.patchValue({
        "email": atob(checkbox.email),
        "password": atob(checkbox.password),
        "checkbox": checkbox.checkbox
      })
    }
  }
  //RETAILER // CUSTOMER  // SERVICE_PROVIDER
  login() {
    let apiReqData = {
      userType: 'CUSTOMER',
      emailNumberId: this.loginForm.value.email,
      password: this.loginForm.value.password,
    }
    let apiReqUrl = `user/usersLogin`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          if(res.result.userType=='CUSTOMER'){
            this.commonService.hideSpinner();
            localStorage.setItem('token', res.result.token);
            localStorage.setItem('userType', 'CUSTOMER');
            localStorage.setItem('userId', res.result._id);
            localStorage.setItem('email', this.loginForm.value.email)
            if(res.result.primaryAddressId != 0){
              localStorage.setItem('primaryAddressId', res.result.primaryAddressId)
            }
            localStorage.setItem('completeProfile', 'true')
            this.authService.SharingData.next(true);
            this.authService.isLoggedIn.next(true);
            this.authService.loggedInUserType.next('CUSTOMER');
            localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
            this.authService.userRequestStatus.next(res.result.userRequestStatus);
            this.router.navigate(['/home']);
            if (this.loginForm.value.checkbox == true) {
              let remData = {
                "email": btoa(this.loginForm.value.email),
                "password": btoa(this.loginForm.value.password),
                "checkbox": this.loginForm.value.checkbox
              }
              localStorage.setItem('customerLogin', JSON.stringify(remData))
            } else {
              localStorage.removeItem('customerLogin')
            }
            if(!res.result.otpVerification){
              return this.router.navigate(['/otp-verification-customer'])
            }
          }
        } else {
          this.commonService.hideSpinner()
          this.commonService.errorToast(res['responseMessage'])
        }
      }, (err: any) => {
        if (err.responseCode == 402) {
          this.commonService.hideSpinner()
          this.commonService.errorToast(err.responseMessage)
        }
        else if (err.responseCode == 404) {
          this.commonService.hideSpinner()
          this.commonService.errorToast(err.responseMessage)
        } else {
          this.commonService.hideSpinner()
          this.commonService.errorToast(err.responseMessage)
        }
      })
  }
  preventSpace(event: any) {
    if ((event.charCode == 32) && !event.target.value) {
      event.preventDefault();
    }
  }
}
