import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './../../../provide/common.service';

@Component({
  selector: 'app-service-order-detail',
  templateUrl: './service-order-detail.component.html',
  styleUrls: ['./service-order-detail.component.scss']
})
export class ServiceOrderDetailComponent implements OnInit {
  btn: any = "Pending"
  orderList: any
  pickupOrderList: any
  count: any
  catName: any
  currentPage: any = 1;
  pageSize: any = 10;
  serviceStatus: any = 'PENDING'
  serviceRole: string
  headingName: any = 'Your Service History'
  isLoading: boolean = false
  notFoundData: boolean = false
  linkerRouteItems: any = [
    { name: 'Home', route: '/service-order-detail' },
    { name: 'Service History', route: '/service-order-detail' }
  ]

  constructor(public commonService: CommonService, public router: Router) {
    this.serviceRole = localStorage.getItem('serviceRole')
    if (this.getEndUrlSegment() == "service-order-detail") {
      localStorage.setItem('serviceRole', 'SERVICE')
      this.serviceRole = 'SERVICE'
    }
    if (this.getEndUrlSegment() == "assigned-service-pickup") {
      localStorage.setItem('serviceRole', 'PICKUP')
      this.serviceRole = 'TRANS'
      this.linkerRouteItems = [
        { name: 'Home', route: '/assigned-service-pickup' },
        { name: 'Service History', route: '/assigned-service-pickup' }
      ]
    }
    // if (this.getEndUrlSegment() == "assigned-service-delivery") {
    //   localStorage.setItem('serviceRole', 'DELIVERY')
    //   this.serviceRole = 'DELIVERY'
    //   this.linkerRouteItems = [
    //     { name: 'Home', route: '/assigned-service-delivery' },
    //     { name: 'Service History', route: '/assigned-service-delivery' }
    //   ]
    // }
    if (this.getEndUrlSegment() == "assigned-service-field") {
      localStorage.setItem('serviceRole', 'FIELD')
      this.serviceRole = 'FIELD'
      this.linkerRouteItems = [
        { name: 'Home', route: '/assigned-service-field' },
        { name: 'Service History', route: '/assigned-service-field' }
      ]
    }
    if (this.getEndUrlSegment() == "service-order-rejected-detail") {
      
      this.serviceRole = 'ALL'
      this.linkerRouteItems = [
        { name: 'Home', route: '/assigned-service-field' },
        { name: 'Service History', route: '/assigned-service-field' }
      ]
    }
  }

  ngOnInit(): void {
    this.myService()
    this.commonService.profileUpdateItem.next(true)
    if (this.serviceRole == 'SERVICE') {

      this.serviceOrderList();
    }
    else if (this.serviceRole == 'TRANS' || this.serviceRole == 'PICKUP' || this.serviceRole == 'FIELD' || this.serviceRole == 'DELIVERY') {
      this.getpickupOrderList()


      this.linkerRouteItems = [
        { name: 'Home', route: '/service-order-detail' }
      ]

    }
    else if(this.serviceRole == 'ALL'){
      this.getpickupOrderList()


      this.linkerRouteItems = [
        { name: 'Home', route: '/service-order-detail' }
      ]

    }
  }
  setUrl(e) {
    this.router.navigate([`/${e}`])
  }
  showService = false
  serviceList: any

  myService() {
    let url = `user/myServiceList`
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        let result = res.result.services
        let transport = result.find((ele) => { return ele.category.categoryType == 'PICKUP_PARTNER' })

        let field = result.find((ele) => { return ele.category.categoryType == 'FIELD_ENTITY' })

        let standard = result.find((ele) => { return ele.category.categoryType == 'SERVICE_PROVIDER' })
        if (!res.result.isConfig) {
          if (res.result.services.length > 1) {
            this.router.navigate(['services'])


          }
          else if (res.result.services.length == 1) {
            if (transport || field) {
              this.router.navigate(['service-provider-list-deals'], { queryParams: { _id: res.result.services[0].category._id, name: res.result.services[0].category.categoryName } })

            }
            if (standard) {
              this.router.navigate(['services-provider'])

            }

          }
          this.serviceList = res.result.services
        }

        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()

      }

    }, (err: any) => {

      if (err.responseCode == 409) {

      }
    })
  }

  selectBtn(status) {
    this.btn = status;
    this.serviceStatus = this.btn == 'Pending' ? 'PENDING' : 'ACCEPTED'
    // this.serviceOrderList();
    this.currentPage = 1
    if (this.serviceRole == 'SERVICE') {

      this.serviceOrderList();
    }
    else if (this.serviceRole == 'PICKUP' || this.serviceRole == 'FIELD' || this.serviceRole == 'DELIVERY' || this.serviceRole == 'TRANS') {
      this.serviceStatus = this.btn == 'Pending' ? 'PENDING' : 'COMPLETED'

      this.getpickupOrderList()
    }

  }

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }

  serviceOrderList() {
    this.isLoading = true
    this.orderList = []
    this.count = 0
    const data = {
      page: this.currentPage,
      limit: this.pageSize,
      orderType: 'SERVICE',
      statusType: this.serviceStatus
    }
    this.commonService.postApi('v2/service/serviceOrderlist', data, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        this.orderList = res.result.docs;
        this.count = res.result.count
        if (res.result.docs.length > 0) {
          this.notFoundData = false
        } else {
          this.notFoundData = true
        }
      }

    }, (err: any) => {
      this.isLoading = false
      this.notFoundData = true
      this.orderList = []
      if (err.responseCode == 409) {
        this.orderList = []
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  walletDetail: any
  getNoOfquantity(prodArr) {
    let noOfQuant = 0
    for (const item of prodArr) {
      noOfQuant += item.quantity
    }
    return noOfQuant
  }
  isRejected: boolean = false
  getpickupOrderList() {

    let userType = this.serviceRole == 'PICKUP' ? 'PICKUP_PARTNER' :
      this.serviceRole == 'DELIVERY' ? 'DELIVERY_PARTNER' : 
      this.serviceRole == 'TRANS' ? 'TRANS' : 
      this.serviceRole == 'ALL' ? 'ALL' : 'FIELD_ENTITY'

    this.isLoading = true
    this.orderList = []
    this.count = 0
    const data = {
      page: this.currentPage,
      limit: this.pageSize,

      statusType: this.serviceStatus,
      userType: userType
    }
    if (this.getEndUrlSegment() == 'service-order-rejected-detail') {
      data.statusType = 'REJECTED'
      this.serviceStatus = 'REJECTED'
      this.isRejected = true
      this.headingName = 'Your Rejected Orders'
    }
    this.commonService.postApi('deliveryOrders/getAllOrders?orderStatus=' + this.serviceStatus, data, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        this.pickupOrderList = res.result.docs;
        this.walletDetail = res.result.walletBalance
        this.count = res.result.total
        if (res.result.docs.length > 0) {
          this.notFoundData = false
        } else {
          this.notFoundData = true
        }
      }

    }, (err: any) => {
      this.isLoading = false
      this.notFoundData = true
      this.pickupOrderList = []
      if (err.responseCode == 409) {
        this.pickupOrderList = []
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })

  }

  navigate(item){
    if(item.userType == 'PICKUP_PARTNER'){
      localStorage.setItem('serviceRole', 'PICKUP')
      this.serviceRole = 'PICKUP'
    }
    if(item.userType == 'DELIVERY_PARTNER'){
      localStorage.setItem('serviceRole', 'DELIVERY')
      this.serviceRole = 'DELIVERY'
    }
    if(item.userType == 'FIELD_ENTITY'){
      localStorage.setItem('serviceRole', 'FIELD')
      this.serviceRole = 'FIELD'
    }
    this.router.navigate(['/service-order-detail-view'],{queryParams : {_id : item?._id, status: item?.orderStatus,type : this.btn}})
  }

  changePageNumber(page) {
    this.currentPage = page;
    // this.selectBtn(this.btn);
    // this.btn = status;
    this.serviceStatus = this.btn == 'Pending' ? 'PENDING' : 'ACCEPTED'
    // this.serviceOrderList();
    // this.currentPage = 1
    if (this.serviceRole == 'SERVICE') {

      this.serviceOrderList();
    }
    else if (this.serviceRole == 'PICKUP' || this.serviceRole == 'FIELD' || this.serviceRole == 'DELIVERY' || this.serviceRole == 'TRANS') {
      this.serviceStatus = this.btn == 'Pending' ? 'PENDING' : 'COMPLETED'

      this.getpickupOrderList()
    }
  }
  getDeliveryName(e: string) {
    let deliveryOption = e == 'SUNDAY' ? 'Sunday' :
      e == 'SAMEDAY' ? 'Sameday' :
        e == 'BUSINESSDAY' ? 'Next business day' :
          e == 'SATURDAY' ? 'Saturday' : 'Standard'

    return deliveryOption

  }

  getRole(e){
    let role = e == 'PICKUP_PARTNER' ? 'Pickup Driver' : 
    e == 'DELIVERY_PARTNER' ? 'Delivery Driver' : 'Fulfillment'
    return role
  }

  getEndUrlSegment(): string {
    const url = this.router.url;
    const segments = url.split('/');
    const lastSegmentWithQueryParamsAndFragments = segments[segments.length - 1];
    const endUrlSegment = lastSegmentWithQueryParamsAndFragments.split('?')[0].split('#')[0];
    return endUrlSegment;
  }
}

