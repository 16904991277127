

<div class="container mt-2 mb-5 wrapper-content">
    <div class="">
       
        <div class="row mt-3 mb-5">
            <div class="col-lg-12 col-md-12 col-sm-6">
                <div class="" style="margin-top: 1em;">
                    <h3 class="title">All services</h3>
                    <div id="accordion" *ngFor="let item of serviceDetailsArray;let i = index">
                        <mat-accordion>
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header class="faq-qsn">
                                    <mat-panel-title class="gg" style="color:#bf1e2e ;">
                                        {{item?.categoryData?.categoryName || '---'}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ng-container *ngFor="let data of item.subCategories;let j = index">
                                    <mat-accordion>
                                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                            <mat-expansion-panel-header class="faq-qsn">
                                                <mat-panel-title class="gg" style="color:#bf1e2e ;">
                                                    {{data?.subCategoryData?.subCategoryName || '---'}}
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <ng-container>
                                                <div class="d-flex justify-content-between"
                                                    *ngFor="let service of data?.services;let k = index"
                                                    style="text-align: center;align-items: center;">
                                                    <div class="custom-control custom-checkbox my-1 mr-sm-2"
                                                        style="padding: 0px !important;">
                                                        <input type="checkbox" class="custom-control-input"
                                                            [(ngModel)]="service.isSelected"
                                                            [attr.id]="'customControlInline'+i + '' + j+''+k"
                                                            (input)="checkValidation($event.target.checked,service.price, service?._id)">
                                                        <label class="custom-control-label"
                                                            [attr.for]="'customControlInline'+i+ '' + j + '' + k">{{service?.serviceName}}</label>
                                                    </div>
                                                    <div>
            
                                                        <p class="mb-0 !important ner">R <input type="number" class="inpPrice"
                                                                (keypress)="commonService.numberOnly($event)"
                                                                [(ngModel)]="service.price" placeholder="Amount" [value]="service.price">
                                                            /unit
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </mat-expansion-panel>
                                       
                                    </mat-accordion>
                                
                                </ng-container>
                               
                                <!-- <ng-container *ngIf="!item?.serviceArray.length">
                                    <p class="text-center">No data found</p>
                                </ng-container> -->

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="right-totalamout">
                        <!-- <span><button class="butCh" (click)="navigateToserviceAvailableAdd()">Update</button></span> -->
                        <span><button class="butCh" (click)="update()">Submit</button></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>