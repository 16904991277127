import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-retailer-requested-price-view',
  templateUrl: './retailer-requested-price-view.component.html',
  styleUrls: ['./retailer-requested-price-view.component.scss']
})
export class RetailerRequestedPriceViewComponent implements OnInit {
  headingName:any ='Requested Details'
  linkerRouteItems:any =[
   
      { name:'Home',route: '/home'},
      {name:'Requested Price',route:'/retailer-requested-price'},
      {name:'Requested View Detail',route:'/retailer-requested-price-view'}

  ]
  // static-data.ts

  viewOrderData  = [
  {
    productId: {
      thumbnail: 'assets/images/img/dummy.jpeg',
      productName: 'Product 1',
    },
    price: 25.99,
    quantity: 2,
    priceSizeDetails: {
      value: 100,
      unit: 'g',
    },
    _id: '1234567890',
  },
  {
    productId: {
      thumbnail: 'assets/images/img/dummy.jpeg',
      productName: 'Product 2',
    },
    price: 15.49,
    quantity: 1,
    priceSizeDetails: {
      value: 200,
      unit: 'ml',
    },
    _id: '0987654321',
  },
  // Add more objects as needed
];

  constructor() { }

  ngOnInit(): void {
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
}
