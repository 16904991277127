<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="container mb-5 wrapper-content">

   

  <div class="addnewicon"> 
    <!-- <p class="del"> Select a delivery address</p> -->
    <img  (click)="navigatetoAdd()" src="assets/latest/plusicon.png"> <span
      (click)="navigatetoAdd()" class="addcolr">Add New Address</span> </div>
  <div class="right-side position-relative" *ngFor="let item of listAddressData">
    <input type="radio" name="add"   (change)="getChackBox($event.target.checked, item._id)" class="position-absolute mlp" *ngIf="getEndUrlSegment() == 'list-address-customer'">
    <div class="d-flex ad1">
     
      <div class="left-container">
        <p class="addressName">{{item?.firstName}} {{item?.lastName}}</p>
        <p style="margin: 1px;"><span class="addressName1">Address:</span><br>
          <strong class="text-sty">
            {{item?.addressLine1}} <span *ngIf="item?.addressLine2"></span>
            {{item?.addressLine2}}

          </strong>
        </p>
        <p style="margin: 1px;">
          <!-- <strong class="text-sty">{{item?.city}},
            {{item?.state}},{{item?.country}}</strong>, -->
          <strong class="text-sty">{{item?.zipCode}}</strong>
        </p>
        <p style="margin: 1px;">
          <strong class="text-sty"> {{item?.countryCode}} </strong>
          <strong class="text-sty"> {{item?.mobileNumber}} </strong>
        </p>
      </div>
      <div class="right-container">
        <div class="d-flex trash">
          <span><img class="pd20 pointer imagHover" (click)="navigateToEdit(item?._id)" src="assets/latest/edit1.png">
          </span>
          <span *ngIf="!item?.isPrimary"> <img class="pointer imagHover"
              (click)="addressModals(item?._id,item?.isPrimary)" src="assets/latest/delete1.png"></span>
        </div>
        <div class="pr-set">
          <button class="add-primary-add " [disabled]="item?.isPrimary" (click)="setPrimaryAddress(item?._id)">
            {{item?.isPrimary ? 'Your Primary Address' : 'Set Primary Address' }} </button>
          <spna *ngIf="item?.isPrimary" class="seticonfixed">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </spna>
        </div>

      </div>
    </div>
  </div>

  
  <app-table-loader [isTable]="'ADDRESS'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10">
  </app-table-loader>
  <app-table-loader [isTable]="'ADDRESS'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10">
  </app-table-loader>
  <div style="text-align: center;" *ngIf="getEndUrlSegment() == 'list-address-customer'">
    <button class="continue-btn"    [ngClass]="{'disableButton': !newAddressId}" [disabled]="!newAddressId" (click)="navigateToOrderOverview()">Continue with this
      address</button>
  </div>

</div>

<div class="modal fade" id="addressModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content ggg" style="width: 77%;">
      <div class="d-flex justify-content-center mt-5 mb-4" id="book">
        <img class="for-img1" style="height: 100%;width: 18%;" src="assets/latest/logoutui.png" alt="login form"
          class="img-fluid" />
      </div>
      <h2 class="modal-title d-flex justify-content-center okay" style="color:#263238;">
        Are you sure you want to delete <br> this address?
      </h2>
      <div class="modal-body d-flex justify-content-center mb-4">
        <button type="button" class="btn btntt1" data-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btntt ml-3" data-dismiss="modal" (click)="deleteAddress()">
          Yes
        </button>
      </div>
    </div>
  </div>

</div>

<address-pop-up *ngIf="showAddress" [addressData]="addressData" (closeModal)="closeModals()"></address-pop-up>
<payment-status *ngIf="paymentStatus" [paymentStatus]="paymentStatus"></payment-status>

<!-- </div> -->

