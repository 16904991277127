import { CommonService } from './../../../../provide/common.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password-service-provider',
  templateUrl: './change-password-service-provider.component.html',
  styleUrls: ['./change-password-service-provider.component.scss']
})
export class ChangePasswordServiceProviderComponent implements OnInit {

  changPassword!: FormGroup



  // foricon eye
  // myEmail: any;
  visible: boolean = false;
  changetype: boolean = true;

  myToken: any;

  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;

  }

  // 2nd

  visibles: boolean = false;
  changetypes: boolean = true

  viewpas() {
    this.visibles = !this.visibles;
    this.changetypes = !this.changetypes
  }

  // 3rd


  visibled: boolean = false;
  cengesestype: boolean = true

  viewpassd() {
    this.visibled = !this.visibled;
    this.cengesestype = !this.cengesestype;
  }




  constructor(private fb: FormBuilder, http: HttpClient, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.changPassword = this.fb.group({
      'oldpassword': new FormControl('', [
        Validators.required,
        // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)

      ]),
      'password': new FormControl('', [
        Validators.required,
        // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)
      ]),
      'confirmPassword': new FormControl('', [
        Validators.required,
      ]),

    })
    this.myToken = localStorage.getItem('token')
  }

  navigateTochangePassword() {
    if(this.changPassword.invalid){
      return this.commonService.errorToast("Please fill all required field.")
    }
    if(this.changPassword.value.password != this.changPassword.value.confirmPassword){
      return this.commonService.errorToast("Password and confirm password must be same.")
    }
    let apiReqData = {
      password: this.changPassword.value.oldpassword,
      newPassword: this.changPassword.value.password,
      confirmPassword: this.changPassword.value.confirmPassword,
      // "newPassword": this.changPassword.value.confirmPassword,
    }
    // let apiReqUrl = "user/changePassword"
    this.commonService.showSpinner();
    this.commonService.putApi('user/changePassword', apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.router.navigate(['/profile'])
        this.commonService.successToast(res.responseMessage)
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    })

  }

  preventSpace(event: any) {
    if ((event.charCode == 32) && !event.target.value) {
      event.preventDefault();
    }
  }

}
