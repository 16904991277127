import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'deals-card',
  templateUrl: './deals-card.component.html',
  styleUrls: ['./deals-card.component.scss']
})
export class DealsCardComponent implements OnInit {
  @Input() data : any
  @Input() countDown : any
  @Input() showEdit : boolean
  @Input() isImage : boolean
  @Input() width : boolean
  @Input() height : boolean

  @Output() on_Click = new EventEmitter
  @Output() on_Click_Edit = new EventEmitter
  isLoaded: boolean = false;
  theme : any
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.theme = { width: this.width || '228px', height: this.height || '329px', position: 'relative' }
  }
  dealVIEw(e){
    this.on_Click.emit(e)
    
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  onEdit(e){
    this.on_Click_Edit.emit(e)
  }
  onImageLoad(){
    this.isLoaded = true
  }
  
}
