<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mt-4  mb-5 ">
  <div class="">
    <div class="row mt-3 mb-4">
      <div class="col-md-7 p-0">
        <p style="cursor: pointer;position: relative;z-index: 9;">
          <i class="fa fa-pencil-square-o edit-icon"
            [routerLink]="['/service-provider-edit-deals']" [queryParams]="{_id: deals?._id}" aria-hidden="true"></i>
        </p>
        <div class="row">
          <span>
            <!-- <img class="image-custom qwqwqw"  [hidden]="!imageLoaded" (load)="onImageLoad()" [src]="imgurl" alt="" 
                    style=" cursor: pointer;" >
                    <ngx-skeleton-loader *ngIf="!imageLoaded"  [theme]="{width: '670px',
                    height: '405px',
                    position: 'relative'}" count="1"></ngx-skeleton-loader> -->
            <img class="image-custom qwqwqw" [hidden]="!imageLoaded" (load)="onImageLoad()" [src]="imgurl" alt="">
            <ngx-skeleton-loader *ngIf="!imageLoaded" [theme]="{width: '670px',
                                        height: '405px',
                                        position: 'relative'}" count="1"></ngx-skeleton-loader>
          </span>
        </div>
        <div class="row mt-4">
          <div *ngFor="let item of DealsImage" class="col-4 p-0 col-md-4">
            <img class="custom-img h-100 change-img" style=" cursor: pointer;" [src]="item"
              (click)="changeProductimage(item)">
          </div>
        </div>
      </div>

      <div class="col-lg-5 p-0">
        <div class="row">
          <!-- <div class="col-5">
                        <p class="pid">Category</p>
                    </div> -->
          <div class="col-12">
            <p class="prdct-home">
              Category : {{deals?.serviceId?.categoryId?.categoryName}}</p>
          </div>
          <!-- <div class="col-2">:</div>
                    <div class="col-5">
                        <p class="pid-1">{{deals?.serviceId?.categoryId?.categoryName}}</p>
                    </div> -->
          <div class="col-12">
            <p class="para-text-common  mb-0">
              Sub-Service : {{deals?.serviceSubCategoryId?.subCategoryName}}
            </p>
          </div>

          <!-- price -->
          <div class="col-4">
            <div class="row  justify-content-between">
              <span [ngClass]="{'priceTestP' :deals?.dealPrice }">
                {{getPrice(deals?.serviceId?.price | currency:"ZAR":"R")}}
              </span>
              <span *ngIf="deals?.dealPrice" class="priceTestPColor">
                {{getPrice(deals?.dealPrice | currency:"ZAR":"R")}}
              </span>
              <span style="margin-top: 7px;" *ngIf="deals?.dealPrice" class="priceTestPColorD">
                {{discounCal(deals?.serviceId?.price,deals?.dealPrice)}}% off
              </span>
            </div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <img src="assets/latest/veriticalline.svg" style="height: 2px;width: 100%;" alt="">
          </div>

          <div class="col-12">
            <p class="para-text-common  mb-0">
              Service : {{deals?.serviceId?.serviceName}}
            </p>
          </div>
          <div class="col-12">
            <p class="para-text-common  mb-0">
              Start Date/Time : {{commonService.convertDateIntoLocalTime(deals?.dealStartTime) || '--'}}
            </p>
          </div>
          <div class="col-12">
            <p class="para-text-common  mb-0">
              End Date/Time : {{commonService.convertDateIntoLocalTime(deals?.dealEndTime) || '--'}}
            </p>
          </div>
        </div>

      </div>
      <!-- <div class="col-12 text-center mt-4">
                <button class="markBtn" (click)="commonService.backLocation()">Back</button>
            </div> -->
    </div>
  </div>

</div>