import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-retailer-payment-from-wholesaler',
  templateUrl: './retailer-payment-from-wholesaler.component.html',
  styleUrls: ['./retailer-payment-from-wholesaler.component.scss'],
})
export class RetailerPaymentFromWholesalerComponent implements OnInit {
  paymentData: any;
  currentPage: any = 1;
  pageSize: any = 10;
  totalItems: any;
  headingName:any ='Payment to Wholesalers'
  isLoading:boolean =false
  notFoundData:boolean =false
  linkerRouteItems:any =[
    {name:'Home',route: '/retailer-home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Payment History',route:'/retailer-payment-from-wholesaler'}
  ]
  constructor(public service: CommonService) {}

  ngOnInit(): void {
    this.paymentList();
  }

  paymentList() {
    this.isLoading =true
    this.paymentData =[];
    this.totalItems= 0;
    let url = `order/transactionToWholeSaler`;
    let data = {
      page: this.currentPage,
      limit: this.pageSize,
    };
    this.service.postApi(url, data, 1).subscribe(
      (res: any) => {
        this.isLoading =false
        if (res['responseCode'] == 200 && res.result.docs.length>0) {
          this.service.hideSpinner();
          this.paymentData = res.result.docs;
          if(res.result.docs.length>0){
            this.notFoundData =false
          }else{
            this.notFoundData =true
          }
          this.totalItems = res.result.total;
          
        } else {
          this.service.hideSpinner();
          this.notFoundData =true
          this.service.errorToast(res.responseMessage);
        }
      },
      (err: any) => {
        this.isLoading =false
        this.notFoundData =true
        this.service.hideSpinner();
        if (err.responseCode == 409) {
          this.service.errorToast(err.responseMessage);
        }
      }
    );
  }

  getPrice(e: any) {
    
    let a = e.replace(/,/g, ' ');
    return a.replace(/R/g, 'R ');
  }
  pagination(event) {
    this.currentPage = event;
    this.paymentList();
  }
   getdate(date:string){
    date = date.replace('Z','')
    let currenDate = new Date(date).toLocaleString()
    return currenDate
  }
}
