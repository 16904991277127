import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-upload-document-retailer',
  templateUrl: './upload-document-retailer.component.html',
  styleUrls: ['./upload-document-retailer.component.scss'],
})
export class UploadDocumentRetailerComponent implements OnInit {
  @ViewChild('fileInput1', { static: false }) fileInput1: ElementRef;
  @ViewChild('fileInput2', { static: false }) fileInput2: ElementRef;
  @ViewChild('fileInput3', { static: false }) fileInput3: ElementRef;
  @ViewChild('fileInput4', { static: false }) fileInput4: ElementRef;
  @ViewChild('fileInput5', { static: false }) fileInput5: ElementRef;

  constructor(
    public commonService: CommonService,
    private router: Router,
    public auth: AuthService,
    private renderer: Renderer2,
    private toastrService: ToastrService
  ) {}

  imageUrl: any;
  submitButtonText: string = 'Submit';
  submitButtonDisabled: boolean = false;
  IncorporationForntUrl: any;
  IncorporationBackUrl: any;
  VatForntUrl: any;
  VatBackUrl: any;
  DirectorForntUrl: any;
  DirectorBackUrl: any;
  ConfirmationForntUrl: any;
  ConfirmationBacktUrl: any;
  DirectorIDDocumentUrl: any;

  ngOnInit(): void {}

  totalDocument = 5;
  uploadedDocument: number = 0;

  async imgeUplode() {
    this.loading = true;

    let busnessDetail: any = {
      cirtificationOfIncorporation: this.IncorporationForntUrl,
      directConsentForm: this.DirectorForntUrl,
      directorId: this.DirectorIDDocumentUrl,
      VatRegConfirmationDocs: this.VatForntUrl,
      bankConfirmationLetter: this.ConfirmationForntUrl,
    };

    let obj = {
      flag: 2,
      userRequestStatus: 'PENDING',
      completeProfile: true,
      businessDocumentUpload: {},
    };

    let data = new FormData();
    data.append('cirtificationOfIncorporation', this.IncorporationForntUrl);
    data.append('directConsentForm', this.DirectorForntUrl);
    data.append('directorId', this.DirectorIDDocumentUrl);
    data.append('VatRegConfirmationDocs', this.VatForntUrl);
    data.append('bankConfirmationLetter', this.ConfirmationForntUrl);

    // Check if any document is not uploaded
    for (const key in busnessDetail) {
      if (!busnessDetail[key]) {
        this.commonService.errorToast('Please upload all documents');
        this.loading = false;
        this.setSubmitButtonState('Submit', false);
        return;
      }
    }

    const promises = Object.keys(busnessDetail).map(async (item) => {
      let url = await this.uploadImageFunc(busnessDetail[item], item);
      this.uploadedDocument++;
      console.log(item, url);
      return [item, url];
    });

    const results = await Promise.all(promises);

    results.forEach((ele) => {
      obj['businessDocumentUpload'][ele[0]] = { pdf: ele[1] };
    });

    // Check if all documents are uploaded before submitting
    if (this.uploadedDocument !== this.totalDocument) {
      this.commonService.errorToast('Please upload all documents');
      this.loading = false;
      this.setSubmitButtonState('Submit', false);
      return;
    }

    // Disable the button and update text during the loading state
    this.setSubmitButtonState(
      `Document uploaded ${this.uploadedDocument}/${this.totalDocument}`,
      true
    );

    let url = `user/fillDetails`;

    this.commonService.postFormDataApi(url, obj, 1).subscribe(
      (res: any) => {
        if (res['responseCode'] == 200) {
          this.toastrService.success(
            'Thank you for Registration. Please wait for vetting and approval.'
          );
          // this.commonService.successToast(
          //   'Thank you for Registration. Please wait for vetting and approval.'
          // );
          this.auth.logout();
          this.router.navigate(['/login-retailer']);
        } else {
          this.loading = false;
          // Enable the button and reset text if there's an error
          this.setSubmitButtonState('Submit', false);
        }
      },
      (err) => {
        this.loading = false;
        this.commonService.errorToast(err.error['responseMessage']);
        // Enable the button and reset text if there's an error
        this.setSubmitButtonState('Submit', false);
      }
    );
  }

  private setSubmitButtonState(text: string, disabled: boolean): void {
    this.submitButtonText = text;
    this.submitButtonDisabled = disabled;
  }

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-

  uploadImg(event: any, uplodeImage: any, index: any): void {
    var img = event.target.files[0];
    if (img) {
      const fileSize = img.size; // Size in bytes
      const fileSizeKB = fileSize / 1024; // Size in kilobytes (KB)
      const fileSizeMB = fileSizeKB / 1024; // Size in megabytes (MB)

      const fileType = String(img.type).split('/')[1];
      if (fileSizeMB > 3) {
        var fileInput: any = document.getElementById('file-upload' + index);
        fileInput.value = '';
        return this.commonService.errorToast(
          'Please select a document that is 3 MB or smaller.'
        );
      }
    }
    if (uplodeImage == 'INCORPORATIONFORNT') {
      this.IncorporationForntUrl = img;
    } else if (uplodeImage == 'VATFORNT') {
      this.VatForntUrl = img;
    } else if (uplodeImage == 'DIRECTORFORNT') {
      this.DirectorForntUrl = img;
    } else if (uplodeImage == 'DIRECTORDOCUMENT') {
      this.DirectorIDDocumentUrl = img;
    } else if (uplodeImage == 'CONFIRMATIONFORNT') {
      this.ConfirmationForntUrl = img;
    }
    // this.uploadImageFunc(img, uplodeImage);
  }
  flag: boolean = false;
  async uploadImageFunc(img: any, key: string) {
    var fb = new FormData();
    fb.append('file', img);
    this.commonService.showSpinner();
    this.flag = true;
    try {
      let res: any = await this.commonService
        .postFormDataApi('user/uploadDocument', fb, 1)
        .toPromise();

      return res['result']['mediaUrl'];
    } catch (error) {
      return null;
    }
  }
  loading = false;

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;
    }, 60000); // 1 minute
  }
}
