import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-retailer-payment-from-customer',
  templateUrl: './retailer-payment-from-customer.component.html',
  styleUrls: ['./retailer-payment-from-customer.component.scss'],
})
export class RetailerPaymentFromCustomerComponent implements OnInit {
 
  paymentData: any;
  currentPage: any = 1;
  pageSize: any = 10;
  totalItems: any;
  notFoundData:boolean =false;
  headingName:any ='Payment From Customers'
  linkerRouteItems:any =[
    {name:'Home',route: '/retailer-home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Payment History',route:'/retailer-payment-from-customer'}
  ]
  constructor(public service: CommonService) {}

  ngOnInit(): void {
    this.paymentList();
  }
  isLoading:boolean =false

  paymentList() {
    this.isLoading =true
    this.paymentData =[];
    this.totalItems= 0;
    let url = `order/v2/retailerOrderList`;
    let data = {
      page: this.currentPage,
      limit: this.pageSize,
      userType: 'CUSTOMER',
    };
    this.service.postApi(url, data, 1).subscribe(
      (res: any) => {
       this.isLoading =false
        if (res['responseCode'] == 200) {
          this.service.hideSpinner();
          this.paymentData = res.result.docs;
          this.totalItems = res.result.count;
          if(this.paymentData.length>0){
            this.notFoundData =false
          }else{
            this.notFoundData =true
          }
        } else {
          this.service.hideSpinner();
          this.service.errorToast(res.responseMessage);
        }
      },
      (err: any) => {
        this.isLoading =false
        this.notFoundData =true
        this.service.hideSpinner();
        if (err.responseCode == 409) {
          this.service.errorToast(err.responseMessage);
        }
      }
    );
  }

  getPrice(e: any) {
    
    let a = e.replace(/,/g, ' ');
    return a.replace(/R/g, 'R ');
  }
  pagination(event) {
    this.currentPage = event;
    this.paymentList();
  }
  getdate(date:string){
    date = date.replace('Z','')
    let currenDate = new Date(date).toLocaleString()
    return currenDate
  }
}
