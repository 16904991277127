import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
declare var $
@Component({
  selector: 'app-service-provider-view-deals',
  templateUrl: './service-provider-view-deals.component.html',
  styleUrls: ['./service-provider-view-deals.component.scss']
})
export class ServiceProviderViewDealsComponent implements OnInit {
  headingName:any ='DEAL DETAILS'
  linkerRouteItems:any =[
    { name:'Home',route: '/service-order-detail'},
    {name:'Service Deal Detail',route:'/service-provider-view-deals'}
  ]
  _id: any
  isLoggedIn: any
  loggedInUserType: any
  userId: any;
  productDetails: any;
  serviceDetail: any
  productId: any;
  serviceImage: any
  orderStatus: any
  imageLoaded:boolean =false
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.orderStatus = res.status;
    })
  }

  onImageLoad(){
    this.imageLoaded = true
  }

  ngOnInit(): void {
    this.viewDeals();
    // newValue = this.commonService.loc
  }


  deals: any
  imgurl: any;

  DealsImage:any = []
  viewDeals() {
    let url = `deal/viewDeal?dealId=${this._id}`
    // this.commonService.showSpinner()
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.deals = res.result
        $(document).ready(function () {
          $('.service_slider').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
        this.imgurl = res.result.dealImage[0]
        this.DealsImage = res.result.serviceId.serviceImage
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
      }

    }, (err: any) => {

      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  getPrice(e: any) {
    if(e){
      let a = e.replace(/,/g, ' ')
      return a.replace(/R/g, 'R ')
    }

  }

  discounCal(actualprice,Dealprice){
    if(Dealprice && actualprice){
      let  DiscountPercentage = ((Number(actualprice) - Number(Dealprice)) / Number(actualprice)) * 100
      return DiscountPercentage  ? DiscountPercentage : 0
    }else{
      return 0
    }
  }
  mrp: any
  quantity: any
  value: any
  unit: any
  pdId: any
  price(e: any, q: any, v: any, id, u) {
    this.mrp = e,
      this.quantity = q
    this.value = v
    this.unit = u
    this.pdId = id
  }
  imgSrc: any
  productImage: any
  serviceData: any = []

  changeProductimage(url: any) {
    
    this.imgurl = url
  }
}
