import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

declare var $
@Component({
  selector: 'app-customer-deals-product-view',
  templateUrl: './customer-deals-product-view.component.html',
  styleUrls: ['./customer-deals-product-view.component.scss']
})
export class CustomerDealsProductViewComponent implements OnInit {
  _id: any
  isLoggedIn: any
  loggedInUserType: any
  spordicType: string = 'AUTOMATIC';
  imageLoaded: boolean = false
    headingName:any ='DEAL DETAILS'
    name: string
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Deal Detail',route:'/customer-product-view'}
  ]
  isLoading: boolean =false;
  interestedPriceModal: any = []

  flag = true
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private toastrService: ToastrService,private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.name = res.q
      if(res.q){
        this.linkerRouteItems[1] = {name: this.name,route:'/customer-product-view'}
      }
    })
    this.spordicType = localStorage.getItem('campainPrefrences')
  }

  ngOnInit(): void {

    this.authService.isLoggedIn.subscribe((res: any) => {


      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {

      this.loggedInUserType = res
    })
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn()
    this.getProduct();
    // this.getSimiliarProduct()
  }



  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any

  getProduct() {
   
      this.viewRetailerProductd()
    
  }
  firstPriceSize: any = [];
  productDetailsSize: any = []
  // get retailer detail by id
  intrestedPrice : boolean = false
  iscampainValid : boolean[] = []
  viewRetailerProductd() {
    let apiReqUrl: any = `deal/viewDeal?dealId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.getApi(apiReqUrl, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.productDetails = res.result;
        this.isLoading=true
        this.productDetailsSize = res.result.dealDetails
        this.imgurl = res.result.dealImage[0]
        this.firstPriceSize = res.result.dealDetails[0]
        this.mrp = this.productDetails.productId[0].priceSizeDetails[0].price
        this.price(this.firstPriceSize.weight, this.firstPriceSize.volume, this.firstPriceSize.price, this.firstPriceSize.quantity, this.firstPriceSize.value, this.firstPriceSize.id, this.firstPriceSize.unit, this.mrp)
        // this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.intrestedPrice = res.result.isIntrestPriceAdded
        if (this.productDetails.isCampaignActive) {

          this.getCoundown(this.productDetails.campainId.endDate)
          for (let i = 0; i < this.productDetails.productId[0].priceSizeDetails.length; i++) {
           let findCampain = this.productDetails.campainId.campaignDetail.find((ele)=>{
            return ele.id == this.productDetails.productId[0].priceSizeDetails[i].id
           })
           if(findCampain){
            this.iscampainValid[i] = true
           }
           else{
            this.iscampainValid[i] = false
           }
            
          }


        }
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.isLoading=false
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.commonService.hideSpinner()
    })
  }

  isCampaignActive(){
    if(!this.productDetails || !this.productDetails.campainId || !this.productDetails.campainId.campaignDetail){
      return false
    }
    let find = this.productDetails.campainId.campaignDetail.find((ele)=>{
      return ele.id == this.pdId
    })
    if(find){
      return true
    }
    else{
      return false
    }
  }

  getcampaignDiscountedPrice(){
    
    let ff = this.productDetails.campainId.campaignDetail.find((ele)=>{
      return ele.id == this.pdId
    })
    return ff
  }
  viewRetailerProductdWithoutLogin() {
    let apiReqUrl: any = `deal/viewDeal?dealId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`

    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {


        this.productDetails = res.result;
        this.isLoading=true
        this.imgurl = res.result.dealImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.isLoading=false
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.commonService.hideSpinner()
    })
  }
  timerInterval: any;
  countDown: any;
  getCoundown(date: Date) {
    var countDownDate = new Date(date).getTime();
    let me = this;
    // Update the count down every 1 second

    this.timerInterval = setInterval(() => {
      if (true) {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        this.countDown =
          days + 'D:' + hours + 'H:' + minutes + 'M:' + seconds + 'S';
        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(me.timerInterval);
          me.countDown = 'EXPIRED';
        }
      }

      // me.countDown[i] = mm
    }, 1000);
  }
  getCountDownTime(date?: Date) {

    if (!this.countDown) {
      this.getCoundown(date)
    }
    return this.countDown
  }


  forID(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } })

  }




  changeProductimage(url: any) {
    this.imgurl = url
  }

  afterDiscountPrice: any;
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  mrp: any
  quantity: any
  value: any
  unit: any
  weight: any
  volume: any
  pdId: any
  dealP: any
  price(w: any, vol: any, e: any, q: any, v: any, id, u, dp) {
    this.mrp = e,
      this.quantity = q
      this.weight = w
      this.volume = vol
    this.value = v
    this.unit = u
    this.pdId = id,
      this.dealP = dp
      this.afterDiscountPrice = this.calDiscountOn(this.mrp)
  }
  calDiscountOn(realPrice){
    let dsp  = (Number(this.productDetails.dealDiscount)/ 100) * realPrice
    return this.mrp - dsp
  }
  imgSrc: any
  productImage: any
  serviceData: any = []
  getSimiliarProduct() {
    // let apiReqUrl: any = `product/similarProductList?productId=${this._id}`
    let apiReqUrl: any = `product/productMightLike`
    // let apiReqUrl: any = `product/viewProduct?productId=${this._id}`/    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    //this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {


        this.serviceData = res.result.docs;
        // this.imgurl = res.result.productImage[0]
        // this.imgSrc = res.result.productImage[0]
        // this.imageLinks = res["result"]["thumbnail"]
        // this.productImage =res.result.productImage

        $(document).ready(function () {
          $('.service_slider').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  showInterestedPriceModal=false;
  openModal() {
    $('#interestedPriceModal').modal('show')
    this.showInterestedPriceModal =true
    // this.selectedIndex = 0
    // this.campaignModel[0] = { ...this.productDetails.priceSizeDetails[0], ...{ discountedPrice: this.productDetails.priceSizeDetails[0].price, discountedPercentage: 0, releaseQuantity: 0 } }
    // this.priceOfSize = this.productDetails.priceSizeDetails[0]
  }
  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {

        this.commonService.hideSpinner();
        this.viewRetailerProductd()
        // this.router.navigate(['/edit-product'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }
  addToCart() {
    let apiReqUrl = `user/v2/productAddToCart`
    let apiReqData = {

      // "productId": this._id,
      "productId": this.productDetails.productId[0]._id,
      "priceSizeDetails": {
        "value": this.value,
        "price": Number(this.mrp),
        "unit": this.unit,
        "weight": this.weight,
        "volume": this.volume,
        "id": this.pdId
      },
      "orderType": "DEAL",
      "quantity": 1,
      "totalPrice": this.mrp,
      "addType": "DEAL"

    }

    if (this.loggedInUserType == 'RETAILER') {
      apiReqData.priceSizeDetails['quantity'] = this.quantity
    }
    this.flag = false
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.router.navigate(['/addtoCart-customer'])
        this.flag = true
        this.commonService.successToast(res.responseMessage);
      } else {
        this.flag = true
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.flag = true
      this.commonService.errorToast(err.responseMessage)
    })
  }
  showMessage() {
    return this.commonService.errorToast('Please Login')
  }
  onImageLoad(){
    this.imageLoaded = true
  }
  getRawText(str : string):string{
    return str.replace(/\n/g, '<br>')
  }

  limitInterestedPrice() {
    const maxPrice = this.getMaxPrice(); 
    if (this.interestedPriceModal > maxPrice) {
      this.interestedPriceModal = maxPrice;
    }
  }

  getMaxPrice(): number {
    return Math.max(...this.productDetails[0].priceSizeDetails.map(item => item.price));
  }
  intrestedQuantity = 0
  interestedPrice;

  confirmInterestedPrice() {
    // this.mrp = e,
    // this.quantity = q
    // this.value = v
    // this.unit = u
    // this.pdId = id
    // this.afterDiscountPrice = this.calDiscountOn(this.mrp)
    let url = `campaign/addIntrestPriceOnProduct`
    let reqObj = {
      quantity : this.intrestedQuantity,
      productId : this.productDetails.productId[0]._id,
      productSizeId : this.pdId,
      value : this.value,
      unit : this.unit,
      interestedPrice : this.interestedPrice,
      spordicType : this.spordicType

    }

    this.commonService.postApi(url, reqObj, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        $('#interestedPriceModal').modal('hide')
        this.commonService.hideSpinner();
        this.toastrService.success("Intrested price added successfully");
        // this.commonService.successToast(res.responseMessage);

      } else {

        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.flag = false
      this.commonService.errorToast(err.responseMessage)
    })
   
  }
}
