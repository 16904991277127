import { CommonService } from './../../../../provide/common.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-otp-verification-service-provider',
  templateUrl: './otp-verification-service-provider.component.html',
  styleUrls: ['./otp-verification-service-provider.component.scss']
})
export class OtpVerificationServiceProviderComponent implements OnInit,OnDestroy
 {

  // otp: any;
  otpForm!: FormGroup
  otp: any;
  myEmail: any;
  countDowon: any = 2.59
  serviceRole: string;
  signupTitle: string;
  userType: string;

  flag = false


  contDownTimer() {
    var a = window.setInterval(() => {
      if (this.countDowon - (parseInt(this.countDowon)) > 0.60) {

        this.countDowon = this.countDowon - 0.40
      }
      if (this.countDowon > 0) {
        this.countDowon = this.countDowon - 0.01
        this.getTime((this.countDowon).toFixed(2))
      }
      else {
        this.resend_otp_active = true
        clearInterval(a)
      }
    }, 1000)
  }

  resend_otp_active: boolean = false
  time!: string
  getTime(e: any) {
    this.time = String(e).replace('.', ':')
    this.time = String(e).replace('-', '')
  }
  constructor(private http: HttpClient, public commonService: CommonService, private router: Router) { 
    this.serviceRole = localStorage.getItem('serviceRole')
    if(this.serviceRole == "PICKUP"){
      this.signupTitle  = "Signup As Pickup Partner"
      this.userType = "PICKUP_PARTNER"
    }
    else if(this.serviceRole == "DELIVERY"){
      this.signupTitle  = "Signup As delivery Partner"
      this.userType = "DELIVERY_PARTNER"
      
    }
    else if(this.serviceRole == "FIELD"){
      this.signupTitle  = "Signup As field entity"
      this.userType = "FIELD_ENTITY"
    }
    else{
      this.signupTitle  = "Signup As Service Provider"
      this.userType = "SERVICE_PROVIDER"
    }
  }

  ngOnInit(): void {
    this.otpForm = new FormGroup({
      otp: new FormControl("", [
        Validators.required,

      ]),
    });

    this.myEmail = localStorage.getItem('email')
    let a  = Number(localStorage.getItem('remainingTime'))
    this.timer(a || 180);

    // this.resendOtp();
  }
  timerOn = true;

  onOtpChange(otp: any) {
    this.otp = otp;
  }



  navigateToResetPassword() {
    let userData = {
      email: this.myEmail,
      otp: this.otp,
      userType: this.userType,
    }
this.flag = true

    this.commonService.postApi('user/verifyOTP', userData, 0).subscribe((res: any) => {
      
      // if(res['']) 
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner()
        this.commonService.successToast(res['responseMessage'])
        this.router.navigate(['/reset-password-service-provider'])
      }

    }, err => {
      this.commonService.hideSpinner()
      if (err['responseCode'] == 400) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err['responseMessage'])
      }
      
    })

  }


  // resend otp 
  resendOtp() {
    if (!this.isExpire) {
      return
    }
    this.commonService.showSpinner();
    this.myEmail = localStorage.getItem('email');
    var email = {
      'email': this.myEmail,
      userType: this.userType,
    }
    this.commonService.postApi('user/resendOTP', email, 0).subscribe(res => {
      
      if (res['responseCode'] == 200) {
        this.timer(180);
        this.isExpire=false;
        this.commonService.hideSpinner()
        this.commonService.successToast(res['responseMessage'])
      }
    }, err => {
      
      this.commonService.hideSpinner()
      this.commonService.successToast(err['responseMessage'])
    })
  }

  remainingTime: any
  isExpire: boolean = false
  timeOutId:any;
  timer(remaining) {
    let m: any = Math.floor(remaining / 60);
    let s: any = remaining % 60;

    m = m < 10 ? '' + m : m;
    s = s < 10 ? '0' + s : s;
    this.remainingTime = m + ':' + s

    // document.getElementById('timer').innerHTML = m + ':' + s;
    remaining -= 1;
    let me = this
    localStorage.setItem('remainingTime',remaining)
    if (remaining >= 0 && this.timerOn) {
      this.timeOutId =  setTimeout(function () {
        me.timer(remaining);
      }, 1000);
      return;
    }

    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    localStorage.removeItem('remainingTime')
    this.isExpire = true
  }
  ngOnDestroy(): void {
    // This method will be called just before the component is destroyed
    this.timer(0)
    clearTimeout(this.timeOutId)
    localStorage.removeItem('remainingTime');
 }

}
