import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateManagementService {
  public categoryRetailer = new BehaviorSubject<any>(undefined); // login status
  public getProductRetailer = new BehaviorSubject<any>(undefined); // login status
  public getDealRetailer = new BehaviorSubject<any>(undefined); // login status
  public getserviceOnBookingRetailer = new BehaviorSubject<any>(undefined); // login status
  public getServiceRetailer = new BehaviorSubject<any>(undefined); // login status

  public categoryCustomer = new BehaviorSubject<any>(undefined); // login status
  public getProductCustomer = new BehaviorSubject<any>(undefined); // login status
  public getDealCustomer = new BehaviorSubject<any>(undefined); // login status
  public getServiceCustomer = new BehaviorSubject<any>(undefined); // login status
  public getserviceOnBooking = new BehaviorSubject<any>(undefined); // login status

  constructor() { }
}
