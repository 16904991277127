import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {
  btn: any = "Pending"
  orderList: any
  count: any
  catName: any
  currentPage: any = 1;
  pageSize: any = 10;
  serviceStatus: any = 'PENDING'
  notFoundData: boolean = false
  headingName: any = 'Transaction History'
  linkerRouteItems: any = [
    // { name:'Home',route: '/service-order-detail'},
    { name: 'Transaction History', route: '/customer-profile' }
  ]

  service
  serviceRole: string;
  myId
  constructor(public commonService: CommonService, public router: Router) { }

  ngOnInit(): void {
    this.serviceOrderList();
    this.serviceRole = localStorage.getItem("serviceRole")
    this.myId = localStorage.getItem("myId")

  }

  selectBtn(status) {

    this.serviceOrderList();

  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  isLoading: boolean = false
  wallet: any
  wallet2: any
  serviceOrderList() {
    this.isLoading = true;
    this.orderList = []
    this.count = 0;
    const data = {
      page: this.currentPage,
      limit: this.pageSize,
    }
    this.commonService.getApi(`deliveryOrders/getWalletTransaction?isCommission=true&page=${this.currentPage}&limit=${this.pageSize}`, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        this.orderList = res.result.docs;
        this.wallet = res.result.wallet
        this.wallet2 = res.result
        if (res.result.docs.length > 0) {
          this.notFoundData = false
        } else {
          this.notFoundData = true
        }
        this.count = res.result.total
        this.catName = res.result.docs.myOrders[0]
      }

    }, (err: any) => {
      this.isLoading = false
      this.notFoundData = true
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  changePageNumber(page) {
    this.currentPage = page;
    this.serviceOrderList();
  }

  getWalletName(usType: string) {
    let walletName = usType == "PICKUP_PARTNER" ? "PW" :
      usType == "FIELD_ENTITY" ? "FW" :
        usType == "DELIVERY_PARTNER" ? "DDW" :
          usType == "CUSTOMER" ? "CW" :
            usType == "RETAILER" ? "RW" :
              usType == "ADMIN" ? "XAW" : ""



    if (walletName == 'XAW') {
      if (this.serviceRole == 'DELIVERY') {
        walletName = "DW"
      }
    }

    return walletName
  }

}
