import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-linker-route',
  templateUrl: './linker-route.component.html',
  styleUrls: ['./linker-route.component.scss']
})
export class LinkerRouteComponent implements OnInit {
@Input() headingName :any
@Input() linkerRouteItems  :any
@Output() clicked = new EventEmitter<void>();
  constructor(public router: Router,public commonService:CommonService) { }

  ngOnInit(): void {
  }
  routeClick(event,last){
    if(!last){
      if(event.query){
        this.router.navigate([event.route],{queryParams : event.query})
      }
      else{

        this.router.navigate([event.route])
      }
    }
  }
}
