import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../provide/common.service';
import { AuthService } from '../provide/auth.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    public authService: AuthService,
    private commonService: CommonService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.commonService.hideSpinner();
        var errorThrow = {
          responseCode: error.error ? error.error.responseCode : 400,
          responseMessage: error.error
            ? error.error.responseMessage
            : 'Something went wrong',
          result: error.error ? error.error.result : {},
        };
        if (error.error instanceof ErrorEvent) {
        } else {
          switch (error.error.responseCode) {
            case 400:
              // this.authService.logout()
              // this.commonService.errorToast(error.error.responseMessage);
              break;
            case 401:
              // this.commonService.errorToast('Unauthorized Access.');
              this.commonService.errorToast('Please Login');
              this.authService.logout();
              break;
            case 402:
              // this.commonService.errorToast('You have been blocked by Admin.');
              // this.commonService.errorToast(error.error.responseMessage);
              // this.authService.logout();
              break;
            case 403:
              // this.commonService.errorToast('You have been blocked by Admin.');
              this.commonService.errorToast(error.error.responseMessage);
              // this.authService.logout();
              break;
            case 404:
              // this.commonService.errorToast('Unauthorized Access.');
              // this.commonService.errorToast(error.error.responseMessage);
              // this.authService.logout()
              break;
            case 440:
              // this.commonService.errorToast('Please provide token');
              this.commonService.errorToast(
                'Your session has expired please login again.'
              );
              this.authService.logout();
              break;
            case 500:
              if (error.error.responseMessage.includes('jwt expired')) {
                this.authService.logout();
              }
              // this.commonService.errorToast('Internal Server Error.');
              break;
            default:
              // this.commonService.errorToast(error.error.responseMessage);
              break;
          }
        }
        return throwError(errorThrow);
      })
    );
  }
}
