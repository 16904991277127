import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $ : any
@Component({
  selector: 'map-navigation',
  templateUrl: './map-navigation.component.html',
  styleUrls: ['./map-navigation.component.scss']
})
export class MapNavigationComponent implements OnInit {
  @Input() navDetail : any

  constructor(private router : Router) { }

  ngOnInit(): void {
    $('#navigateModal').modal({ backdrop: 'static' });
  }
  openGoogleMapsWithRouting() {
    var origin = "Your Current Location"; // You can replace this with your actual origin address or coordinates
    var destination = this.navDetail.lat + "," + this.navDetail.long;

    var mapsUrl = "https://www.google.com/maps/dir/?api=1&origin=" + encodeURIComponent(origin) + "&destination=" + encodeURIComponent(destination);

    this.router.navigate(['/service-order-detail'])
    window.open(mapsUrl, '_blank');
  }
  backRoute(){
    this.router.navigate(['/service-order-detail'])

  }
}
