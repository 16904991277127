import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-cancel-customer-payment',
  templateUrl: './cancel-customer-payment.component.html',
  styleUrls: ['./cancel-customer-payment.component.scss']
})
export class CancelCustomerPaymentComponent implements OnInit {

  constructor(public commonService:CommonService) { }

  ngOnInit(): void {
  }

}
