import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements AfterContentChecked {
  @Input() feedBackData: any
  @Input() isFeedbackDataSubmited: boolean
  @Output() submitFeedBack = new EventEmitter
  orderId: any
  review: any = []
  constructor(private activatedRoute: ActivatedRoute, private commonService: CommonService) {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.orderId = res._id
    })
  }

  ngAfterContentChecked(): void {
    if (this.isFeedbackDataSubmited) {
      let i = 0
      for (const item of this.feedBackData) {
        this.review[i] = item.review
        let rating = item.rating * 2


        let star: any = document.getElementById('rating' + rating + i);
      
        if (star) {
          star.checked = true;
        }
        i++
      }
    }

  }
  finalResult: any
  getSelectedRating(value: any, index) {
    this.feedBackData[index]['rating'] = value / 2


  }
  submitFeedback() {
    let arr = []
    for (let i = 0; i < this.feedBackData.length; i++) {
      if (!this.feedBackData[i].rating || !this.review[i]) {
        return this.commonService.errorToast('Please select star and enter your review')
      }
      let obj = {
        ratingToUserType: this.getRatingTo(i),
        orderId: this.orderId,
        rating: this.feedBackData[i].rating,
        review: this.review[i]
      }
      arr.push(obj)
    }
    if (arr.length) this.submitFeedBack.emit(arr)
    console.log(arr);

  }
  getRatingTo(i) {
    if (this.feedBackData[i].type == 'ORDER') {
      return 'RETAILER'
    }
    if (this.feedBackData[i].type == 'DELIVERY') {
      return 'DELIVERY_PARTNER'
    }
    if (this.feedBackData[i].type == 'PICKUP') {
      return 'PICKUP_PARTNER'
    }
    if (this.feedBackData[i].type == 'CUSTOMER') {
      return 'CUSTOMER'
    }
    if (this.feedBackData[i].type == 'FIELD_ENTITY') {
      return 'FIELD_ENTITY'
    }
  }
}
