import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-change-password-customer',
  templateUrl: './change-password-customer.component.html',
  styleUrls: ['./change-password-customer.component.scss']
})
export class ChangePasswordCustomerComponent implements OnInit {
  changPassword!: FormGroup
  headingName:any ='Change Password'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'My Profile',route:'/customer-profile'},
    {name:'Change Password',route:'/change-password-customer'}
  ]


  // foricon eye
  // myEmail: any;
  visible: boolean = false;
  changetype: boolean = true;

  myToken: any;

  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;

  }

  // 2nd

  visibles: boolean = false;
  changetypes: boolean = true

  viewpas() {
    this.visibles = !this.visibles;
    this.changetypes = !this.changetypes
  }

  // 3rd


  visibled: boolean = false;
  cengesestype: boolean = true

  viewpassd() {
    this.visibled = !this.visibled;
    this.cengesestype = !this.cengesestype;
  }




  constructor(private fb: FormBuilder, http: HttpClient, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {


    this.changPassword = this.fb.group({
      'oldpassword': new FormControl('', [
        Validators.required,
        // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)

      ]),
      'password': new FormControl('', [
        Validators.required,
        // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)
      ]),
      'confirmPassword': new FormControl('', [
        Validators.required,
      ]),

    })

    // this.myEmail = localStorage.getItem('email')
    this.myToken = localStorage.getItem('token')
 


  }
  elseBlock=false; //mychange

  navigateTochangePassword() {
    if(this.changPassword.invalid){
      return this.commonService.errorToast("Please fill all required field.")
    }
    if(this.changPassword.value.password != this.changPassword.value.confirmPassword){
      return this.commonService.errorToast("Password and confirm password must be same.")
    }

    let apiReqData = {
      password: this.changPassword.value.oldpassword,
      newPassword: this.changPassword.value.password,
      confirmPassword: this.changPassword.value.confirmPassword,
      // "newPassword": this.changPassword.value.confirmPassword,
    }
    // let apiReqUrl = "user/changePassword"
    this.commonService.showSpinner();
    this.commonService.putApi('user/changePassword', apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.router.navigate(['/customer-profile'])
        // this.commonService.successToast(res.responseMessage)
        this.commonService.showSuccessToast("Updated Successfully");
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    },(err)=>{
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage);
    })
  }
  preventSpace(event: any) {
    if ((event.charCode == 32) && !event.target.value) {
      event.preventDefault();
    }
  }
  navigateToProfile(){
    this.router.navigate(['/customer-profile'])
  }
}
