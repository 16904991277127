import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  emailRegex = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
  footerpage!: FormGroup
  activeButton: any
  currUrl: any;
  emailN: any
  constructor(private fb: FormBuilder, http: HttpClient, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {

    // this.loggedin()
    this.currUrl = (this.router.url).replace(/\//g, "");


    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.currUrl = (this.router.url).replace(/\//g, "");

      }
    });

    this.footerpage = new FormGroup({


      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
    })

    // this.getSocialLinks()
  }



  // ====================================================save button================================
  getDeals() {
    if(!String(this.emailN).match(this.emailRegex)){
      this.commonService.errorToast('Please enter valid email');
      return
    }
    let data = {
      email: this.emailN
      // email: this.footerpage.value.email,
    }

    let apiReqUrl: any = `user/addSubscription`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
        this.commonService.showSuccessToast("Subscribed");
        this.emailN=""

        // this.router.navigate(['/list-deals'])
        // localStorage.setItem('token', res.result.token)
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })

  }
  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }
  showOrderStatus(status: any) {
    this.activeButton = status;
  }
  isEmailValid() : boolean{
    let a = String(this.emailN).match(this.emailRegex)
    return a ? false : true
  }
}