<div class="container fw-b">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="container mb-5 wrapper-content">
  <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3">
    <ng-container *ngIf="productDetails?.length">
      <div class="mb-3 col newCardWidth newCardWidth1" *ngFor="
      let item of productDetails
        | paginate
          : {
              itemsPerPage: pageSize,
              currentPage: currentPage,
              totalItems: totalItems
            };
      let i = index
    ">
        <deals-layout-service [data]="item" [countDown]="countDown[i]" (on_Click)="navigateToViewProduct(item)">
        </deals-layout-service>
      </div>
    </ng-container>

    <ng-container *ngIf="!productDetails?.length && !isLoading">
      <div class="mb-3 col newCardWidth newCardWidth1" *ngFor="
      let item of length(12)">
        <ngx-skeleton-loader  [theme]="{ width: '227px', height: '329px', position: 'relative' }"
          count="1"></ngx-skeleton-loader>
      </div>
    </ng-container>

    <div *ngIf="isLoading" class="no-data">
      <p style="text-align: center; font-size: 16px; font-weight: 500">
        No Data Found...
      </p>
    </div>
  </div>
  <div class="custom-pagination mt-2 text-align-right" style="text-align: right" *ngIf="totalItems > 12">
    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
  </div>
</div>