import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-customer-deals-service-view',
  templateUrl: './customer-deals-service-view.component.html',
  styleUrls: ['./customer-deals-service-view.component.scss']
})
export class CustomerDealsServiceViewComponent implements OnInit {
  serviceName: any
  serviceDetail: any = []
  _id: any
  dealImg: any
  currentPage: any = 1
  latitude: any;
  longitude: any;
  pageSize = 12;
  totalItems: any
  constructor(public commonService: CommonService, public activated: ActivatedRoute, public router: Router) {
    this.activated.queryParams.subscribe((res: any) => {
      this._id = res._id
    })
  }

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition(function(position) {  
      // alert('allow');  
  }, function() {
      alert('You need to enable geolocation services in your browser to use this feature.');
  });
  this.getPosition().then(pos=>
    
    {
    localStorage.setItem('location',JSON.stringify(pos))
  });
  this.getLocation();
  
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {

          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.serviceOrderList()
        }
      },
        (error) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  isLoading: boolean = false;
  serviceOrderList() {
    // let url = `deal/dealsListServiceParticular?categoryId=${this._id}`
    let url = `deal/dealsListOnCategory?categoryId=${this._id}`
    // let url =`product/listProductBySortion`
    // let data = {
    //   "dealType": "PRODUCT",
    //   "userType": "RETAILER",
    //   'lng': this.longitude,
    //   'lat': this.latitude,
    //   'page': this.currentPage,
    //   'limit': this.pageSize,
    //   search: this.serviceName,
    // }
    // this.commonService.removeEmptyKey(data)

    this.commonService.showSpinner()
    this.commonService.getApi(url, 0).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        if (this.timerInterval) {
          clearInterval(this.timerInterval);
          this.countDown = [];
        }
        this.serviceDetail = res.result;
        if (!this.serviceDetail.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        this.totalItems = res.result.totalPages
        for (let index = 0; index < this.serviceDetail.length; index++) {
          this.getCoundown(this.serviceDetail[index]['dealEndTime'], index);
        }
        // this.service.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
      } else {
        this.isLoading = true;
        this.serviceDetail = [];
        this.commonService.hideSpinner()

      }

    }, (err: any) => {
      this.isLoading = true;
      this.commonService.hideSpinner()
      this.serviceDetail = [];
      if (err.responseCode == 409) {
        this.isLoading = true;
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }
  pagination(event) {
    this.currentPage = event;
    this.serviceOrderList();
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }

  // timer

  myTimer() { }
  // getDealDescription
  countDown: any = [];
  timerInterval: any = [];
  new: any
  getCoundown(date: Date, i: number) {
    var countDownDate = new Date(date).getTime();
    let me = this;
    // Update the count down every 1 second

    this.timerInterval[i] = setInterval(() => {
      if (true) {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        me.countDown[i] =
        days + 'D:' + hours + 'H:' + minutes + 'M:' + seconds + 'S';        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(me.timerInterval);
          me.countDown[i] = 'EXPIRED';
        }
      }
    }, 1000);
  }
  navigateToViewProduct(id) {
    sessionStorage.setItem('selectedService', JSON.stringify(id))
    this.router.navigate(['/customer-service-deal-view-particular'])
  }

}
