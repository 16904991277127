import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

declare var $
@Component({
  selector: 'app-customer-category-particular-view',
  templateUrl: './customer-category-particular-view.component.html',
  styleUrls: ['./customer-category-particular-view.component.scss']
})
export class CustomerCategoryParticularViewComponent implements OnInit {
  _id: any
  isLoggedIn: any
  loggedInUserType: any
  spordicType: string = 'AUTOMATIC';
  iaAPILoaded: boolean = false
  imageLoaded: boolean = false
  headingName: any = 'PRODUCT DETAILS'
  linkerRouteItems: any = [
    { name: 'Home', route: '/home' },
    { name: 'Categroy', route: '/customer-category-list' },
    { name: 'Categroy Deatil', route: '/customer-category-particular-view' },
  ]
  afterDiscountPrice: any;
  categoryName: any;
  otName: any;
  subCatId: any = []
  otId: any;
  index: number = 0
  constructor(private activatedroute: ActivatedRoute,     private toastrService: ToastrService,public commonService: CommonService, private router: Router, public authService: AuthService) {

    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.categoryName = res.categoryName;
      this.otId = res.otId
      this.otName = res.otName

    })
    this.spordicType = localStorage.getItem('campainPrefrences')
    console.log(this.spordicType, "dddd")
  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res: any) => {
      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res
    })
    this.getProduct();
    this.getLocation();
    // this.getSimiliarProduct()
  }

  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any
  flag: boolean = true

  iscampainValid : boolean[] = []
  getEndUrlSegment(): string {
    const url = this.router.url;
    const segments = url.split('/');
    const lastSegmentWithQueryParamsAndFragments = segments[segments.length - 1];
    const endUrlSegment = lastSegmentWithQueryParamsAndFragments.split('?')[0].split('#')[0];
    return endUrlSegment;
  }


  getProduct() {
    if (this.isLoggedIn) {
      this.viewRetailerProductd()
    } else {
      this.viewRetailerProductdWithoutLogin()
    }
  }
  firstPriceSize: any = [];
  productDetailsSize: any = []
  catId: any
  intrestedPrice: boolean = false
  // get retailer detail by id
  viewRetailerProductd(id?) {
    // this.commonService.showSpinner();
    let apiReqUrl: any = `product/viewProduct?productId=${id || this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.iaAPILoaded = false


    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        setTimeout(() => {
          this.iaAPILoaded = true
          this.index = 0

        }, 500);
        this.commonService.hideSpinner();
        this.productDetails = res.result;
        this.subCatId = [this.productDetails.subCategoryId._id]

        if (this.getEndUrlSegment() == 'product-view-customer') {
          this.linkerRouteItems = [
            { name: 'Home', route: '/home' },
            { name: 'Product', route: '/customer-product-list' },
            { name: 'Product Detail', route: '/product-view-customer' },
          ]

        } else {
          this.linkerRouteItems[2] = { name: this.productDetails.categoryId.categoryName, route: '/customer-category-list-view', query: { '_id': this.otId, 'categoryName': this.otName } }
          this.linkerRouteItems[3] = { name: 'Product Deatil', route: '/customer-category-particular-view' }
        }

        if (this.productDetails.isCampaignActive) {

          this.getCoundown(this.productDetails.campainId.endDate)
          for (let i = 0; i < this.productDetails.priceSizeDetails.length; i++) {
           let findCampain = this.productDetails.campainId.campaignDetail.find((ele)=>{
            return ele.id == this.productDetails.priceSizeDetails[i].id
           })
           if(findCampain){
            this.iscampainValid[i] = true
           }
           else{
            this.iscampainValid[i] = false
           }
            
          }


        }



        this.isLoading = true
        this.catId = this.productDetails.categoryId._id
        this.productDetailsSize = res.result.priceSizeDetails
        this.firstPriceSize = res.result.priceSizeDetails[0]
        this.afterDiscountPrice = this.calDiscountOn(this.firstPriceSize.price)
        this.price(this.firstPriceSize.weight, this.firstPriceSize.volume, this.firstPriceSize.price, this.firstPriceSize.quantity, this.firstPriceSize.value, this.firstPriceSize.id, this.firstPriceSize.unit)
        this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.intrestedPrice = res.result.isIntrestPriceAdded
        this.getProducts();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.isLoading = false
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {
      this.commonService.hideSpinner();
    })
  }

  getcampaignDiscountedPrice(){
    
    let ff = this.productDetails.campainId.campaignDetail.find((ele)=>{
      return ele.id == this.pdId
    })
    return ff
  }
  viewRetailerProductdWithoutLogin(id?) {
    // this.commonService.showSpinner();
    let apiReqUrl: any = `product/viewProduct?productId=${id || this._id}`
    this.iaAPILoaded = false
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`


    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        setTimeout(() => {
          this.iaAPILoaded = true
          this.index = 0
        }, 500);

        this.commonService.hideSpinner();
        this.productDetails = res.result;
        this.subCatId = [this.productDetails.subCategoryId._id]
        this.linkerRouteItems[2] = { name: this.productDetails.categoryId.categoryName, route: '/customer-category-list-view' }
        this.linkerRouteItems[3] = { name: 'Product details', route: '/customer-category-particular-view' }

        if (this.productDetails.isCampaignActive) {

          this.getCoundown(this.productDetails.campainId.endDate)
          for (let i = 0; i < this.productDetails.priceSizeDetails.length; i++) {
           let findCampain = this.productDetails.campainId.campaignDetail.find((ele)=>{
            return ele.id == this.productDetails.priceSizeDetails[i].id
           })
           if(findCampain){
            this.iscampainValid[i] = true
           }
           else{
            this.iscampainValid[i] = false
           }
            
          }


        }
        this.isLoading = true
        this.mrp = this.productDetails.priceSizeDetails[0].price
        this.afterDiscountPrice = this.calDiscountOn(this.productDetails.priceSizeDetails[0].price)
        this.catId = this.productDetails.categoryId._id
        this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"];
        this.getProducts()
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.isLoading = false
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  isLoading: boolean = false

  forID(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } })

  }
  timerInterval: any;
  countDown: any;
  getCoundown(date: Date) {
    var countDownDate = new Date(date).getTime();
    let me = this;
    // Update the count down every 1 second

    this.timerInterval = setInterval(() => {
      if (true) {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        this.countDown =
          days + 'D:' + hours + 'H:' + minutes + 'M:' + seconds + 'S';
        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(me.timerInterval);
          me.countDown = 'EXPIRED';
        }
      }

      // me.countDown[i] = mm
    }, 1000);
  }
  getCountDownTime(date?: Date) {

    if (!this.countDown) {
      this.getCoundown(date)
    }
    return this.countDown
  }


  changeProductimage(url: any) {

    this.imgurl = url
  }


  getPrice(e: any) {
    if (e) {
      let a = e.replace(/,/g, ' ')
      return a.replace(/R/g, 'R ')
    } else {

    }

  }
  mrp: any
  quantity: any
  value: any
  unit: any
  weight: any
  volume: any
  pdId: any

  price(w: any, vol: any, e: any, q: any, v: any, id, u, i?) {
    this.index = i
    this.weight = w
    this.volume = vol
    this.mrp = e,
    this.quantity = q
    this.value = v
    this.unit = u
    this.pdId = id
    this.afterDiscountPrice = this.calDiscountOn(this.mrp)
  }
  calDiscountOn(realPrice) {
    let dsp = (Number(this.productDetails.dealDiscount) / 100) * realPrice
    return this.mrp - dsp
  }
  imgSrc: any
  productImage: any
  serviceData: any = []
  latitude: any;
  longitude: any;

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getProducts()

        }
      },
        (error) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  // get retailer detail by id
  getProducts() {
    if (this.isLoggedIn) {
      this.getSimiliarProductwithToken()
    } else {
      this.getSimiliarProduct()
    }
  }
  user: any
  currentPage = 1;
  pageSize = 12;

  isOutofStock(quantity, otherQty) {
    if (quantity || otherQty) {
      return quantity ? quantity : otherQty
    } else {
      return 'Out of stock'
    }
  }
  isOutofStockButton(quantity, otherQty) {
    if (quantity || otherQty) {
      return (quantity || otherQty) ? false : true
    }
  }
  getSimiliarProduct() {
    // let apiReqUrl: any = `product/similarProductList?productId=${this._id}`

    let apiReqUrl: any = `product/listProductBySortion`
    // let apiReqUrl: any = `product/viewProduct?productId=${this._id}`/    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    if (this.loggedInUserType == 'RETAILER') {
      this.user = 'WHOLE_SALER'
    } else {
      this.user = 'RETAILER'
    }

    let data = {
      "userType": this.user,
      'lng': this.longitude,
      'lat': this.latitude,
      'page': this.currentPage,
      'limit': this.pageSize,
      "categoryId": [
        this.productDetails.length && this.productDetails.categoryId._id
      ],
      "similarSubCategoryIds": this.subCatId
    }
    this.commonService.postApi(apiReqUrl, data, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.serviceData = []
        for (let item of res.result.docs) {
          if (this.productDetails._id !== item._id) {
            this.serviceData.push(item);
          }

        }

        // this.imgurl = res.result.productImage[0]
        // this.imgSrc = res.result.productImage[0]
        // this.imageLinks = res["result"]["thumbnail"]
        // this.productImage =res.result.productImage



        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {

    })
  }
  getSimiliarProductwithToken() {
    // let apiReqUrl: any = `product/similarProductList?productId=${this._id}`
    if (!this.productDetails) {
      return
    }

    let apiReqUrl: any = `product/listProductBySortion`
    // let apiReqUrl: any = `product/viewProduct?productId=${this._id}`/    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    if (this.loggedInUserType == 'RETAILER') {
      this.user = 'WHOLE_SALER'
    } else {
      this.user = 'RETAILER'
    }
    let data = {
      "userType": this.user,
      'lng': this.longitude,
      'lat': this.latitude,
      'page': this.currentPage,
      'limit': this.pageSize,
      "categoryId": [
        this.productDetails && this.productDetails.categoryId._id
      ],
      "similarSubCategoryIds": this.subCatId
    }
    // this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, data, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.serviceData = []
        for (let item of res.result.docs) {
          if (this.productDetails._id !== item._id) {
            this.serviceData.push(item);
          }

        }

      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {

    })
  }
  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.viewRetailerProductd()
        // this.router.navigate(['/edit-product'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }
  addToCart() {
    let apiReqUrl = `user/v2/productAddToCart`
    let price = Number(this.mrp)
    if (this.productDetails.campainId && this.productDetails.campainId.campaignDetail && this.productDetails.campainId.campaignDetail.length ) {
      let find = this.productDetails.campainId.campaignDetail.find((ele)=>{return ele.id == this.pdId})
      if(find){
        price = find.discountedPrice
      }
      
    }
    let apiReqData = {

      "productId": this._id,
      "priceSizeDetails": {
        "value": this.value,
        "price": price,
        "unit": this.unit,
        "weight": this.weight,
        "volume": this.volume,
        "id": this.pdId
      },
      "orderType": "PRODUCT",
      "quantity": 1,
      "totalPrice": this.mrp,
      "addType": "PRODUCT"

    }
    this.flag = false
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
        this.router.navigate(['/addtoCart-customer'])
        this.flag = false
      } else {
        this.flag = false

        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.flag = false
      this.commonService.errorToast(err.responseMessage)
    })
  }
  showMessage() {
    return this.commonService.errorToast('Please Login')
  }

  viewProducts(_id) {
    this.iaAPILoaded = false

    if (this.isLoggedIn) {
      this.viewRetailerProductd(_id);
    } else {
      this.viewRetailerProductdWithoutLogin(_id)
    }



    // this.router.navigate(['/product-view-customer'],{queryParams:{_id:_id}})
  }

  onImageLoad() {
    this.imageLoaded = true
  }
  rawText(str: string): string {
    return str.replace(/\n/g, '<br>')
  }
  length(n) {
    return new Array(n)
  }
  showInterestedPriceModal = false;
  openModal() {
    $('#interestedPriceModal').modal('show')
    this.showInterestedPriceModal = true
    // this.selectedIndex = 0
    // this.campaignModel[0] = { ...this.productDetails.priceSizeDetails[0], ...{ discountedPrice: this.productDetails.priceSizeDetails[0].price, discountedPercentage: 0, releaseQuantity: 0 } }
    // this.priceOfSize = this.productDetails.priceSizeDetails[0]
  }
  lengthOf(n: number) {
    return new Array(n)
  }
  intrestedQuantity = 0
  interestedPrice
  confirmInterestedPrice() {
    if(this.interestedPrice === "0"){
      this.commonService.errorToast("Interested Price cannot be 0 ")
      return;
    }
    // this.mrp = e,
    // this.quantity = q
    // this.value = v
    // this.unit = u
    // this.pdId = id
    // this.afterDiscountPrice = this.calDiscountOn(this.mrp)
    let url = `campaign/addIntrestPriceOnProduct`
    let reqObj = {
      quantity: this.intrestedQuantity,
      productId: this._id,
      productSizeId: this.pdId,
      value: this.value,
      unit: this.unit,
      weight: this.weight,
      volume: this.volume,
      interestedPrice: this.interestedPrice,
      spordicType: this.spordicType

    }

    this.commonService.postApi(url, reqObj, 1).subscribe((res: any) => {
      if (res.responseCode === 200) {
        $('#interestedPriceModal').modal('hide')
        this.commonService.hideSpinner();
        this.toastrService.success("Intrested price added successfully");
        // this.commonService.successToast(res?.responseMessage);

      } else {
        this.commonService.errorToast(res?.responseMessage)
      }
    }, (err) => {

      this.commonService.errorToast(err.responseMessage)
    })

  }
  
}
