<!-- NAVBAR -->


<div class="container" style="max-width:95% ;">
    <div class="row">
        <div class="col-lg-12 ">
            <!-- <img class="w-100" src="/assets/product.png" alt=""> -->
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="2500">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="d-block rounded mx-auto qwqwqw-1 w-100" src="{{BannerListArray[0]?.thumbnail || '/assets/product.png'

                            }}" alt="First slide">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block rounded mx-auto qwqwqw-1 w-100" src="{{BannerListArray[1]?.thumbnail || '/assets/imgab.png'

                            }}" alt="First slide">
                    </div>
                    <div class="carousel-item">
                        <img class="d-block rounded mx-auto qwqwqw-1 w-100" src="{{BannerListArray[3]?.thumbnail || '/assets/product.png'

                            }}" alt="First slide">
                    </div>
                </div>
                <!-- <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a> -->
            </div>

        </div>
    </div>
    <div class="row ">
        <div class="col-xl-2 col-lg-12 mt-4 mb-3 scrol">
            <!-- <h2 class="gg ml-3 list-cate">List Category</h2> -->
            <div class="ex1">
                <div id="accordion">

                    <mat-accordion class="accordian-class">
                        <!-- <h2 class="gg ml-3">List Category</h2> -->
                        <mat-expansion-panel (opened)="panelOpenState = true" (click)="getSubCategoryList(item?._id)"
                            (closed)="panelOpenState = false" *ngFor="let item of categoryListArray"
                            (change)="getCategoryLists()">
                            <mat-expansion-panel-header class="faq-qsn">

                                <mat-panel-title class="gg" style="color:#8c3030 ;" (click)="searchCategory(item?._id)">

                                    <h3 style="margin-bottom: 0;">{{item?.categoryName | titlecase}}</h3>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngFor="let item of subCategoryListArray" (click)="productBySubCategory(item?._id)">
                                <!-- <i class="fa fa-arrow-right" aria-hidden="true"></i> -->

                                <span class="ggg ml-2" style="cursor: pointer;"
                                    [ngClass]="{'active' : tab == item?.subCategoryName }"
                                    (click)="select(item?.subCategoryName)">{{item?.subCategoryName}} <i
                                        *ngIf="tab == item?.subCategoryName" (click)="reset()"
                                        class="fa fa-times"></i></span>
                            </div>

                        </mat-expansion-panel>
                    </mat-accordion>

                </div>
            </div>

        </div>

        <!-- newcord for card  -->
        <div class="col-xl-10 col-lg-12 col-md-12 ">
            <div class="btn d-flex justify-content-end mt-2">
                <button type="button" class="btn btn6" routerLink="/add-product" id="asd"><i class="fa fa-plus"
                        aria-hidden="true"></i>Add Products</button>
            </div>
            <div class="row">

                <div class="col-lg-4 col-md-6"
                    *ngFor="let item of categoryListArrayNew | paginate:{itemsPerPage:itemPerPage, currentPage: currentPage, totalItems: totalItems};let j = last">
                    <mat-card>

                        <div class="d-flex justify-content-end mb-3">
                            <!-- <i class="fa-solid fa-pen" style="color: #BF1E2E; cursor: pointer;"
                                (click)="forEdit(item?._id)"></i> -->
                        </div>
                        <div id="carouselExampleControls" class="carousel slide" data-interval="50000">

                            <div class="carousel-inner">

                                <div class="carousel-item active">

                                    <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">

                                            <div class="carousel-item active">

                                                <img class="qwqwqw" [src]="item?.thumbnail || '/assets/img/dummy.jpeg'"
                                                    alt="">

                                                <!-- <img class="qwqwqw"
                                                    [src]="item?.productImage[0] || '/assets/img/dummy.jpeg'" alt=""> -->

                                            </div>
                                            <div class="carousel-item">

                                                <img class="qwqwqw"
                                                    [src]="item?.productImage[1] || '/assets/img/dummy.jpeg'" alt="">
                                            </div>
                                            <div class="carousel-item">

                                                <img class="qwqwqw"
                                                    [src]="item?.productImage[2] || '/assets/img/dummy.jpeg'" alt="">
                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>



                        </div>

                        <!-- <div class="d-flex justify-content-around mt-3">

                            <div class=" ">
                                <p class="pid">Product Id <span class="gg text-center">:</span></p>
                                <p class="pid"> Product Name :</p>
                                <P class="pid"> Product Category:</P>
                                <P class="pid"> Qty.Available :</P>
                                <P class="pid"> Price :</P>

                            </div>
                            <div class="mr-4">

                                <p class="pid-1"> {{item?._id | slice:0:7 || '--'}}</p>
                                <p class="pid-1"> {{item?.productName || '--'}}</p>
                                <p class="pid-1"> {{item?.categoryId.categoryName || '--'}}</p>
                                <p class="pid-1"> {{item?.quantity || '--'}}</p>
                                <p class="pid-1"> {{item?.price | currency : "R " || '--'}}</p>

                            </div>
                        </div> -->


                        <div class="row mt-3">

                            <div class="col-5">
                                <p class="pid">Product Id </p>
                            </div>
                            <div class="col-2">:</div>
                            <div class="col-5">
                                <div>
                                    <p class="pid-1"> {{item?._id | slice:0:7 || '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-5">
                                <p class="pid"> Product Name </p>
                            </div>
                            <div class="col-2">:</div>
                            <div class="col-5">
                                <p class="pid-1"> {{item?.productName || '--'}}</p>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-5">
                                <P class="pid"> Product Category </P>
                            </div>
                            <div class="col-2">:</div>
                            <div class="col-5">
                                <p class="pid-1"> {{item?.categoryId.categoryName || '--'}}</p>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-5">
                                <p class="pid"> Quantity </p>
                            </div>
                            <div class="col-2 ">:</div>
                            <div class="col-5">
                                <p class="pid-1"> {{item?.priceSizeDetails[0]?.quantity || '--'}}</p>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-5">
                                <p class="pid"> Size/Value </p>
                            </div>
                            <div class="col-2 ">:</div>
                            <div class="col-5 kl-product">
                                <p class="pid-1" *ngFor="let item of item?.priceSizeDetails"> {{item.value || '--'}} {{
                                    j ? '' : ', '}} </p>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-5">
                                <p class="pid"> Price </p>
                            </div>
                            <div class="col-2">:</div>
                            <div class="col-5">
                                <!-- <p class="pid-1"> {{item?.price | currency : "R " || '--'}}</p> -->
                                <!-- <td>{{item?.orderPrice && getPrice(item?.orderPrice | currency:"ZAR":"R")}}</td> -->
                                <p class="pid-1"> {{item?.priceSizeDetails[0]?.price &&
                                    getPrice(item?.priceSizeDetails[0]?.price | currency:"ZAR":"R")}}</p>
                            </div>
                        </div>




                        <div class="d-flex flex-row justify-content-end mt-3">
                            <button mat-stroked-button color="primary" class="view-button" (click)="forID(item?._id)">
                                <i class="fa fa-heart-o mr-1" aria-hidden="true"></i>
                                View
                            </button>
                        </div>

                    </mat-card>
                </div>

            </div>
            <div class="d-flex align-items-center  m-auto" *ngIf="categoryListArrayNew?.length <= 0"
                style="width: 100%;">
                <!-- <h1 class="table-no-record " colspan="6">No Data Found</h1> -->
                <!-- <img style="width: 50%;margin:auto;" src="assets/img/nor.png" alt=""> -->
                <span> Data Not Found</span>
            </div>

        </div>

    </div>
    <div class="custom-pagination mt-2 pagin22" style="text-align-last: end;" *ngIf="totalItems > 20">
        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
    </div>

</div>