<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-4 topfooter">
    <div class="d-flex mt-1" >
        <div class="radio-group">
            <input type="radio"  name="del"  id="Product" (change)="tabChange('PRODUCT')"  [(ngModel)]="interestedPrice" value="PRODUCT">
            <label for="Product" class="ml-2">Product</label>
        </div>
        <div class="radio-group">
            <input type="radio"  name="del"  id="Services" (change)="tabChange('SERVICE')"   [(ngModel)]="interestedPrice" value="SERVICE">
            <label for="Services" class="ml-2"> Services</label>
        </div>
        
    </div>

    <div class="row mt-3">
        <div class="table-responsive" *ngIf="interestedPrice == 'PRODUCT' ">
            <table class="table table-hover table-striped" aria-describedby="">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Product Image</th>
                        <th width="11%" scope="col">Product</th>
                        <th scope="col">Unit/Size</th>
                        <th scope="col">Actual Price</th>
                        <th scope="col">Intrested Price</th>
                        <th scope="col">Campaign Type</th>
                        <th scope="col">Created At</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of orderList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                        <td scope="row">{{10 * (currentPage - 1) + i+1}}</td>
                        <td scope="row">
                            <img class="imagtag" src="{{item?.productId?.thumbnail}}"
                                 alt="">
                        </td>
                        <td>
                            {{item?.productId?.productName}}
                        </td>
                        <td> {{ item?.priceSizeDetails?.value }} {{ item?.priceSizeDetails?.unit !== 'other' ? '' + item?.priceSizeDetails?.unit : '' }}</td>
                        <td>{{item?.priceSizeDetails?.price ? getPrice(item?.priceSizeDetails?.price | currency:"ZAR":"R") : item?.priceSizeDetails?.price}}</td>
                        <td>{{item?.interestedPrice ?  getPrice(item?.interestedPrice | currency:"ZAR":"R") : item?.interestedPrice}}</td>
                        <td> {{ item?.spordicType }} </td>
                        
                        <td>{{item?.createdAt | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        
                   
                    </tr>
                </tbody>
            </table>
            <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'80px'" ></app-table-loader>
            <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%;"
                (pageChange)="changePageNumber($event)"></pagination-controls>
        </div>
        <div class="table-responsive" *ngIf="interestedPrice == 'SERVICE' ">
            <table class="table table-hover table-striped" aria-describedby="">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Booking Image</th>
                        <th scope="col">Booking Category</th>
                        <th scope="col">Booking Name</th>
                        <th scope="col">Actual Price</th>
                        <th scope="col">Intrested Price</th>
                        <th scope="col">Campaign Type</th>
                        <th scope="col">Created At</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of orderList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                        <td scope="row">{{10 * (currentPage - 1) + i+1}}</td>
                        <td scope="row">
                            <img class="imagtag" src="{{item?.serviceId?.userId?.profilePic}}"
                                 alt="">
                        </td>
                        <td>
                            {{item?.serviceId?.categoryId?.categoryName}}
                        </td>
                        <td> {{ item?.serviceId?.serviceName }}</td>
                        <td>{{item?.serviceId?.price ? getPrice(item?.serviceId?.price | currency:"ZAR":"R") : item?.serviceId?.price}}</td>
                        <td>{{item?.interestedPrice ?  getPrice(item?.interestedPrice | currency:"ZAR":"R") : item?.interestedPrice}}</td>
                        <td> {{ item?.spordicType }} </td>
                        
                        <td>{{item?.createdAt | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        
                   
                    </tr>
                </tbody>
            </table>
            <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'80px'" ></app-table-loader>
            <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%;"
                (pageChange)="changePageNumber($event)"></pagination-controls>
        </div>
    </div>
</div>