<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<!-- <span (click)="commonService.backLocation()" class="circle-arrow">
  <img class="arrow-image-add-new" src="assets/svg/ar.svg" alt="">
</span> -->

<div class="container mt-5 mb-5 productContainerBox">
  <div class="">
    <!-- <div class="text-center" style="padding-top: 20px;">
      <h2 class=" pro-2">Product Details</h2>
    </div> -->
    <div class="row mt-3 mb-5">
      <div class="col-md-7">
        <div class="row">
          <span>
            <img class="image-custom qwqwqw" [hidden]="!isLoaded" (load)="onImageLoad()" [src]="imgurl" alt="">
            <ngx-skeleton-loader *ngIf="!isLoaded" [theme]="{width: '670px',
            height: '405px',
            position: 'relative'}" count="1"></ngx-skeleton-loader>
            <span class="heart" *ngIf="isLoggedIn" (click)="addWishlist(productDetails?._id)">

              <i class="fa fa-heart-o" *ngIf="productDetails?.isLike == false"></i>
              <i class="fa fa-heart" style="color: red;" *ngIf="productDetails?.isLike == true"></i>
            </span>
            <span class="heart" *ngIf="!isLoggedIn" (click)="showMessage()" [routerLink]="['/login']">

              <i class="fa fa-heart-o" *ngIf="productDetails?.isLike == false"></i>
              <i class="fa fa-heart" style="color: red;" *ngIf="productDetails?.isLike == true"></i>
            </span>
          </span>
        </div>
        <div class="row mt-4" *ngIf="productDetails?.productImage?.length">
          <div *ngFor="let item of productDetails?.productImage" class="col-4 p-0 col-md-4">
            <img class="custom-img h-100 change-img" style=" cursor: pointer;" [src]="item"
              (click)="changeProductimage(item)">
          </div>
        </div>
      </div>
      <ng-container>
        <app-table-loader></app-table-loader>
      </ng-container>
      <ng-container *ngIf="isLoading">
      <div class="col-md-5 p-0">
        <div class="row">

          <div class="col-12 productPaddingBox">
            <p class="prdct-home">{{productDetails?.productName}}</p>
          </div>
          <div class="col-12 productPaddingBox1">
            <p class="para-text-common mb-0">
              Product ID : {{productDetails?._id }}
            </p>
          </div>
          <div class="col-6 productPaddingBox1">
            <div class="row  justify-content-between">
              <span [ngClass]="{'priceTestP':productDetails?.isDealActive}">
                {{ mrp ? getPrice(mrp | currency:"ZAR":"R") :
                productDetails?.priceSizeDetails[0]?.price && getPrice(productDetails?.priceSizeDetails[0]?.price |
                currency:"ZAR":"R")}}
              </span>
             
              <span *ngIf="productDetails?.isDealActive" class="priceTestPColor">
                {{ afterDiscountPrice ? getPrice(afterDiscountPrice | currency:"ZAR":"R") :
                getPrice(afterDiscountPrice | currency:"ZAR":"R") }}
              </span>
              <span class="priceTestPColorD" *ngIf="productDetails?.isDealActive">
                {{productDetails?.dealDiscount}}% off
              </span>
            </div>
          </div>
          <div class="col-12 mt-2 mb-2 productPaddingBox">
            <img src="assets/latest/veriticalline.svg" style="height: 2px;width: 100%;" alt="">
          </div>
        </div>

        <div class="" style="margin-top: 1em;">
         
          <div class="row">

            <div class="col-4 pr-0 productPaddingBox">
              <p class="pid">Product Name</p>
            </div>
            <div class="">:</div>
            <div class="col-7">
              <p class="pid-1">{{productDetails?.productName }}</p>
            </div>
          </div>
          <div class="row">

            <div class="col-4 pr-0 productPaddingBox">
              <p class="pid">Category Name</p>
            </div>
            <div class="">:</div>
            <div class="col-7">
              <p class="pid-1">{{productDetails?.categoryId.categoryName }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4 pr-0 productPaddingBox">
              <p class="pid">Quantity Available</p>
            </div>
            <div class="">:</div>
            <div class="col-7">
              <p class="pid-1">{{ quantity ? quantity : productDetails?.priceSizeDetails[0]?.quantity}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4 pr-0 productPaddingBox">
              <p class="pid">Unit</p>
            </div>
            <div class="">:</div>
            <div class="col-7">
              <p class="pid-1">{{ productDetails?.priceSizeDetails[0]?.unit}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4 pr-0 productPaddingBox">
              <p class="pid">Size/Value</p>
            </div>
            <div class="">:</div>
            <div class="col-7 flex-data">
              <p class="pid-1 new-loop-para" *ngFor="let item of productDetails?.priceSizeDetails"
                (click)="price(item?.weight, item?.volume, item?.price,item?.quantity,item?.value,item?.id,item?.unit)"
                [ngClass]="{'active' :  value == item?.value}">{{item?.value}}</p>
            </div>
          </div>

        </div>

        <div class="mt-4 pt-3 productPaddingBox">
          <span class="d-flex justify-content-between">
            <h1 class="text-center desc">Description</h1>
          </span>
          <p style="word-break: break-word;"  class=" text-left descrip ml-3">
            {{productDetails?.description}}</p>
        </div>

        <div class="ml-3 mt-3 productPaddingBox">
          <button class="add-cart" *ngIf="isLoggedIn && quantity == 0" disabled>Out of stock</button>
          <button class="add-cart" *ngIf="isLoggedIn && quantity > 0" (click)="addToCart()">Add to Cart</button>
          <button class="add-cart" *ngIf="!isLoggedIn" (click)="showMessage()" [routerLink]="['/login']">Add
            to Cart</button>
        </div>
      </div>
    </ng-container>
   
    </div>
  </div>
</div>
<div class="container mt-5 pt-5" *ngIf="iaAPILoaded">
  <div class="d-flex justify-content-start">
    <h1 class="heading-text-cat" style="color: #000000 !important;font-size: 22px !important;">
      YOU MAY ALSO LIKE
    </h1>
  </div>
  <div class="items mt-4">
    <div class="slick_slider">
      <div class="sevice slider slider-customize">
        <ng-container *ngFor="let data of serviceData; let i = index">
          <div *ngIf="productDetails._id != data._id">
            <product-card [data]="data" (on_Click)="viewProducts(data?._id)"></product-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>