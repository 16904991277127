import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  pageName: any;
  description: any;
  type: any;
  result: any;
  staticContentDetails: any;
  privacyData: any;


  constructor(public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getPrivacy();
  }
  getPrivacy() {
    this.commonService.getApi('static/viewStaticContent?type=privacyPolicy', 0).subscribe((res: any) => {
      this.commonService.showSpinner()
      if (res['responseCode'] == 200) {
        this.privacyData = res['result']
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {

      this.commonService.warningToast(err['error']['responseMessage'])
    }
    )
  }

}
