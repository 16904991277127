import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-service-provider-available-deals',
  templateUrl: './service-provider-available-deals.component.html',
  styleUrls: ['./service-provider-available-deals.component.scss']
})
export class ServiceProviderAvailableDealsComponent implements OnInit {

  serviceDetail: any
  _id: any
  dealImg: any
  headingName:any ='DEALS TO CUSTOMERS'
  linkerRouteItems:any =[
    {name:'Home',route: '/service-order-detail'},
    {name:'Account',route:'/customer-profile'},
    {name:'Deals',route:'/service-provider-available-deals'}
  ]
  constructor(public service: CommonService, public activated: ActivatedRoute) {
    this.activated.queryParams.subscribe((res: any) => {
      this._id = res._id
    })
  }

  ngOnInit(): void {
    this.serviceOrderList();
  }

  isLoading: boolean = false;

  serviceOrderList() {
    let url = `deal/dealsListServiceParticular?categoryId=${this._id}`
    // this.service.showSpinner()
    this.serviceDetail =[]
    this.service.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        if (this.timerInterval) {
          clearInterval(this.timerInterval);
          this.countDown = [];
        }
        this.serviceDetail = res.result;
        if (!this.serviceDetail.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        for (let index = 0; index < this.serviceDetail.length; index++) {
          this.getCoundown(this.serviceDetail[index]['dealEndTime'], index);
        }
        this.service.successToast(res['responseMessage'])
        this.service.hideSpinner()
      }

    }, (err: any) => {
      this.isLoading = true;
      this.service.hideSpinner()
      if (err.responseCode == 409) {
        this.isLoading = true;
        this.service.hideSpinner()
        this.service.errorToast(err.responseMessage)
      }
    })
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }

  // timer

  myTimer() { }
  // getDealDescription
  countDown: any = [];
  timerInterval: any = [];
  new: any
  getCoundown(date: Date, i: number) {
    var countDownDate = new Date(date).getTime();
    let me = this;
    // Update the count down every 1 second

    this.timerInterval[i] = setInterval(() => {
      if (true) {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        me.countDown[i] =
        days + 'D:' + hours + 'H:' + minutes + 'M:' + seconds + 'S';
        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(me.timerInterval);
          me.countDown[i] = 'EXPIRED';
        }
        me.new = me.countDown[i]
      }

      // me.countDown[i] = mm
    }, 1000);
  }

}
