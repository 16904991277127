<section id="maincontent" class="page-main container"> <a id="contentarea" tabindex="-1"></a>
    <div class="row">
        <div class="row column main p-0 col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="clr"></div>
            <div class="cart-container col-md-8 p-0 uf_cartleftbox uf_cart_container border">
                <div class="cart-products-toolbar cart-products-toolbar-top toolbar"
                    data-attribute="cart-products-toolbar-top"></div> <input type="hidden" id="uf_mmtdetailurl"
                    value="https://udesignapi.utsavfashion.com/api/v1/mmt/get.svc/getdetails"><input type="hidden"
                    id="totalshipamt" value="0">
                <form action="https://www.utsavfashion.com/checkout/cart/updatePost/" method="post" id="form-validate"
                    class="form form-cart" novalidate="novalidate"><input name="form_key" type="hidden"
                        value="iqOHv1Upl97Gkn6q">
                    <div class="cart table-wrapper uf_cartwrapper">
                        <table id="shopping-cart-table" class="cart items data table">
                            <caption role="heading" aria-level="2" class="table-caption">Shopping Cart Items</caption>
                            <thead>
                                <tr>
                                    <th class="col item" scope="col"><span>Products</span></th>
                                    <!-- <th class="col price" scope="col"><span>Unit Price</span></th> -->
                                    <!-- <th class="col subtotal" scope="col"><span>Subtotal</span></th> -->
                                </tr>
                            </thead>
                            <tbody class="cart item uf_bagitem" data-sku="KMDF127_32" data-productid="3240488">
                                <tr class="item-info" *ngFor="let item of cartListDetails;let i = index">
                                    <td data-th="Item" class="col item">
                                        <div class="uf_cartproductimg"><a
                                                title="Embroidered Chanderi Silk Abaya Style Suit in Off White"
                                                tabindex="-1" class="product-item-photo">
                                                <span class="product-image-container product-image-container-3240488"
                                                    style="width: 295px;"><span class="product-image-wrapper">

                                                        <img class="product-image-photo"
                                                            [src]="item?.productId?.thumbnail" loading="lazy"
                                                            width="295" height="427"
                                                            alt="Embroidered Chanderi Silk Abaya Style Suit in Off White"></span></span>
                                            </a></div>
                                        <div class="product-item-details position-relative">
                                            <div class="product-item-name">

                                                <p class="productTitle m-0">{{item?.productId?.productName}}</p>


                                                <span class="price-excluding-tax" data-label="Excl. Tax">
                                                    <span class="cart-price">
                                                        <span class="reg_price" style="margin: 0 12px 0 0;">
                                                            <span [ngClass]="item?.isDealActive ? 'price' : ''">
                                                                {{getPrice(item?.priceSizeDetails?.price |
                                                                currency:"ZAR":"R")}}</span>
                                                        </span>
                                                        <span class="spl_price" *ngIf="item?.isDealActive"><span
                                                                class="price">{{getPrice(item?.dealPrice |
                                                                currency:"ZAR":"R")}}</span> &nbsp;
                                                            <span class="discount-percent">{{item.dealDiscount}}%
                                                                Off</span>
                                                        </span>
                                                    </span>
                                                </span>

                                            </div>

                                            <ul class="item-options uf_bagitemoptions" style="padding: 2px;">
                                                <li class="uf_bagoptionlist "><span class="uf_bagitemsize">Available Qty
                                                        : {{item?.availableQuantity}}
                                                    </span>
                                                </li>
                                                <li class="uf_bagoptionlist "><span class="uf_bagitemsize">Value/Size :
                                                        {{item?.priceSizeDetails?.value}}
                                                        {{item?.priceSizeDetails?.unit != 'other'
                                                        ?item?.priceSizeDetails?.unit : '' }}
                                                    </span>
                                                </li>
                                                <li class="uf_bagoptionlist "><span class="uf_bagitemsize">Expected
                                                        delivery date :
                                                         <span style="margin-left: 8px;"
                                                           >
                                                            <span
                                                                >{{ payOutDetail?.expectedDate
                                                                | date : 'y-MM-dd' }}</span>
                                                            </span>
                                                  
                                                    </span>
                                                </li>
                                                <div class="d-flex mb-0" style="max-width: 158px">
                                                    <button style="box-shadow: none;"
                                                        class="btn qty-less-high px-2 me-2"
                                                        [disabled]="item.quantity <= 1"
                                                        (click)="updateCartValue(item?._id,item?.priceSizeDetails, item.quantity - 1,item.quantity,i,item?.availableQuantity)">
                                                        <em class="fa fa-minus" style="margin-left: -5px;"></em>
                                                    </button>
                                                    
                                                    <!-- add input field for increase the Quantity in My cart -->
                                                    <div class="form-outline qty"> 
                                                        <input id="form1" min="0" name="quantity" type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==4) return false;"
                                                            class="form-control new-inp ghy" 
                                                            (input)="updateCartValue(item?._id,item?.priceSizeDetails, item.quantity ,item.quantity,i,item?.availableQuantity)" 
                                                            [(ngModel)]="item.quantity" 
                                                            [ngModelOptions]="{standalone:true}"
                                                            maxlength="5"
                                                            />
                                                        <!-- <p class="qty m-0">{{ item.quantity}}</p> -->
                                                        <!-- <label class="form-label" for="form1">Quantity</label> -->
                                                    </div>

                                                    <button style="box-shadow: none;"
                                                        class="btn qty-less-high me-2 ms-2 hhjlds"
                                                        (click)="updateCartValue(item?._id,item?.priceSizeDetails,item.quantity + 1,item.quantity,i,item?.availableQuantity)">
                                                        <em class="fa fa-plus" style="color: #c01e2e;"></em>
                                                        <!-- <em class="fa fa-circle-notch fa-spin" style="color: #c01e2e;" *ngIf="loadingButton[i]"></em> -->
                                                    </button>
                                                </div>


                                                <li class="uf_bagoptionlist">
                                                    <span class="uf_bagitemsize" style="cursor: pointer;
                                                    color: #bf1e2e;">{{erorMessageArr[i]}}
                                                    </span>
                                                </li>

                                            </ul>
                                            <span class="uf_bagoptionlist lmp">
                                                <i class="uf_bagitemsize fa fa-trash" style="    cursor: pointer;
                                                " (click)="removeCartList(item?._id)">
                                                </i>
                                            </span>
                                        </div>

                                    </td>
                                    <!-- <td class="col price uf_mobhide" data-th="Price">
                                        <span class="price-excluding-tax" data-label="Excl. Tax">
                                            <span class="cart-price">
                                                <span class="reg_price">
                                                    <span class="price">{{getPrice(getActualPrice(item) |
                                                        currency:"ZAR":"R")}}</span></span><br> <span
                                                    class="spl_price"><span class="price">{{getPrice(item?.totalPrice |
                                                        currency:"ZAR":"R")}}</span><br>
                                                    <span class="discount-percent">({{getDiscount(item)}}% Off)</span>
                                                </span> </span> </span>
                                    </td> -->
                                    <!-- <td class="col subtotal uf_mobhide" data-th="Subtotal"> <span
                                            class="price-excluding-tax" data-label="Excl. Tax"> <span
                                                class="cart-price"> <span class="price">{{getPrice((item?.totalPrice * item.quantity) | currency:"ZAR":"R")}}</span></span> </span>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </form>
                <div class="cart-products-toolbar cart-products-toolbar-top toolbar"
                    data-attribute="cart-products-toolbar-top"></div>
            </div>
            <div id="uf_cartdockable" class="col-md-4 uf_cartdockable">
                <div class="row shopping-cart-bottom w-100 uf_cartrightbox uf_cartdockable_inner">

                    <div class="uf_cartsummary w-100">
                        <div class="summary title cart_summary">Order Summary
                        </div>
                        <div id="cart-totals" class="cart-totals" data-bind="scope:'block-totals'">
                            <!-- ko template: getTemplate() -->
                            <div class="table-wrapper" data-bind="blockLoader: isLoading">
                                <table class="data table totals">

                                    <tbody>

                                        <tr class="totals sub">
                                            <th data-bind="i18n: title" scope="row">Subtotal</th>
                                            <td class="amount">
                                                <span class="price"
                                                    data-bind="text: getValue(), attr: {'data-th': title}"
                                                    data-th="Subtotal">{{payOutDetail && getPrice(payOutDetail.subTotal
                                                    |
                                                    currency:"ZAR":"R")}}</span>
                                            </td>
                                        </tr>

                                        <tr class="totals sub uf_disdetailhead uf_discountcont">
                                            <td colspan="1" class="uf_title" scope="row">
                                                Discount

                                            </td>
                                            <td class="amount discount_td" data-bind="attr: {'data-th': title}"
                                                data-th="Discount">
                                                <span><span class="price" data-bind="text: getValue()">-
                                                        {{getPrice(payOutDetail.totalDiscount |
                                                        currency:"ZAR":"R")}}</span></span>
                                            </td>
                                        </tr>
                                        <tr class="totals sub uf_disdetailhead uf_discountcont">
                                            <td colspan="1" class="uf_title" scope="row">
                                                Total

                                            </td>
                                            <td class="amount discount_td" data-bind="attr: {'data-th': title}"
                                                data-th="Discount">
                                                <span><span class="price" data-bind="text: getValue()">
                                                        {{getPrice(payOutDetail.totalAmount |
                                                        currency:"ZAR":"R")}}</span></span>
                                            </td>
                                        </tr>
                                        <tr class="totals sub uf_disdetailhead uf_discountcont">
                                            <td colspan="1" class="uf_title" scope="row">
                                                Shipping & Handling <br> ({{getDeliveryName(payOutDetail?.deliveryMode)}})

                                            </td>
                                            <td class="amount discount_td" data-bind="attr: {'data-th': title}"
                                                data-th="Discount">
                                                <span class="price"
                                                    data-bind="html: getShipValueUf(), attr: {'data-th': title}"
                                                    data-th="Shipping &amp; Handling">

                                                    <!-- <span
                                                        class="reg_price_c text-muted"
                                                        style="text-decoration: line-through;"><span
                                                            class="reg_price">₹200.00 </span></span> -->

                                                    <span class="spl_price"> {{payOutDetail.deliveryFee ?'+ '+
                                                        getPrice(payOutDetail.deliveryFee | currency:"ZAR":"R") :
                                                        'Free'}}</span>
                                                </span>
                                            </td>
                                        </tr>




                                        <tr class="grand totals grand-total redcolor">
                                            <th scope="row">
                                                <strong data-bind="i18n: title">Grand Total</strong>
                                            </th>
                                            <td data-bind="attr: {'data-th': title}" class="amount"
                                                data-th="Grand Total">
                                                <strong><span style="color:#c01e2e;" class="price"
                                                        data-bind="text: getValue()">{{payOutDetail.totalAmount &&
                                                        getPrice(payOutDetail.totalAmount + payOutDetail.deliveryFee
                                                        | currency:"ZAR":"R")}}</span></strong>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="cart-summary">
                            <!-- <div *ngIf="discountPercent>0" id="uf-yousave-ko" class="uf_yousaved_box special-price"
                                data-bind="scope:'utsav-yousave-ko'">

                                <span class="uf_yousave_text" data-bind="afterRender: getYouSaved()">You saved <b><span
                                            class="price">{{discountPercent}}%</span></b> on this purchase.</span>

                            </div> -->
                            <ul class="checkout methods items checkout-methods-items">
                                <li class="item">
                                    <button type="button" data-role="proceed-to-checkout"
                                    (click)="navigateToaddresss()" title="Proceed to Checkout" aria-label="Proceed to Checkout"
                                    class="action primary checkout"><span>Proceed to
                                        Checkout</span></button>
                                    <!-- <button type="button" *ngIf="flag" data-role="proceed-to-checkout"
                                        (click)="navigateToaddresss()" title="Proceed to Checkout"
                                        class="action primary checkout"><span>Proceed to
                                            Checkout</span></button>
                                    <button type="button" *ngIf="!flag" data-role="proceed-to-checkout"
                                        title="Proceed to Checkout"
                                        class="action primary checkout"><span>Loading..</span></button> -->

                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="option mt-2 w-100">
                        <p class="text-center">You can choose delivery options <a class="deliveryLink" (click)="openDeliveryModal()">here.</a> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="modal fade" id="modalDeliveryConfirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ggg position-relative" style="padding:30px 10px">
            <i class="fa fa-times position-absolute colorTheme" data-dismiss="modal"></i>
            <h2>Delivery Options</h2>

            <div class="table-responsive-lg">
                <table class="table">
                   
                    <tbody>
                        <tr *ngFor="let item of optionList">
                            <td scope="row"><input type="radio" (change)="onSelectDeliveryOption(item)"
                                    name="deliveryOption" [checked]="item?.deliveryType == payOutDetail?.deliveryMode">
                            </td>
                            <td class="price-excluding-tax"> {{item?.deliveryOption}}</td>
                            <td class="price-excluding-tax">{{item.description}}</td>
                            <td  class="disc_price" *ngIf="item?.deliveryAmount">{{item?.deliveryAmount &&
                                getPrice(item?.deliveryAmount | currency:"ZAR":"R")}}</td>
                            <td  class="disc_price" *ngIf="!item?.deliveryAmount">Free</td>
                        </tr>
                    </tbody>

                </table>
            </div>
            <!-- <form id="deliveryForm">
                <label style="margin-bottom: 8px;" *ngFor="let item of optionList">
                    <input type="radio" (change)="onSelectDeliveryOption(item)" name="deliveryOption"
                        [checked]="item?.deliveryType == payOutDetail?.deliveryMode">
                    {{item?.deliveryOption}} - {{item.description}}
                    <span class="disc_price" *ngIf="item?.deliveryAmount">{{item?.deliveryAmount &&
                        getPrice(item?.deliveryAmount | currency:"ZAR":"R")}}</span>
                    <span class="disc_price" *ngIf="!item?.deliveryAmount">Free</span>
                </label>
                <br>

                <br>
            </form> -->


            <div class="modal-body d-flex justify-content-center mb-4">

                <button type="button" (click)="onConFirm()" class="btn btn-primary btn-theme" data-dismiss="modal">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</div>