import { CommonService } from 'src/app/provide/common.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-provider-payment-from-customers',
  templateUrl: './service-provider-payment-from-customers.component.html',
  styleUrls: ['./service-provider-payment-from-customers.component.scss']
})
export class ServiceProviderPaymentFromCustomersComponent implements OnInit {

  paymentData: any
  headingName:any ='Payment From Customers'
  linkerRouteItems:any =[
    {name:'Home',route: '/service-order-detail'},
    {name:'Account',route:'/customer-profile'},
    {name:'Payment History',route:'/service-provider-payment-from-customers'}
  ]
  currentPage: any = 1;
  pageSize: any = 10;
  totalItems: any;
  isLoading:boolean =false;
  notFoundData:boolean =false;
  constructor(public service: CommonService) { }

  ngOnInit(): void {
    this.paymentList();
  }

  paymentList() {
    this.isLoading =true;
    this.paymentData =[]
    this.totalItems =0;
    let url = `order/paymentFromCustomer`
      let data = {
      page: this.currentPage,
      limit: this.pageSize
      }
    this.service.postApi(url,data, 1).subscribe((res: any) => {
      this.isLoading =false;
      if (res['responseCode'] == 200) {
        this.service.successToast(res['responseMessage'])
        this.service.hideSpinner()
        this.paymentData = res.result.docs;
        this.totalItems = res.result.total;
        if(res.result.docs.length>0){
          this.notFoundData =false
        }else{
          this.notFoundData =true
        }
      }

    }, (err: any) => {
      this.isLoading =false;
      this.notFoundData =true
      if (err.responseCode == 409) {
        this.service.hideSpinner()
        this.service.errorToast(err.responseMessage)
      }
    })
  }

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  pagination(event) {
    this.currentPage = event;
    this.paymentList();
  }
}
