<auth-layout title="Verify Your Email ID" type="SIGNUP" subTitile="Please enter the 4-digit verification code that was sent to Your email. The code is valid for 3 minute.">

  <form [formGroup]="otpForm">
    <div class="otp">
      <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:4}"></ng-otp-input>
    </div>
    <strong style="position: relative;left: 44%;color: #c01e2f;">{{remainingTime}}</strong>
    <div class="vca mt-3">
      <span class="o-f" *ngIf="!loading" (click)="navigateToVerifyCustomer()">Verify</span>
      <span class="o-f op05" *ngIf="loading" >Loading...</span>
    </div>
    <div class="mt-2 forcenter">
      <a class="res-otp" (click)="resendOtp()"  *ngIf="isExpire; else notExpired">Resend OTP</a>
      <ng-template #notExpired><a style="color: gray; cursor: no-drop;">Resend OTP</a></ng-template>
      <!-- <p class="mt-10">{{time == '1.59' || time == '0.59' ? '' : time }}</p> -->
    </div>
  </form>
</auth-layout>





<!-- <div class="content">
      <div class="o-c">
        <div>
          <div class="card">
            <p class="a-o-i">Enter verification code</p>
            <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
            <span  class="o-t-p">
              <ng-container *ngIf="otp">
                Entered otp :-{{otp}}
              </ng-container>
              </span>
          </div>
        </div>
      </div>
    </div> -->