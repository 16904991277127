<div class="container mb-5 wrapper-content">
    <span (click)="commonService.backLocation()" class="circle-arrow text-start">
        <img class="arrow-image-add-new" src="assets/svg/ar.svg" alt="">

    </span>
    <h1 class="text-center mt-3" style="text-align: center;">Deals on Services</h1>
    <div class="row justify-content-center">
        <div class="form-group has-search">
            <!-- <span class="fa fa-search form-control-feedback"></span>
            <input type="text" class="form-control" [(ngModel)]="serviceName" placeholder="Search by deal name"
                (input)="serviceOrderList()"> -->
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-3"
            *ngFor="let data of serviceDetail | paginate : {itemsPerPage: pageSize, currentPage: currentPage,totalItems: totalItems};let i = index">
            <div class="card innerDiv" (click)="navigateToViewProduct(data)">
                <div class="image">
                    <img class="img" [src]="data?.thumbnail" alt="categoryImage">
                </div>
                <div class="mt-3">
                    <p class="description">{{data?.serviceId?.description}}</p>
                </div>
                <div class="">
                    <p class="dealName">Service Name: {{data?.serviceId?.serviceName}}</p>
                </div>

                <div style="margin-top: -8px;">

                    <div class="row">
                        <div class="col-12 p-0 m-0">
                            <p class="price">Price : <span
                                    style="text-decoration: line-through;;">{{data?.serviceId?.price
                                    && getPrice(data?.serviceId?.price |
                                    currency:"ZAR":"R")}}</span> </p>
                        </div>
                    </div>

                    <div class="row" style="margin-top: -8px;">
                        <div class="col-12 p-0 m-0">
                            <p class="dealPrice">Deal Price : {{data?.dealPrice &&
                                getPrice(data?.dealPrice | currency:"ZAR":"R")}}</p>
                        </div>
                    </div>

                    <!-- <div class="row" style="margin-top:-6px;">
                            <div class="col-12 p-0 m-0">
                                <p class="dealDiscount">Deal Discount: {{data?.dealDiscount}}%</p>
                            </div>
                        </div> -->
                    <div class="row" style="margin-top: -10px;">
                        <div class="col-12 p-0 m-0">
                            <p class="dealEnd">Deal End: {{commonService.removeTimeZone(data?.dealEndTime) | date:
                                'yyyy-MM-dd h:mm:ss a' ||
                                '--' }}</p>
                        </div>
                    </div>
                </div>

                <div class="end-pro-time text-center mb-2">
                    {{ countDown[i] }}
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="!serviceDetail?.length" class="no-data">
        <img src="assets/svg/no-service.svg" alt="">
    </div> -->
    <div *ngIf="isLoading" class="no-data">
        <p style="text-align: center; font-size: 16px; font-weight: 500;">No Data Found...</p>
    </div>

    <div class="custom-pagination mt-2 text-align-right" style="text-align: right;" *ngIf="totalItems > 12">
        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
    </div>
</div>