import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'category-layout-home',
  templateUrl: './category-layout-home.component.html',
  styleUrls: ['./category-layout-home.component.scss']
})
export class CategoryLayoutHomeComponent implements OnInit {
  @Input() data : any
  @Input() showEdit : boolean
  @Output() on_Click = new EventEmitter
  @Output() on_Click_Edit = new EventEmitter
  isLoaded: boolean = false;

  
  constructor(private router : Router) { }

  ngOnInit(): void {
    console.log(this.data);
    
  }
  navigateToViewProduct(e){
    if(!e){
      this.router.navigate(['/services-provider'])
      return
    }
    this.on_Click.emit(e)
  }
  // navigateTocategory(e,data){
  //   this.on_Click_Cat.emit({
  //     e:e,data:data
  //   })
  // }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  onEdit(e){
    this.on_Click_Edit.emit(e)
  }
  onImageLoad(){
    this.isLoaded = true
  }

}
