import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-retailer-order-otp-verification',
  templateUrl: './retailer-order-otp-verification.component.html',
  styleUrls: ['./retailer-order-otp-verification.component.scss']
})
export class RetailerOrderOtpVerificationComponent implements OnInit {
  c = console.log.bind(document)

  otpForm!: FormGroup
  otp: any;
  myEmail: any;
  countDowon: any = 2.59
  flag: boolean;
  _id: any;
  _id1: any;
  orderId: any
  headingName:any ='OTP Verification'
  linkerRouteItems:any =[
    {name:'Home',route: '/retailer-home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Order History',route:'/retailer-order-list'},
    {name:'View',route:'/retailer-order-history-view'},
    {name:'OTP Verify',route:'/retailer-order-otp-verify'}

  ]
  //commonService.backLocation()
  contDownTimer() {
    var a = window.setInterval(() => {
      if (this.countDowon - (parseInt(this.countDowon)) > 0.60) {

        this.countDowon = this.countDowon - 0.40
      }
      if (this.countDowon > 0) {
        this.countDowon = this.countDowon - 0.01
        this.getTime((this.countDowon).toFixed(2))
      }
      else {
        this.resend_otp_active = true
        clearInterval(a)
      }
    }, 1000)
  }

  resend_otp_active: boolean = false
  time!: string
  getTime(e: any) {
    this.time = String(e).replace('.', ':')
    this.time = String(e).replace('-', '')
  }
  constructor(public commonService: CommonService, private router: Router, public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.flag = res.flag;
      this._id = res._id;
      this._id1 = res._id1;
      this.orderId = res.orderId;
      this.c(this.flag)
      this.c(this._id)
      this.c(this._id1)
    })
  }

  ngOnInit(): void {
    this.otpForm = new FormGroup({
      otp: new FormControl("", [
        Validators.required,

      ]),
    });

    this.myEmail = localStorage.getItem('email')

    this.timer(180);
    // this.resendOtp();
  }
  timerOn = true;

  markAsDone() {
    let url = `user/productMarkAsDone`
    let data = {
      orderId: this.orderId
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.timer(60)
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.error.responseMessage)
    })
  }

  verifyOTP() {
    let url = `user/verifyDeliveryStatus`
    let data = {
      orderId: this.orderId,
      otpProduct: this.otp
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.router.navigate(['/retailer-order-list'])
        this.commonService.successToast(res.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.error.responseMessage)
    })
  }

  backToView() {
    if (this.flag) {
      this.router.navigate(['/service-order-detail-view'], { queryParams: { _id: this._id } });
    } else {
      this.router.navigate(['/service-provider-request-services-view'], { queryParams: { _id: this._id1 } })
    }
  }

  onOtpChange(otp: any) {
    this.otp = otp;
  }

  navigateToResetPassword() {
    let userData = {
      email: this.myEmail,
      otp: this.otp,
      userType: 'SERVICE_PROVIDER',
    }
    this.commonService.showSpinner()

    this.commonService.postApi('user/verifyOTP', userData, 0).subscribe((res: any) => {
      
      // if(res['']) 
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner()
        this.commonService.successToast(res['responseMessage'])
        this.router.navigate(['/reset-password-service-provider'])

        // if (!this.isActivate) {
        //   this.router.navigate(['/homepage'])
        // }
        // if (this.currentUrl === '/auth/signUp') {
        //   this.router.navigate(['/auth/login'])
        // } else {
        //   localStorage.setItem('verifyOtpToken', res['result']['token'])
        //   this.router.navigate(['/auth/reset-password'])
        // }
      }

    }, err => {
      this.commonService.hideSpinner()
      if (err['error']['responseCode'] == 400) {
        this.commonService.warningToast(err['responseMessage'])
      }
      
    })

  }


  // resend otp 
  resendOtp() {
    this.commonService.showSpinner();
    this.myEmail = localStorage.getItem('email');
    var email = {
      'email': this.myEmail,
      userType: 'RETAILER',
    }
    this.commonService.postApi('user/resendOTP', email, 0).subscribe(res => {
      if (res['responseCode'] == 200) {
        this.timer(180);
        this.commonService.hideSpinner()
        this.commonService.successToast(res['responseMessage'])
      }
      
    }, err => {
      this.commonService.hideSpinner()
      this.commonService.errorToast(err['responseMessage'])
      
    })
  }

  remainingTime: any
  isExpire: boolean = false
  timer(remaining) {
    let m: any = Math.floor(remaining / 60);
    let s: any = remaining % 60;

    m = m < 10 ? '' + m : m;
    s = s < 10 ? '0' + s : s;
    
    this.remainingTime = m + ':' + s

    // document.getElementById('timer').innerHTML = m + ':' + s;
    remaining -= 1;
    let me = this
    if (remaining >= 0 && this.timerOn) {
      setTimeout(function () {
        me.timer(remaining);
      }, 1000);
      return;
    }

    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    this.isExpire = true
  }


}
