import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/provide/common.service';
declare var $
@Component({
  selector: 'mycart-layout',
  templateUrl: './mycart-layout.component.html',
  styleUrls: ['./mycart-layout.component.scss']
})
export class MycartLayoutComponent implements OnInit {
  @Input() cartListDetails: any
  @Input() payOutDetail: any
  @Input() optionList: any

  @Output() onRemoveItem = new EventEmitter
  // @Output() navigateToaddress = new EventEmitter
  @Output() navigateToaddress = new EventEmitter<{ deliveryMode: string, weight: string, volume: string }>();

  @Output() onUpdateCartValue = new EventEmitter
  @Output() payOutEvent = new EventEmitter


  sum: any = 0
  grandTotal: any = 0
  subtotal: any
  discountPercent: any
  discount: any
  flag: boolean = true
  loadingButton: any = []
  inputValue: number;
  
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.getCartList()
  }
  
  getAddedDate(createdAt: string, days: number) {
    let date = new Date(createdAt).setDate(new Date(createdAt).getDate() + 7);
    return date
  }


  getSundayDate(inputDate) {
    // Create a new Date object from the input date
    const currentDate = new Date();

    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDayOfWeek = currentDate.getDay();

    // Calculate the number of days until the next Sunday
    const daysUntilNextSunday = 7 - currentDayOfWeek;

    // Create a new Date object for the next Sunday
    const nextSunday = new Date(currentDate);
    nextSunday.setDate(currentDate.getDate() + daysUntilNextSunday);

    // Format the date as a string (e.g., "YYYY-MM-DD")
    const nextSundayDate = nextSunday.toISOString().split('T')[0];

    return nextSundayDate;

  }



  getNextSaturdayFromDate(inputDate) {
    // Create a new Date object from the input date
    const currentDate = new Date();
  
    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDayOfWeek = currentDate.getDay();
  
    // Calculate the number of days until the next Saturday
    const daysUntilNextSaturday = 6 - currentDayOfWeek + 7;
  
    // Create a new Date object for the next Saturday
    const nextSaturday = new Date(currentDate);
    nextSaturday.setDate(currentDate.getDate() + daysUntilNextSaturday);
  
    // Format the date as a string (e.g., "YYYY-MM-DD")
    const nextSaturdayDate = nextSaturday.toISOString().split('T')[0];
  
    return nextSaturdayDate;
  }

  getNextBusinessDayFromDate(inputDate) {
    // Create a new Date object from the input date
    const currentDate = new Date();
  
    // Calculate the next day of the week
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);
  
    // Check if the next day is a weekend (Saturday or Sunday)
    if (nextDay.getDay() === 0) {
      // If it's Sunday, add 2 days to get to the next business day (Monday)
      nextDay.setDate(nextDay.getDate() + 2);
    } else if (nextDay.getDay() === 6) {
      // If it's Saturday, add 1 day to get to the next business day (Monday)
      nextDay.setDate(nextDay.getDate() + 1);
    }
  
    // Format the date as a string (e.g., "YYYY-MM-DD")
    const nextBusinessDay = nextDay.toISOString().split('T')[0];
  
    return nextBusinessDay;
  }

  getNextDayOrSameDay(inputDate) {
    // Create a new Date object from the input date
    const currentDate = new Date();
  
    // Get the current hour of the day (0-23)
    const currentHour = currentDate.getHours();
  
    // Check if the current hour is less than 17 (5 PM in 24-hour format)
    if (currentHour < 17) {
      // If it's before 5 PM, return the same day
      return currentDate.toISOString().split('T')[0];
    } else {
      // If it's 5 PM or later, return the next day
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() + 1);
      return nextDay.toISOString().split('T')[0];
    }
  }
  removeCartList(e) {
    this.onRemoveItem.emit(e)
  }
  erorMessageArr: any = []
  localQuantity: any = []
  
  updateCartValue(id, prizeSize, avlQty, currentqty?, ind?, total?) {
    let obj = {
      id: id, prizeSize: prizeSize, avlQty:Number(avlQty)
    }
    this.inputValue = Number(avlQty);
    console.log(obj, currentqty, ind);
    if (total < this.inputValue) {

      this.erorMessageArr[ind] = `You cannot add more than available quantity.`
      setTimeout(() => {

        this.erorMessageArr[ind] = ''
      }, 3000);
      return
    }
    this.loadingButton[ind] = true

    setTimeout(() => {
      this.loadingButton[ind] = false
    }, 1000);

    this.onUpdateCartValue.emit(obj)
    this.localQuantity[ind] = avlQty
    // this.cartListDetails[ind]['quantity'] =avlQty
  }


getDeliveryName(e){
  let deliveryOption = e == 'SUNDAY' ? 'Sunday' : 
  e == 'SAMEDAY' ? 'Sameday' : 
  e == 'BUSINESSDAY' ? 'Next business day' : 
  e == 'SATURDAY' ? 'Saturday' : 'Standard'

  return deliveryOption

}

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ');
  }

  getActualPrice(e) {
    let pricesizeId = e.priceSizeDetails.id
    let product = e.productId.priceSizeDetails.find((ele) => {
      return ele.id == pricesizeId
    })


    return 'price' in product ? product.price : 0

  }
  getDiscount(e) {
    let pricesizeId = e.priceSizeDetails.id
    let product = e.productId.priceSizeDetails.find((ele) => {
      return ele.id == pricesizeId
    })
    let actualPrice = Number(product.price)
    let discountedPrice = Number(e.totalPrice)

    let discounted = Number((actualPrice - discountedPrice) / actualPrice * 100).toFixed(0)

    return discounted

  }

  navigateToaddresss() {
    // this.flag = false
    // this.payOutDetail = JSON.parse(localStorage.getItem('payOutDetail'))
    // this.navigateToaddress.emit({deliveryMode : this.payOutDetail.deliveryMode, weight: this.payOutDetail.weight, volume: this.payOutDetail.volume})
    const storedDetail = localStorage.getItem('payOutDetail');
    if (storedDetail) {
        this.payOutDetail = JSON.parse(storedDetail);
        this.navigateToaddress.emit({
            deliveryMode: this.payOutDetail.deliveryMode,
            weight: this.payOutDetail.weight,
            volume: this.payOutDetail.volume
        });
    } else {
        console.error('No payOutDetail found in localStorage');
    }
  }

  getCartList(e?) {
    //  console.log(e);

    this.sum = 0
    this.grandTotal = 0
    this.cartListDetails = e || this.cartListDetails
    for (const item of this.cartListDetails) {
      this.sum += this.getActualPrice(item) * item.quantity
      if (item.isDealActive) {
        this.grandTotal += item.dealPrice * item.quantity
      }
      else {
        this.grandTotal += item.priceSizeDetails.price * item.quantity
      }

    }

    this.discount = this.sum - this.grandTotal
    this.discountPercent = Number(((this.sum - this.grandTotal) / this.grandTotal) * 100).toFixed(2)
  }
  openDeliveryModal() {
    $('#modalDeliveryConfirmation').modal('show')
  }

  deliveryOption: any
  onSelectDeliveryOption(e) {
    this.deliveryOption = e

  }
  onConFirm() {
    this.payOutDetail.deliveryFee = this.deliveryOption.deliveryAmount
    this.payOutDetail.deliveryMode = this.deliveryOption.deliveryType
    this.payOutDetail.weight = this.deliveryOption.weight
    this.payOutDetail.volume = this.deliveryOption.volume

    localStorage.setItem('payOutDetail', JSON.stringify(this.payOutDetail))

    this.payOutEvent.emit({deliveryMode : this.payOutDetail.deliveryMode, weight: this.payOutDetail.weight, volume: this.payOutDetail.volume} )
  }
}
