import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss']
})
export class OtpModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter
  @Output() onSumit = new EventEmitter
  @Output() onResend = new EventEmitter
  @Input()   orderId: any
  @Input()   isService: boolean
  @Input()   customerType: string
  @Input()   custom: boolean
  c = console.log.bind(document)

  otpForm!: FormGroup
  otp: any;
  myEmail: any;
  countDowon: any = 1
  flag: boolean;
  _id: any;
  _id1: any;

  @Input() title : string = `Please enter the 4-digit verification code that was sent to {{customerType ? customerType :'customer'}} email
  or phone number. The
  code is valid for 1 minute.`

  headingName:any ='OTP Verification'
  linkerRouteItems:any =[
    {name:'Home',route: '/home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Order History',route:'/retailer-order-list'},
    {name:'View',route:'/retailer-order-history-view'},
    {name:'OTP Verify',route:'/retailer-order-otp-verify'}

  ]
  //commonService.backLocation()
  contDownTimer() {
    var a = window.setInterval(() => {
      if (this.countDowon - (parseInt(this.countDowon)) > 0.60) {

        this.countDowon = this.countDowon - 0.40
      }
      if (this.countDowon > 0) {
        this.countDowon = this.countDowon - 0.01
        this.getTime((this.countDowon).toFixed(2))
      }
      else {
        this.resend_otp_active = true
        clearInterval(a)
      }
    }, 1000)
  }

  resend_otp_active: boolean = false
  time!: string
  getTime(e: any) {
    this.time = String(e).replace('.', ':')
    this.time = String(e).replace('-', '')
  }
  constructor(public commonService: CommonService, private router: Router, public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.flag = res.flag;
      this._id = res._id;
      this._id1 = res._id1;

    })
  }

  ngOnInit(): void {
    this.otpForm = new FormGroup({
      otp: new FormControl("", [
        Validators.required,

      ]),
    });
    this.c(this.orderId)

    this.myEmail = localStorage.getItem('email')

    if(this.isService){
      this.resendOTPService()
    }
    this.timer(180);
    // this.resendOtp();
  }
  timerOn = true;



  verifyOTP() {
    let url = `user/verifyDeliveryStatus`
    let data : any = {
      orderId: this.orderId,
      otpProduct: this.otp
    }
   
   
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.onClose()
        this.router.navigate(['/retailer-order-list'])
        this.commonService.successToast(res.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.error.responseMessage)
    })
  }


  verifyOTPService() {
    let url = `service/verifyServiceRequest`
    let data = {
      orderId: this.orderId,
      otpService: this.otp
    }
    this.commonService.showSpinner();
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        // this.router.navigate(['/service-order-detail'])
        this.onClose()
        this.commonService.successToast(res.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.error.responseMessage)
    })
  }


  onOtpChange(otp: any) {
    this.otp = otp;
  }




  // resend otp 
  resendOtp() {
    if(this.custom){

      this.onResend.emit()
      this.timer(180);
      return
    }
    this.commonService.showSpinner();
    this.myEmail = localStorage.getItem('email');
    var email = {
      'email': this.myEmail,
      userType: 'RETAILER',
    }
    this.commonService.postApi('user/resendOTP', email, 0).subscribe(res => {
      if (res['responseCode'] == 200) {
        this.timer(180);
        this.commonService.hideSpinner()
        this.commonService.successToast(res['responseMessage'])
      }
      
    }, err => {
      this.commonService.hideSpinner()
      this.commonService.errorToast(err['responseMessage'])
      
    })
  }

  remainingTime: any
  isExpire: boolean = false
  timer(remaining) {
    let m: any = Math.floor(remaining / 60);
    let s: any = remaining % 60;

    m = m < 10 ? '' + m : m;
    s = s < 10 ? '0' + s : s;
    
    this.remainingTime = m + ':' + s

    // document.getElementById('timer').innerHTML = m + ':' + s;
    remaining -= 1;
    let me = this
    if (remaining >= 0 && this.timerOn) {
      setTimeout(function () {
        me.timer(remaining);
      }, 1000);
      return;
    }

    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    this.isExpire = true
  }

  resendOTPService(){
    let url = `user/serviceMarkAsDone`
    let data = {
      orderId: this.orderId
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }

  onClose(){
    this.closeModal.emit()
  }
  onSumbitOtp(){
    this.onSumit.emit(this.otp)
  }
  onResendSubmit(){
    this.onResend.emit()
  }


  loading = false
  load() {
    this.loading = true;
 
    setTimeout(() => {
      this.loading = false;
  
    }, 30000); // 1 minute
  }

}
