<div>
  <div class="postion-relative">
    <div class="bannerContent position-relative">
      <span class="heart"  *ngIf="showEdit">
        <i
        class="fa fa-pencil"
       
        style="color: #bf1e2e; cursor: pointer"
        (click)="onEdit(data?._id)"
      ></i>
      </span>
    
      <div class="card innerDiv" (click)="dealVIEw(data?._id)">
        <div class="image" style="position: relative">
          <div class="end-pro-time1 text-center mb-2" *ngIf="countDown">
            {{ countDown }}
          </div>
          <img
            [ngClass]="{ imgTrue: isImage, imgPro: !isImage }"
            [src]="data?.thumbnail"
            class="img"
            [hidden]="!isLoaded"
            (load)="onImageLoad()"
            alt="categoryImage"
          />
          <ngx-skeleton-loader
            *ngIf="!isLoaded"
            [theme]="theme"
            count="1"
          ></ngx-skeleton-loader>
        </div>
        <div class="mt-3">
          <p class="description">
            {{ commonService.splitString(data?.dealName) }}
          </p>
        </div>
        <div style="margin-top: -8px">
          <div class="row">
            <div class="col-12 p-0 m-0">
              <p class="price d-flex">
                <span style="text-decoration: line-through">
                  <ng-container *ngIf="data?.dealDetails; else productView">
                    {{
                      data?.dealDetails[0]?.price &&
                        getPrice(
                          data?.dealDetails[0]?.price | currency : "ZAR" : "R"
                        )
                    }}
                  </ng-container>
                  <ng-template #productView>
                    {{
                      data?.productId &&
                        data?.productId[0]?.priceSizeDetails &&
                        getPrice(
                          data?.productId[0]?.priceSizeDetails[0]?.price
                            | currency : "ZAR" : "R"
                        )
                    }}
                  </ng-template>
                </span>
                <span class="price-color">
                  <div *ngIf="data?.dealDetails; else productBlock">
                    {{
                      data?.dealDetails[0]?.dealPrice &&
                        getPrice(
                          data?.dealDetails[0]?.dealPrice
                            | currency : "ZAR" : "R"
                        )
                    }}
                  </div>
                  <ng-template #productBlock>
                    {{
                      data?.dealPrice &&
                        getPrice(data?.dealPrice | currency : "ZAR" : "R")
                    }}
                  </ng-template>
                </span>
              </p>
            </div>
          </div>

          <div class="row" style="margin-top: -8px">
            <div class="col-12 p-0 m-0">
              <p class="dealPrice" style="color: #707070">
                Deal Discount :
                <span class="newn"> {{ data?.dealDiscount }}% off </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
