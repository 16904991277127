import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'service-card-category',
  templateUrl: './service-card-category.component.html',
  styleUrls: ['./service-card-category.component.scss']
})
export class ServiceCardCategoryComponent implements OnInit {
  @Input() data : any
  @Input() catName : any
  @Input() showDescription : any
  @Output() on_Click = new EventEmitter
  @Output() on_Click_Cat = new EventEmitter
  isLoaded: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  navigateToViewProduct(e){
    this.on_Click.emit(e)
  }
  // navigateTocategory(e,data){
  //   this.on_Click_Cat.emit({
  //     e:e,data:data
  //   })
  // }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
  onImageLoad(){
    this.isLoaded = true
  }
}
