<div class="mt-5">
    <article class="card">
       
        <div class="card-body">
      
            
            <div class="track">
                <div class="step " style="text-align: left;"  [ngClass]="{'active': orderDataDetials.booking}"> <span class="icon text-center"> <i class="fa fa-check"></i> </span> <span class="text">Booking Received</span> </div>
                <div class="step" [ngClass]="{'active': orderDataDetials.ontheway}"> <span class="icon"> <i class="fa fa-archive"></i> </span> <span class="text"> Service Provider on the way </span> </div>
                <div class="step " [ngClass]="{'active': orderDataDetials.execution}"> <span class="icon"> <i class="fa fa-user"></i> </span> <span class="text"> Execution in Progress </span> </div>
                <div class="step" style="text-align: end;" [ngClass]="{'active': orderDataDetials.completed}"> <span class="icon text-center" style="left:5px"> <i class="fa fa-check-square-o"></i> </span> <span class="text">Execution Completed </span> </div>
            </div>

        
          
        </div>
    </article>
</div>
