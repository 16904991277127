<!-- jj -->
<auth-layout title="Fee Configuration" type="SIGNUP" subTitile="" isHiddenImage="true">
    <div class="container mb-4 wrapper-content">


        <div class="row mt-3">

            <div class="col-12 table-responsive">
               
                <table class="table table-hover" *ngIf="serviceRole == 'PICKUP' || serviceRole == 'DELIVERY'">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Delivery Type</th>
                            <th scope="col">Fee</th>
                            <th scope="col" *ngIf="this.getEndUrlSegment() == 'update-fee-pickup-partner'">Action</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pickupOrderList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                            <th>{{10 * (currentPage - 1) + i+1}}</th>
                            <th scope="row">{{item?.feeName}}</th>
                            <th scope="row" >
                                <input type="text" *ngIf="serviceRole == 'PICKUP'" class="form-control" [(ngModel)]="item.pickupFee">
                                <input type="text" *ngIf="serviceRole == 'DELIVERY'" class="form-control" [(ngModel)]="item.deliveryFee">
                            </th>
                            <th scope="row" *ngIf="this.getEndUrlSegment() == 'update-fee-pickup-partner'">
                                <button class="btn btn-theme btn-primary" *ngIf="serviceRole == 'PICKUP'" (click)="onSubmit(item._id, item.pickupFee)">Update</button>
                                <button class="btn btn-theme btn-primary" *ngIf="serviceRole == 'DELIVERY'" (click)="onSubmitDelivery(item)">Update</button>
                            </th>


                        </tr>
                    </tbody>
                </table>
                <table class="table table-hover" *ngIf="serviceRole == 'FIELD'">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Item/Size</th>
                            <th scope="col" colspan="5">Weight</th>
                            <th scope="col" *ngIf="this.getEndUrlSegment() == 'update-fee-pickup-partner'">Action</th>

                        </tr>
                        <tr>
                            <th scope="col"></th>    
                            <th scope="col"></th>    
                            <th scope="col">Daily</th>    
                            <th scope="col">Weekly</th>    
                            <th scope="col">Monthly</th>    
                            <th scope="col">Quarterly</th>    
                            <!-- <th scope="col">Yearly</th>     -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pickupOrderList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                            <th>{{10 * (currentPage - 1) + i+1}}</th>
                            <th scope="row">{{item?.feeName}}</th>
                            <th scope="row" >
                                <input type="text"  class="form-control" [(ngModel)]="item.storageFee.daily">
                               
                            </th>
                            <th scope="row" >
                                <input type="text"  class="form-control" [(ngModel)]="item.storageFee.weekly">
                               
                            </th>
                            <th scope="row" >
                                <input type="text"  class="form-control" [(ngModel)]="item.storageFee.monthly">
                               
                            </th>
                            <th scope="row" >
                                <input type="text"  class="form-control" [(ngModel)]="item.storageFee.quarterly">
                               
                            </th>
                           
                            <th scope="row" *ngIf="this.getEndUrlSegment() == 'update-fee-pickup-partner'">
                                <button class="btn btn-theme btn-primary" (click)="onSubmitStorage(item)">Update</button>
                            </th>


                        </tr>
                    </tbody>
                </table>




            </div>
            <ng-container *ngIf="!getData">
                  <div class="col-12 text-right" *ngIf="this.getEndUrlSegment() != 'update-fee-pickup-partner'">
                <button class="btn btn-primary btn-theme" (click)="onSubmit()">Save</button>
            </div>
            </ng-container>
            <ng-container *ngIf="getData">
                  <div class="col-12 text-right" >
                <button class="btn btn-primary btn-theme" (click)="onDataGet()">Save</button>
            </div>
            </ng-container>
        </div>



    </div>


</auth-layout>