<div class="container fw-b">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-5 wrapper-content">
  <div class="row justify-content-center searchproductBox">
    <div class="form-group has-search">
      <span class="fa fa-search form-control-feedback"></span>
      <input type="text" class="form-control" [(ngModel)]="serviceName" placeholder="Search by product name"
        (input)="getproduct(); currentPage = 1" />
    </div>
  </div>
  <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3 productpaddingBox">
    <ng-container *ngIf="bannerData?.length">
      <div class="col newCardWidth mb-3" *ngFor="
        let item of bannerData
          | paginate
            : {
                itemsPerPage: pageSize,
                currentPage: currentPage,
                totalItems: totalItems
              };
        let i = index
      ">
        <div class="innerDiv">


          <product-card [data]="item" (on_Click)="navigateToViewProduct(item?._id)"></product-card>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!bannerData?.length && !isLoading">
      <div class="col newCardWidth mb-3" *ngFor="
        let item of length(12)">
        <div class="innerDiv">


          <product-card [data]="item" ></product-card>
        </div>
      </div>
    </ng-container>
    <div *ngIf="isLoading" class="no-data">
      <p style="text-align: center; font-size: 20px; font-weight: 500">
        No Product Available...
      </p>
    </div>
  </div>
  <div class="custom-pagination mt-2 text-align-right" style="text-align: right" *ngIf="totalItems > 15">
    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
  </div>
</div>