import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-select-service',
  templateUrl: './select-service.component.html',
  styleUrls: ['./select-service.component.scss']
})
export class SelectServiceComponent implements OnInit {

  _id: any
  isLoggedIn: any
  loggedInUserType: any
  catID: any
  UsID: any
  categoryName: any
  serviceId: any
  name: any;
  amount: any = [];
  serviceArrayId: any

  panelOpenState = false; //accordian open close question's and answer's
  linkerRouteItems:any =[
    { name:'Home',route: '/service-order-detail'},
    {name:'Service Deal List',route:'/service-provider-list-deals'}
  ]
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this.catID = res.categoryId;
      this.UsID = res.userId;
      this.categoryName = res.categoryName
      this.serviceId = res._id;
      this.name = res.name;
    })
  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res: any) => {


      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {

      this.loggedInUserType = res
    })
    // this.getProduct();
    this.viewCategoryAvailableService()
  }



  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any

  getProduct() {
    if (this.isLoggedIn) {
      this.viewRetailerProductd()
    } else {
      this.viewRetailerProductdWithoutLogin()
    }
  }
  firstPriceSize: any = [];
  productDetailsSize: any = []
  // get retailer detail by id
  viewRetailerProductd() {
    let apiReqUrl: any = `product/viewProduct?productId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {

        this.productDetails = res.result;
        this.productDetailsSize = res.result.priceSizeDetails

        this.firstPriceSize = res.result.priceSizeDetails[0]

        this.price(this.firstPriceSize.price, this.firstPriceSize.quantity, this.firstPriceSize.value, this.firstPriceSize.id, this.firstPriceSize.unit)
        this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  viewRetailerProductdWithoutLogin() {
    let apiReqUrl: any = `product/viewProduct?productId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.productDetails = res.result;
        this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }



  forID(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } })

  }




  changeProductimage(url: any) {
    this.imgurl = url
  }


  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  mrp: any
  quantity: any
  value: any
  unit: any
  pdId: any
  price(e: any, q: any, v: any, id, u) {
    this.mrp = e,
      this.quantity = q
    this.value = v
    this.unit = u
    this.pdId = id
  }
  imgSrc: any
  productImage: any
  serviceData: any = []

  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.viewRetailerProductd()
        // this.router.navigate(['/edit-product'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }

  // @my code

  UserDetailsData: any
  serviceDetailsArray: any = []
  viewCategoryAvailableService() {
    let apiReqUrl = `v2/service/allServiceList`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.serviceDetailsArray = res.result.categories
        // this.serviceDetailsArray = res.result.docs
        // this.serviceDetailsArray.forEach((item, index) => {
        //   item['_id'] = index
        // });

        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }
  serviceArray: any = []
  navigateToAddDeals() {
    let isValid: boolean = false
    this.serviceDetailsArray.forEach(element => {
      element.serviceArray.forEach(ele => {
        if (ele.isSelected) {
          isValid = true

        }


      });

    });
    if (isValid) {
      this.router.navigate(['/service-provider-add-deals'], { queryParams: { id: this.serviceId } })
    } else {
      return this.commonService.errorToast('Please add atleast one service')
    }
  }


  update() {
    let arr = []
    for (let item of this.serviceDetailsArray) {
      for (let data of item.subCategories) {
        for (const service of data.services) {
          if (service.isSelected) {
            if(service.isSelected && !service.price){
              this.commonService.errorToast("Please enter amount")
              return
            }
            let obj = {
              categoryId : item.categoryData._id,
              categoryType : item.categoryData.categoryType || 'SERVICE_PROVIDER',
              subCategoryId : data.subCategoryData._id,
              categoryEnum : service.categoryEnum,
              pickupFeeId : service.pickupFeeId,
              storageFeeId : service.storageFeeId,
              deliveryFeeId : service.deliveryFeeId,
              "isSelected": service.isSelected,
              "price": service.price,
              "serviceId": service._id
            }
            arr.push(obj)
          }
        }
       
      }
    }

    console.log(arr);

    if(!arr.length){
      return this.commonService.errorToast('Please add atleast one service') 
    }
    

    
    let apiReqUrl: any = `v2/service/updateServiceUser3`;
    const data = {
      serviceDetailsArray: arr,
      categoryId: this.serviceId

    }
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, arr, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.successToast(res.responseMessage)
        this.commonService.hideSpinner();
        this.router.navigate(['/business-details-service-provider'])
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    });
  }

  requirePrice: any
  checkValidation(e, price, id) {
    this.serviceArrayId = id;
    this.requirePrice = price
    if (e) {
      if (!price) {
        this.commonService.errorToast("Please enter amount")
      }
    }
  }
  // @my code end

  navigateToserviceAvailableAdd() {
    sessionStorage.setItem('selectedService', JSON.stringify(this.selectedService))
    this.router.navigate(['/customer-service-quantity-add'], { queryParams: { categoryId: this.catID, userId: this.UsID, categoryName: this.categoryName } })
  }

  selectedService = []
  getSelectedService(e, obj, data, index) {




  }
  navigateToback() {
    this.router.navigate(['/services'], { queryParams: { _id: this.catID } })
  }


}
