import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
declare var $ : any;

@Component({
  selector: 'app-customer-address-list',
  templateUrl: './customer-address-list.component.html',
  styleUrls: ['./customer-address-list.component.scss']
})
export class CustomerAddressListComponent implements OnInit {
  _id: any
  qtyA:any
  serviceData:any
  headingName:any ='DELIVERY ADDRESSES'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    { name:'Account',route: '/customer-profile'},
    { name:'Address',route: '/customer-address-list'}
  ]

  isLoading: boolean = false;
  notFoundData: boolean = false;
  showAddress = false
  checkedBox = []
  paymentStatus : any


  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      if(res.paymentStatus){
        this.paymentStatus = res.paymentStatus
      }
    })
  }

  ngOnInit(): void {
    this.listAddress();
  }

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }

listAddressData:any = []
listAddress(){
  this.isLoading =true
  let apiReqUrl = `user/listAddress`
  this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
  this.isLoading =false
    if (res.responseCode == 200 ) {
      this.listAddressData = res.result.docs;
      
      if(this.listAddressData.length>0){
        this.notFoundData =false
      }else{
        this.notFoundData =true
      }
      let temp = res.result.docs.filter((ele) => {
        return ele.isPrimary
      })
      this.addressId = temp[0]?._id
      this.commonService.hideSpinner();
    } else {
      this.notFoundData =true
      this.commonService.hideSpinner();
      this.commonService.errorToast(res.responseMessage)
    }
  },err=>{
    this.notFoundData =true
      this.isLoading =false
  })
}
navigateToEdit(id){
  this.router.navigate(['/edit-address-customer'], { queryParams: { id: id } })
}
addressId:any
toAddressId(id){
this.addressId = id
}
navigateToOrderOverview(){
  if(!this.addressId){
    return this.commonService.errorToast('Please select address.')
  }
  this.router.navigate(['/order-overview-customer'], { queryParams: { idOfAddress : this.newAddressId || this.addressId } })
}
addId:any
isPrimary:any
addressModals(id,prmy) {
  this.addId = id
  this.isPrimary = prmy
  $('#addressModal').modal('show')
}

navigatetoAdd(){
  
  if(this.getEndUrlSegment() == "list-address-customer"){

    this.router.navigate(['/edit-address-customer'],{queryParams : {q : 'addAddress'}})
  }
  else{
    this.router.navigate(['/edit-address-customer'])
  }
  
}

deleteAddress() {
  let apiReqUrl = `user/deleteAddress?addressId=${this.addId}`
  
  if(this.isPrimary == true){
    return this.commonService.errorToast('You can not delete primary address')
   
  }else{
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        // this.listAddressData = res.result.docs;
        $('#addressModal').modal('hide')
        this.listAddress()
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    },(err:any)=>{
      this.commonService.hideSpinner();
    })
  }
}
setPrimaryAddress(id){
  let url = `user/markAddressPrimary`
  let data = {
    "addressId": id,
    //  "isPrimary": true
  }
  this.commonService.showSpinner();
  this.commonService.putApi(url,data, 1).subscribe((res: any) => {
    if (res.responseCode == 200) {
      // this.listAddressData = res.result.docs;
      if(res.result._id != 0){
        localStorage.setItem('primaryAddressId', res.result._id)
      }
      this.listAddress()
      this.commonService.hideSpinner();
    } else {
      this.commonService.hideSpinner();
      this.commonService.errorToast(res.responseMessage)
    }
  },(err:any)=>{
    this.commonService.hideSpinner();
  })
 
}
getEndUrlSegment(): string {
  const url = this.router.url;
  const segments = url.split('/');
  const lastSegmentWithQueryParamsAndFragments = segments[segments.length - 1];
  const endUrlSegment = lastSegmentWithQueryParamsAndFragments.split('?')[0].split('#')[0];
  return endUrlSegment;
}
newAddressId : any
getChackBox(is,id){
  if(is){
    this.newAddressId = id
  }
  else{
    this.newAddressId = this.addressId
  }
}
}
