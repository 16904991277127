<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container">
  <div class="" id="xyz">

    <form [formGroup]="addProduct" class="form mt-3 ">
      <!-- <h1 class="add mb-5 col-md-12">Add Address</h1> -->
      <div class="row">
        <h1 class="top-text col-md-12 pl-0">Personal Details</h1>
      </div>
      <div class="row mb-4">
        <div class="col-md-4 fna">

          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">First Name<span class="astict">*</span></label>
            <input type="text" placeholder="Enter first name" formControlName="fName" maxlength="62"
              (keypress)="preventSpace($event)" class="form-control form-control-small" />
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('fName')?.hasError('required') && addProduct.get('fName')?.touched ">
              *Please enter  first name.</div>

            <div class="for-validation text-danger"
              *ngIf="addProduct.get('fName')?.hasError('minlength') && addProduct.get('fName')?.dirty ">
              *Please enter minimum 2 character.</div>

            <!-- <div class="for-validation text-danger"
              *ngIf="addProduct.get('fName')?.hasError('pattern') && addProduct.get('fName')?.dirty">
              *Please enter valid first name.

            </div> -->
          </div>
        </div>
        <div class="col-md-4 fna">

          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">Last Name<span class="astict">*</span></label>
            <input type="text" id="form3Examplea8" placeholder=" Enter last name" formControlName="lName"
              maxlength="250" (keypress)="preventSpace($event)" class="form-control form-control-small" />
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('lName')?.hasError('required') && addProduct.get('lName')?.touched ">
              *Please enter last name.</div>

            <div class="for-validation text-danger"
              *ngIf="addProduct.get('lName')?.hasError('minlength') && addProduct.get('lName')?.dirty ">
              *Please enter minimum 2 character.</div>

            <!-- <div class="for-validation text-danger"
              *ngIf="addProduct.get('lName')?.hasError('pattern') && addProduct.get('lName')?.dirty">
              *Please enter valid Last name.</div> -->
          </div>
        </div>
        <div class="col-md-4 fna">
          <label class="form-label Eadd">Mobile Number<span class="astict">*</span></label>


          <div class="">
            <div class="input-group">
              <div class="input-group-prepend ">
                <span class="asd3"> <input list="browsers" class="form-control email-pass-pro ddd"
                    style="border-radius: 5px 0px 0px 5px !important;" formControlName="countryCode" name="browser"
                    id="browser"></span>

                <datalist id="browsers">
                  <option *ngFor="let code of datas" value="{{code?.phonecode}}">{{code?.flag}}
                    {{code?.phonecode}}
                    {{code?.isoCode}} </option>

                </datalist>
                <input type="text" class="fbb" (keypress)="preventSpace($event)"
                  style="border-radius: 0px 5px 5px 0px !important;" (keypress)="NumOnly($event)"
                  formControlName="mobileNumber" maxlength="15" class="form-control-small form-control">

              </div>
              <div class="for-validation text-danger"
                *ngIf="addProduct.get('mobileNumber').hasError('required') &&  addProduct.get('mobileNumber').touched"
                padding>*Mobile number field can not be empty.</div>
              <div class="for-validation text-danger"
                *ngIf="addProduct.get('mobileNumber').hasError('pattern') &&  addProduct.get('mobileNumber').touched"
                padding>*Invalid mobile number.</div>
              <div class="for-validation text-danger"
                *ngIf="addProduct.get('mobileNumber').hasError('minlength') || addProduct.get('mobileNumber').hasError('maxlength')"
                padding>*Please enter valid number between 8-15 digit.
              </div>
              <!-- <div class="for-validation  text-danger mt-1 mb-1"
                            *ngIf="addProduct.get('mobileNumber')?.hasError('required') && addProduct.get('mobileNumber')?.touched ">
                            Mobile number is required.</div>
                        <div class="for-validation  text-danger mt-1 mb-1"
                            *ngIf="addProduct.get('mobileNumber')?.hasError('pattern') && addProduct.get('mobileNumber')?.dirty ">
                            Please enter valid Mobile number</div>


                        <div class="for-validation text-danger mt-1 mb-1"
                            *ngIf="addProduct.get('mobileNumber')?.hasError('minlength') && addProduct.get('mobileNumber')?.dirty ">
                            Please enter valid number between 8-15 digit.</div>

                        <div class="for-validation text-danger mt-1 mb-1"
                            *ngIf="addProduct.get('mobileNumber')?.hasError('maxlength') && addProduct.get('mobileNumber')?.dirty">
                            Please enter valid number between 8-15 digit.</div> -->
            </div>
          </div>
        </div>
      </div>


      <div class="row mb-4">
        <div class="col-md-4 fna">

          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">Email<span class="astict">*</span></label>
            <input type="text" id="addr" placeholder="Enter your email " formControlName="email" maxlength="250"
              (keypress)="preventSpace($event)" class="form-control form-control-small" />
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('email')?.hasError('required') && addProduct.get('email')?.touched ">
              *Email is required.</div>
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('email')?.hasError('pattern') && addProduct.get('email')?.dirty ">
              *Please
              enter a valid email.</div>
          </div>
        </div>

      </div>



      <div class="row mt-5">
        <h1 class="top-text col-md-12 pl-0">Address Details</h1>
      </div>
      <!-- name  -->
      <div class="row mb-4">
        <div class="col-md-4 fna">

          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">Address Line 1<span class="astict">*</span></label>
            <!-- <input type="text" id="addr" placeholder="Enter Address" formControlName="address" maxlength="250"
                  (keypress)="preventSpace($event)" class="form-control form-control-small" /> -->
            <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
              (onAddressChange)="handleAddressChange($event)" id="addr" placeholder="Enter Address "
              formControlName="address1" (keypress)="preventSpace($event)" class="form-control form-control-small" />
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('address')?.hasError('required') && addProduct.get('address')?.touched ">
              *Address1 is required.</div>
            <!-- <div class="for-validation text-danger"
                  *ngIf="addProduct.get('address')?.hasError('maxlength') && addProduct.get('address')?.touched">
                  *Can not enter more than maxlength 32 character</div> -->
            <div class="for-validation text-danger"
              *ngIf="!addProduct.get('address')?.hasError('required') && addProduct.get('address')?.touched && is_addressInValid">
              *Please enter valid address.</div>
          </div>
        </div>

        <div class="col-md-4 fna">

          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">Address Line 2<span class="astict">*</span></label>
            <input type="text" id="addr" placeholder="Enter your address2 " formControlName="address2" maxlength="250"
              (keypress)="preventSpace($event)" class="form-control form-control-small" />
            <!-- <div class="for-validation text-danger"
                *ngIf="addProduct.get('email')?.hasError('required') && addProduct.get('email')?.touched ">
                *Address is required.</div> -->
          </div>
        </div>

        <!-- <div class="col-md-4 fna">
  
              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">Address 2</label>
                <input type="text" id="form3Examplea8" placeholder="Enter Address 2" formControlName="address1"
                  (keypress)="preventSpace($event)" maxlength="250" class="form-control form-control-small" />
              </div>
            </div> -->
        <div class="col-md-4 fna">
          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">Country<span class="astict">*</span></label>

            <!-- <div class="col-6 "> -->
            <select class="form-control email-pass-pro" id="asd3" formControlName="country"
              (change)="getStateList($event)">
              <option value="" selected style="text-align: start;">Select your country
              </option>
              <option *ngFor="let item of countryList" [value]="item.isoCode">
                {{item?.name}}</option>
            </select>
            <!-- </div> -->
          </div>
        </div>

      </div>

      <div class="row mt-4">

        <div class="col-md-4 fna">
          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">State<span class="astict">*</span></label>
            <select class="form-control email-pass-pro" id="asd3" formControlName="state"
              (change)="getCityList($event)">
              <option value="" selected>Select your state</option>
              <option *ngFor="let item of stateList" [value]="item.isoCode">{{item?.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-4 fna">

          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">City<span class="astict">*</span></label>
            <select class="form-control email-pass-pro" id="asd3" formControlName="city">
              <option value="" selected>Select your city</option>
              <option *ngFor="let item of cityList" [value]="item.name">{{item?.name}}
              </option>
            </select>

          </div>
        </div>
        <div class="col-md-4 ">

          <div class="form-outline form-white">
            <label class="vantiy" for="form3Examplea8">Zip Code<span class="astict">*</span></label>
            <input type="text" id="asd" formControlName="zipCode" placeholder=" Enter Zip Code"
              (keypress)="preventSpace($event)" (keypress)="NumOnly($event)" class="form-control email-pass-pro"
              maxlength="6" />
            <div class="for-validation text-danger mt-1 mb-1"
              *ngIf="addProduct.get('zipCode')?.hasError('required') && addProduct.get('zipCode')?.touched">
              *Zip code field can not be empty.</div>
          </div>
        </div>
      </div>
      <div class="ml-0 mt-5 mb-4">
        <button class="btn btn-outline-success mr-3 back-button back-can" type="submit"
          (click)="commonService.backLocation()">Cancel</button>
        <button class="btn btn-outline-success my-2 my-sm-0 back-button" type="submit"
          (click)="addAddress()">Add</button>
      </div>
    </form>

  </div>
</div>