<!-- <div class="container mb-5">
    <h2 class=" text-center mt-3">Deals to customers</h2>
    <div class="row mt-3">
        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-3" *ngFor="let item of serviceDetail">
            <div class="card innerDiv" [queryParams]="{_id: item?._id}"
                [routerLink]="['/service-provider-available-deals']">
                <div class="image">
                    <img class="img" [src]="item?.categoryImage" alt="categoryImage">
                </div>
                <div class="mt-3">
                    <h3 class="text-center">{{item?.categoryName}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading" class="no-data">
        <p style="text-align: center; font-size: 16px; font-weight: 500;">No Data Found...</p>
    </div>
</div> -->
<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
  </div>
<!-- new style add -->
<div class="container mb-5 wrapper-content">
    <!-- <span (click)="commonService.backLocation()" class="circle-arrow text-start">
      <img class="arrow-image-add-new" src="assets/svg/ar.svg" alt="" />
    </span> -->
    <!-- <h1 class="text-center mt-3" style="text-align: center;">Services</h1> -->
    <!-- <h1 class="text-center mt-3" style="text-align: center">
        <img src="assets/latest/left-arrow.svg" alt="">
        DEALS TO CUSTOMERS
                <img src="assets/latest/right-arrow.svg" alt="">
            </h1> -->
    <!-- <div class="row justify-content-center">
      <div class="form-group has-search">
        <span class="fa fa-search form-control-feedback"></span>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="serviceName"
          placeholder="Search by service name"
          (input)="serviceOrderList();currentPage=1"
        />
      </div>
    </div> -->
    <div class="row  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3">
      <div
        class="col newCardWidth mb-3"
        *ngFor="
          let item of serviceDetail
            | paginate
              : {
                  itemsPerPage: pageSize,
                  currentPage: currentPage,
                  totalItems: totalItems
                };
          let i = index
        "
      >
        <div
          class="innerDiv"
          [queryParams]="{_id: item?._id}"
          [routerLink]="['/service-provider-available-deals']"
        >
          <!-- <div class="image">
            <img class="img" [src]="item?.thumbnail" alt="" />
          </div>
          <div class="mt-3">
            <h3 class="text-center">{{ item?.categoryName }}</h3>
          </div> -->
          <!-- <service-card-category [data]="item" [catName]="item?.categoryName" ></service-card-category> -->
          <category-layout-home [data]="item" [catName]="item?.categoryName"></category-layout-home>
        </div>
      </div>
      <div *ngIf="isLoading" class="no-data">
        <p style="text-align: center; font-size: 16px; font-weight: 500">
          No Data Found...
        </p>
      </div>
    </div>
    <div
      class="custom-pagination mt-2 text-align-right"
      style="text-align: right"
      *ngIf="totalItems > 12"
    >
      <pagination-controls
        (pageChange)="pagination($event)"
      ></pagination-controls>
    </div>
  </div>
  