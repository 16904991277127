import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-service-order-otp-verification',
  templateUrl: './service-order-otp-verification.component.html',
  styleUrls: ['./service-order-otp-verification.component.scss']
})
export class ServiceOrderOtpVerificationComponent implements OnInit, OnDestroy {
  c = console.log.bind(document)

  otpForm!: FormGroup
  otp: any;
  myEmail: any;
  countDowon: any = 2.59
  flag: boolean;
  _id: any;
  _id1: any;
  orderId: any


  contDownTimer() {
    var a = window.setInterval(() => {
      if (this.countDowon - (parseInt(this.countDowon)) > 0.60) {

        this.countDowon = this.countDowon - 0.40
      }
      if (this.countDowon > 0) {
        this.countDowon = this.countDowon - 0.01
        this.getTime((this.countDowon).toFixed(2))
      }
      else {
        this.resend_otp_active = true
        clearInterval(a)
      }
    }, 1000)
  }

  resend_otp_active: boolean = false
  time!: string
  getTime(e: any) {
    this.time = String(e).replace('.', ':')
    this.time = String(e).replace('-', '')
  }
  constructor(private http: HttpClient, public commonService: CommonService, private router: Router, public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.flag = res.flag;
      this._id = res._id;
      this._id1 = res._id1;
      this.orderId = res.orderId;
      this.c(this.flag)
      this.c(this._id)
      this.c(this._id1)
    })
  }

  ngOnInit(): void {
    this.otpForm = new FormGroup({
      otp: new FormControl("", [
        Validators.required,

      ]),
    });
    this.timer(180)
    this.myEmail = localStorage.getItem('email')
    this.markAsDone();


    // this.resendOtp();
  }
  timerOn = true;

  markAsDone() {
    let url = `user/serviceMarkAsDone`
    let data = {
      orderId: this.orderId
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res && res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res?.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err?.responseMessage)
    })
  }
  resend() {
    if (!this.isExpire) {
      return
    }
    let url = `user/serviceMarkAsDone`
    let data = {
      orderId: this.orderId
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res && res.responseCode == 200) {
        console.log(res,"m,essss")
        this.timer(180);
        this.isExpire = false;
        this.commonService.hideSpinner();
        this.commonService.successToast(res?.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err?.responseMessage)
    })
  }

  verifyOTP() {
    let url = `service/verifyServiceRequest`
    let data = {
      orderId: this.orderId,
      otpService: this.otp
    }
    this.commonService.showSpinner();
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      if (res && res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.router.navigate(['/service-order-detail'])
        this.commonService.successToast(res?.responseMessage)
      }
    }, (err: any) => {
      console.log(err,"errrrr")
      this.commonService.hideSpinner();
      this.commonService.errorToast(err?.responseMessage)
    })
  }

  backToView() {
    if (this.flag) {
      this.router.navigate(['/service-order-detail-view'], { queryParams: { _id: this._id } });
    } else {
      this.router.navigate(['/service-provider-request-services-view'], { queryParams: { _id: this._id1 } })
    }
  }

  onOtpChange(otp: any) {
    this.otp = otp;
  }

  navigateToResetPassword() {
    let userData = {
      email: this.myEmail,
      otp: this.otp,
      userType: 'SERVICE_PROVIDER',
    }
    this.commonService.showSpinner()

    this.commonService.postApi('user/verifyOTP', userData, 0).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner()
        this.commonService.successToast(res['responseMessage'])
        this.router.navigate(['/reset-password-service-provider'])
      }

    }, err => {
      this.commonService.hideSpinner()
      if (err['error']['responseCode'] == 400) {
        this.commonService.warningToast(err['responseMessage'])
      }
    })

  }

  remainingTime: any
  isExpire: boolean = false
  timeOutId:any;
  timer(remaining) {
    let m: any = Math.floor(remaining / 60);
    let s: any = remaining % 60;

    m = m < 10 ? '' + m : m;
    s = s < 10 ? '0' + s : s;
    this.remainingTime = m + ':' + s

    // document.getElementById('timer').innerHTML = m + ':' + s;
    remaining -= 1;
    let me = this
    localStorage.setItem('remainingTime',remaining)
    if (remaining >= 0 && this.timerOn) {
      this.timeOutId =  setTimeout(function () {
        me.timer(remaining);
      }, 1000);
      return;
    }

    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    localStorage.removeItem('remainingTime')
    this.isExpire = true
  }
  ngOnDestroy(): void {
    // This method will be called just before the component is destroyed
    this.timer(0)
    clearTimeout(this.timeOutId)
    localStorage.removeItem('remainingTime');
 }
}
