import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-customer-product-list',
  templateUrl: './customer-product-list.component.html',
  styleUrls: ['./customer-product-list.component.scss']
})
export class CustomerProductListComponent implements OnInit {
  _id: any
  currentPage = 1;
  pageSize = 15;
  serviceName: any
  latitude: any;
  longitude: any;
  isLoggedIn: any
  loggedInUserType: any
   headingName:any ='PRODUCTS'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Products',route:'/customer-product-list'}
  ]
  constructor(private activatedroute: ActivatedRoute, public authService: AuthService, public commonService: CommonService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })
  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res: any) => {
      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res
    })
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn()
    navigator.geolocation.getCurrentPosition(function (position) {
      // alert('allow');  
    }, function () {
      alert('You need to enable geolocation services in your browser to use this feature.');
    });
    this.getPosition().then(pos => {
      localStorage.setItem('location', JSON.stringify(pos))
    });
    this.getLocation();

  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getProducts()

        }
      },
        (error) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any
  totalItems: any
  bannerData: any = []
  // get retailer detail by id
  getProducts() {
    if (this.isLoggedIn) {
      this.getproductwithLogin()
    } else {
      this.getproduct()
    }
  }
  user: any
  getproduct() {
    let url = `product/listProductBySortion`
    if (this.loggedInUserType == 'RETAILER') {
      this.user = 'WHOLE_SALER'
    } else {
      this.user = 'RETAILER'
    }
    let data = {
      // "dealType" :  "PRODUCT",
      "userType":this.user,
      'lng': this.longitude,
      'lat': this.latitude,
      'page': this.currentPage,
      'limit': this.pageSize,
      search: this.serviceName,
    }
    
    this.commonService.removeEmptyKey(data)
    this.bannerData = []
    this.commonService.postApi(url, data, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.bannerData = res["result"]["docs"];
        this.totalItems = res['result']['count']
        if (!this.bannerData.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        this.commonService.hideSpinner()
      } else {
        this.totalItems = 0
        this.isLoading = true;
        this.bannerData = []
        this.commonService.hideSpinner()
      }
    }, (err) => {
      this.isLoading = true;
      this.totalItems = 0
      this.bannerData = []
      this.commonService.hideSpinner()
    })
  }
  isLoading: boolean = false
  getproductwithLogin() {
    let url = `product/listProductBySortion`
    if (this.loggedInUserType == 'RETAILER') {
      this.user = 'WHOLE_SALER'
    } else {
      this.user = 'RETAILER'
    }
    let data = {
      // "dealType" :  "PRODUCT",
      "userType": this.user,
      'lng': this.longitude,
      'lat': this.latitude,
      'page': this.currentPage,
      'limit': this.pageSize,
      search: this.serviceName,
    }
    
    this.commonService.removeEmptyKey(data)
    this.bannerData = []
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {

        this.bannerData = res["result"]["docs"];
        this.totalItems = res['result']['count']
        if (!this.bannerData.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        this.commonService.hideSpinner()
      } else {
        this.totalItems = 0
        this.bannerData = []
        this.commonService.hideSpinner()
      }
    }, (err) => {
      this.totalItems = 0
      this.bannerData = []
      this.commonService.hideSpinner()
    })
  }
  navigateToViewProduct(_id) {
    this.router.navigate(['/product-view-customer'], { queryParams: { _id: _id } })
  }
  pagination(event) {
    this.currentPage = event;
    this.getProducts();
  }
  getPrice(e: any) {

    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.getProducts()
        // this.router.navigate(['/edit-product'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }

  length(n){
    return new Array(n)
  }
}
