<div class="container mb-4 wrapper-content"></div>


<div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="height: 90px; background: #BF1E2E;">
        <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img
            style="height: 100%; width:100%; object-fit: cover;" src="assets/img/whiteLogo.png" alt=""></h5>

      </div>
      <div class="modal-body" style="background: #BF1E2E;">
        <div class="row justify-content-center">
          <div class="col-12 text-center text-white">
            <h3 class="m-0 " style="font-weight: 500;">Please select the service(s) you would like to provide</h3>
          </div>
          <div class="col-12 text-center division">
            <div class="role-container">
              <input type="checkbox" [checked]="standard" class="check-input"
                [disabled]="serviceArray.includes('STANDARD')" class="check-input" #transportationCheckbox
                (change)="setService(transportationCheckbox.checked, 'STANDARD')" name="" id="standard">

              <div class="img-container">
                <img src="https://exobe.s3.af-south-1.amazonaws.com/1694516619481_convert2.jpeg" alt="">
              </div>
              <p>Standard Services</p>
            </div>
          </div>
          <div class="col-6 text-center division">
            <div class="role-container">
              <input type="checkbox" [checked]="field" class="check-input" #fullfillmentCheckbox
                [disabled]="serviceArray.includes('FULLFILLMENT')" class="check-input"
                (change)="setService(fullfillmentCheckbox.checked, 'FULLFILLMENT')" name="" id="fullfillent">
              <div class="img-container">
                <img [src]="field2 ? field2?.categoryImage :'assets/social/fullfillment.png'" alt="">
              </div>
              <p>{{field2 ? field2?.categoryName :'Fulfilment Services'}}</p>
            </div>
          </div>
          <div class="col-6 text-center division">
            <div class="role-container">
              <input type="checkbox" [checked]="transport" class="check-input" #otherCheckbox
                [disabled]="serviceArray.includes('TRANSAPORTATION')" class="check-input"
                (change)="setService(otherCheckbox.checked, 'TRANSAPORTATION')" name="" id="transaportation">
              <div class="img-container">
                <img [src]="transport2 ? transport2?.categoryImage :'assets/social/deliverydriver.png'" alt="">
              </div>
              <p>{{transport2 ? transport2?.categoryName : 'Transportation Services'}}</p>
            </div>
          </div>
          <div class="col-12 text-center division">
            <div class="role-container">
              <button class="btn w-100 h-100" (click)="onContinue()">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>