<div >
    <div>
        <div class="bannerContent">
            <div class="card innerDiv">
            <!-- <div class="card innerDiv" (click)="navigateToViewProduct(data?._id);navigateTocategory(data?._id,data?.categoryName)"> -->
                <div class="image">
                    <img class="img" [hidden]="!isLoaded" (load)="onImageLoad()" [src]="data?.categoryImage" alt="service provider">
                    <ngx-skeleton-loader *ngIf="!isLoaded" [theme]="{width: '227px',
                    height: '329px',
                    position: 'relative'}" count="1"></ngx-skeleton-loader>
                </div>
                <div class="mt-3">
                    <p class="description">{{data?.categoryName}}</p>
                    <p class="description" *ngIf="showDescription" style="height: 50px;text-wrap: wrap;">{{data?.description}}</p>

                </div>
            </div>
        </div>
    </div>
</div>