<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="main-container">
    <div class="container d-flex mp11">
      <div class="left-container">
        <div class="mp10">
          <div class="emailitem">
            <img src="assets/latest/emailimg.png" alt=""> <span class="emailaddres"> info@exobe.africa</span>
          </div>
            <form [formGroup]="helpForm">
                <div class="form-group">
                  <div class="input-control">
                    <input type="text" class="form-control subject" placeholder="Subject" type="text" formControlName="subject" maxlength="256" 
                      id="username">
                  </div>
                  <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                      *ngIf="helpForm.get('subject')?.hasError('required') && helpForm.get('subject')?.touched">
                      *Please enter subject.
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-control">
                      <textarea type="text"  class="user-email" placeholder="Description" rows="10" formControlName='description'></textarea>
                  </div>
                  <div class="for-validation  mb-3 ">
                    <div class="for-validation text-danger"
                      *ngIf="helpForm.get('description')?.hasError('required') && helpForm.get('description')?.touched">
                      *Please enter description.</div>
              
                  </div>
                </div>
                <div class="d-flex justify-content-center" style="align-items: center;">
                  <div class="left-wrap">
                      <button class="butwhite" (click)="cancel()"
                   type="button">Cancel</button>
          
                    <button class="butRed" (click)="helpApi()"
                      [disabled]="!helpForm.valid" type="submit">Submit</button>
                  </div>
                </div>
              </form>
        </div>
  
      </div>
      <div class="right-container">
        <div class="image-container" style="margin-top: 0px;">
          <img [src]="'assets/latest/productEnq.svg'" alt="Image" class="img-fluid">
        </div>
      </div>
    </div>
  </div>




<!-- <auth-layout title="" subTitile="" sideImage="assets/latest/productEnq.svg">
   
 
</auth-layout> -->

<!-- <div class="main-container wrapper-content">
    <mat-card class="mat-elevation-z0 bdr" aria-hidden="true">
        <div class="main">
            <div class="top-text">
                <mat-card-title class="sign-text">Product Enquiry   dccdc
                </mat-card-title> 
            </div>
            <div class="input-field">
                <div class="d-flex col-md-12 sty" >
                    <em class="fa fa-envelope" aria-hidden="true"></em>
                    <a href="mailto:http://info@exobe.africa">info@exobe.africa</a>
                </div>
                <form [formGroup]="helpForm">

                    <input type="text" class="user-password" placeholder="Subject" formControlName='subject' maxlength="60">
         
                    <div class="error" *ngIf="helpForm.get('subject').hasError('required') && (helpForm.get('subject').touched ||helpForm.get('subject').dirty)  ">
                        *Subject is required.</div>

                    <textarea type="text" class="user-email" placeholder="Description" rows="10" formControlName='description'></textarea>
                    <div class="error" *ngIf="helpForm.get('description').hasError('required') && (helpForm.get('description').touched ||helpForm.get('description').dirty)  ">
                        *Description is required.</div>
                </form>
            </div>
       
            <div class="down-button">
                <button mat-dialog-close="true" class="cancel" *ngIf="loggedInUserType == 'CUSTOMER'" routerLink="/home">Cancel</button>
                <button mat-dialog-close="true" class="cancel" *ngIf="loggedInUserType == 'RETAILER'" routerLink="/retailer-home">Cancel</button>
                <button class="submit" mat-dialog-close (click)="helpApi()" [disabled]="helpForm.invalid">Submit</button>
            </div>
           
        </div>
    </mat-card>
</div> -->