import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $
@Component({
  selector: 'payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {
  @Input() paymentStatus: any
  paymentList: any
  paymentImg: string
  heading: string
  description: string

  constructor(private router: Router) {
    this.paymentList = localStorage.getItem('paymentList')
  }

  ngOnInit(): void {
    $('#paymentModal').modal({ backdrop: 'static', keyboard: false }, "show")
    if (this.paymentStatus == 'success') {
      this.paymentImg = "assets/latest/payment-success.png"
      this.heading = "Payment Successfull"
      this.description = "Congartulation your transaction has  been completed successfully"
    }
    else if (this.paymentStatus == 'error') {
      this.paymentImg = "assets/latest/payment-failed.png"
      this.heading = "Payment Failed"
      this.description = "Your payment failed please try again"
    }
    else if (this.paymentStatus == 'cancel') {
      this.paymentImg = "assets/latest/payment-failed.png"
      this.heading = "Payment Cancelled"
      this.description = "Your payment has failed. Please try again or contact customer support for assistance. 'you have cancelled your payment."
    }

  }
  closeProduct() {
    $('#paymentModal').modal("hide")
    if (this.paymentList == 'product') {
      this.router.navigate(['/customer-order-history'])
    }
    else if (this.paymentList == 'booking') {
      this.router.navigate(['/ustomer-service-request-list-table'])
    }
  }
  navigatecart() {
    $('#paymentModal').modal("hide")

    this.router.navigate(['/addtoCart-customer'])

  }

}
