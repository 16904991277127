import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-signup-retailer',
  templateUrl: './signup-retailer.component.html',
  styleUrls: ['./signup-retailer.component.scss'],
})
export class SignupRetailerComponent implements OnInit {
  products: any = [];

  signup!: FormGroup;

  lanlog: any;

  imageUrl: any;
  vertical: any = 'true';
  categoryListArray: any;
  countryCode = new FormControl('+27');
  country: any = [];
  state: any = [];
  city: any = [];
  isoCode: any;

  // =============for icon 1==========

  visible: boolean = false;
  changetype: boolean = true;

  myToken: any;

  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }

  // 2nd

  visibles: boolean = false;
  changetypes: boolean = true;

  viewpas() {
    this.visibles = !this.visibles;
    this.changetypes = !this.changetypes;
  }
  // myImage: Observable<any> | undefined;
  // title = 'imgtobase64';
  // base64code: any;
  // signUpForm!: FormGroup;
  user: any = {};
  googleData: any;
  isSocial: boolean = false;
  constructor(
    private http: HttpClient,
    public commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService
  ) {
    this.googleData = JSON.parse(sessionStorage.getItem('googleApi'));
    if (this.googleData) {
      this.imageUrl = this.googleData.profilePic;
    }
    this.activatedRoute.queryParams.subscribe((ele) => {
      this.isSocial = ele.social;
    });
  }
  //changes
  lettersOnlyValidator(control: AbstractControl) {
    const value = control.value;
    const lettersOnlyPattern = /^[A-Za-z]+$/;
    if (value && !lettersOnlyPattern.test(value)) {
      return { lettersOnly: true };
    }
    return null;
  }
  changes
  passwordValidator(control: AbstractControl) {
    const value = control.value;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    if (value && !passwordPattern.test(value)) {
      return { invalidPassword: true };
    }
    return null;
  }


  ngOnInit(): void {
    this.signup = new FormGroup({
      // image: new FormControl('', [
      //   Validators.required
      // ]),
      fName: new FormControl(this.googleData ? this.googleData.firstName : '', [
        Validators.required,
        this.lettersOnlyValidator, //changes
        Validators.minLength(2),
        Validators.maxLength(32),
      ]),
      lName: new FormControl(this.googleData ? this.googleData.lastName : '', [
        Validators.required,
        this.lettersOnlyValidator, //changes
        Validators.minLength(2),
        Validators.maxLength(32),
      ]),
      email: new FormControl(this.googleData ? this.googleData.email : '', [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
        ),
      ]),

      Phone: new FormControl('', []),

      countryCode: new FormControl('+27', [Validators.required]),

      mobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/),
        Validators.minLength(8),
        Validators.maxLength(15),
      ]),

      address: new FormControl('', [Validators.required]),
      term: new FormControl('', [Validators.requiredTrue]),

      locatione: new FormControl('', []),

      city: new FormControl('', [
        Validators.required,
        // Validators.maxLength(32),
        // Validators.pattern("^[A-Z][a-zA-Z]*")
      ]),

      state: new FormControl('', [
        Validators.required,
        // Validators.maxLength(32),
        // Validators.pattern("^[A-Z][a-zA-Z]*")
      ]),    
          // changes
      zipCode: new FormControl('', [
        Validators.required,
        Validators.pattern("[0-9]*")
        // Validators.pattern("/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(sZip)")
      ]),

      Country: new FormControl('', [Validators.required]),

      Password: new FormControl('', [
        Validators.required,
       this.passwordValidator
        // Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')
        // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'),
      ]),
      // Validators.pattern("(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$")]),

      confirmPassword: new FormControl('', [Validators.required]),
      campaign: new FormControl('MANUAL', Validators.required),
    });
    this.getCountryList();
    this.http.get('assets/countrycode.json').subscribe((data) => {
      this.products = data['result'];
    });

    if (this.isSocial) {
      this.signup.controls['Password'].clearValidators();
      this.signup.controls['confirmPassword'].clearValidators();
    }
  }
  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }
  preventSpace(event: any) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault();
    }
  }
  address: any;
  options: any = [];
  longitude: any;
  latitude: any;
  completedAddress_country: boolean = false;
  completedAddress_state: boolean = false;
  completedAddress_city: boolean = false;
  completedAddress_zip: boolean = false;
  public handleAddressChange(address: any) {
    if ('formatted_address' in address) {
      this.options = [];
      this.address = address.formatted_address;
      this.longitude = address.geometry.location.lng();
      this.latitude = address.geometry.location.lat();
      this.options[0] = Number(address.geometry.location.lng());
      this.options[1] = Number(address.geometry.location.lat());
      let country = address.address_components.find((res: any) => {
        return res.types.includes('country');
      });
      let state = address.address_components.find((res: any) => {
        return res.types.includes('administrative_area_level_1');
      });
      let city = address.address_components.find((res: any) => {
        return res.types.includes('locality');
      });
      let zip = address.address_components.find((res: any) => {
        return res.types.includes('postal_code');
      });

      if (country) {
        this.completedAddress_country = true;
        this.signup.patchValue({
          Country: country.long_name,
        });
      }
      if (state) {
        this.completedAddress_state = true;
        this.signup.patchValue({
          state: state.long_name,
        });
      }

      if (city) {
        this.completedAddress_city = true;
        this.signup.patchValue({
          city: city.long_name,
        });
      }
      if (zip) {
        this.completedAddress_zip = true;
        this.signup.patchValue({
          zipCode: zip.long_name,
        });
      }
    }
  }
  getCountryCode(e: string) {}
  getCirtyISO(e: any) {}
  isSubmitted = false;
  async signUp() {
    if (this.signup.invalid) {
      this.loading = false;
      this.isSubmitted = true;
      this.commonService.errorToast('Please fill all required field.');
      return;
    }
    if (this.signup.value.Password != this.signup.value.confirmPassword) {
      return this.commonService.errorToast(
        'Password and confirm password must be same.'
      );
    }

    let countryISOCode, stateISOCode;
    if (!this.signup.value.Country.name) {
      countryISOCode = this.country.find((ele) => {
        return (
          String(ele.name).toLowerCase() ==
          String(this.signup.value.Country).toLowerCase()
        );
      });
    }

    if (!this.signup.value.state.name) {
      let stateList = await this.stateList(countryISOCode?.isoCode);
      stateISOCode = stateList.find((ele) => {
        return (
          String(ele.name).toLowerCase() ==
          String(this.signup.value.state).toLowerCase()
        );
      });
    }

    if (!this.latitude || !this.longitude) {
      let response = await this.commonService
        .fetchCordinates(this.address || this.signup.value.address)
        .toPromise();

      this.latitude = response.result.results[0].geometry.location.lat;
      this.longitude = response.result.results[0].geometry.location.lng;
    }
    var signUpData = {
      firstName: this.signup.value.fName,
      lastName: this.signup.value.lName,
      email: String(this.signup.value.email).toLowerCase(),
      phoneNumber: this.signup.value.Phone,
      mobileNumber: this.signup.value.mobileNumber,
      countryCode: this.signup.value.countryCode,
      addressLine1: this.address || this.signup.value.address,
      addressLine2: this.signup.value.locatione,
      city: this.signup.value.city,
      state: this.signup.value.state.name || this.signup.value.state,
      stateIsoCode: this.signup.value.state.isoCode || stateISOCode.isoCode,
      zipCode: this.signup.value.zipCode,
      country: this.signup.value.Country.name || this.signup.value.Country,
      countryIsoCode:
        this.signup.value.Country.isoCode || countryISOCode.isoCode,
      password: this.signup.value.Password,
      campainPrefrences: this.signup.value.campaign,
      profilePic: this.imageUrl,
      userType: 'RETAILER',
      storeLocation: {
        type: 'Point',
        coordinates: [this.longitude || 0.0, this.latitude || 0.0],
      },
    };
    this.loading = true;
    let url = 'user/signUp';
    if (this.isSocial) {
      url = 'user/signUp?isSocial=true';
    }

    this.commonService.postApi(url, signUpData, 0).subscribe(
      (res: any) => {
        if (res['responseCode'] == 200) {
          this.commonService.successToast(res['responseMessage']);
          localStorage.setItem('email', this.signup.value.email);
          this.loading = false;
          if (this.isSocial) {
            this.login(res.result);
          } else {
            this.router.navigate(['/login-retailer']);
          }
        }
      },
      (err: any) => {
        this.loading = false;
        if (err.responseCode == 409) {
          this.commonService.hideSpinner();
          this.commonService.errorToast(err.responseMessage);
        }
      }
    );
  }

  login(result) {
    localStorage.setItem('userId', result._id);
    localStorage.setItem('email', String(result.email).toLowerCase());
    localStorage.setItem('completeProfile', result.completeProfile);
    localStorage.setItem('userRequestStatus', result.userRequestStatus);
    this.authService.SharingData.next(result.completeProfile);
    this.authService.userRequestStatus.next(result.userRequestStatus);
    this.authService.loggedInUserType.next('RETAILER');
    localStorage.setItem('userRequestStatus', result.userRequestStatus);
    localStorage.setItem('token-auth', result.token);
    this.router.navigate(['/business-details-retailer']);
  }
  async stateList(iso: any) {
    let apiReqUrl = `${'user/listState?countryCode=' + iso}`;
    let resp: any = await this.commonService.getApi(apiReqUrl, 1).toPromise();
    return resp.result;
  }

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-
  uploadImg($event: any): void {
    var selectedFile = $event.target.files[0];
    let me = this;
    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = function () {
        me.imageUrl = reader.result; // Get the Base64 part of the data URL
      };

      // Read the image file as a data URL
      reader.readAsDataURL(selectedFile);
    }
    // this.uploadImageFunc(img);
  }
  uploadImageFunc(img: any) {
    var fb = new FormData();
    fb.append('file', img);
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('user/uploadFile', fb, 0).subscribe(
      (res: any) => {
        this.commonService.hideSpinner();
        if (res.responseCode == '200') {
          this.imageUrl = res.result.mediaUrl;
        }
      },
      (err) => {
        this.commonService.hideSpinner();
      }
    );
  }
  getCountryList() {
    this.commonService.getApi('user/listCountry', 1).subscribe((res: any) => {
      this.country = res.result;
    });
  }
  countryIsoCode: any;
  getStateList(event: any) {
    this.signup.patchValue({
      state: '',
      city: '',
    });
    this.countryIsoCode = this.signup.value.Country.isoCode;
    let apiReqUrl = `${'user/listState?countryCode=' + this.countryIsoCode}`;
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      this.state = res.result;
    });
  }

  getCityList(event: any) {
    this.signup.patchValue({
      city: '',
    });
    let stateIsoCode = this.signup.value.state.isoCode;
    let apiReqUrl = `${
      'user/listCity?countryCode=' +
      this.countryIsoCode +
      '&stateCode=' +
      stateIsoCode
    }`;
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      this.city = res.result;
    });
  }
  loading = false;

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;
    }, 60000); // 1 minute
  }
  changePasswordIcon() {
    this.changetype = !this.changetype;
  }
  changePasswordIcon1() {
    this.changetypes = !this.changetypes;
  }
}
