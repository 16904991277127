import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { CommonService } from './provide/common.service';
import { AuthService } from './provide/auth.service';

declare var $: any
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event: PopStateEvent) {
  //   // Prevent the back navigation
  //   history.forward();
  // }

  title = 'exobe-web';

  currUrl: any;
  view: boolean = true;
  constructor(public commonService: CommonService, public routes: Router,public authService: AuthService) {
    localStorage.getItem('userFirstVisit') ? '' : this.openPopupModal();
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
      }
      if (this.currUrl == 'user-not-approved') {
        this.view = false
      } else {
        this.view = true
      }
    })
  }

  ngOnInit(): void {
  }


  handleUnload(event: Event): void {
  }
  openPopupModal() {
    setTimeout(() => {
      $("#exampleModal").modal({backdrop: 'static', keyboard: false},"show")
    }, 2000);
  }

  navigateTo(userType) {
    this.authService.logout()
    $("#exampleModal").modal("hide")
    localStorage.setItem('userFirstVisit', "true")
    localStorage.setItem('userType',userType)
    if (userType == 'CUSTOMER') {
      this.commonService.userTypeSelectionUpdate.next(userType);
      this.routes.navigate(['/home'])
    } else if (userType == 'RETAILER') {
      this.commonService.userTypeSelectionUpdate.next(userType);
      this.routes.navigate(['/login'])
    } else {
      this.commonService.userTypeSelectionUpdate.next(userType);
      this.routes.navigate(['/login-service-provider'])

    }
  }

}
