<div class="container mb-5 wrapper-content">
    <!-- <span (click)="commonService.backLocation()" class="circle-arrow text-start">
        <img class="arrow-image-add-new" src="assets/svg/ar.svg" alt="">
    </span> -->
    <h1 class="text-center mt-3">Payment Successfull</h1>
    <div class="right-side" style="width: 100%;">
        <div class="row mt-3">
            <div class="top-success">
                <div class="row">
                    <div class="col-md-6">
                        <div class="datas">
                            <img class="right-img" src="assets/social/success-right.svg" alt="">
                            <p class="new-text text-center">Congratulations! Your transaction has been completed successfully.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="left-img" src="assets/svg/right-sucess.svg" alt="">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div style="text-align: center;">
        <button class="add-cart" [routerLink]="['/customer-order-history']">Continue</button>
    </div> -->
    <div style="text-align: center;" *ngIf="paymentList =='product'">
        <button class="add-cart" [routerLink]="['/customer-order-history']">Continue</button>
    </div>
    <div style="text-align: center;" *ngIf="paymentList =='booking'">
        <button class="add-cart" [routerLink]="['/customer-service-request-list-table']">Continue</button>
    </div>
    <div style="text-align: center;" *ngIf="paymentList =='wallet'">
        <button class="add-cart" [routerLink]="['/customer-wallet']">Continue</button>
    </div>


</div>