import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-customer-deals-service-list',
  templateUrl: './customer-deals-service-list.component.html',
  styleUrls: ['./customer-deals-service-list.component.scss']
})
export class CustomerDealsServiceListComponent implements OnInit {
  _id: any
  currentPage = 1;
  pageSize = 12;
  serviceName: any
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })
  }
  headingName: any = 'DEALS ON BOOKINGS'
  linkerRouteItems: any = [
    { name: 'Home', route: '/home' },
    { name: 'Deals On Booking', route: '/customer-service-deal-list' }
  ]
  ngOnInit(): void {
    this.getServiceList();
  }



  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any
  totalItems: any
  isLoading: boolean = false;
  // get retailer detail by id
  getServiceList() {
    let apiReqUrl: any = `deal/dealsListOnCategory`
    // this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        console.log(res.result,"deals response")
        this.productDetails = res.result;
        // this.productDetails = res.result.docs;
        if (!this.productDetails.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        for (let index = 0; index < this.productDetails.length; index++) {
          this.getCoundown(this.productDetails[index]['dealEndTime'], index);
        }
        // this.imgurl = res.result.productImage[0]
        this.totalItems = res.result.total
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.isLoading = true;
        this.productDetails = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.isLoading = true;
      this.productDetails = []
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }
  navigateToViewProduct(_id) {
    // this.router.navigate(['/customer-service-deal-view'], { queryParams: { _id: _id, catName: catName } })
    sessionStorage.setItem('selectedService', JSON.stringify(_id))
    this.router.navigate(['/customer-service-deal-view-particular'])
  }
  pagination(event) {
    this.currentPage = event;
    this.getServiceList();
  }

  myTimer() { }
  // getDealDescription
  countDown: any = [];
  timerInterval: any = [];
  new: any;
  getCoundown(date: Date, i: number) {
    var countDownDate = new Date(date).getTime();
    let me = this;
    // Update the count down every 1 second

    this.timerInterval[i] = setInterval(() => {
      if (true) {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        me.countDown[i] =
          days + 'D:' + hours + 'H:' + minutes + 'M:' + seconds + 'S';        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(me.timerInterval);
          me.countDown[i] = 'EXPIRED';
        }
        // me.new = me.countDown[i]
      }

      // me.countDown[i] = mm
    }, 1000);
  }
  length(n){
    return new Array(n)
  }
}
