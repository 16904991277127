import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
declare var $;
@Component({
  selector: 'app-service-provider-list-deals',
  templateUrl: './service-provider-list-deals.component.html',
  styleUrls: ['./service-provider-list-deals.component.scss'],
})
export class ServiceProviderListDealsComponent implements OnInit {
  _id: any;
  fromDate: any;
  toDate: any;
  isLoggedIn: any;
  loggedInUserType: any;
  catID: any;
  UsID: any;
  categoryName: any;
  serviceId: any;
  name: any;
  amount: any = [];
  serviceArrayId: any;

  panelOpenState = false; //accordian open close question's and answer's
  linkerRouteItems: any = [
    { name: 'Home', route: '/service-order-detail' },
    { name: 'Service Deal List', route: '/service-provider-list-deals' },
  ];
  flag: boolean = false;
  constructor(
    private activatedroute: ActivatedRoute,
    public commonService: CommonService,
    private router: Router,
    public authService: AuthService
  ) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      console.log(res, 'ressssssssssssssssss');
      this.catID = res.categoryId;
      this.UsID = res.userId;
      this.categoryName = res.categoryName;
      this.serviceId = res._id;
      this.name = res.name;
      this.flag = res.flag;
    });
  }

  categoryType: any;
  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res: any) => {
      this.isLoggedIn = res;
    });
    this.isLoggedIn = this.authService.isLogin();
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res;
    });
    // this.getProduct();
    this.viewCategoryAvailableService();
    // this.serviceListArray();

    
  }

  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any;

  getProduct() {
    if (this.isLoggedIn) {
      this.viewRetailerProductd();
    } else {
      this.viewRetailerProductdWithoutLogin();
    }
  }
  firstPriceSize: any = [];
  productDetailsSize: any = [];
  // get retailer detail by id
  viewRetailerProductd() {
    let apiReqUrl: any = `product/viewProduct?productId=${this._id}`;
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0;
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.productDetails = res.result;
        this.productDetailsSize = res.result.priceSizeDetails;

        this.firstPriceSize = res.result.priceSizeDetails[0];

        this.price(
          this.firstPriceSize.price,
          this.firstPriceSize.quantity,
          this.firstPriceSize.value,
          this.firstPriceSize.id,
          this.firstPriceSize.unit
        );
        this.imgurl = res.result.productImage[0];
        this.imageLinks = res['result']['thumbnail'];
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }
  viewRetailerProductdWithoutLogin() {
    let apiReqUrl: any = `product/viewProduct?productId=${this._id}`;
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0;
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.productDetails = res.result;
        this.imgurl = res.result.productImage[0];
        this.imageLinks = res['result']['thumbnail'];
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }

  forID(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } });
  }

  changeProductimage(url: any) {
    this.imgurl = url;
  }

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ');
    return a.replace(/R/g, 'R ');
  }
  mrp: any;
  quantity: any;
  value: any;
  unit: any;
  pdId: any;
  startModalCampaign: [] = [];
  price(e: any, q: any, v: any, id, u) {
    (this.mrp = e), (this.quantity = q);
    this.value = v;
    this.unit = u;
    this.pdId = id;
  }
  imgSrc: any;
  productImage: any;
  serviceData: any = [];
  newObj = [];
  subCategory = [];
  deliveryType = [];
  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`;
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          this.viewRetailerProductd();
          // this.router.navigate(['/edit-product'])
          this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
          if (res.responseCode == 401) {
            this.router.navigate['/login'];
          }
        }
      },
      (err) => {
        this.commonService.hideSpinner();
        if (err.responseCode == 401) {
          this.router.navigate['/login'];
        }
      }
    );
  }

  // @my code
  deliveryOption: any = [
    { display: 'Standard Delivery', value: 'STANDARD' },
    { display: 'Same Day Delivery', value: 'SAMEDAY' },
    { display: 'Sunday Delivery', value: 'SUNDAY' },
    { display: 'Saturday Delivery', value: 'SATURDAY' },
    { display: 'Next Business Day Delivery', value: 'BUSINESSDAY' }
  ];
  getDeliveryDisplay(deliveryType: string): string {
    console.log("Delivery Type: ", deliveryType); // Debugging log
    const option = this.deliveryOption.find((item: any) => item.value === deliveryType);
    console.log("Found Option: ", option); // Debugging log
    return option ? option.display : '---';
  }
  UserDetailsData: any;
  serviceDetailsArray: any = [];
  viewCategoryAvailableService() {
    let apiReqUrl = `v2/service/selectedServices?categoryId=${this.serviceId}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          this.UserDetailsData = res.result.userDeails;
          this.serviceDetailsArray = res.result.docs;

          console.log(this.serviceDetailsArray, 'array');
          // this.serviceDetailsArray.forEach((item, index) => {
          //   item['_id'] = index
          // });

          // this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
          if (res.responseCode == 401) {
            this.router.navigate['/login'];
          }
        }
      },
      (err) => {
        this.commonService.hideSpinner();
        if (err.responseCode == 401) {
          this.router.navigate['/login'];
        }
      }
    );
  }
  serviceArray: any = [];
  navigateToAddDeals() {
    let isValid: boolean = false;
    this.serviceDetailsArray.forEach((element) => {
      element.serviceArray.forEach((ele) => {
        if (ele.isSelected) {
          isValid = true;
        }
      });
    });
    if (isValid) {
      this.router.navigate(['/service-provider-add-deals'], {
        queryParams: { id: this.serviceId },
      });
    } else {
      return this.commonService.errorToast('Please add atleast one service');
    }
  }

  update() {
    let arr = [];
    for (let item of this.serviceDetailsArray) {
      for (let data of item.serviceArray) {
        if (data.isSelected) {
          // if (item.subCategory.categoryEnum == 'FIELD') {
          //   if (
          //     data.isSelected &&
          //     !data.price_D &&
          //     !data.price_W &&
          //     !data.price_M &&
          //     !data.price_Q
          //   ) {
          //     this.commonService.errorToast('Please enter amount');
          //     return;
          //   }
          //   let obj = {
          //     isSelected: data.isSelected,
          //     price_D: data.price_D,
          //     price_W: data.price_W,
          //     price_M: data.price_M,
          //     price_Q: data.price_Q,
          //     serviceId: data.serviceDetails._id,
          //   };
          //   arr.push(obj);
          // }
          //  else {
          if (!data.price) {
            this.commonService.errorToast('Please enter amount');
            return;
          }
          let obj = {
            isSelected: data.isSelected,
            price: data.price,
            serviceId: data.serviceDetails._id,
          };
          arr.push(obj);
        }
        // }
      }
    }
    let apiReqUrl: any = `v2/service/updateServiceUser2`;
    const data = {
      serviceDetailsArray: arr,
      categoryId: this.serviceId,
      isSelected: this.isSelected
    };
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.commonService.successToast(res.responseMessage);
          this.commonService.hideSpinner();
          this.router.navigate(['/services']);
        }
      },
      (err: any) => {
        this.commonService.hideSpinner();
        this.commonService.errorToast(err.responseMessage);
      }
    );
  }

  requirePrice: any;
  checkValidation(e: boolean, price: number, id: string) {
    this.serviceArrayId = id;
    this.requirePrice = price;
    if (e) {
      if (!price) {
        // this.commonService.errorToast("Please enter amount")
      }
    }
  }
  // @my code end

  navigateToserviceAvailableAdd() {
    sessionStorage.setItem(
      'selectedService',
      JSON.stringify(this.selectedService)
    );
    this.router.navigate(['/customer-service-quantity-add'], {
      queryParams: {
        categoryId: this.catID,
        userId: this.UsID,
        categoryName: this.categoryName,
      },
    });
  }

  selectedService = [];
  getSelectedService(e, obj, data, index) {}
  navigateToback() {
    this.router.navigate(['/services'], { queryParams: { _id: this.catID } });
  }
  modalStartCampaign() {
    $('#startModalCampaign').modal('show');
    this.serviceListArray();
    // this.selectedIndex = 0
    // this.campaignModel[0] = { ...this.productDetails.priceSizeDetails[0], ...{ discountedPrice: this.productDetails.priceSizeDetails[0].price, discountedPercentage: 0, releaseQuantity: 0 } }
    // this.priceOfSize = this.productDetails.priceSizeDetails[0]
  }
  _categoryId: any;
  _serviceId: any;
  _discountedPrice: any;
  startCampaign() {
    let data = {
      serviceId: this._serviceId,

      startDate: new Date(this.fromDate).toISOString(),
      endDate: new Date(this.toDate).toISOString(),
      serviceDiscountedPrice: this._discountedPrice,
      campaignOn: 'SERVICE',
    };

    console.log(data);

    let url = `campaign/addCampaign`;
    this.commonService.postApi(url, data, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.commonService.successToast('Campaign Created Successfully');
          this.commonService.hideSpinner();

          $('#startModalCampaign').modal('hide');
        }
      },
      (err: any) => {
        this.commonService.hideSpinner();
        this.commonService.errorToast(err.responseMessage);
      }
    );
  }

  serviceListArray() {
    this.newObj = this.serviceDetailsArray.filter((e) => {
      if (e.serviceArray.length) {
        return e.serviceArray[0].isSelected;
      } else {
        return false;
      }
    });

    console.log(this.newObj, 'newObj');
  }
  subServiceList: any = [];
  getSubCatList(e) {
    // this._categoryId = e
    this.subServiceList = this.newObj.find((ele) => {
      return ele.subCategory._id == e;
    });
    this.servicePrice = '';
    console.log(this.subServiceList);
  }
  servicePrice: any;
  _serviceCatgoryId: any;
  _serviceSubCatgoryId: any;
  getPriceService(e) {
    // this._serviceId = e
    let find = this.subServiceList.serviceArray.find((ele) => {
      return ele.catalogueId == e;
    });
    console.log(find);

    this._serviceCatgoryId = find.serviceDetails.categoryId._id;
    this._serviceSubCatgoryId = find.serviceDetails.subCategoryId._id;
    this.servicePrice = find.price;
    console.log(this.servicePrice, 'serviceprice');
  }


  isSelected: boolean;
  data = {
    price: null // Initial value for price
  };

  onAmountInput(data: any): void {
    if (data.price && data.price > 0) {
      data.isSelected = true;
    } else {
      data.isSelected = false;
    }
  }

}
