

<div class="container">
    <linker-router [headingName]="headingName"  [linkerRouteItems]="linkerRouteItems"></linker-router>
</div>

<div class="container ss mt-3 mb-5 wrapper-content">
    <div class="fff pb-5">
        <h2 class="text-center mt-4">Upload Documentation</h2>
        <div class="col-md-10">
            <p class="Letter mt-5">Certificate Of Incorporation ( Business <br>
                Registration Letter )</p>

            <div class=" textaligncenter">
                <div class="form-group jj ">
                    <!-- <label for="file-upload" class="custom-file-upload">
                    Upload Image
                </label> -->

                    <img *ngIf="IncorporationForntUrl" [src]="IncorporationForntUrl" id="ggg" class="mt-1" alt="">
                    <input id="file-upload" type="file" accept=".pdf,.docx" class="form-control"
                        (change)="uploadImg($event,'INCORPORATIONFORNT')">
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->

                </div>
            </div>

        </div>
        <!-- <object [data]="_sanitizer.bypassSecurityTrustResourceUrl(IncorporationForntUrl)" type="application/pdf"
            width="100%" height="100%">
            <iframe [src]="_sanitizer.bypassSecurityTrustResourceUrl(IncorporationForntUrl)" width="100%" height="100%"
                style="border: none;">
                <p>
                    Your browser does not support PDFs.
                    <a [href]="IncorporationForntUrl">Download the
                        PDF</a>
                    .
                </p>
            </iframe>
        </object> -->



        <div class="col-md-10">
            <p class="Letter mt-5">VAT Registration Confirmation ( If Applicable )</p>

            <div class=" textaligncenter">
                <div class="form-group jj ">
                    <!-- <label for="file-upload" class="custom-file-upload">
                    Upload Image
                </label> -->

                    <img *ngIf="VatForntUrl" [src]="VatForntUrl" id="ggg" alt="">
                    <input id="file-upload" type="file" accept=".pdf,.docx" class="form-control"
                        (change)="uploadImg($event,'VATFORNT')">
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->

                </div>
            </div>

        </div>




        <div class="col-md-10">
            <p class="Letter mt-5">Director Consent Form ( To Be Completed )</p>

            <div class=" textaligncenter">
                <div class="form-group jj ">
                    <!-- <label for="file-upload" class="custom-file-upload">
                    Upload Image
                </label> -->
                    <img *ngIf="DirectorForntUrl" [src]="DirectorForntUrl" id="ggg" alt="">
                    <input id="file-upload" type="file" accept=".pdf,.docx" class="form-control"
                        (change)="uploadImg($event,'DIRECTORFORNT')">
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->

                </div>
            </div>

        </div>


        <div class="col-md-10">
            <p class="Letter mt-5">Director (s) ID Document</p>

            <div class=" textaligncenter">
                <div class="form-group jj ">
                    <!-- <label for="file-upload" class="custom-file-upload">
                    Upload Image
                </label> -->
                    <img *ngIf="DirectorIDDocumentUrl" [src]="DirectorIDDocumentUrl" id="ggg" alt="">
                    <input id="file-upload" type="file" accept=".pdf,.docx" class="form-control"
                        (change)="uploadImg($event,'DIRECTORDOCUMENT')">
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->

                </div>
            </div>

        </div>


        <div class="col-md-10">
            <p class="Letter mt-5">Bank Confirmation Letter </p>

            <div class=" textaligncenter">
                <div class="form-group jj ">
                    <!-- <label for="file-upload" class="custom-file-upload">
                    Upload Image
                </label> -->

                    <img *ngIf="ConfirmationForntUrl" [src]="ConfirmationForntUrl" id="ggg" alt="">
                    <input id="file-upload" type="file" accept=".pdf,.docx" class="form-control"
                        (change)="uploadImg($event,'CONFIRMATIONFORNT')">
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->

                </div>
            </div>

        </div>


        <div class="d-flex justify-content-center submit-div">
            <button class="btn btn-outline-success2 my-2 my-sm-0 mt-3" (click)="imgeUplode()"
                type="submit">Update</button>
        </div>

    </div>

</div>