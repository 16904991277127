import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss']
})
export class ProfileLayoutComponent implements OnInit {
  @Input() data : any
  @Output() on_Click = new EventEmitter
  serviceRole: string;

  constructor(private commonService : CommonService) { 
    this.serviceRole = localStorage.getItem('serviceRole')
  }

  ngOnInit(): void {
  }
  navigateToViewProduct(e){
    this.on_Click.emit(e)
  }

  updateSpardic(){
    let url = ``
    this.commonService.postApi(url,{},1).subscribe((res)=>{
      
    })
  }

}
