<!-- -----------------------------------------------again----------------------------------- -->
<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
  </div>


<div class="container  mb-4">
    <div class="">

        <!-- <div class="row mt-4 mb-4">
            <span><i class="fa fa-arrow-left titt" style=" cursor: pointer;" (click)="forID(_id)"></i></span>
            <h2 class="ml-5 deal-2">Edit Deal</h2>
        </div> -->
        <div class="grid-container">
            <div class="section1">
        <form [formGroup]="addDeals">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputEmail4" class="tite">Title</label>
                    <!-- <input type="email" class="form-control" id="deal-5" maxlength="150" formControlName="title"
                        id="deal-5" placeholder="Enter title" (keypress)="preventSpace($event)"> -->

                    <input type="text" list="codes" class="form-control" id="deal-5" formControlName="title"
                        [(ngModel)]="codeValue" (change)="getSearch($event)">
                    <datalist id="codes">
                        <option *ngFor="let item of searchList" [value]="item.productName">{{item?.productName}}
                        </option>
                    </datalist>

                    <div class="for-validation text-danger"
                        *ngIf="addDeals.get('title')?.hasError('required') && addDeals.get('title')?.touched ">
                        *Title is required.</div>





                </div>
                <!-- <button type="button" class="add-4 ml-1" (click)="getSearch($event)">Add</button> -->




            </div>
            <!-- <div class="ff mb-3">

                <div class="profle-sec1">
                    <img [src]="imageUrl" class="" alt="" id="ert">                  
                    <i class="fa fa-camera cmra-icon" id="rr1" aria-hidden="true">
                        <input type="file" class="input-file" accept=".png, .jpg, .jpeg" (change)="uploadImg($event)">

                    </i>
                </div>
            </div> -->

            <!-- <mat-card class="mat1 m-auto"> -->

            <!-- <div class="image-upload" *ngIf="imageLinks.length != 0">
                    <div class="mr-2" *ngFor="let item of imageLinks ; let index = index"
                        style="border: 1px solid;">
                        <i class="fa fa-times" aria-hidden="true" (click)="closeimage(index)" id="closeImage"></i>
                        <img mat-card-sm-image [src]="item" alt="image">

                    </div>

                </div> -->
            <!-- <div class="image-upload mr-3" *ngIf="imageLinks.length != 0">
                <div class="mr-2" *ngFor="let item of imageLinks let index = index" style="border: 1px solid;">
                    <i class="fa fa-times" aria-hidden="true" (click)="closeimage(index)" id="closeImage"></i>
                    <img mat-card-sm-image [src]="item" alt="image">

                </div>

            </div>
            <div class="row justify-content-start">
                <div class="col-md-3 mt-4">
                    <div class="input-file-container file">
                        <input class="input-file image hidden" id="my-file" accept=".png, .jpg, .jpeg, .webp " multiple
                            type="file" (change)="uploadImg($event)">
                        <label tabindex="0" for="my-file" class="input-file-trigger">Upload Images</label>
                    </div>
                </div>


            </div> -->
            <!-- </mat-card> -->

            <div class="form-row mt-2">
                <div class="col-md-6">
                    <label for="inputEmail4" class="tite">Category</label>
                    <div>
                        <input type="text" class="form-control" id="deal-5" placeholder="Enter qty. available"
                            maxlength="50" formControlName="category" (keypress)="preventSpace($event)" readonly>

                    </div>
                </div>
                <div class="col-md-6">
                    <label for="inputEmail4" class="tite">Subcategory</label>


                    <input type="text" class="form-control" id="deal-5" placeholder="Enter qty. available" readonly
                        maxlength="50" formControlName="subCategory" (keypress)="preventSpace($event)">


                    <div class="for-validation text-danger"
                        *ngIf="addDeals.get('subCategory')?.hasError('required') && addDeals.get('subCategory')?.touched ">
                        *SubCategory is required.</div>

                </div>
            </div>

            <!-- <div class="form-row mb-3 mt-3">
                <div class="col-md-6">
                    <label for="inputEmail4" class="tite">M.R.P</label>
                    <input type="text" class="form-control" id="deal-5" placeholder="Enter M.R.P" maxlength="12"
                        (keypress)="NumOnly($event)" formControlName="mrp" (keypress)="preventSpace($event)" readonly>

                    <div class="for-validation text-danger"
                        *ngIf="addDeals.get('mrp')?.hasError('required') && addDeals.get('mrp')?.touched ">
                        *M.R.P is required.</div>


                    <div class="for-validation  text-danger"
                        *ngIf="addDeals.get('mrp')?.hasError('pattern') && addDeals.get('mrp')?.dirty ">
                        *Please enter valid M.R.P</div>


                </div>
                <div class="col-md-6">
                    <label for="inputEmail4" class="tite">Qty. Available</label>
                    <input type="text" class="form-control" id="deal-5" placeholder="Enter qty. available"
                        maxlength="50" formControlName="qty" (keypress)="preventSpace($event)"
                        (keypress)="NumOnly($event)">

                    <div class="for-validation text-danger"
                        *ngIf="addDeals.get('qty')?.hasError('required') && addDeals.get('qty')?.touched ">
                        *Enter qty.is required.</div>

                    <div class="for-validation  text-danger"
                        *ngIf="addDeals.get('qty')?.hasError('pattern') && addDeals.get('qty')?.dirty ">
                        *Please enter valid qty</div>


                </div>
            </div> -->


            <!-- <div class="form-group">
                <label for="inputEmail4" class="tite">Discount</label>
                <input type="text" class="form-control" id="deal-5" placeholder="0%" maxlength="50"
                    formControlName="discount"  (keypress)="preventSpace($event)" >
                
                <div class="for-validation text-danger"
                    *ngIf="addDeals.get('discount')?.hasError('required') && addDeals.get('discount')?.touched ">
                    *Enter discount.is required.</div>

               
            </div> -->



            <div class="form-row mb-3 mt-3">
                <div class="col-md-4">
                    <label for="inputEmail4" class="tite">Actual Price (R)</label>
                    <input type="text" class="form-control" id="deal-5" placeholder="Enter Deal Price" maxlength="12"
                        (keypress)="NumOnly($event)" formControlName="actualprice" (keypress)="preventSpace($event)" readonly>
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4" class="tite">Discount</label>
                    <input type="text" class="form-control" id="deal-5" (keyup)="calcMargin()" placeholder="0%" maxlength="12"
                        (keypress)="NumOnly($event)" formControlName="discount"
                        (keypress)="preventSpace($event);numberOnly($event)">

                    <div class="for-validation text-danger"
                        *ngIf="addDeals.get('discount')?.hasError('required') && addDeals.get('discount')?.touched ">
                        *Discount is required.</div>


                    <div class="for-validation  text-danger"
                        *ngIf="addDeals.get('qty')?.hasError('pattern') && addDeals.get('qty')?.dirty ">
                        *Please enter valid Discount</div>



                </div>
                <div class="col-md-4">
                    <label for="inputEmail4" class="tite">Deal Price (R)</label>
                    <input type="text" class="form-control" id="deal-5" placeholder="Enter Deal Price" maxlength="12"
                    (keypress)="NumOnly($event)" (keyup)="discountCalforvalue()" formControlName="mrp" (keypress)="preventSpace($event)" >
                    <span class="for-validation text-danger"> {{priceMsg}}</span>

                    <div class="for-validation text-danger"
                        *ngIf="addDeals.get('mrp')?.hasError('required') && addDeals.get('mrp')?.touched ">
                        *Deal price is required.</div>


                    <div class="for-validation  text-danger"
                        *ngIf="addDeals.get('mrp')?.hasError('pattern') && addDeals.get('mrp')?.dirty ">
                        *Please enter valid deal price</div>


                </div>

               

                <div class="col-md-6">
                    <!-- <label for="inputEmail4" class="tite">Description</label>
                    <input type="text" class="form-control" id="deal-5" placeholder="Enter description" maxlength="150"
                        formControlName="description" (keypress)="preventSpace($event)">
                   
                    <div class="for-validation text-danger"
                        *ngIf="addDeals.get('description')?.hasError('required') && addDeals.get('description')?.touched ">
                        *Description is required.</div> -->


                </div>

            </div>




            <div class="form-group">
                <label for="inputEmail4" class="tite">Description</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" id="deal-5" rows="3"
                    placeholder="Enter description" maxlength="150" formControlName="description"
                    (keypress)="preventSpace($event)"></textarea>
                <div class="for-validation text-danger"
                    *ngIf="addDeals.get('description')?.hasError('required') && addDeals.get('description')?.touched ">
                    *Description is required.</div>

            </div>



            <div class="form-row mb-3 mt-3">
                <div class="col-md-6">
                    <label for="inputEmail4" class="tite">Start Date & Time</label>
                    <input type="datetime-local" class="form-control" id="deal-5" formControlName="dealStartTime"
                        [min]="today">
                    <div class="for-validation text-danger"
                        *ngIf="addDeals.get('dealStartTime')?.hasError('required') && addDeals.get('dealStartTime')?.touched ">
                        *Enter dealStartTime required.</div>
                </div>
                <div class="col-md-6 ">
                    <label for="inputEmail4" class="tite">End Date & Time</label>

                    <input type="datetime-local" class="form-control" id="deal-5" formControlName="dealEndTime"
                        [min]="today">


                </div>
            </div>

            <div class="text-center mt-4 mb-4">
                <button type="button" class="SAVE" style=" cursor: pointer;" (click)="editDeals()">SAVE</button>

            </div>

        </form>
    </div>
    <div class="section2">
        <div class="image-upload mr-3" *ngIf="imageLinks.length != 0">
            <div class="" *ngFor="let item of imageLinks let index = index" style="background-color: #F0F0F0;">
                <i class="fa fa-times" aria-hidden="true" (click)="closeimage(index)" id="closeImage"></i>
                <img mat-card-sm-image [src]="item" alt="image">

            </div>

        </div>
        <div class="row justify-content-start">
            <div class="col-md-3 mt-4">
                <div class="input-file-container file">
                    <input class="input-file image hidden" id="my-file" accept=".png, .jpg, .jpeg, .webp " multiple
                        type="file" (change)="uploadImg($event)">
                    <label tabindex="0" for="my-file" class="input-file-trigger">Upload Images</label>
                </div>
            </div>


        </div>
    </div>
    </div>

</div>