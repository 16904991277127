import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MycartLayoutComponent } from 'src/app/components/mycart-layout/mycart-layout.component';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
declare var $
@Component({
  selector: 'app-customer-add-to-cart',
  templateUrl: './customer-add-to-cart.component.html',
  styleUrls: ['./customer-add-to-cart.component.scss']
})
export class CustomerAddToCartComponent implements OnInit {
  @ViewChild(MycartLayoutComponent) cartLayOut: MycartLayoutComponent;
  _id: any
  headingName:any ='My Cart'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'My Cart',route:'/addtoCart-customer'}
  ]
  qtyA: any
  serviceData: any
  latitude: any;
  longitude: any;
  isLoggedIn: any
  loggedInUserType: any
  flag: boolean = true;
  constructor(private activatedroute: ActivatedRoute,private toastrService: ToastrService, public commonService: CommonService, private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })
  }
  flag2 : boolean = false

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res: any) => {
      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res
    })
    this.getuserCartList();
    this.getDeliveryOptions()
  }
  
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getProducts()

        }
      },
        (error) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  getProducts() {
    if (this.isLoggedIn) {
      this.getSimiliarProductwithToken()
    } else {
      this.getSimiliarProduct()
    }
  }
  cartListDetails: any = []
  productIds: any = []

  getCatId: any = []
  newCatID: any = []
  subCatID: any = []
  isLoading:boolean =false
  payOutDetail : any
  getuserCartList(b?) {
    let apiReqUrl = `user/cartList`
    // this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      this.flag2 = true
      if (res.responseCode == 200) {
        this.cartListDetails = res.result.cartList;
        this.payOutDetail = res.result.payOutAmount;
        if(this.cartListDetails.length>0){
          this.isLoading =false
        }else{
          this.isLoading =true
        }
        this.cartListDetails.forEach(element => {
          this.newCatID.push(element.productId.categoryId._id)
          this.productIds.push(element.productId._id)
          this.subCatID.push(element.productId.subCategoryId._id)
        });
        this.qtyA = this.cartListDetails.quantity
        this.commonService.hideSpinner();
        if(b){
          this.getCartList()

        }
        this.getTotalProductPrice()
        this.getLocation();
        this.handleSummary()
      } else {
        this.cartListDetails = []
        this.isLoading =true
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        this.getTotalProductPrice()
      }
    }, (err) => {
      this.cartListDetails = []
      this.isLoading =true
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }
  subtotals = 0
  discount = 0
  discountPercent : any = 0
  handleSummary() {
    console.log(this.cartListDetails);
    
    for (const item of this.cartListDetails) {
      let actualPriceObj = item.productId.priceSizeDetails.find((ele) => {
        console.log(ele.id);
        
        return ele.id == item.priceSizeDetails.id
      })
      console.log(item.priceSizeDetails.id);
      
      this.subtotals += Number(actualPriceObj.price)
      

    }
    this.discount = Number(this.sum) - Number(this.subtotals)
      this.discountPercent = Number(this.discount/ this.subtotals * 100).toFixed(0)
  }
  user: any
  currentPage = 1;
  pageSize = 12;
  getSimiliarProduct() {
    // let apiReqUrl: any = `product/productMightLike`
    let apiReqUrl: any = `product/listProductBySortion`
    if (this.loggedInUserType == 'RETAILER') {
      this.user = 'WHOLE_SALER'
    } else {
      this.user = 'RETAILER'
    }
    // let data = {
    //   "categoryId": this.newCatID
    // }
    let data = {
      "userType": this.user,
      'lng': this.longitude,
      'lat': this.latitude,
      // 'page': this.currentPage,
      // 'limit': this.pageSize,
      "categoryId": [
        this.newCatID
      ],
      "similarSubCategoryIds" : this.subCatID
    }
    this.commonService.postApi(apiReqUrl, data, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.serviceData = res.result.docs;
        // $(document).ready(function () {
        //   $('.service_slider_new').slick({
        //     dots: false,
        //     arrow: true,
        //     infinite: false,
        //     speed: 300,
        //     slidesToShow: 5,
        //     slidesToScroll: 1,
        //     responsive: [
        //       {
        //         breakpoint: 1024,
        //         settings: {
        //           slidesToShow: 3,
        //           slidesToScroll: 3,
        //           infinite: true,
        //           dots: false
        //         }
        //       },
        //       {
        //         breakpoint: 600,
        //         settings: {
        //           slidesToShow: 2,
        //           slidesToScroll: 2
        //         }
        //       },
        //       {
        //         breakpoint: 480,
        //         settings: {
        //           slidesToShow: 1,
        //           slidesToScroll: 1
        //         }
        //       }
        //       // You can unslick at a given breakpoint now by adding:
        //       // settings: "unslick"
        //       // instead of a settings object
        //     ]
        //   });
        // });
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  getSimiliarProductwithToken() {
    // let apiReqUrl: any = `product/productMightLike`
    let apiReqUrl: any = `product/listProductBySortion`
    
    if (this.loggedInUserType == 'RETAILER') {
      this.user = 'WHOLE_SALER'
    } else {
      this.user = 'RETAILER'
    }
    // let data = {
    //   "categoryId": this.newCatID
    // }
    let data = {
      "userType": this.user ,
      'lng': this.longitude,
      'lat': this.latitude,
      // 'page': this.currentPage,
      // 'limit': this.pageSize,
      "categoryIds": this.newCatID,
      "similarSubCategoryIds" : this.subCatID
    }
    this.commonService.postApi(apiReqUrl, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.serviceData = res.result.docs;
        // $(document).ready(function () {
        //   $('.service_slider_new').slick({
        //     dots: false,
        //     arrow: true,
        //     infinite: false,
        //     speed: 300,
        //     slidesToShow: 5,
        //     slidesToScroll: 1,
        //     responsive: [
        //       {
        //         breakpoint: 1024,
        //         settings: {
        //           slidesToShow: 3,
        //           slidesToScroll: 3,
        //           infinite: true,
        //           dots: false
        //         }
        //       },
        //       {
        //         breakpoint: 600,
        //         settings: {
        //           slidesToShow: 2,
        //           slidesToScroll: 2
        //         }
        //       },
        //       {
        //         breakpoint: 480,
        //         settings: {
        //           slidesToShow: 1,
        //           slidesToScroll: 1
        //         }
        //       }
        //       // You can unslick at a given breakpoint now by adding:
        //       // settings: "unslick"
        //       // instead of a settings object
        //     ]
        //   });
        // });
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }

  cartId:  any
  getItemId(id){
    this.cartId = id
 
  
    $('#modalDeleteConfirmation').modal('show')
  }
  removeCartList() {
    this.flag = false
    let url = `user/removeItemFromCart?_id=${this.cartId}`
    this.commonService.showSpinner();
    this.commonService.deleteApi(url, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.flag = true
        this.commonService.successToast(res.responseMessage)
        this.commonService.hideSpinner();
        this.getuserCartList()
        this.getDeliveryOptions()
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  updateCartValue(e) {
    let id = e.id
    let prizeSize = e.prizeSize
    let avlQty = e.avlQty
    if (avlQty == 0) {
      return this.commonService.infoToast('Quantity can not be 0.')
    }
    let apiReqUrl = `user/updateCartItem?_id=${id}`
    let apiReqData = {
      quantity: avlQty,
      "priceSizeDetails": {
        "value": prizeSize.value,
        "price": Number(prizeSize.price),
        "unit": prizeSize.unit,
        "weight": prizeSize.weight,
        "volume": prizeSize.volume,
        "id": prizeSize.id
      },
    }

    this.commonService.showSpinner();
    this.commonService.putApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        // this.cartListDetails = res.result;
        // this.qtyA = this.cartListDetails.quantity
        // this.commonService.successToast(res.responseMessage)
        // this.toastrService.success("Successfully Updated");
        this.commonService.hideSpinner();
        this.getuserCartList(true)
        this.getDeliveryOptions()
        // this.getSimiliarProduct();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }


  optionList : any
  getDeliveryOptions() {
  
    let apiReqUrl = `deliveryFee/listOption`
    

    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.optionList = res.result
        this.commonService.successToast(res.responseMessage)
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }
  onProceed(payout: { deliveryMode?: string, weight?: string, volume?: string }) {
    const deliveryMode = payout.deliveryMode || 'STANDARD';
    const weight = payout.weight || 'XXX-Heavy';
    const volume = payout.volume || 'XXX-Large';
    const apiReqUrl = `deliveryFee/proceedToCheckOut?deliveryType=${deliveryMode}&weight=${weight}&volume=${volume}`;

    this.commonService.showSpinner();

    this.commonService.getApi(apiReqUrl, 1).subscribe(
        (res: any) => {
            this.ngOnInit();
            this.commonService.hideSpinner();
        },
        (err: any) => {
            this.commonService.hideSpinner();
            this.commonService.errorToast(err.responseMessage);
        }
    );
  }
  date: any = Date

  getAddedDate(createdAt: string, days: number) {
    let date = new Date(createdAt).setDate(new Date(createdAt).getDate() + 3);
    return date
  }
  subtotal: any = 0
  total: any = 0
  sum: any = 0
  getTotalProductPrice() {
    if (this.cartListDetails.length) {
      this.sum = 0
      this.total = 0
      // this.subtotal = 0
      this.cartListDetails.forEach(element => {

        // this.subtotal = this.subtotal + element.totalMrp * element.enteredQuantity
        this.total = (element.totalPrice * element.quantity)
        this.sum += this.total
      });
    } else {
      this.sum = 0
    }
  }
  goToProduct(_id) {
    this.router.navigate(['/product-view-customer'], { queryParams: { _id: _id } })
  }
  navigateToaddress(e) {
    localStorage.setItem('Ids', JSON.stringify({ catID: this.newCatID, productId: this.productIds }))
    
    this.onProceed(e)
    this.router.navigate(['/list-address-customer'])
  }
  getCartList(){
    return this.cartLayOut.getCartList(this.cartListDetails)
  }
}
