<div class="container mb-5 wrapper-content">
    <app-linker-route [headingName]="headingName"  [linkerRouteItems]="linkerRouteItems"></app-linker-route>
    <div>
        <!-- <span class="dateset">Ordered on {{orderDataDetials?.createdAt | date : 'mediumDate' }}  </span>  -->
        <span class="line"> </span>
        <!-- <span class="orderSet">Order {{orderDataDetials?.orderId}}</span> -->
    </div>
    <div class="right-side" style="width: 100%;">
        <div class="row mt-3">
            <div class="col-md-5">
                <div class="row">
                    <div class="container">
                        <p class="titlem">Product</p>
                            <div class=" mt-4 " *ngFor="let item of viewOrderData">
                                <div class="row">
                                    <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                                        <div class="bg-image hover-overlay hover-zoom ripple rounded" data-ripple-color="light">
                                            <img [src]="item?.productId?.thumbnail" class="productImageNew" alt="" />
                                            <a>
                                                <div class="mask" style="background-color: rgba(251, 251, 251, 0.2)"></div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-9 col-md-12 mb-4 mb-lg-0">
                                        <p style="margin: 1px;" class="product">
                                           {{item?.productId?.productName}}
                                        </p>
                                        <p class="new-pr" style="margin: 1px;">
                                           {{getPrice((item?.price) |
                                                currency:"ZAR":"R")}}
                                        </p>
                                        <p class="mb-0 deliv">Qty: {{item?.quantity}}</p>
                                      
                                        <p class="mb-1 deliv">Value/Size:
                                            {{item?.priceSizeDetails?.value}} <span *ngIf="item?.priceSizeDetails?.unit">-</span> {{item?.priceSizeDetails?.unit}}</p>
                                        <p class="mb-0 deliv">Product Id: {{item?._id}}</p>
                                       
                                    </div>
        
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
