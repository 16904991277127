import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-retailer-edit-product',
  templateUrl: './retailer-edit-product.component.html',
  styleUrls: ['./retailer-edit-product.component.scss'],
})
export class RetailerEditProductComponent implements OnInit {
  headingName: any = 'EDIT PRODUCT';
  linkerRouteItems: any = [
    { name: 'Home', route: '/retailer-home' },
    { name: 'Edit Product', route: '/retailer-edit-product' },
  ];
  addProduct!: FormGroup;
  addImage!: FormGroup;
  codeValue: any;

  categoryListArray: any = [];
  subCategoryListArray: any = [];
  imageUrl: any;
  thumbnail: any;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  categoryId: any;
  subCategoryId: any;
  attendeeArray = [
    { value: '', price: '', quantity: '', unit: '', volume: '', weight: '' },
  ];
  unitsArray: any = [
    'kg',
    'gm',
    'mg',
    'mm',
    'cm',
    'm',
    'in',
    'ft',
    'l',
    'ml',
    'Yrs',
    'Size',
    'Pieces',
    'other',
  ];
  volumeArr: any = ['Standard', 'Large', 'X-Large', 'XX-Large', 'XXX-Large'];
  weightArr: any = ['Light', 'Heavy', 'X-Heavy', 'XX-Heavy', 'XXX-Heavy'];
  // addDynamicImageArray: any

  addDynamicImageArray: any = [];
  validationMessage: any; //global validation code
  productReferenceId: any;
  imageLinks: any;
  imagThumb: any;

  constructor(
    private activatedroute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    public commonService: CommonService
  ) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    });
  }

  ngOnInit(): void {
    this.viewRetailerProductd();
    this.getCategoryList();
    this.addDynamicImage();
    // this. getSubCategoryList()
    // form validation

    this.addImage = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(32),
      ]),
    });

    this.addProduct = new FormGroup({
      category: new FormControl('', [Validators.required]),

      subCategory: new FormControl('', [Validators.required]),

      deliveryDays: new FormControl('', [Validators.required]),

      description: new FormControl('', [Validators.required]),
      unitProduct: new FormControl(''),
    });
  }

  NumOnly(event: any) {
    let Numpattern = /^([R0-9$.])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  // search get api===========================================

  unit: any;

  catId: any;
  subid: any;
  addAttendee() {
    let mesage = '';
    // for (let item of this.attendeeArray) {

    //   if (!item.value || !item.price || !item.quantity) {

    //     if (!item.value) {
    //       mesage = "Please enter size/value"
    //     }
    //     else if (!item.price) {
    //       mesage = "Please enter amount"
    //     }
    //     else {
    //       mesage = "Please enter quantity"
    //     }

    //     return this.commonService.errorToast(mesage)
    //   }
    // }

    for (let item of this.attendeeArray) {
      if (
        !item.value ||
        !item.price ||
        !item.quantity ||
        !item.volume ||
        !item.weight
      ) {
        let message = '';
        if (!item.value) {
          message = 'Please enter size/value';
        } else if (!item.price) {
          message = 'Please enter amount';
        } else if (!item.quantity) {
          message = 'Please enter quantity';
        } else if (!item.volume) {
          message = 'Please enter volume';
        } else {
          message = 'Please enter weight';
        }
        return this.commonService.errorToast(message);
      }
    }

    // for (let item of this.attendeeArray) {
    //   item.unit = this.addProduct.value.unitProduct
    // }
    this.attendeeArray.push({
      value: '',
      price: '',
      quantity: '',
      unit: this.addProduct.value.unitProduct,
      volume: '',
      weight: '',
    });
  }
  removeAttendee(i: any) {
    this.attendeeArray.splice(i, 1);
  }
  searchList: any = [];
  aarayData: any = [];
  dataArry: any;
  // search get api===========================================
  getSearch(event: any) {
    if (!event.target.value) {
      // setTimeout(() => {
      this.catId = '';
      this.subid = '';
      this.searchList = [];
      this.imageLinks = [];
      this.productReferenceId = '';
      this.addProduct.reset();
      return;
      // }, 10);
    }
    this.isproductExist = false;
    let title = this.addImage.value.title;
    if (title.length > 0) {
      let url = `admin/productListByAdmin`;
      let data = {
        search: this.addImage.value.title,
        page: this.currentPage,
        limit: this.itemPerPage,
      };
      this.commonService.postApi(url, data, 1).subscribe(
        (res: any) => {
          if (res['responseCode'] == 200) {
            this.searchList = res['result']['docs'];
            this.addProduct.patchValue({
              description: this.searchList[0].description,
              category: this.searchList[0].categoryId.categoryName,
              subCategory: this.searchList[0].subCategoryId.subCategoryName,
              unit: this.searchList[0].unit,
            });
            this.catId = this.searchList[0].categoryId._id;
            this.subid = this.searchList[0].subCategoryId._id;
            let temp = [];
            let ind = this.imageLinks.length;
            this.isproductExist = true;
            res['result']['docs'][0]['productImage'].forEach(
              (element: any, index: number) => {
                if (ind < 3) {
                  temp.push(element);
                  ind++;
                }
              }
            );
            this.imageLinks = this.commonService.removeDuplicate(temp, ['url']);
            this.productReferenceId = res['result']['docs'][0]['_id'];
          } else {
            this.isproductExist = false;
            this.catId = '';
            this.subid = '';
            this.searchList = [];
            // this.imageLinks = [];
            this.imageLinks = this.imageLinks.filter((res: any) => {
              return !res.isListed;
            });
            this.productReferenceId = '';
            this.addProduct.reset();
          }
        },
        (err) => {
          this.isproductExist = false;
          this.catId = '';
          this.subid = '';
          this.searchList = [];
          this.imageLinks = this.imageLinks.filter((res: any) => {
            return !res.isListed;
          });
          this.productReferenceId = '';
          this.addProduct.reset();
        }
      );
    }
    // this.isValide = true;
  }
  UnitValue: any;
  changeValue(event: any) {
    this.UnitValue = event.target.value;
  }
  getSearchfirst() {
    let url = `admin/productListByAdmin`;
    let data = {
      page: this.currentPage,
      limit: this.itemPerPage,
    };
    this.commonService.postApi(url, data, 1).subscribe(
      (res: any) => {
        if (res['responseCode'] == 200) {
          this.searchList = res['result']['docs'];
        } else {
          this.searchList = [];
          this.imageLinks = [];
        }
      },
      (err) => {}
    );

    // this.isValide = true;
  }

  getCategoryList() {
    let apiReqUrl: any = `admin/listCategory`;
    this.commonService.showSpinner();
    let apiReqData: any = {
      // page: String(this.currentPage),
      // limit: String(this.itemPerPage)
    };
    // remove empty keys from request body
    // apiReqData = this.commonService['removeEmptyKey'](apiReqData)
    apiReqData = this.commonService.removeEmptyKey(apiReqData);

    this.commonService
      .postApi(apiReqUrl, apiReqData, 1)
      .subscribe((res: any) => {
        if (res.responseCode == 200) {
          this.categoryListArray = res.result.docs ? res.result.docs : '';
          this.totalItems = res.result.total;
          this.commonService.hideSpinner();
          // this.commonService.successToast(res.responseMessage);
        } else {
          this.categoryListArray = [];
          this.totalItems = 0;
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      });
  }
  getCategoryId(id: any) {
    this.categoryId = id.target.value;

    this.getSubCategoryList();
  }

  getSubCategoryList(id?: any) {
    let apiReqUrl = `${
      'admin/subcategoryListWithCategory' + '?categoryId=' + this.categoryId
    }`;
    if (id) {
      // alert(id)
      apiReqUrl = `${
        'admin/subcategoryListWithCategory' + '?categoryId=' + id
      }`;
    }
    this.commonService.showSpinner();

    // remove empty keys from request body
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.subCategoryListArray = res.result.subCategory
          ? res.result.subCategory
          : '';
        this.totalItems = res.result;
        // if(id){
        //   this.addProduct.patchValue({
        //     subCategory : id
        //   })
        // }
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.categoryListArray = [];
        this.totalItems = 0;
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    });
  }
  getSubCategoryId(id: any) {
    this.subCategoryId = id.target.value;
  }
  // =======================================new=============

  addDynamicImage() {
    if (
      this.addDynamicImageArray.length &&
      this.addDynamicImageArray[this.addDynamicImageArray.length - 1].image ==
        ''
    ) {
      return this.commonService.infoToast(
        'Please choose the image in previous added image field.'
      );
    }
    if (
      this.addDynamicImageArray.length >= 0 &&
      this.addDynamicImageArray.length <= 2
    ) {
      this.addDynamicImageArray.push('');
    } else {
      this.commonService.infoToast('You can only upload maximum 3 images.');
    }
  }
  removeDynamicImage(index: number) {
    this.addDynamicImageArray.splice(index, 1);
  }
  isValide: boolean = false;

  // =========================================== view detai============================================

  _id: any;

  userId: any;
  productDetails: any = {};
  productId: any;
  retailerId: any;
  imgurl: any;

  primaryDetail: any;
  dataProductId: any = [];
  viewRetailerProductd() {
    let apiReqUrl: any = `product/viewProduct?productId=${this._id}`;
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0;
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.productDetails = res.result;
        // let temp = this.productDetails.attendeeDetails.findIndex((ele:any) => {
        //   // return ele.isPrimary
        //   return ele
        // })

        this.addProduct.patchValue({
          category: this.productDetails?.categoryId.categoryName,
          subCategory: this.productDetails.subCategoryId.subCategoryName,
          // subCategory: this.productDetails?.subCategoryId?.categoryId || this.productDetails?.subCategoryId?._id,
          // mrp: this.productDetails.price,
          unitProduct: this.productDetails.unit,
          deliveryDays: this.productDetails.expectedDeliveryDays,
          description: this.productDetails.description,
          // addDynamicImageArray: this.productDetails.productImage,
        });
        this.getSubCategoryList(this.productDetails?.subCategoryId.categoryId),
          (this.addProduct.value.category =
            this.productDetails.categoryId.categoryName),
          (this.addProduct.value.subCategory =
            this.productDetails.subCategoryId.subCategoryName),
          this.addImage.patchValue({
            title: this.productDetails.productName,
          });
        this.imageLinks = res.result.productImage;
        this.attendeeArray = this.productDetails?.priceSizeDetails;
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }
  // ============================================updateform=========================================
  isproductExist = true;

  isSubmitted = false;
  editProduct() {
    if (!this.addProduct.valid) {
      this.isSubmitted = true;
      return this.commonService.errorToast('Please fill all the fields.');
    }
    let url = 'product/editProduct';
    let productImage = [];
    for (let item of this.imageLinks) {
      productImage.push(item);
    }

    let temp = false;
    let mesage = '';

    for (let item of this.attendeeArray) {
      if (
        !item.value ||
        !item.price ||
        !item.quantity ||
        !item.volume ||
        !item.weight
      ) {
        temp = true;
        if (!item.value) {
          mesage = 'Please enter size/value';
        } else if (!item.price) {
          mesage = 'Please enter amount';
        } else if (!item.quantity) {
          mesage = 'Please enter quantity';
        } else if (!item.weight) {
          mesage = 'Please enter weight';
        } else if (!item.volume) {
          mesage = 'Please enter volume';
        }

        return this.commonService.errorToast(mesage);
      }
      // item.unit = this.addProduct.value.unitProduct;
    }

    const data = {
      productName: this.addImage.value.title,
      // productImage: this.aarayData.length ? this.aarayData : this.addDynamicImageArray,
      productImage: productImage,
      categoryId: this.addProduct.value.category._id,
      subCategoryId: this.addProduct.value.subCategory._id,
      price: this.addProduct.value.mrp,
      quantity: this.addProduct.value.qty,
      expectedDeliveryDays: this.addProduct.value.deliveryDays,
      description: this.addProduct.value.description,
      brand: 'Myntra',
      productFor: 'RETAILER',
      // productReferenceId:"62b9889f86720a36944c80a8",
      productId: this._id,
      // thumbnail : this.imagThumb,
      unit: this.addProduct.value.unitProduct,
      priceSizeDetails: this.attendeeArray,
      thumbnail: productImage[0],
    };

    // this.commonService.putApi(url, data, 1)
    this.commonService.showSpinner();
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner();
        // this.commonService.successToast(res["responseMessage"]);
        this.router.navigate(['/retailer-product-list']);
        // this.getProfileDetails()
        // this.authService.loggedInData.next(res['result']['profilePic'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res['responseMessage']);
      }
    });
  }

  uploadImgS(event: any, index: any): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img, index);
  }
  uploadImageFunc(img: any, index: any) {
    var fb = new FormData();
    fb.append('file', img);
    this.commonService.showSpinner();
    this.commonService
      .postFormDataApi('user/uploadFile', fb, 1)
      .subscribe((res: any) => {
        if (res.responseCode == 200) {
          // this.imageUrl = res.result
          this.addDynamicImageArray[index] = res.result.mediaUrl;

          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      });
  }
  // ========================

  postImage: any = [];
  imgSrc: any = [];
  mediaType: any = 'media';
  // onvertToBase(file, i) {
  //   var me = this
  //   var reader = new FileReader();
  //   reader.onloadend = function () {

  //     me.imgSrc[i] = reader.result
  //   }
  //   reader.readAsDataURL(file);
  // }
  inds: any;

  async uploadImg(event: any) {
    if (event.target.files.length + this.imageLinks.length > 3) {
      this.commonService.infoToast('Please select maximum 3 image');
      return;
    }

    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      this.imageUrl = file;
      await this.uploadProfilePic(file, index);
    }
    this.mediaType = 'png || jpg';
  }

  async uploadProfilePic(img: any, i: number) {
    let url = 'user/uploadMultipleFile';
    this.imageLinks.push('assets/img/imageLoader.gif');

    const formData = new FormData();
    formData.append('uploaded_file', img);
    let res: any = await this.commonService
      .postFormDataApi(url, formData, 1)
      .toPromise();
    if ((await res.responseCode) == 200) {
      this.imageLinks.pop();

      this.imageLinks.push(res['result'][0]['mediaUrl']);

      this.imagThumb = res['result'][0]['thumbnail'];
    } else {
      this.commonService.errorToast("Can't Upload This File");
    }
  }
  closeimage(i: number) {
    this.imageLinks.splice(i, 1);
    if (this.imageLinks.length == 0) {
      this.mediaType = 'png';
    }
  }

  forID(_id: any) {
    this.router.navigate(['/retailer-product-list'], {
      queryParams: { _id: _id },
    });
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 48 && !event.target.value) {
      return false;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
