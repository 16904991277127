

<div class="modal fade" id="navigateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content" style="margin-top: 230px;">
        <div class="modal-header" style="height: 90px; background: #BF1E2E;">
            <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img
                    style="height: 100%; width:100%; object-fit: cover;" src="assets/img/whiteLogo.png" alt=""></h5>

        </div>
        <div class="modal-body">
           <div class="row justify-content-center">
            
            <div class="col-12 text-center">
                
                <p class="srchTxt" >
                    Payment has been credited in your wallet.
                   {{navDetail?.text}}
                </p>
            </div>

           </div>

        </div>
        <div class="modal-footer" >
            <button class="btn btn-primary btn-theme"  data-dismiss="modal" (click)="backRoute()">Cancel</button>
            <button class="btn btn-primary btn-theme"  data-dismiss="modal" (click)="openGoogleMapsWithRouting()">Navigate</button>
        </div>
    </div>
</div>
</div>
