<div>
    <div class="postion-relative">
        <div class="bannerContent position-relative">
            <div class="card innerDiv" (click)="dealVIEw(data?._id)">
                <div class="image img">
                    <!-- <img class="img" [src]="data?.dealImage[0]" alt="categoryImage" alt="categoryImage"> -->
                    <img  [src]="data?.dealImage[0]"  [hidden]="!isLoaded" (load)="onImageLoad()" alt="categoryImage">
                    <ngx-skeleton-loader *ngIf="!isLoaded" [theme]="{width: '100%',
                    height: '100%',
                    position: 'relative'}" count="1"></ngx-skeleton-loader>

                </div>
                <div class="mt-3">
                    <p class="description">{{commonService.splitString(data?.serviceId?.serviceName)}}</p>
                </div>
                <div style="margin-top: -8px;">
                    <div class="row">
                        <div class="col-12 p-0 m-0">
                            <p class="price" > <span    style="text-decoration: line-through;">
                                <ng-container >
                                    {{data?.serviceId?.price ?
                                        getPrice(data?.serviceId?.price | currency:"ZAR":"R") :'--'}}
                                </ng-container>
                            </span>
                            <span   class="price-color">
                                <ng-container >
                                    {{data?.dealPrice ?
                                        getPrice(data?.dealPrice | currency:"ZAR":"R")
                                        :'--'}}
                                </ng-container>
                                    </span>
                                 </p>
                        </div>
                    </div>
                     <div class="row" style="margin-top: -8px;">
                        <div class="col-12 p-0 m-0">
                            <p  class="dealPrice" style="color: #707070;">Deal Discount : <span class="newn">
                                {{this.discounCal(data?.serviceId?.price,data?.dealPrice)}}% off
                            </span>
                            </p>
                        </div>
                    </div>
                </div>
    
                <div class="end-pro-time text-center mb-2" *ngIf="countDown">
                    {{ countDown }}
                </div>
            </div>
        </div>
    </div>
</div>