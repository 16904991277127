import { CommonService } from 'src/app/provide/common.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-service-provider-deals-to-customer',
  templateUrl: './service-provider-deals-to-customer.component.html',
  styleUrls: ['./service-provider-deals-to-customer.component.scss']
})
export class ServiceProviderDealsToCustomerComponent implements OnInit {

  serviceDetail: any
  currentPage = 1;
  pageSize = 15;
  serviceName: any
  totalItems:any
   headingName:any ='DEALS TO CUSTOMERS'
  linkerRouteItems:any =[
    { name:'Home',route: '/service-order-detail'},
    { name:'Categroy List',route: '/service-provider-deals-to-customer'}
  ]
  constructor(public service: CommonService, private router:Router) { }

  ngOnInit(): void {
    this.serviceOrderList();
  }
  isLoading: boolean = false;
  serviceOrderList() {
    // let apiReqData = {
    //   search: this.serviceName,
    //   page: this.currentPage,
    //   limit: this.pageSize,
    // }
    // this.service.removeEmptyKey(apiReqData)
    let url = `deal/dealsServiceCategoryPartucular`
    this.service.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.serviceDetail = res.result
        this.totalItems = res.result.total
        if (!this.serviceDetail.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        // this.service.successToast(res['responseMessage'])
        this.service.hideSpinner()
      }

    }, (err: any) => {
      this.isLoading = true;
      this.service.hideSpinner()
      if (err.responseCode == 409) {
        this.isLoading = true;
        this.service.hideSpinner()
        this.service.errorToast(err.responseMessage)
      }
    })
  }
  pagination(event) {
    this.currentPage = event;
    this.serviceOrderList();
  }
  navigateToViewProduct(_id) {
    this.router.navigate(['/customer-service-available'], { queryParams: { _id: _id} })
  }
}
