import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'deals-layout-service',
  templateUrl: './deals-layout-service.component.html',
  styleUrls: ['./deals-layout-service.component.scss']
})
export class DealsLayoutServiceComponent implements OnInit {
  @Input() data : any
  @Input() countDown : any
  @Input() showEdit : boolean
  @Output() on_Click = new EventEmitter
  @Output() on_Click_Edit = new EventEmitter
  isLoaded: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  
  }
  dealVIEw(e){
    this.on_Click.emit(e)
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  onEdit(e){
    this.on_Click_Edit.emit(e)
  }
  onImageLoad(){
    this.isLoaded = true
  }
}
