import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-reset-password-retailer',
  templateUrl: './reset-password-retailer.component.html',
  styleUrls: ['./reset-password-retailer.component.scss']
})
export class ResetPasswordRetailerComponent implements OnInit {



  resetPassword!: FormGroup;

  myEmail: any;


  //  for icon 1nd

  visibles: boolean = false;
  changetypes: boolean = true

  viewpas() {
    this.visibles = !this.visibles;
    this.changetypes = !this.changetypes
  }


  visible: boolean = false;
  changetype: boolean = true;

  // myToken: any;

  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;

  }



  constructor(private fb: FormBuilder, http: HttpClient, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {

    this.resetPassword = this.fb.group({
      'password': new FormControl('', [
        Validators.required,
        // Validators.pattern("(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$")
        // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'),
      ]),
      'confirmPassword': new FormControl('', Validators.required),
    })

    this.myEmail = localStorage.getItem('email')

  }



  navigateToResetPassword() {
    if(this.resetPassword.invalid){
      return this.commonService.errorToast("Please fill all required field.")
    }
    if(this.resetPassword.value.password != this.resetPassword.value.confirmPassword){
      return this.commonService.errorToast("Password and confirm password must be same.")
    }
    let apiReqData = {
      // userType: 'RETAILER',
      email: this.myEmail,
      newPassword: this.resetPassword.value.password,
      userType: 'RETAILER',

    }
    // var newPassword = { newPassword: this.resetPassword.value.password }
    // var token = localStorage.getItem('verifyOtpToken')
    this.commonService.showSpinner();
    this.commonService.putApi('user/resetPassword', apiReqData, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        // this.router.navigate(['/change-password'])
        this.router.navigate(['/login-retailer'])
        // localStorage.removeItem("verifyOtpToken")
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    });
  }


  preventSpace(event: any) {
    if ((event.charCode == 32) && !event.target.value) {
      event.preventDefault();
    }
  }



}
// function subscribe(arg0: (res: any) => void) {
//   throw new Error('Function not implemented.');
// }

