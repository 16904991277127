import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'service-provider-deal-layout',
  templateUrl: './service-provider-deal-layout.component.html',
  styleUrls: ['./service-provider-deal-layout.component.scss']
})
export class ServiceProviderDealLayoutComponent implements OnInit {
  @Input() data : any
  @Input() countDown : any
  @Input() showEdit : boolean
  @Output() on_Click = new EventEmitter
  @Output() on_Click_Edit = new EventEmitter
  isLoaded:boolean = false
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
  
  }
  dealVIEw(e){
    this.on_Click.emit(e)
    
  }
  onImageLoad(){
    this.isLoaded = true
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  onEdit(e){
    this.on_Click_Edit.emit(e)
  }
p

  discounCal(actualprice,Dealprice){
    if(Dealprice && actualprice){
      let  DiscountPercentage = ((Number(actualprice) - Number(Dealprice)) / Number(actualprice)) * 100
      return DiscountPercentage  ? DiscountPercentage : 0
    }else{
      return 0
    }
  }
}
