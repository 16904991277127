<div class="modal fade" id="addressDetailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ggg position-relative">

            <div class="modal-header position-relative d-flex justify-content-center ">
                <h2 class="title" *ngIf="addressData">Update your shipping address</h2>
                <h2 class="title" *ngIf="!addressData">Add your shipping address</h2>
                <i class="fa fa-times position-absolute colorTheme" data-dismiss="modal" (click)="closePopup()"></i>
            </div>

            <div class="modal-body">

                <form [formGroup]="addressForm">
                    <div class="form-group">
                        <label for="firstName">First Name</label>
                        <input type="text" placeholder="Enter first name" formControlName="fName" maxlength="60"
                            (keypress)="commonService.preventSpace($event)" class="form-control form-control-small" />
                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('fName')?.hasError('required') && addressForm.get('fName')?.touched ">
                            *First name is required.</div>

                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('fName')?.hasError('minlength') && addressForm.get('fName')?.touched ">
                            *Please enter minimum 2 character.</div>
                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('fName')?.hasError('pattern') && addressForm.get('fName')?.touched">
                            *Please enter valid first name.

                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Last Name</label>
                        <input type="text" id="form3Examplea8" placeholder=" Enter last name" formControlName="lName"
                            maxlength="60" (keypress)="commonService.preventSpace($event)"
                            class="form-control form-control-small" />
                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('lName')?.hasError('required') && addressForm.get('lName')?.touched ">
                            *Last name is required.</div>

                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('lName')?.hasError('minlength') && addressForm.get('lName')?.touched ">
                            *Please enter minimum 2 character.</div>

                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('lName')?.hasError('pattern') && addressForm.get('lName')?.touched">
                            *Please enter valid last name.</div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Mobile Number</label>
                        <div class="mobile-input">
                            <div class="country-code">
                                <input list="browsers" class="form-control email-pass-pro ddd"
                                    formControlName="countryCode" name="browser" id="browser">
                                <datalist id="browsers">
                                    <option *ngFor="let code of datas" value="{{code?.phonecode}}">{{code?.flag}}
                                        {{code?.isoCode}} </option>
                                </datalist>
                            </div>
                            <hr class="mobile-inout-seperator">
                            <div class="mobile-number w-100">
                                <input type="text" class="mobile-number-input"
                                    (keypress)="commonService.preventSpace($event)"
                                    (keypress)="commonService.numberOnly($event)" formControlName="mobileNumber"
                                    placeholder="Enter mobile number" maxlength="15">
                            </div>

                        </div>
                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('mobileNumber').hasError('required') &&  addressForm.get('mobileNumber').touched"
                            class="text-danger" padding>*Mobile number is required.</div>
                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('mobileNumber').hasError('pattern') &&  addressForm.get('mobileNumber').touched"
                            class="text-danger" padding>*Invalid mobile number.</div>
                        <div class="for-validation text-danger isRed"
                            *ngIf="addressForm.get('mobileNumber').touched &&  (addressForm.get('mobileNumber').hasError('minlength') || addressForm.get('mobileNumber').hasError('maxlength'))"
                            class="text-danger" padding>*Please enter valid number between 8-15 digit.
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Email Address</label>
                        <input type="text" id="addr" placeholder="Enter your email " formControlName="email"
                            maxlength="100" (keypress)="commonService.preventSpace($event)"
                            class="form-control form-control-small" />
                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('email')?.hasError('required') && addressForm.get('email')?.touched ">
                            *Email is required.</div>
                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('email')?.hasError('pattern') && addressForm.get('email')?.touched ">
                            *Please enter valid email.</div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Address Line 1</label>
                        <input id="addr" placeholder="Enter address"
                            formControlName="address1" maxlength="350" (keypress)="commonService.preventSpace($event)"
                            class="form-control form-control-small" />
                        <div class="for-validation text-danger"
                            *ngIf="addressForm.get('address1')?.hasError('required') && addressForm.get('address1')?.touched ">
                            *Address1 is required.</div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Address Line 2</label>
                        <input type="text" id="addr" placeholder="Enter your address2 " formControlName="address2"
                            maxlength="100" (keypress)="commonService.preventSpace($event)"
                            class="form-control form-control-small" />
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="form-group w-45">
                            <label for="exampleInputPassword1">Country</label>
                            <select name="country" id="countryList" formControlName="country" class="form-control"
                                (change)="getStateList($event.target.value)">
                                <option value="">Select Country</option>
                                <option *ngFor="let item of countryList" [value]="item.isoCode">
                                    {{item?.name}}</option>
                            </select>
                            <div class="for-validation text-danger"
                                *ngIf="addressForm.get('country')?.hasError('required') && addressForm.get('country')?.touched ">
                                *Country is required.</div>
                        </div>
                        <div class="form-group w-45">
                            <label for="exampleInputPassword1">State</label>
                            <select name="country" id="countryList" class="form-control"
                                (change)="getCityList($event.target.value)" formControlName="state">
                                <option value="">Select State</option>
                                <option *ngFor="let item of stateList" [value]="item.isoCode">{{item?.name}}
                                </option>
                            </select>
                            <div class="for-validation text-danger"
                                *ngIf="addressForm.get('state')?.hasError('required') && addressForm.get('state')?.touched ">
                                *State is required.</div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="form-group w-45">
                            <label for="exampleInputPassword1">City</label>
                            <select name="country" id="countryList" class="form-control"
                                (change)="getCityList($event.target.value)" formControlName="city">
                                <option value="">Select State</option>
                                <option *ngFor="let item of cityList" [value]="item.name">{{item?.name}}
                                </option>
                            </select>
                            <div class="for-validation text-danger"
                                *ngIf="addressForm.get('city')?.hasError('required') && addressForm.get('city')?.touched ">
                                *City is required.</div>
                        </div>

                        <div class="form-group w-45">
                            <label for="exampleInputPassword1">Zip Code</label>
                            <input type="text" id="asd" formControlName="zipCode" placeholder=" Enter zip code"
                            (keypress)="commonService.preventSpace($event)"  class="form-control email-pass-pro"
                            maxlength="8" />
                          <div class="for-validation text-danger mt-1 mb-1"
                            *ngIf="addressForm.get('zipCode')?.hasError('required') && addressForm.get('zipCode')?.touched">
                            *Zip code is required.</div>
                            <div class="for-validation text-danger"
                            *ngIf="addressForm.get('zipCode')?.hasError('pattern') && addressForm.get('zipCode')?.touched">
                            *Please enter valid zipcode.
              
                          </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="addressData && !addressData.isPrimary">
                        <div class="form-group w-50">
                           
                          
                            <input type="checkbox" (change)="checked = $event.target.checked">
                            <label for="exampleInputPassword1" class="ml-3">Make this my primary address</label>
                        </div>

                       
                    </div>

                </form>
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary btn-theme" *ngIf="addressData" (click)="getAddressDetails2()" >
                        Update Address
                    </button>
                    <button type="button" class="btn btn-primary btn-theme" *ngIf="!addressData" (click)="getAddressDetails()" >
                        Add Address
                    </button>
                </div>
            
            </div>
        </div>
    </div>
</div>