import { CommonService } from 'src/app/provide/common.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
declare var $

@Component({
  selector: 'app-retailer-view-deals',
  templateUrl: './retailer-view-deals.component.html',
  styleUrls: ['./retailer-view-deals.component.scss']
})
export class RetailerViewDealsComponent implements OnInit {
  headingName:any ='DEAL DETAILS'
  isLoaded: boolean = false
  linkerRouteItems:any =[
    { name:'Home',route: '/retailer-home'},
    {name:'Deal Detail',route:'/retailer-view-deals'}
  ]
  _id: any
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private http: HttpClient, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })
  }

  ngOnInit(): void {
    this.viewRetailerProductd();
  }
  onImageLoad(){
    this.isLoaded = true
  }




  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any

  // get retailer detail by id
  viewRetailerProductd() {
    let apiReqUrl: any = `deal/viewDeal?dealId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    // this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, {},).subscribe((res: any) => {
      if (res.responseCode == 200) {
        $(document).ready(function () {
          $('.sevice').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });

        this.productDetails = res.result;
        this.imgurl = res.result.dealImage[0]
        this.imageLinks = res["result"]["thumbnail"]

        // this.imgurl = res.result.dealImage;
        this.commonService.hideSpinner();
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    })
  }


  // =================


  forID(_id: any) {
    this.router.navigate(['/retailer-edit-deals'], { queryParams: { _id: _id } })
  }


  changeProductimage(url: any) {
    
    this.imgurl = url
  }

  mrp: any
  quantity: any
  value: any
  dealPL: any
  price(price: any, qty: any, value: any,id:any,unit:any, dealPrice: any) {
    this.mrp = price,
      this.quantity = qty
    this.value = value
    this.value = value
    // this.productId =id
    // this.unit =unit
    this.dealPL = dealPrice
  }
  getPrice(e: any) {
    if(e){
      let a = e.replace(/,/g, ' ')
      return a.replace(/R/g, 'R ')
    }
  }
}
