<auth-layout [title]="signupTitle" type="SIGNUP" subTitile="Access the eXobe using your email and password"
sideImage="assets/latest/service-provider-signup.png"
>

    <form [formGroup]="signup">


        <div class="form-group">
            <div class="text-center">
                <div class="profle-sec">
                    <img [src]="imageUrl || '/assets/images/man.png'" alt="">
                    <i class="fa fa-camera cmra-icon" aria-hidden="true">
                        <input type="file" class="input-file" accept=".png, .jpg, .jpeg" (change)="uploadImg($event)" />
                    </i>
                </div>

            </div>
            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">First Name<span class="redis">*</span></label>
                    <div class="input-control">
                        <input type="text" class="form-control" placeholder="Enter your email" type="text"
                            formControlName="fName" placeholder=" Enter First Name" (keypress)="preventSpace($event)"
                            id="username">



                    </div>
                    <div class="for-validation mb-4">
                        <!-- Show error message for 'required' if the field is touched and not filled -->
                        <div class="for-validation text-danger"
                             *ngIf="signup.get('fName')?.errors?.required && isSubmitted">
                          *Please enter your first name.
                        </div>
                        
                        <ng-container *ngIf="signup.get('fName')?.hasError('minlength') && signup.get('fName')?.touched ; else elseBlock ">
                          <div class="for-validation text-danger">
                            *Please enter minimum 2 character.
                        </div>
                        </ng-container>
                        <ng-template #elseBlock>
                          <ng-container *ngIf="signup.get('fName')?.hasError('pattern') && signup.get('fName')?.touched; else elseifBlock">
                            <div class="for-validation text-danger">
                              *Please enter only alphabets.
                          </div>
                          </ng-container>
                          <ng-template #elseifBlock>
                            <div
                            *ngIf="signup.get('fName')?.hasError('maxlength') && signup.get('fName')?.touched" class="for-validation text-danger">
                              *Please enter maximum 32 character.
                          </div>
                          </ng-template>
                          
                        </ng-template>
                        <!-- <div class="for-validation text-danger"
                        *ngIf="signup.get('fName')?.hasError('minlength') && signup.get('fName')?.touched">
                          *Please enter minimum 2 character.
                      </div>
                      <div class="for-validation text-danger"
                        *ngIf="signup.get('fName')?.hasError('maxlength') && signup.get('fName')?.touched">
                          *Please enter maximum 32 character.
                      </div>
                      <div class="for-validation text-danger"
                          *ngIf="signup.get('fName')?.hasError('pattern') && signup.get('fName')?.touched">
                          *Please enter only alphabets.
                      </div> -->
                        
                       
                    </div>
                </div>
                <div class="w-50">
                    <label for="username">Last Name<span class="redis">*</span></label>
                    <div class="input-control">
                        <input type="text" class="form-control" formControlName="lName" placeholder=" Enter Last Name"
                            (keypress)="preventSpace($event)" id="username">
                    </div>
                    <div class="for-validation mb-4">
                        <!-- Show error message for 'required' if the field is touched and not filled -->
                        <div class="for-validation text-danger"
                             *ngIf="signup.get('lName')?.errors?.required && isSubmitted">
                          *Please enter your last name.
                        </div>
                      
                        <!-- Show error message for 'pattern' if the field is touched and contains invalid characters -->
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('lName')?.hasError('required')  && isSubmitted">
                            Please enter last name.</div>

                        <!-- <div class="for-validation text-danger"
                            *ngIf="signup.get('lName')?.hasError('pattern') && signup.get('lName')?.touched ">
                            Only alphabets and one or two spaces allowed.</div>

                        <div class="for-validation text-danger"
                            *ngIf="signup.get('lName')?.hasError('minlength') && signup.get('lName')?.touched ">
                            Please enter minimum 2 character.</div>
                           
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('lName')?.hasError('maxlength') && signup.get('lName')?.touched ">
                            Please enter maximum 32 character.</div> -->
                            <ng-container *ngIf="signup.get('lName')?.hasError('minlength') && signup.get('lName')?.touched ; else elseBlock1 ">
                              <div class="for-validation text-danger">
                                *Please enter minimum 2 character.
                            </div>
                            </ng-container>
                            <ng-template #elseBlock1>
                              <ng-container *ngIf="signup.get('lName')?.hasError('pattern') && signup.get('lName')?.touched; else elseifBlock1">
                                <div class="for-validation text-danger">
                                  *Please enter only alphabets and one or two spaces.
                              </div>
                              </ng-container>
                              <ng-template #elseifBlock1>
                                <div
                                *ngIf="signup.get('lName')?.hasError('maxlength') && signup.get('lName')?.touched" class="for-validation text-danger">
                                  *Please enter maximum 32 character.
                              </div>
                              </ng-template>
                              
                            </ng-template>
                    </div>


                </div>

            </div>

            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">Email<span class="redis">*</span></label>
                    <div class="input-control">
                        <input type="text" id="asd" formControlName="email" placeholder="  Enter your email"
                            maxlength="30" (keypress)="preventSpace($event)" class="form-control" />


                        <span class="eyeicon">
                            <img src="assets/latest/email.svg" alt="">
                        </span>
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('email')?.hasError('required')  && isSubmitted ">
                            Email is required.</div>

                        <div class="for-validation text-danger"
                            *ngIf="signup.get('email')?.hasError('pattern')  ">
                            Please
                            enter a valid email</div>
                    </div>
                </div>
                <div class="w-50">
                    <label for="username">Phone Number (optional )</label>
                    <div class="input-control d-flex">


                        <select id="browsers" class="form-select tel p-o" formControlName="countryCode">

                            <option *ngFor="let code of products" value="{{code?.phonecode}}">{{code?.flag}}
                                {{code?.phonecode}}
                                {{code?.isoCode}} </option>

                        </select>
                        <input type="text" class="form-control tel" (keypress)="preventSpace($event)"
                            (keypress)="NumOnly($event)" placeholder="Enter Phone Number" formControlName="Phone"
                            maxlength="15">
                    </div>
                    <div class="for-validation mb-4">
                        <!-- <div class="for-validation  text-danger"
                            *ngIf="signup.get('Phone')?.hasError('required') ">
                            Phone number is required.</div> -->
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('Phone')?.hasError('pattern') && signup.get('Phone')?.touched ">
                            Please enter valid Phone number</div>
                            <div class="for-validation text-danger"
                            *ngIf="signup.get('Phone')?.hasError('minlength') && signup.get('Phone')?.touched ">
                            Please enter valid number between 6-15 digit.</div>

                        <div class="for-validation text-danger"
                            *ngIf="signup.get('Phone')?.hasError('maxlength') && signup.get('Phone')?.touched">
                            Please enter valid number between 6-15 digit.</div>
                    </div>



                </div>

            </div>

            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">Mobile Number<span class="redis">*</span></label>
                    <div class="input-control d-flex">


                        <select id="browsers" class="form-select tel p-0" formControlName="countryCode">

                            <option *ngFor="let code of products" value="{{code?.phonecode}}">{{code?.flag}}
                                {{code?.phonecode}}
                                {{code?.isoCode}} </option>

                        </select>
                        <input type="text" class="form-control tel" (keypress)="preventSpace($event)" placeholder="Enter Mobile Number"
                            (keypress)="NumOnly($event)" formControlName="mobileNumber" maxlength="15">

                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('mobileNumber')?.hasError('required')  && isSubmitted">
                            Mobile number is required.</div>
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('mobileNumber')?.hasError('pattern') && signup.get('mobileNumber')?.touched ">
                            Please enter valid Mobile number</div>


                        <div class="for-validation text-danger"
                            *ngIf="signup.get('mobileNumber')?.hasError('minlength') && signup.get('mobileNumber')?.touched ">
                            Please enter valid number between 8-15 digit.</div>

                        <div class="for-validation text-danger"
                            *ngIf="signup.get('mobileNumber')?.hasError('maxlength') && signup.get('mobileNumber')?.touched">
                            Please enter valid number between 8-15 digit.</div>
                    </div>



                </div>
                <div class="w-50">
                    <label for="username">Address Line 1<span class="redis">*</span></label>
                    <div class="input-control">

                        <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                            (onAddressChange)="handleAddressChange($event)" id="asd" placeholder="Enter Address 1"
                            formControlName="address" (keypress)="preventSpace($event)"
                            class="form-control form-control-small" />
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('address')?.hasError('required')  && isSubmitted">
                            Please enter valid address line 1.</div>
                    </div>



                </div>

            </div>


            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">Address Line 2</label>
                    <div class="input-control">

                        <input type="text" id="asd" formControlName="locatione" placeholder=" Enter Address Line 2"
                            (keypress)="preventSpace($event)" class="form-control" />

                    </div>


                </div>
                <div class="w-50">
                    <label for="username">Country<span class="redis">*</span></label>
                    <div class="input-control">
                        <select class="form-select" id="asd3" *ngIf="!completedAddress_country"
                            formControlName="Country" (change)="getStateList($event)">

                            <option value="" selected style="text-align: start;">Enter Your Country</option>
                            <option *ngFor="let item of country" [ngValue]="item">{{item?.name}}</option>
                        </select>
                        <input type="text" *ngIf="completedAddress_country" id="asd" formControlName="Country"
                            placeholder="Enter Your Country" readonly class="form-control" />
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('Country')?.hasError('required') && isSubmitted">
                            Please enter country.</div>
                    </div>



                </div>

            </div>

            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">State<span class="redis">*</span></label>
                    <div class="input-control">

                        <select class="form-select" id="asd3" *ngIf="!completedAddress_state" formControlName="state"
                            (change)="getCityList($event)">
                            <option value="" selected>Enter Your State</option>
                            <option *ngFor="let item of state" [ngValue]="item">{{item?.name}} </option>
                        </select>
                        <input type="text" *ngIf="completedAddress_state" id="asd" formControlName="state"
                            placeholder="Enter Your State" readonly class="form-control" />
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                        *ngIf="signup.get('state')?.hasError('required')  && isSubmitted">
                        Please select state.</div>
                    </div>


                </div>
                <div class="w-50">
                    <label for="username">City<span class="redis">*</span></label>
                    <div class="input-control">
                        <select class="form-select" id="asd3" *ngIf="!completedAddress_city" formControlName="city">
                            <option value="" selected>Enter Your City</option>
                            <option *ngFor="let item of city">{{item?.name}}</option>
                        </select>
                        <input type="text" *ngIf="completedAddress_city" id="asd" formControlName="city"
                            placeholder="Enter Your City" readonly class="form-control" />
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                        *ngIf="signup.get('city')?.hasError('required')  && isSubmitted">
                        Please select city.</div>
                    </div>



                </div>

            </div>


            <div class="d-flex justify-content-between">

                <div class="w-48">
                    <label for="username">Zip Code<span class="redis">*</span></label>
                    <div class="input-control">
                        <input type="text" id="asd" *ngIf="!completedAddress_zip" formControlName="zipCode"
                            placeholder=" Enter Zip Code" (keypress)="preventSpace($event)" class="form-control"
                            maxlength="10" (keypress)="NumOnly($event)"/>

                        <input type="text" id="asd" *ngIf="completedAddress_zip" formControlName="zipCode"
                            placeholder=" Enter Zip Code" class="form-control  mt-2" readonly />
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('zipCode')?.hasError('required')  && isSubmitted">
                            Zip code field can not be empty.</div>
                    </div>



                </div>

                <!-- <div class="w-50">
                    <label for="username">Fee Configuration<span class="redis">*</span></label>
                    <div class="input-control">

                       <button class="btn btn-primary btn-theme" (click)="openFeeModal()">Fee Configuration</button>
                    </div>
                   



                </div> -->

            </div>


            <div class="d-flex justify-content-between" *ngIf="!isSocial">
                <div class="w-48">
                    <label for="username">Password<span class="redis">*</span></label>
                    <div class="input-control">
                        <input [type]="changetype?'password':'text'" id="asd" class="form-control"
                            placeholder=" Enter Password" (keypress)="preventSpace($event)" formControlName="Password"
                            maxlength="20" onselectstart="return false;" onpaste="return false;" oncopy="return false;"
                            oncut="return false" ondrag="return false" ondrop="return false" autocomplete="off" />
                            <i class="eye-icon-posistion" [ngClass]="changetype ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true" (click)="changePasswordIcon()"></i>
                        <!-- <span class="eyeicon" (click)="viewpass()">
                         
                            <img src="assets/latest/eye2.svg" alt="" *ngIf="!changetype">
                            <img src="assets/latest/eye1.svg" alt="" *ngIf="changetype">

                        </span> -->
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('Password')?.hasError('required')  && isSubmitted">
                            Please enter valid password.</div>
                            <div class="for-validation text-danger"
                                *ngIf="signup.get('Password')?.hasError('pattern')  && isSubmitted">
                                Password must be 8-16 characters long, include one uppercase letter, one lowercase letter, one digit, and one special character.
                            </div>

                    </div>
                </div>
                <div class="w-50">
                    <label for="username">Confirm Password<span class="redis">*</span></label>
                    <div class="input-control">

                        <input [type]="changetypes?'password':'text'" id="asd" class="form-control"
                            placeholder=" Enter Confirm Password" (keypress)="preventSpace($event)"
                            formControlName="confirmPassword" maxlength="20" onselectstart="return false;"
                            onpaste="return false;" oncopy="return false;" oncut="return false" ondrag="return false"
                            ondrop="return false" autocomplete="off" />
                            <i class="eye-icon-posistion" [ngClass]="changetypes ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true" (click)="changePasswordIcon1()"></i>
                            <!-- <i class="eye-icon-posistion" [ngClass]="changetype ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true" (click)="changePasswordIcon()"></i> -->
                        <!-- <span class="eyeicon" (click)="viewpas()">
                         
                            <img src="assets/latest/eye2.svg" alt="" *ngIf="!changetypes">
                            <img src="assets/latest/eye1.svg" alt="" *ngIf="changetypes">
                        </span> -->
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('confirmPassword')?.hasError('required') && signup.get('confirmPassword')?.touched">
                            Please enter valid confirm password.</div>
                        <div class="for-validation text-danger"
                            *ngIf="(signup.get('Password')?.value != signup.get('confirmPassword')?.value)  && signup.get('confirmPassword')?.touched">
                            Password and Confirm Password must be match.</div>

                    </div>



                </div>
            </div>


            <div class="d-flex justify-content-between">
                <div class="w-100">


                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="term" formControlName="term">
                        <label class="form-check-label" for="term">
                          I agree to <a routerLink="/terms-and-condition" target="_blank">Terms & Conditions</a>
                        </label>
                      </div>
                      <div class="for-validation text-danger" *ngIf="signup.get('term')?.errors?.required && signup.get('term')?.touched && isSubmitted">
                        Please agree to the Terms & Conditions.
                      </div>


                </div>

            </div>


            <div class="d-flex justify-content-between" style="align-items: center;">
                <div class="left-wrap">
                    <button class="for-login btn text-white btn-block btn-primary" *ngIf="!loading"
                        (click)="load();signUp()" type="submit">Register Now</button>
                    <button class="for-login btn text-white btn-block btn-primary op05" *ngIf="loading"
                        type="submit">Loading...</button>
                </div>
                <div class="right-wrap">
                    <span class="d-block text-left my-4 text-muted" [routerLink]="['/login-service-provider']">Already
                        Have Account?
                        <span class="cols">Login</span></span>
                </div>
                
            </div>
        </div>
    </form>

</auth-layout>

<div class="modal fade" id="FeeConfigModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" [ngClass]="serviceRole == 'FIELD' ? 'mx-wt' : ''">
        <div class="modal-content">
            <set-fee [getData]="true" (feeData)=feeData($event)></set-fee>
        </div>
    </div>
</div>