import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-customer-deals-on-product-list',
  templateUrl: './customer-deals-on-product-list.component.html',
  styleUrls: ['./customer-deals-on-product-list.component.scss'],
})
export class CustomerDealsOnProductListComponent implements OnInit {
  serviceName: any;
  serviceDetail: any;
  _id: any;
  dealImg: any;
  currentPage: any = 1;
  latitude: any;
  longitude: any;
  pageSize = 15;
  totalItems: any;
  totalCounts: any;
  loggedInUserType: any;
  headingName:any ='DEALS ON PRODUCTS'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Deals On Products',route:'/customer-product-deal-list'}
  ]
  constructor(
    public commonService: CommonService,
    public authService: AuthService,
    public activated: ActivatedRoute,
    public router: Router
  ) {
    this.activated.queryParams.subscribe((res: any) => {
      this._id = res._id;
    });
  }

  ngOnInit(): void {
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res;
    });
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn();
    navigator.geolocation.getCurrentPosition(
      function (position) {
        // alert('allow');
      },
      function () {
        alert(
          'You need to enable geolocation services in your browser to use this feature.'
        );
      }
    );
    this.getPosition().then((pos) => {
      localStorage.setItem('location', JSON.stringify(pos));
    });
    this.getLocation();
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.serviceOrderList();
          }
        },
        (error) => console.log(error)
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }
  user: any;
  isLoading: boolean = false;
  serviceOrderList() {
    // let url = `deal/dealsListServiceParticular?categoryId=${this._id}`
    let url = `deal/listDealByLocation`;
    // let url =`product/listProductBySortion`
    if (this.loggedInUserType == 'RETAILER') {
      this.user = 'WHOLE_SALER';
    } else {
      this.user = 'RETAILER';
    }
    let data = {
      dealType: 'PRODUCT',
      userType:  this.user,
      lng: this.longitude,
      lat: this.latitude,
      page: this.currentPage,
      limit: this.pageSize,
      search: this.serviceName,
    };
    if(!this.serviceName){
      // this.commonService.showSpinner();

    }

    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(url, data, a).subscribe(
      (res: any) => {
        if (res['responseCode'] == 200) {
          if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.countDown = [];
          }

          // this.serviceDetail = []
          this.serviceDetail = res.result.docs;
          if (!this.serviceDetail.length) {
            this.isLoading = true;
          } else {
            this.isLoading = false;
          }
          this.totalItems = res.result.totalPages;
          this.totalCounts = res.result.count;
          for (let index = 0; index < this.serviceDetail.length; index++) {
            this.getCoundown(this.serviceDetail[index]['dealEndTime'], index);
          }
          // this.service.successToast(res['responseMessage'])
          this.commonService.hideSpinner();
        } else {
          this.isLoading = true;
          this.serviceDetail = [];
          this.commonService.hideSpinner();
        }
      },
      (err: any) => {
        this.isLoading = true;
        this.commonService.hideSpinner();
        this.serviceDetail = [];
        if (err.responseCode == 409) {
          this.isLoading = true;
          this.commonService.hideSpinner();
          this.commonService.errorToast(err.responseMessage);
        }
      }
    );
  }
  pagination(event) {
    this.currentPage = event;
    this.serviceOrderList();
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ');
    return a.replace(/R/g, 'R ');
  }

  // timer

  myTimer() {}
  // getDealDescription
  countDown: any = [];
  timerInterval: any = [];
  new: any;
  getCoundown(date: Date, i: number) {
    var countDownDate = new Date(date).getTime();
    let me = this;
    // Update the count down every 1 second

    this.timerInterval[i] = setInterval(() => {
      if (true) {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        me.countDown[i] =
        days + 'D:' + hours + 'H:' + minutes + 'M:' + seconds + 'S';        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(me.timerInterval);
          me.countDown[i] = 'EXPIRED';
        }
        // me.new = me.countDown[i]
      }

      // me.countDown[i] = mm
    }, 1000);
  }
  navigateToViewProduct(_id) {
    this.router.navigate(['/customer-product-view'], {
      queryParams: { _id: _id },
    });
  }
  length(n){
    return new Array(n)
  }
}
