import { EventEmitter, Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ApiEndPoint } from '../config/api-end-point';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { Location } from '@angular/common';

// import { ToastConfig, Toaster, ToastType } from 'ngx-toast-notifications';
// import { BehaviorSubject, Subject } from "rxjs";
export interface CountryCode {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public userTypeSelectionUpdate = new BehaviorSubject<any>('');
  public profileUpdateItem = new BehaviorSubject<any>(false);

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  // loginAs = new Subject<any>()
  // // public baseUrl = 'http://182.72.203.250:1892/api/v1/' // domain backend url
  // // public baseUrl = 'https://node-moteemaids.mobiloitte.com/api/v1/' // domain backend url
  // // public baseUrl = 'http://172.16.12.55:3031/api/v1/' // rajat
  // // public baseUrl = 'http://172.16.1.119:3031/api/v1/'  // moin
  // public baseUrl = 'http://172.16.1.246:3031/api/v1/' // moin new system
  // // public webUrl = 'http://localhost:4200/'
  // public webUrl = 'https://motee-maids-customer.mobiloitte.org/'
  // // public baseUrl ='http://172.16.1.119:3031/api/v1/'
  public webSiteUrl =
    window.location.protocol + '//' + window.location.host + '/';
  public baseUrl = environment.baseUrl;
  public webUrl = environment.webUrl;
  public ozhoBaseUrl = environment.ozhoBaseUrl;
  publicApikey: any = 'AIzaSyDfieYGllQw_bVgzU5MZMsF2MeOtM59lF4';
  public onFirstComponentButtonClick() {
    this.invokeFirstComponentFunction.emit();
    this.userTypeSelectionUpdate.subscribe((value) => {
      if (localStorage.getItem('userType')) {
        return localStorage.getItem('userType');
      }
      console.log('Received value:', value);
    });
  }
  invokeFirstComponentFunction = new EventEmitter();
  subsVar: any = Subscription;
  currencyUsed: any = 'R';
  // public loginData = new Subject<any>();

  // public postData = new BehaviorSubject<any>(0);
  // public particularPost = new BehaviorSubject<any>(0);
  // public notificationId = new Subject<any>();

  // public stripePublishKey = 'pk_test_51J28USSJYd6eSwM1We3AOGpaxVtlWNqSAsd080mxesGynxVop8H5tLTBnYGHaTM2mxim8o3xyHzP1zSdV2mgG98g00kWpT1MT6' // moin system
  public stripePublishKey = 'pk_test_bPLMoVgKgkEh5Q42Q6g3suQP00OR22mpEL'; // moin / stagging system

  getCurrentDate: any = new Date().toISOString().split('T')[0];

  tokenInfo = this.getDecodedAccessToken(localStorage.getItem('token')); // decode token
  constructor(
    public router: Router,
    // private toaster: ToastrService,
    public httpClient: HttpClient,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    public _backLocation: Location
  ) {}
  // setNotificationId(id) {
  //   this.notificationId.next(id)
  // }
  // setPostData(data: any) {
  //   this.postData.next(data);
  // }
  // setParticularPostData(data: any) {
  //   this.particularPost.next(data);
  // }

  // ------------ get local file data ---------- //
  getLocalData(url) {
    return this.httpClient.get(url);
  }

  // ------------ get third party api ---------- //
  getThirdPartyApi(url) {
    return this.httpClient.get(url, { observe: 'response' });
  }

  getIPDetails() {
    // let
    return localStorage.getItem('ipDetails')
      ? JSON.parse(localStorage.getItem('ipDetails'))
      : 'INR';
  }
  splitString(inputString) {
    if (inputString && inputString.length > 0) {
      const splitStrings = [];
      for (let i = 0; i < inputString.length; i += 100) {
        splitStrings.push(inputString.substr(i, 50));
      }
      return splitStrings + '';
    }
  }
  onDealDiscountPrice() {}

  routingSinup(userType) {
    if (userType) {
      if (userType == 'CUSTOMER') {
        return '/signup-customer';
      }
      if (userType == 'RETAILER') {
        return '/signup-retailer';
      }
      if (userType == 'SERVICE_PROVIDER') {
        return '/signUp-service-provider';
      }
      if (userType == 'PICKUP_PARTNER') {
        return '/signUp-service-provider';
      }
      if (userType == 'DELIVERY_PARTNER') {
        return '/signUp-service-provider';
      }
      if (userType == 'FIELD_ENTITY') {
        return '/signUp-service-provider';
      }
    }
  }
  // change time ISO to Local Time
  isoDateTime: any;
  localDateTime: any;
  convertDateIntoLocalTime(date) {
    this.isoDateTime = new Date(date);
    this.localDateTime =
      this.isoDateTime.toLocaleDateString() +
      ' ' +
      this.isoDateTime.toLocaleTimeString();
    return this.localDateTime;
  }
  localTime: any;
  originalLocalTime: any;
  convertISODateIntoLocalTime(date) {
    this.localTime = new Date(date);
    this.originalLocalTime = this.localTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return this.originalLocalTime;
  }

  // ----------- get api ---------------- //
  getApi(endPoint, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          token: `${
            localStorage.getItem('token') || localStorage.getItem('token-auth')
          }`,
        }),
      };
    }
    return this.httpClient.get(this.baseUrl + endPoint, httpHeaders);
  }

  // ------------ post api -------------- //
  postApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          token: `${
            localStorage.getItem('token') || localStorage.getItem('token-auth')
          }`,
        }),
      };
    }
    return this.httpClient.post(this.baseUrl + endPoint, data, httpHeaders);
  }

  // ------------ put api -------------- //
  putApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          token: `${
            localStorage.getItem('token') || localStorage.getItem('token-auth')
          }`,
        }),
      };
    }
    return this.httpClient.put(this.baseUrl + endPoint, data, httpHeaders);
  }

  // ----------- patch api --------------- //
  patchApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          token:
            localStorage.getItem('token') || localStorage.getItem('token-auth'),
        }),
      };
    }
    return this.httpClient.patch(this.baseUrl + endPoint, data, httpHeaders);
  }

  // ------------ delete api -------------- //
  deleteApi(endPoint, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          token: `${
            localStorage.getItem('token') || localStorage.getItem('token-auth')
          }`,
        }),
      };
    }
    return this.httpClient.delete(this.baseUrl + endPoint, httpHeaders);
  }

  fetchCordinates(address) {
    let url = 'https://maps.googleapis.com/maps/api/geocode/json';
    const params = {
      address: address,
      key: 'AIzaSyDfieYGllQw_bVgzU5MZMsF2MeOtM59lF4', // Replace with your Google Maps API key
    };
    return this.httpClient.get<any>(url, { params });
  }
  getHtmlContent(
    endPoint: string,
    data: any,
    isHeader: boolean
  ): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders({});

    if (isHeader) {
      const token =
        localStorage.getItem('token') || localStorage.getItem('token-auth');
      if (token) {
        headers = headers.set('token', token);
      }
    }

    // Make the POST request
    return this.httpClient.post(`${this.baseUrl}${endPoint}`, data, {
      headers: headers,
      responseType: 'blob',
    });
  }
  // --------- upload image----------- //
  uploadImage(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({}),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          token: `${
            localStorage.getItem('token') || localStorage.getItem('token-auth')
          }`,
        }),
      };
    }
    return this.httpClient.post(this.baseUrl + endPoint, data, httpHeaders);
  }

  //  --------- upload file ------------- //
  postFormDataApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          // "Content-Type": "application/json",
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          // "Content-Type": "application/json",
          token:
            localStorage.getItem('token') || localStorage.getItem('token-auth'),
        }),
      };
    }
    return this.httpClient.post(this.baseUrl + endPoint, data, httpHeaders);
  }

  /** to prevent first space */
  preventSpace(event) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  // spinner service
  showSpinner() {
    this.spinnerService.show();
  }
  hideSpinner() {
    this.spinnerService.hide();
  }
  // ---------- toaster ----------------- //
  showSuccessToast(msg){
    this.toastrService.success(msg);
  }
  successToast(msg) {
    // this.toastrService.success(msg);
  }
  successToastr(msg){
    this.toastrService.success(msg);
  }
  errorToast(msg) {
    this.toastrService.error(msg);
  }
  infoToast(msg) {
    this.toastrService.info(msg);
  }
  warningToast(msg) {
    this.toastrService.warning(msg);
  }

  alertSuccess(msg) {
    Swal.fire({
      icon: 'success',
      text: msg,
    });
  }

  alertError(msg) {
    Swal.fire({
      icon: 'error',
      text: msg,
    });
  }

  public isLoggedIn(): boolean {
    return localStorage.getItem('token') &&
      localStorage.getItem('userRequestStatus') == 'APPROVED'
      ? true
      : false;
  }
  // public isLoggedIn(): boolean {
  //   return localStorage.getItem("token") ? true : false;
  // }

  /** Call logout function **/
  // logout() {
  //   localStorage.removeItem("token");
  //   // localStorage.removeItem("name");
  //   // localStorage.clear()
  //   this.routes.navigate(["/homepage"]);
  // }

  // third party Api for get ip address
  public getIPAddress() {
    return this.httpClient.get('https://jsonip.com/?format=json');
  }
  public getLocationViaIP(ipAddress): Observable<any> {
    return this.httpClient.get(
      'https://api.ipgeolocation.io/ipgeo?apiKey=5cb98274eb3d46eaa0359cf9cbbedf77&ip=' +
        ipAddress
    );
  }

  // third party Api for get location
  getLocation(url) {
    return this.httpClient.get(url);
  }

  /** Function to restrict character */
  public numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getLatLongFromAddress(address: string): Observable<any> {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${this.publicApikey}`;
    return this.httpClient.get(apiUrl);
  }

  // public getDeviceType() {
  //   const ua = navigator.userAgent;
  //   if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
  //     return "tablet";
  //   }
  //   if (
  //     /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
  //       ua
  //     )
  //   ) {
  //     return "mobile";
  //   }
  //   return "desktop";
  // }
  // incommingMeassageAudio() {
  //   var audio = new Audio("assets/gif/iPhone Message Tone.mp3");
  //   audio.play()
  // }

  // successToast(msg) {
  //   this.toaster.open({
  //     text: msg,
  //     caption: 'Success',
  //     type: 'success',
  //     position: 'top-right',
  //     duration: 5000,
  //   });
  // }
  // errorToast(msg) {
  //   this.toaster.open({
  //     position: 'top-right',
  //     text: msg,
  //     caption: 'Error',
  //     type: 'danger',
  //     duration: 5000,
  //   });
  // }
  // warningToast(msg) {
  //   const type = 'warning';
  //   this.toaster.open({
  //     text: msg,
  //     caption: 'warning',
  //     type: 'warning',
  //     position: 'top-right',
  //     duration: 5000,
  //   });
  // }
  // infoToast(msg) {
  //   this.toaster.open({
  //     text: msg,
  //     caption: 'Info',
  //     type: 'info',
  //     position: 'top-right',
  //     duration: 5000,
  //   });
  // }
  isUserType() {
    if (localStorage.getItem('userType')) {
      return localStorage.getItem('userType');
    }
  }
  public removeDuplicate(arr: any, key1: any) {
    const listOfTags = arr,
      keys = key1,
      filtered = listOfTags.filter(
        (
          (s) => (o) =>
            ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join('|'))
        )(new Set())
      );
    return filtered;
  }
  public backLocation() {
    this._backLocation.back();
  }

  // remove empty key from object
  removeEmptyKey(obj) {
    Object.entries(obj).forEach(([key, val]) => val === '' && delete obj[key]);
    return obj;
  }

  incommingMeassageAudio() {
    var audio = new Audio('assets/gif/iPhone-Message-Tone.mp3');
    audio.play();
  }

  // 'http://localhost:3031/api/v1/user/getConversionRate?currencyCode=INR'
  public getConversionRate(currencyCode) {
    return this.getApi(
      `${'user/getConversionRate?currencyCode=' + currencyCode}`,
      0
    );
  }

  public totalItemsInShoppingBag = new BehaviorSubject<any>(0);
  myShopingBagApi() {
    let apiReqUrl = `${ApiEndPoint.shoppingbagList + '?page=1&limit=100'}`;
    this.getApi(apiReqUrl, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          // this.shoppingBagBuffer = res.result.docs
          this.totalItemsInShoppingBag.next(res.result.total);
        } else {
          this.totalItemsInShoppingBag.next(res.result.total);
        }
      },
      (err) => {
        if (err.responseCode == 404) {
          this.totalItemsInShoppingBag.next(0);
        }
      }
    );
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  getCurrentYearAndMonth() {
    var dateObj = new Date();
    var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    var date = ('0' + dateObj.getDate()).slice(-2);
    var year = dateObj.getFullYear();
    var shortDate = year + '/' + month + '/' + date;
    // alert(shortDate);
    return year + '-' + month;
  }

  // country code array list
  countryListJson: CountryCode[] = [
    {
      code: '+7 840',
      name: 'Abkhazia',
    },
    {
      code: '+93',
      name: 'Afghanistan',
    },
    {
      code: '+355',
      name: 'Albania',
    },
    {
      code: '+213',
      name: 'Algeria',
    },
    {
      code: '+1 684',
      name: 'American Samoa',
    },
    {
      code: '+376',
      name: 'Andorra',
    },
    {
      code: '+244',
      name: 'Angola',
    },
    {
      code: '+1 264',
      name: 'Anguilla',
    },
    {
      code: '+1 268',
      name: 'Antigua and Barbuda',
    },
    {
      code: '+54',
      name: 'Argentina',
    },
    {
      code: '+374',
      name: 'Armenia',
    },
    {
      code: '+297',
      name: 'Aruba',
    },
    {
      code: '+247',
      name: 'Ascension',
    },
    {
      code: '+61',
      name: 'Australia',
    },
    {
      code: '+672',
      name: 'Australian External Territories',
    },
    {
      code: '+43',
      name: 'Austria',
    },
    {
      code: '+994',
      name: 'Azerbaijan',
    },
    {
      code: '+1 242',
      name: 'Bahamas',
    },
    {
      code: '+973',
      name: 'Bahrain',
    },
    {
      code: '+880',
      name: 'Bangladesh',
    },
    {
      code: '+1 246',
      name: 'Barbados',
    },
    {
      code: '+1 268',
      name: 'Barbuda',
    },
    {
      code: '+375',
      name: 'Belarus',
    },
    {
      code: '+32',
      name: 'Belgium',
    },
    {
      code: '+501',
      name: 'Belize',
    },
    {
      code: '+229',
      name: 'Benin',
    },
    {
      code: '+1 441',
      name: 'Bermuda',
    },
    {
      code: '+975',
      name: 'Bhutan',
    },
    {
      code: '+591',
      name: 'Bolivia',
    },
    {
      code: '+387',
      name: 'Bosnia and Herzegovina',
    },
    {
      code: '+267',
      name: 'Botswana',
    },
    {
      code: '+55',
      name: 'Brazil',
    },
    {
      code: '+246',
      name: 'British Indian Ocean Territory',
    },
    {
      code: '+1 284',
      name: 'British Virgin Islands',
    },
    {
      code: '+673',
      name: 'Brunei',
    },
    {
      code: '+359',
      name: 'Bulgaria',
    },
    {
      code: '+226',
      name: 'Burkina Faso',
    },
    {
      code: '+257',
      name: 'Burundi',
    },
    {
      code: '+855',
      name: 'Cambodia',
    },
    {
      code: '+237',
      name: 'Cameroon',
    },
    {
      code: '+1',
      name: 'Canada',
    },
    {
      code: '+238',
      name: 'Cape Verde',
    },
    {
      code: '+ 345',
      name: 'Cayman Islands',
    },
    {
      code: '+236',
      name: 'Central African Republic',
    },
    {
      code: '+235',
      name: 'Chad',
    },
    {
      code: '+56',
      name: 'Chile',
    },
    {
      code: '+86',
      name: 'China',
    },
    {
      code: '+61',
      name: 'Christmas Island',
    },
    {
      code: '+61',
      name: 'Cocos-Keeling Islands',
    },
    {
      code: '+57',
      name: 'Colombia',
    },
    {
      code: '+269',
      name: 'Comoros',
    },
    {
      code: '+242',
      name: 'Congo',
    },
    {
      code: '+243',
      name: 'Congo, Dem. Rep. of (Zaire)',
    },
    {
      code: '+682',
      name: 'Cook Islands',
    },
    {
      code: '+506',
      name: 'Costa Rica',
    },
    {
      code: '+385',
      name: 'Croatia',
    },
    {
      code: '+53',
      name: 'Cuba',
    },
    {
      code: '+599',
      name: 'Curacao',
    },
    {
      code: '+537',
      name: 'Cyprus',
    },
    {
      code: '+420',
      name: 'Czech Republic',
    },
    {
      code: '+45',
      name: 'Denmark',
    },
    {
      code: '+246',
      name: 'Diego Garcia',
    },
    {
      code: '+253',
      name: 'Djibouti',
    },
    {
      code: '+1 767',
      name: 'Dominica',
    },
    {
      code: '+1 809',
      name: 'Dominican Republic',
    },
    {
      code: '+670',
      name: 'East Timor',
    },
    {
      code: '+56',
      name: 'Easter Island',
    },
    {
      code: '+593',
      name: 'Ecuador',
    },
    {
      code: '+20',
      name: 'Egypt',
    },
    {
      code: '+503',
      name: 'El Salvador',
    },
    {
      code: '+240',
      name: 'Equatorial Guinea',
    },
    {
      code: '+291',
      name: 'Eritrea',
    },
    {
      code: '+372',
      name: 'Estonia',
    },
    {
      code: '+251',
      name: 'Ethiopia',
    },
    {
      code: '+500',
      name: 'Falkland Islands',
    },
    {
      code: '+298',
      name: 'Faroe Islands',
    },
    {
      code: '+679',
      name: 'Fiji',
    },
    {
      code: '+358',
      name: 'Finland',
    },
    {
      code: '+33',
      name: 'France',
    },
    {
      code: '+596',
      name: 'French Antilles',
    },
    {
      code: '+594',
      name: 'French Guiana',
    },
    {
      code: '+689',
      name: 'French Polynesia',
    },
    {
      code: '+241',
      name: 'Gabon',
    },
    {
      code: '+220',
      name: 'Gambia',
    },
    {
      code: '+995',
      name: 'Georgia',
    },
    {
      code: '+49',
      name: 'Germany',
    },
    {
      code: '+233',
      name: 'Ghana',
    },
    {
      code: '+350',
      name: 'Gibraltar',
    },
    {
      code: '+30',
      name: 'Greece',
    },
    {
      code: '+299',
      name: 'Greenland',
    },
    {
      code: '+1 473',
      name: 'Grenada',
    },
    {
      code: '+590',
      name: 'Guadeloupe',
    },
    {
      code: '+1 671',
      name: 'Guam',
    },
    {
      code: '+502',
      name: 'Guatemala',
    },
    {
      code: '+224',
      name: 'Guinea',
    },
    {
      code: '+245',
      name: 'Guinea-Bissau',
    },
    {
      code: '+595',
      name: 'Guyana',
    },
    {
      code: '+509',
      name: 'Haiti',
    },
    {
      code: '+504',
      name: 'Honduras',
    },
    {
      code: '+852',
      name: 'Hong Kong SAR China',
    },
    {
      code: '+36',
      name: 'Hungary',
    },
    {
      code: '+354',
      name: 'Iceland',
    },
    {
      code: '+91',
      name: 'India',
    },
    {
      code: '+62',
      name: 'Indonesia',
    },
    {
      code: '+98',
      name: 'Iran',
    },
    {
      code: '+964',
      name: 'Iraq',
    },
    {
      code: '+353',
      name: 'Ireland',
    },
    {
      code: '+972',
      name: 'Israel',
    },
    {
      code: '+39',
      name: 'Italy',
    },
    {
      code: '+225',
      name: 'Ivory Coast',
    },
    {
      code: '+1 876',
      name: 'Jamaica',
    },
    {
      code: '+81',
      name: 'Japan',
    },
    {
      code: '+962',
      name: 'Jordan',
    },
    {
      code: '+7 7',
      name: 'Kazakhstan',
    },
    {
      code: '+254',
      name: 'Kenya',
    },
    {
      code: '+686',
      name: 'Kiribati',
    },
    {
      code: '+965',
      name: 'Kuwait',
    },
    {
      code: '+996',
      name: 'Kyrgyzstan',
    },
    {
      code: '+856',
      name: 'Laos',
    },
    {
      code: '+371',
      name: 'Latvia',
    },
    {
      code: '+961',
      name: 'Lebanon',
    },
    {
      code: '+266',
      name: 'Lesotho',
    },
    {
      code: '+231',
      name: 'Liberia',
    },
    {
      code: '+218',
      name: 'Libya',
    },
    {
      code: '+423',
      name: 'Liechtenstein',
    },
    {
      code: '+370',
      name: 'Lithuania',
    },
    {
      code: '+352',
      name: 'Luxembourg',
    },
    {
      code: '+853',
      name: 'Macau SAR China',
    },
    {
      code: '+389',
      name: 'Macedonia',
    },
    {
      code: '+261',
      name: 'Madagascar',
    },
    {
      code: '+265',
      name: 'Malawi',
    },
    {
      code: '+60',
      name: 'Malaysia',
    },
    {
      code: '+960',
      name: 'Maldives',
    },
    {
      code: '+223',
      name: 'Mali',
    },
    {
      code: '+356',
      name: 'Malta',
    },
    {
      code: '+692',
      name: 'Marshall Islands',
    },
    {
      code: '+596',
      name: 'Martinique',
    },
    {
      code: '+222',
      name: 'Mauritania',
    },
    {
      code: '+230',
      name: 'Mauritius',
    },
    {
      code: '+262',
      name: 'Mayotte',
    },
    {
      code: '+52',
      name: 'Mexico',
    },
    {
      code: '+691',
      name: 'Micronesia',
    },
    {
      code: '+1 808',
      name: 'Midway Island',
    },
    {
      code: '+373',
      name: 'Moldova',
    },
    {
      code: '+377',
      name: 'Monaco',
    },
    {
      code: '+976',
      name: 'Mongolia',
    },
    {
      code: '+382',
      name: 'Montenegro',
    },
    {
      code: '+1664',
      name: 'Montserrat',
    },
    {
      code: '+212',
      name: 'Morocco',
    },
    {
      code: '+95',
      name: 'Myanmar',
    },
    {
      code: '+264',
      name: 'Namibia',
    },
    {
      code: '+674',
      name: 'Nauru',
    },
    {
      code: '+977',
      name: 'Nepal',
    },
    {
      code: '+31',
      name: 'Netherlands',
    },
    {
      code: '+599',
      name: 'Netherlands Antilles',
    },
    {
      code: '+1 869',
      name: 'Nevis',
    },
    {
      code: '+687',
      name: 'New Caledonia',
    },
    {
      code: '+64',
      name: 'New Zealand',
    },
    {
      code: '+505',
      name: 'Nicaragua',
    },
    {
      code: '+227',
      name: 'Niger',
    },
    {
      code: '+234',
      name: 'Nigeria',
    },
    {
      code: '+683',
      name: 'Niue',
    },
    {
      code: '+672',
      name: 'Norfolk Island',
    },
    {
      code: '+850',
      name: 'North Korea',
    },
    {
      code: '+1 670',
      name: 'Northern Mariana Islands',
    },
    {
      code: '+47',
      name: 'Norway',
    },
    {
      code: '+968',
      name: 'Oman',
    },
    {
      code: '+92',
      name: 'Pakistan',
    },
    {
      code: '+680',
      name: 'Palau',
    },
    {
      code: '+970',
      name: 'Palestinian Territory',
    },
    {
      code: '+507',
      name: 'Panama',
    },
    {
      code: '+675',
      name: 'Papua New Guinea',
    },
    {
      code: '+595',
      name: 'Paraguay',
    },
    {
      code: '+51',
      name: 'Peru',
    },
    {
      code: '+63',
      name: 'Philippines',
    },
    {
      code: '+48',
      name: 'Poland',
    },
    {
      code: '+351',
      name: 'Portugal',
    },
    {
      code: '+1 787',
      name: 'Puerto Rico',
    },
    {
      code: '+974',
      name: 'Qatar',
    },
    {
      code: '+262',
      name: 'Reunion',
    },
    {
      code: '+40',
      name: 'Romania',
    },
    {
      code: '+7',
      name: 'Russia',
    },
    {
      code: '+250',
      name: 'Rwanda',
    },
    {
      code: '+685',
      name: 'Samoa',
    },
    {
      code: '+378',
      name: 'San Marino',
    },
    {
      code: '+966',
      name: 'Saudi Arabia',
    },
    {
      code: '+221',
      name: 'Senegal',
    },
    {
      code: '+381',
      name: 'Serbia',
    },
    {
      code: '+248',
      name: 'Seychelles',
    },
    {
      code: '+232',
      name: 'Sierra Leone',
    },
    {
      code: '+65',
      name: 'Singapore',
    },
    {
      code: '+421',
      name: 'Slovakia',
    },
    {
      code: '+386',
      name: 'Slovenia',
    },
    {
      code: '+677',
      name: 'Solomon Islands',
    },
    {
      code: '+27',
      name: 'South Africa',
    },
    {
      code: '+500',
      name: 'South Georgia and the South Sandwich Islands',
    },
    {
      code: '+82',
      name: 'South Korea',
    },
    {
      code: '+34',
      name: 'Spain',
    },
    {
      code: '+94',
      name: 'Sri Lanka',
    },
    {
      code: '+249',
      name: 'Sudan',
    },
    {
      code: '+597',
      name: 'Suriname',
    },
    {
      code: '+268',
      name: 'Swaziland',
    },
    {
      code: '+46',
      name: 'Sweden',
    },
    {
      code: '+41',
      name: 'Switzerland',
    },
    {
      code: '+963',
      name: 'Syria',
    },
    {
      code: '+886',
      name: 'Taiwan',
    },
    {
      code: '+992',
      name: 'Tajikistan',
    },
    {
      code: '+255',
      name: 'Tanzania',
    },
    {
      code: '+66',
      name: 'Thailand',
    },
    {
      code: '+670',
      name: 'Timor Leste',
    },
    {
      code: '+228',
      name: 'Togo',
    },
    {
      code: '+690',
      name: 'Tokelau',
    },
    {
      code: '+676',
      name: 'Tonga',
    },
    {
      code: '+1 868',
      name: 'Trinidad and Tobago',
    },
    {
      code: '+216',
      name: 'Tunisia',
    },
    {
      code: '+90',
      name: 'Turkey',
    },
    {
      code: '+993',
      name: 'Turkmenistan',
    },
    {
      code: '+1 649',
      name: 'Turks and Caicos Islands',
    },
    {
      code: '+688',
      name: 'Tuvalu',
    },
    {
      code: '+1 340',
      name: 'U.S. Virgin Islands',
    },
    {
      code: '+256',
      name: 'Uganda',
    },
    {
      code: '+380',
      name: 'Ukraine',
    },
    {
      code: '+971',
      name: 'United Arab Emirates',
    },
    {
      code: '+44',
      name: 'United Kingdom',
    },
    {
      code: '+1',
      name: 'United States',
    },
    {
      code: '+598',
      name: 'Uruguay',
    },
    {
      code: '+998',
      name: 'Uzbekistan',
    },
    {
      code: '+678',
      name: 'Vanuatu',
    },
    {
      code: '+58',
      name: 'Venezuela',
    },
    {
      code: '+84',
      name: 'Vietnam',
    },
    {
      code: '+1 808',
      name: 'Wake Island',
    },
    {
      code: '+681',
      name: 'Wallis and Futuna',
    },
    {
      code: '+967',
      name: 'Yemen',
    },
    {
      code: '+260',
      name: 'Zambia',
    },
    {
      code: '+255',
      name: 'Zanzibar',
    },
    {
      code: '+263',
      name: 'Zimbabwe',
    },
  ];

  size = Array.from(Array(19).keys());
  public removeTimeZone(e: string) {
    return e.replace('Z', '');
  }
}
