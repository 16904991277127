import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
declare var $
@Component({
  selector: 'app-customer-product-address',
  templateUrl: './customer-product-address.component.html',
  styleUrls: ['./customer-product-address.component.scss']
})
export class CustomerProductAddressComponent implements OnInit {
  _id: any
  qtyA: any
  serviceData: any
  Ids: any
  headingName:any ='Choose Delivery Address'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    { name:'Cart',route: '/addtoCart-customer'},
    { name:'Address',route: '/customer-address-list'}
  ]
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })

  }


  ngOnInit(): void {
    // this.getSimiliarProduct();
    this.listAddress();
    this.Ids = JSON.parse(localStorage.getItem('Ids'))

  }
  getSimiliarProduct() {
    let apiReqUrl: any = `product/productMightLike`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.serviceData = res.result.docs;
        $(document).ready(function () {
          $('.service_slider_add').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  listAddressData: any = []
  listAddress() {
    let apiReqUrl = `user/listAddress`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.listAddressData = res.result.docs;
        let temp = res.result.docs.filter((ele) => {
          return ele.isPrimary
        })
        this.addressId = temp[0]?._id
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  navigateToEdit(id) {
    this.router.navigate(['/edit-address-customer'], { queryParams: { id: id } })
  }
  addressId: any

  toAddressId(id) {
    this.addressId = id
  }
  navigateToOrderOverview() {
    if(!this.addressId){
      return this.commonService.errorToast('Please select address.')
    }
    this.router.navigate(['/order-overview-customer'], { queryParams: { idOfAddress: this.addressId } })
  }
  addId: any
  isPrimary: any
  addressModals(id, prmy) {
    this.addId = id,
      this.isPrimary = prmy
    $('#addressModal').modal('show')
  }

  deleteAddress() {
    let apiReqUrl = `user/deleteAddress?addressId=${this.addId}`
    if (this.isPrimary == true) {
      return this.commonService.errorToast('You can not delete primary address')

    } else {
      this.commonService.showSpinner();
      this.commonService.deleteApi(apiReqUrl, 1).subscribe((res: any) => {
        if (res.responseCode == 200) {
          // this.listAddressData = res.result.docs;
          $('#addressModal').modal('hide')
          this.listAddress()
          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage)
        }
      })
    }
  }
  setPrimaryAddress(id){
    let url = `user/markAddressPrimary`
    let data = {
      "addressId": id,
      //  "isPrimary": true
    }
    this.commonService.showSpinner();
    this.commonService.putApi(url,data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        if(res.result._id != 0){
          localStorage.setItem('primaryAddressId', res.result._id)
        }
        this.listAddress()
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    },(err:any)=>{
      this.commonService.hideSpinner();
    })
   
  }

}
