export const ApiEndPoint = {
    // logo
    viewLogo: 'static/viewLogo',
    headerList: 'static/headerList',
  
    signup: 'user/usersRegistration',
    login: 'user/usersLogin',
    forgotPassword: 'user/forgotPassword',
    resetPassword: 'user/resetPassword',
    verifyOtp: 'user/verifyOTP',
    resendOtp: 'user/resendOTP',
    changePassword: 'user/changeUserPassword',
    userProfile: 'user/usersProfile',
    userEditProfile: 'user/editProfile',
    countryList: 'product/countryList',
    stateList: 'product/stateList',
    uploadFile: 'product/uploadFile',
  
    // dashboard
    dashboard: 'admin/mainDashBoard',
  
    // address
    addAddress: 'user/addAddress',
    listAddress: 'user/listAddress',
    deleteAddress: 'user/deleteAddress',
    viewAddress: 'user/viewAddress',
    editAddress: 'user/editAddress',
  
    // visitor management
    addVisitor: 'admin/visitorAdd',
    listVisitor: 'admin/listVisitors',
    viewVisitor: 'admin/viewVisitors',
    deleteVisitor: 'admin/deleteVisitor',
  
    // appointment
    listAppointment: 'user/listUserAppointment',
    confirmAppointment: 'user/confirmAppointment',
    // http://localhost:3031/api/v1/user/listUserAppointment?email=rajat%40mailinator.com
  
    // wishlist
    listWishlist: 'user/WishList',
    removeWishlist: 'user/removeWishlist',
  
    // coupon
    listCoupon: 'user/couponListForInfluencer',
  
    // collection
    productListWithFilter: 'user/productListWithFilter', // not in use
    listNewProductWithFilter: 'user/listNewProductWithFilter',
  
    // Dress Collection
    listSkirt: 'product/listSkirt',
    listBodice: 'product/listBodice',
    listColor: 'admin/listColor',
    listPremiumColor: 'admin/listPremiumColor',
    listEmbellishment: 'admin/listEmbellishmentWithoutpagination',
  
    //whistlist
    addWishListProduct: 'user/addWishListProduct',
    // userViewProduct: 'user/userViewProduct',
    userViewNewProduct: 'user/userViewNewProduct',
  
    //shopping bag
    addToBag: 'user/addToBag',
    shoppingbagList: 'user/bagList',
    bagDelete: 'user/bagDelete',
    addEvent: 'user/addEvent',
    updateBag: 'user/editToBag',
  
    //faq
    faqList: 'static/faqList',
    viewBrides: 'story/viewBrides',
    // combination
    viewCustomizeProduct: 'product/viewCustomizeProduct',
    listSwatchesWithoutPagination: 'product/listSwatchesWithoutPagination',
    viewHeaderLink: 'static/viewHeaderLink',
  
    // social shoping
    addGroup: 'group/addGroup', // creating group/curation
    generateLink: 'group/generateLink', // generate link to share with friends and family members
    addMember: 'group/addMember', // join other member in group chat
    selectIndex: 'group/selectIndex',
    removeIndex: 'group/removeIndex',
  
    addProductInGroup: 'group/addProductInGroup',
    productListInGroup: 'group/productListInGroup',
    // social shoping multiple address 
    createMultipleOrder: 'group/createMultipleOrder',
  
    //static content
    privacyPolicies: 'static/viewStaticContent?type=PrivacyPolicies',
    termsAndConditions: 'static/viewStaticContent?type=termAndConditions',
    returnPolicy: 'static/viewStaticContent?type=returnPolicy',
  
    createOrder: 'user/createOrder',
    viewOrder: 'user/viewOrder',
    checkOut: 'user/checkOut',
  
    // customize
    listCollection: 'product/listCollection',
    viewCollection: 'product/viewCollection'
  };
  