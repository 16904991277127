import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-forgot-password-retailer',
  templateUrl: './forgot-password-retailer.component.html',
  styleUrls: ['./forgot-password-retailer.component.scss']
})
export class ForgotPasswordRetailerComponent implements OnInit {
  forgotPassword!: FormGroup;

  userType: any = 'RETAILER';

  constructor(private fb: FormBuilder, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.forgot();
  }

  forgot() {
    this.forgotPassword = this.fb.group({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
    })
  }



  add() {
    var data = {
      email: this.forgotPassword.value.email,
      userType: 'RETAILER'
    }
    localStorage.setItem('email', data['email'])
    this.commonService.showSpinner()
    this.commonService.postApi('user/forgotPassword', data, 0).subscribe((res: any) => {
      
      this.commonService.hideSpinner()
      if (res['responseCode'] == 200) {
        localStorage.setItem('email', this.forgotPassword.value.email)
        this.commonService.hideSpinner()
        this.commonService.successToast(res['responseMessage'])
        // set current url to localstorage 
        this.router.navigate(['/otp-verification-retailer']);
      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res['responseMessage'])
      }
    }, err => {
      
      if (err['responseCode'] == 404) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err['responseMessage'])
      }
      else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err['responseMessage'])
      }
    })
  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

}
