import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
import { AuthService } from 'src/app/provide/auth.service';
import jwt_decode from 'jwt-decode';
// import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
// import { AngularFirestore } from '@angular/fire/firestore';

import { timer } from 'rxjs';
import { Observable } from 'rxjs';
declare var google;
declare const gapi: any;
declare var $;
declare var google: any;
interface Item {
  name: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login-customer.component.html',
  styleUrls: ['./login-customer.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm!: FormGroup;
  auth2: any;
  @ViewChild('loginRef', { static: true }) loginElement!: ElementRef;
  user: any;
  visible: boolean = false;
  changetype: boolean = true;
  route: any;
  userType: any = 'CUSTOMER';
  title: any = 'Customer';
  serviceRole: string;
  isSubmitted = false;

  showPassword: boolean = true;
  googleLoginOptions = {
    scope: 'snehajha.jh@gmail.com',
  };
  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  item$: Observable<any[]>;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    public commonService: CommonService,
    public authService: AuthService
  ) {
    this.commonService.userTypeSelectionUpdate.subscribe((value) => {
      this.userType = this.commonService.isUserType();
      this.isHeadingTitle(this.userType);
    });
    this.userType = this.commonService.isUserType();
    this.isHeadingTitle(this.userType);
    sessionStorage.removeItem('googleApi');
    // this.item$ = this.firestore.collection('items').valueChanges();
  }

  //RETAILER // CUSTOMER  // SERVICE_PROVIDER
  isHeadingTitle(userType) {
    if (userType == 'CUSTOMER') {
      this.title = 'Customer';
    }
    if (userType == 'RETAILER') {
      this.title = 'Retailer';
    }
    if (userType == 'SERVICE_PROVIDER') {
      this.serviceRole = localStorage.getItem('serviceRole');
      if (this.serviceRole == 'PICKUP') {
        this.title = 'Pickup Partner';
        this.userType = 'PICKUP_PARTNER';
      } else if (this.serviceRole == 'DELIVERY') {
        this.title = 'Delivery Partner';
        this.userType = 'DELIVERY_PARTNER';
      } else if (this.serviceRole == 'FIELD') {
        this.title = 'Fulfilment Entity';
        this.userType = 'FIELD_ENTITY';
      } else {
        this.title = 'Service Provider';
      }
    }
  }
  ngOnInit(): void {
    // this.social.authState.subscribe((user) => {
    //   this.socialUser = user;
    //   console.log(this.socialUser,"zxzxzx");
    // });
    // this.initgoogle();
    window.scrollTo(0, 0);
    this.loginForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
        ),
      ]),
      password: new FormControl('', [Validators.required]),
      checkbox: new FormControl(false),
    });
    let checkbox = localStorage.getItem('customerLogin')
      ? JSON.parse(localStorage.getItem('customerLogin')!)
      : '';
    if (checkbox) {
      this.loginForm.patchValue({
        email: atob(checkbox.email),
        password: atob(checkbox.password),
        checkbox: checkbox.checkbox,
      });
    }
  }
  signUpUser() {
    this.router.navigate([this.commonService.routingSinup(this.userType)]);
  }
  login() {
    if (this.loginForm.invalid) {
      this.loading = false;
      this.isSubmitted = true;
      return;
    }
    let apiReqData = {
      userType: this.userType,
      emailNumberId: String(this.loginForm.value.email).toLowerCase(),
      password: this.loginForm.value.password,
    };
    let apiReqUrl = `user/usersLogin`;
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          // localStorage.setItem('token', res.result.token);
          localStorage.setItem('userType', this.userType);
          localStorage.setItem('userId', res.result._id);
          localStorage.setItem('userDetail', JSON.stringify(res?.result));
          localStorage.setItem(
            'campainPrefrences',
            res.result.campainPrefrences
          );
          if (
            this.userType === res.result.userType &&
            this.userType == 'CUSTOMER'
          ) {
            this.commonService.hideSpinner();
            localStorage.setItem('token', res.result.token);
            localStorage.setItem('userType', this.userType);
            localStorage.setItem('userId', res.result._id);
            localStorage.setItem(
              'email',
              String(this.loginForm.value.email).toLowerCase()
            );
            if (res.result.primaryAddressId != 0) {
              localStorage.setItem(
                'primaryAddressId',
                res.result.primaryAddressId
              );
            }
            localStorage.setItem('completeProfile', 'true');
            this.authService.SharingData.next(true);
            this.authService.isLoggedIn.next(true);
            this.authService.loggedInUserType.next(this.userType);
            localStorage.setItem(
              'userRequestStatus',
              res.result.userRequestStatus
            );
            this.authService.userRequestStatus.next(
              res.result.userRequestStatus
            );
            this.router.navigate(['/home']);
            if (this.loginForm.value.checkbox == true) {
              let remData = {
                email: btoa(String(this.loginForm.value.email).toLowerCase()),
                password: btoa(this.loginForm.value.password),
                checkbox: this.loginForm.value.checkbox,
              };
              localStorage.setItem('customerLogin', JSON.stringify(remData));
            } else {
              localStorage.removeItem('customerLogin');
            }
            if (!res.result.otpVerification) {
              return this.router.navigate(['/otp-verification-customer']);
            }
          }
          if (
            this.userType === res.result.userType &&
            (this.userType == 'SERVICE_PROVIDER' ||
              this.userType == 'PICKUP_PARTNER' ||
              this.userType == 'DELIVERY_PARTNER' ||
              this.userType == 'FIELD_ENTITY')
          ) {
            this.commonService.hideSpinner();
            // localStorage.setItem('token-auth', res.result.token);
            localStorage.setItem('userId', res.result._id);
            localStorage.setItem(
              'email',
              String(this.loginForm.value.email).toLowerCase()
            );
            localStorage.setItem('completeProfile', res.result.completeProfile);
            this.authService.SharingData.next(res.result.completeProfile);

            this.authService.loggedInUserType.next('SERVICE_PROVIDER');
            localStorage.setItem(
              'userRequestStatus',
              res.result.userRequestStatus
            );
            this.authService.userRequestStatus.next(
              res.result.userRequestStatus
            );
            if (
              res.result.completeProfile &&
              res.result.userRequestStatus == 'APPROVED'
            ) {
              localStorage.setItem('token', res['result']['token']);
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              localStorage.setItem('myId', res['result']['_id']);
              this.router.navigate(['/service-order-detail']);
              this.authService.isLoggedIn.next(true);
            } else if (
              res.result.completeProfile &&
              res.result.userRequestStatus == 'PENDING' &&
              res.result.flag == 3
            ) {
              this.commonService.errorToast(
                'Thanks For Connecting With Us. We will verify your account and will send your approval soon.'
              );
              this.loading = false;
            } else if (
              !res.result.completeProfile &&
              res.result.userRequestStatus == 'PENDING' &&
              res.result.flag == 0
            ) {
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              localStorage.setItem('token-auth', res['result']['token']);
              this.router.navigate(['/business-details-service-provider']);
            } else if (
              !res.result.completeProfile &&
              res.result.userRequestStatus == 'PENDING' &&
              res.result.flag == 1
            ) {
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              localStorage.setItem('token-auth', res['result']['token']);
              this.router.navigate(['/upload-document-service-provider']);
            } else if (
              res.result.completeProfile &&
              res.result.userRequestStatus == 'REJECTED'
            ) {
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              localStorage.setItem('token-auth', res['result']['token']);
              this.router.navigate(['/upload-document-service-provider']);
              if (res.responseCode == 200) {
                this.commonService.errorToast(res.result.rejectReason);
              } else {
                this.commonService.errorToast(
                  'Your application has been rejected. Plesae complete the application and then proceed'
                );
              }
            } else if (
              !res.result.completeProfile &&
              res.result.userRequestStatus == 'REJECTED'
            ) {
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              localStorage.setItem('token-auth', res['result']['token']);
              this.router.navigate(['/business-details-service-provider']);
              if (res.responseCode == 200) {
                this.commonService.errorToast(res.result.rejectReason);
              } else {
                this.commonService.errorToast(
                  'Your application has been rejected. Plesae complete the application and then proceed'
                );
              }
            }
            if (this.loginForm.value.checkbox == true) {
              let remData = {
                email: btoa(String(this.loginForm.value.email).toLowerCase()),
                password: btoa(this.loginForm.value.password),
                checkbox: this.loginForm.value.checkbox,
              };
              localStorage.setItem('serviceLogin', JSON.stringify(remData));
            } else {
              localStorage.removeItem('serviceLogin');
            }
          }
          if (
            this.userType === res.result.userType &&
            this.userType == 'RETAILER'
          ) {
            this.commonService.hideSpinner();
            if (!res.result.otpVerification) {
            }
            // localStorage.setItem('token', res.result.token);
            localStorage.setItem('userId', res.result._id);
            localStorage.setItem('email', this.loginForm.value.email);
            if (res.result.primaryAddressId != 0) {
              localStorage.setItem(
                'primaryAddressId',
                res.result.primaryAddressId
              );
            }
            localStorage.setItem('completeProfile', res.result.completeProfile);
            localStorage.setItem(
              'userRequestStatus',
              res.result.userRequestStatus
            );
            this.authService.SharingData.next(res.result.completeProfile);
            this.authService.userRequestStatus.next(
              res.result.userRequestStatus
            );

            this.authService.loggedInUserType.next('RETAILER');
            if (
              res.result.completeProfile &&
              res.result.userRequestStatus == 'APPROVED'
            ) {
              localStorage.setItem('token', res['result']['token']);
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              localStorage.setItem('myId', res['result']['_id']);
              this.authService.isLoggedIn.next(true);
              window.location.href = '/retailer-home';
              // this.router.navigate(['/retailer-home']);
            } else if (
              res.result.completeProfile &&
              res.result.userRequestStatus == 'PENDING' &&
              res.result.flag == 2
            ) {
              this.commonService.errorToast(
                'Thanks For Connecting With Us. We will verify your account and will send your approval soon.'
              );
              this.loading = false;
              // this.authService.logout();
            } else if (
              !res.result.completeProfile &&
              res.result.userRequestStatus == 'PENDING' &&
              res.result.flag == 0
            ) {
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              localStorage.setItem('token-auth', res['result']['token']);
              this.router.navigate(['/business-details-retailer']);
            } else if (
              !res.result.completeProfile &&
              res.result.userRequestStatus == 'PENDING' &&
              res.result.flag == 1
            ) {
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              localStorage.setItem('token-auth', res['result']['token']);
              this.router.navigate(['/upload-document-retailer']);
            } else if (
              res.result.completeProfile &&
              res.result.userRequestStatus == 'REJECTED'
            ) {
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              localStorage.setItem('token-auth', res['result']['token']);
              this.router.navigate(['/upload-document-retailer']);
              if (res.responseCode == 200) {
                this.commonService.errorToast(res.result.rejectReason);
              } else {
                this.commonService.errorToast(
                  'Your application has been rejected. Plesae complete the application and then proceed'
                );
              }
            } else if (
              !res.result.completeProfile &&
              res.result.userRequestStatus == 'REJECTED'
            ) {
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              localStorage.setItem('token-auth', res['result']['token']);
              this.router.navigate(['/business-details-retailer']);
              if (res.responseCode == 200) {
                this.commonService.errorToast(res.result.rejectReason);
              } else {
                this.commonService.errorToast(
                  'Your application has been rejected. Plesae complete the application and then proceed'
                );
              }
            } else if (res.result.userRequestStatus == 'PENDING') {
              this.commonService.errorToast(
                'Thanks For Connecting With Us. We will verify your account and will send your approval soon.'
              );
            }

            if (this.loginForm.value.checkbox == true) {
              let remData = {
                email: btoa(String(this.loginForm.value.email).toLowerCase()),
                password: btoa(this.loginForm.value.password),
                checkbox: this.loginForm.value.checkbox,
              };
              localStorage.setItem('retailerLogin', JSON.stringify(remData));
            } else {
              localStorage.removeItem('retailerLogin');
            }
          }
        } else {
          this.loading = false;
          this.commonService.hideSpinner();
          this.commonService.errorToast(res['responseMessage']);
        }
      },
      (err: any) => {
        this.loading = false;
        if (err.responseCode == 402) {
          this.commonService.hideSpinner();
          this.commonService.errorToast(err.responseMessage);
        } else if (err.responseCode == 404) {
          this.commonService.hideSpinner();
          this.commonService.errorToast(err.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(err.responseMessage);
        }
      }
    );
  }
  preventSpace(event: any) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault();
    }
  }

  loading = false;

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;
    }, 60000); // 1 minute
  }
  changePasswordIcon() {
    this.showPassword = !this.showPassword;
  }

  decodedToken: any;
  signInWithGoogle(token) {
    const jwtToken: any = token;
    this.decodedToken = jwt_decode(jwtToken);
    // debugger
    console.log(this.decodedToken);

    this.signInGoogle();
  }
  // signInWithGoogle(): void {
  // console.log("dd")
  // this.googleService.signIn(GoogleLoginProvider.PROVIDER_ID)
  //   .then(user => {
  //     this.user = user;
  //     console.log(user);
  //   })
  //   .catch(error => console.error(error));
  // }
  // signInWithGoogle() {
  //   gapi.auth2.getAuthInstance().signIn();
  //   console.log("hjhjhj")
  // }
  ngAfterViewInit() {
    this.initgoogle();
  }
  initgoogle() {
    try {
      google.accounts.id.initialize({
        client_id:
          '360894627388-4dhjgbc38cfss5idmjqt17uqufhr75gs.apps.googleusercontent.com',
        callback: (response) => {
          //handle response in here
          this.signInWithGoogle(response.credential);
          // console.log(response.credential, 'responseee');
        },
      });
      google.accounts.id.renderButton(document.getElementById('buttonDiv'), {
        theme: 'outline', // customization attributes

        size: 'large', // or 'standard' or 'small'
        shape: 'rectangular', // or 'pill'
        text: 'sign_in_with', // or 'sign_in_with'
        logo_alignment: 'center', // or 'center' or 'right'
        width: 700, // or specify a fixed width in pixels
        height: 'standard', // or 'tall'
        longtitle: true, // or false
        // background: 'pink',

        prompt_parent_id: 'customPromptDiv', // specify a custom parent for the prompt
        custom_elements: [{ 'data-prompt_parent_id': 'customPromptDiv' }], // additional custom elements
      });
      google.accounts.id.prompt();
    } catch (error) {
      window.location.reload();
    }
  }

  signInGoogle() {
    const url = 'user/socialSignin';
    const reqObj = {
      userType: this.userType,
      email: this.decodedToken.email,
      firstName: this.decodedToken.given_name,
      lastName: this.decodedToken.family_name,
      profilePic: this.decodedToken.picture,
      deviceType: 'WEB',
    };
    this.commonService.postApi(url, reqObj, 0).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          if (res.result.isAlreadyUser) {
            localStorage.setItem('userType', this.userType);
            localStorage.setItem('userId', res.result._id);
            localStorage.setItem(
              'campainPrefrences',
              res.result.campainPrefrences
            );
            if (
              this.userType === res.result.userType &&
              this.userType == 'CUSTOMER'
            ) {
              this.commonService.hideSpinner();
              localStorage.setItem('token', res.result.token);
              localStorage.setItem('userType', this.userType);
              localStorage.setItem('userId', res.result._id);
              localStorage.setItem(
                'email',
                String(this.loginForm.value.email).toLowerCase()
              );
              if (res.result.primaryAddressId != 0) {
                localStorage.setItem(
                  'primaryAddressId',
                  res.result.primaryAddressId
                );
              }
              localStorage.setItem('completeProfile', 'true');
              this.authService.SharingData.next(true);
              this.authService.isLoggedIn.next(true);
              this.authService.loggedInUserType.next(this.userType);
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              this.authService.userRequestStatus.next(
                res.result.userRequestStatus
              );
              this.router.navigate(['/home']);
              if (this.loginForm.value.checkbox == true) {
                let remData = {
                  email: btoa(String(this.loginForm.value.email).toLowerCase()),

                  checkbox: this.loginForm.value.checkbox,
                };
                localStorage.setItem('customerLogin', JSON.stringify(remData));
              } else {
                localStorage.removeItem('customerLogin');
              }
            }
            if (
              this.userType === res.result.userType &&
              (this.userType == 'SERVICE_PROVIDER' ||
                this.userType == 'PICKUP_PARTNER' ||
                this.userType == 'DELIVERY_PARTNER' ||
                this.userType == 'FIELD_ENTITY')
            ) {
              this.commonService.hideSpinner();
              // localStorage.setItem('token-auth', res.result.token);
              localStorage.setItem('userId', res.result._id);
              localStorage.setItem(
                'email',
                String(this.loginForm.value.email).toLowerCase()
              );
              localStorage.setItem(
                'completeProfile',
                res.result.completeProfile
              );
              this.authService.SharingData.next(res.result.completeProfile);

              this.authService.loggedInUserType.next('SERVICE_PROVIDER');
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              this.authService.userRequestStatus.next(
                res.result.userRequestStatus
              );
              if (
                res.result.completeProfile &&
                res.result.userRequestStatus == 'APPROVED'
              ) {
                localStorage.setItem('token', res['result']['token']);
                localStorage.setItem(
                  'userRequestStatus',
                  res.result.userRequestStatus
                );
                localStorage.setItem('myId', res['result']['_id']);
                this.router.navigate(['/service-order-detail']);
                this.authService.isLoggedIn.next(true);
              } else if (
                res.result.completeProfile &&
                res.result.userRequestStatus == 'PENDING' &&
                res.result.flag == 2
              ) {
                this.commonService.errorToast(
                  'Thanks For Connecting With Us. We will verify your account and will send your approval soon.'
                );
              } else if (
                !res.result.completeProfile &&
                res.result.userRequestStatus == 'PENDING' &&
                res.result.flag == 0
              ) {
                localStorage.setItem(
                  'userRequestStatus',
                  res.result.userRequestStatus
                );
                localStorage.setItem('token-auth', res['result']['token']);
                window.location.href = '/business-details-service-provider';
                // this.router.navigate(['/business-details-service-provider']);
              } else if (
                !res.result.completeProfile &&
                res.result.userRequestStatus == 'PENDING' &&
                res.result.flag == 1
              ) {
                localStorage.setItem(
                  'userRequestStatus',
                  res.result.userRequestStatus
                );
                localStorage.setItem('token-auth', res['result']['token']);
                this.router.navigate(['/upload-document-service-provider']);
              } else if (
                res.result.completeProfile &&
                res.result.userRequestStatus == 'REJECTED'
              ) {
                localStorage.setItem(
                  'userRequestStatus',
                  res.result.userRequestStatus
                );
                localStorage.setItem('token-auth', res['result']['token']);
                this.router.navigate(['/upload-document-service-provider']);
                if (res.responseCode == 200) {
                  this.commonService.errorToast(res.result.rejectReason);
                } else {
                  this.commonService.errorToast(
                    'Your application has been rejected. Plesae complete the application and then proceed'
                  );
                }
              } else if (
                !res.result.completeProfile &&
                res.result.userRequestStatus == 'REJECTED'
              ) {
                localStorage.setItem(
                  'userRequestStatus',
                  res.result.userRequestStatus
                );
                localStorage.setItem('token-auth', res['result']['token']);
                // this.router.navigate(['/business-details-service-provider']);
                window.location.href = '/business-details-service-provider';
                if (res.responseCode == 200) {
                  this.commonService.errorToast(res.result.rejectReason);
                } else {
                  this.commonService.errorToast(
                    'Your application has been rejected. Plesae complete the application and then proceed'
                  );
                }
              }
              if (this.loginForm.value.checkbox == true) {
                let remData = {
                  email: btoa(String(this.loginForm.value.email).toLowerCase()),
                  password: btoa(this.loginForm.value.password),
                  checkbox: this.loginForm.value.checkbox,
                };
                localStorage.setItem('serviceLogin', JSON.stringify(remData));
              } else {
                localStorage.removeItem('serviceLogin');
              }
            }
            if (
              this.userType === res.result.userType &&
              this.userType == 'RETAILER'
            ) {
              this.commonService.hideSpinner();
              if (!res.result.otpVerification) {
              }
              // localStorage.setItem('token', res.result.token);
              localStorage.setItem('userId', res.result._id);
              localStorage.setItem('email', this.loginForm.value.email);
              if (res.result.primaryAddressId != 0) {
                localStorage.setItem(
                  'primaryAddressId',
                  res.result.primaryAddressId
                );
              }
              localStorage.setItem(
                'completeProfile',
                res.result.completeProfile
              );
              localStorage.setItem(
                'userRequestStatus',
                res.result.userRequestStatus
              );
              this.authService.SharingData.next(res.result.completeProfile);
              this.authService.userRequestStatus.next(
                res.result.userRequestStatus
              );

              this.authService.loggedInUserType.next('RETAILER');
              if (
                res.result.completeProfile &&
                res.result.userRequestStatus == 'APPROVED'
              ) {
                localStorage.setItem('token', res['result']['token']);
                localStorage.setItem(
                  'userRequestStatus',
                  res.result.userRequestStatus
                );
                localStorage.setItem('myId', res['result']['_id']);

                // this.router.navigate(['/retailer-home']);
                window.location.href = '/retailer-home';
                this.authService.isLoggedIn.next(true);
              } else if (
                res.result.completeProfile &&
                res.result.userRequestStatus == 'PENDING' &&
                res.result.flag == 2
              ) {
                this.commonService.errorToast(
                  'Thanks For Connecting With Us. We will verify your account and will send your approval soon.'
                );
                // this.authService.logout();
                window.location.reload();
              } else if (
                !res.result.completeProfile &&
                res.result.userRequestStatus == 'PENDING' &&
                res.result.flag == 0
              ) {
                localStorage.setItem(
                  'userRequestStatus',
                  res.result.userRequestStatus
                );
                localStorage.setItem('token-auth', res['result']['token']);
                // this.router.navigate(['/business-details-retailer']);
                window.location.href = '/business-details-retailer';
              } else if (
                !res.result.completeProfile &&
                res.result.userRequestStatus == 'PENDING' &&
                res.result.flag == 1
              ) {
                localStorage.setItem(
                  'userRequestStatus',
                  res.result.userRequestStatus
                );
                localStorage.setItem('token-auth', res['result']['token']);
                // this.router.navigate(['/upload-document-retailer']);
                window.location.href = '/upload-document-retailer';
              } else if (
                res.result.completeProfile &&
                res.result.userRequestStatus == 'REJECTED'
              ) {
                localStorage.setItem(
                  'userRequestStatus',
                  res.result.userRequestStatus
                );
                localStorage.setItem('token-auth', res['result']['token']);
                this.router.navigate(['/upload-document-retailer']);
                if (res.responseCode == 200) {
                  this.commonService.errorToast(res.result.rejectReason);
                } else {
                  this.commonService.errorToast(
                    'Your application has been rejected. Plesae complete the application and then proceed'
                  );
                }
              } else if (
                !res.result.completeProfile &&
                res.result.userRequestStatus == 'REJECTED'
              ) {
                localStorage.setItem(
                  'userRequestStatus',
                  res.result.userRequestStatus
                );
                localStorage.setItem('token-auth', res['result']['token']);
                window.location.href = '/business-details-retailer';
                if (res.responseCode == 200) {
                  this.commonService.errorToast(res.result.rejectReason);
                } else {
                  this.commonService.errorToast(
                    'Your application has been rejected. Plesae complete the application and then proceed'
                  );
                }
              } else if (res.result.userRequestStatus == 'PENDING') {
                this.commonService.errorToast(
                  'Thanks For Connecting With Us. We will verify your account and will send your approval soon.'
                );
              }

              if (this.loginForm.value.checkbox == true) {
                let remData = {
                  email: btoa(String(this.loginForm.value.email).toLowerCase()),
                  password: btoa(this.loginForm.value.password),
                  checkbox: this.loginForm.value.checkbox,
                };
                localStorage.setItem('retailerLogin', JSON.stringify(remData));
              } else {
                localStorage.removeItem('retailerLogin');
              }
            }
          } else {
            let data = {
              email: res.result.email,
              userType: res.result.userType,
              firstName: res.result.firstName,
              lastName: res.result.lastName,
              profilePic: res.result.profilePic,
            };
            sessionStorage.setItem('googleApi', JSON.stringify(data));

            if (res.result.userType == 'CUSTOMER') {
              // this.router.navigate(['/signup-customer']);
              window.location.href = '/signup-customer?social=true';
            } else if (res.result.userType == 'RETAILER') {
              // this.router.navigate(['/signup-retailer']);
              window.location.href = '/signup-retailer?social=true';
            } else if (res.result.userType == 'SERVICE_PROVIDER') {
              // this.router.navigate(['/signUp-service-provider']);
              window.location.href = '/signUp-service-provider?social=true';
            }
          }
        }
      },
      (err: any) => {
        this.loading = false;
        if (err.responseCode == 402) {
          this.commonService.hideSpinner();
          this.commonService.errorToast(err.responseMessage);
        } else if (err.responseCode == 404) {
          this.commonService.hideSpinner();
          this.commonService.errorToast(err.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(err.responseMessage);
        }
      }
    );
  }
}
