<div>
  <div>
    <div class="bannerContent">
      <div class="card innerDiv" (click)="navigateToViewProduct(data?._id)">
        <div class="image">
          <img
            class="img"
            [src]="data?.profilePic"
            [hidden]="!isLoaded"
            (load)="onImageLoad()"
            alt="categoryImage"
          />
          <ngx-skeleton-loader
            *ngIf="!isLoaded"
            [theme]="{
              width: '235px',
              height: '329px',
              position: 'relative',
              margin: 0
            }"
            count="1"
          ></ngx-skeleton-loader>
        </div>
        <div class="prod_desc">
          <div class="mt-1">
            <p class="fullName">
              {{ data?.firstName }} {{ data?.lastName }}
            </p>
          </div>
          <div class="" *ngIf="desc">
            <p class="description">{{ desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
