import { CommonService } from 'src/app/provide/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-retailer-edit-deals',
  templateUrl: './retailer-edit-deals.component.html',
  styleUrls: ['./retailer-edit-deals.component.scss']
})
export class RetailerEditDealsComponent implements OnInit {
  headingName:any ='EDIT DEALS'
  linkerRouteItems:any =[
    { name:'Home',route: '/retailer-home'},
    {name:'Edit Deals',route:'/retailer-edit-deals'}
  ]
  addDeals!: FormGroup;
  addNewSubcategoryField: any = []
  imageUrl: any;
  categoryListArray: any;
  totalItems: any;
  categoryId: any;
  subCategoryListArray: any;
  subCategoryId: any;
  currentPage = 1;
  itemPerPage = 10;
  searchListArray: any;
  dealName: any;
  aarayData: any;
  imageLinks: any;
  thumbnail: any
  subid: any;
  mediaType: any = 'media';
  imagThumb: any;

  codeValue: any
  today: any = new Date().toISOString().slice(0, 16)

  constructor(private activatedroute: ActivatedRoute, private fb: FormBuilder, private router: Router, public commonService: CommonService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })
  }
  ngOnInit(): void {

    // this.getSearch(),


    this.addDeals = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(32),

      ]),

      category: new FormControl(''),

      subCategory: new FormControl('', [
        Validators.required,
        Validators.maxLength(32),

      ]),

      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(32),
      ]),

      actualprice:new FormControl(''),
      mrp: new FormControl('', [
        Validators.required,
        Validators.pattern("^([R0-9$.])*$")
      ]),

      // qty: new FormControl('', [
      //   Validators.required,
      //   Validators.pattern("^([R0-9$.])*$")
      // ]),


      discount: new FormControl('', [
        Validators.required,
        Validators.pattern("^([R0-9$.])*$")
        // Validators.pattern("^[A-Z][a-zA-Z]*")
      ]),

      dealStartTime: new FormControl(''),

      dealEndTime: new FormControl(''),
    })

    this.viewRetailerProductd();

  }


  NumOnly(event: any) {
    let Numpattern = /^([R0-9$%.])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  productId: any
  // ==============================search====================
  searchList: any = []
  catId: any
  getSearch($event: any) {
    let data = {
      search: this.addDeals.value.title,
      categoryId: this.addDeals.value.category,
      subCategoryId: this.addDeals.value.subCategary,

    }
    
    let apiReqUrl: any = `product/productOfOwn`
    // this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.searchList = res['result']['docs']
        this.addDeals.patchValue({
          "description": this.searchList[0].description,
          "category": this.searchList[0].categoryId.categoryName,
          "subCategory": this.searchList[0].subCategoryId.subCategoryName
        })
        this.catId = this.searchList[0].categoryId._id
        this.subid = this.searchList[0].subCategoryId._id
        // this.aarayData = res['result']['docs']['productImage'][0]
        this.productId = res['result']['docs'][0]['_id']
      }
    })
  }

  


  // =====================view==============

  userId: any;
  productlist: any
  productDetails: any;
  // productId: any;
  retailerId: any;
  imgurl: any;
  _id: any
  dataArry: any
  attendeeArray: any = []
  viewRetailerProductd() {
    let apiReqUrl: any = `deal/viewDeal?dealId=${this._id}`

    this.commonService.getApi(apiReqUrl, {},).subscribe((res: any) => {
      if (res.responseCode == 200) {
        

        this.productDetails = res.result;
        this.productlist = res['result']['productId'][0]
        // =====pachvalue======

        this.addDeals.patchValue({
          "category": this.productlist.categoryId.categoryName,
          "subCategory": this.productlist.subCategoryId.subCategoryName
        })
        this.addDeals.patchValue({
          title: this.productDetails.dealName,
          // category: this.productDetails.categoryId,
          // subCategory: this.productDetails.subCategoryId,
          actualprice: this.productlist.priceSizeDetails[0].price,
          description: this.productDetails.description,
          mrp: this.productDetails.dealPrice,
          qty: this.productDetails.quantity,
          discount: this.productDetails.dealDiscount,
          dealStartTime: new Date(this.productDetails.dealStartTime).toISOString().split(':')[0] + ':' + new Date(this.productDetails.dealStartTime).toISOString().split(':')[1],
          dealEndTime: new Date(this.productDetails.dealEndTime).toISOString().split(':')[0] + ':' + new Date(this.productDetails.dealEndTime).toISOString().split(':')[1],

        });
        // this.imageLinks = res['result']['docs'][0]['productImage']
        this.dataArry = res["result"]["thumbnail"]
        this.imageLinks = res.result.dealImage;
        this.productId = res['result']['productId'][0]
        this.commonService.hideSpinner();
        this.attendeeArray = this.productDetails?.productId[0]?.priceSizeDetails
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }



  // editdeales=====================


  editDeals() {
    let url = "deal/editDeal"
    let s = new Date(this.addDeals.value.dealStartTime)
    let e = new Date(this.addDeals.value.dealEndTime)
    let startDate = s.toISOString();
    let endTime = e.toISOString();
    const data = {

      dealName: this.addDeals.value.title,
      categoryId: this.catId,
      // dealImage: this.aarayData,
      dealImage: this.imageLinks,
      // subCategoryId: this.addDeals.value.subCategory,
      // dealPrice: Number(this.addDeals.value.mrp),
      subCategoryId: this.subid,
      dealPrice: this.addDeals.value.mrp,
      quantity: this.addDeals.value.qty,
      description: this.addDeals.value.description,
      dealDiscount: this.addDeals.value.discount,
      dealStartTime: startDate,
      dealEndTime: endTime,
      dealsFor: "RETAILER",
      productId: this.productId,
      // thumbnail:  this.imageUrl,
      // thumbnail: this.imageLinks[0],
      thumbnail: this.dataArry,
      expired: "false",
      priceSizeDetails: this.attendeeArray,

      // socialLink: socialLinks,
      dealId: this._id,
    }
    

    // this.commonService.putApi(url, data, 1)
    this.commonService.showSpinner();
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.router.navigate(['/retailer-deal-list'])
        // this.commonService.successToast(res["responseMessage"]);
        // this.getProfileDetails()
        // this.authService.loggedInData.next(res['result']['profilePic'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"]);
      }
    })
  }







  postImage: any = [];
  imgSrc: any = [];
  // onvertToBase(file, i) {
  //   var me = this
  //   var reader = new FileReader();
  //   reader.onloadend = function () {

  //     me.imgSrc[i] = reader.result
  //   }
  //   reader.readAsDataURL(file);
  // }
  inds: any;
  async uploadImg(event: any) {
    if (event.target.files.length + this.imageLinks.length > 3) {
      this.commonService.infoToast('Please select maximum 3 image');
      return;
    }

    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      this.imageUrl = file;
      await this.uploadProfilePic(file, index);
    }
    this.mediaType = 'png';
  }

  async uploadProfilePic(img: any, i: number) {
    let url = 'user/uploadMultipleFile';
    this.imageLinks.push('assets/img/imageLoader.gif');

    const formData = new FormData();
    formData.append('uploaded_file', img);
    let res: any = await
      this.commonService.postFormDataApi(url, formData, 1).toPromise();
    if ((await res.responseCode) == 200) {
      this.imageLinks.pop();
      this.imageLinks.push(res['result'][0]['mediaUrl']);

      this.imagThumb = res['result'][0]['thumbnail'];
      // this.commonService.showSpinner();
    } else {
      this.commonService.errorToast("Can't Upload This File");
    }
  }
  closeimage(i: number) {
    this.imageLinks.splice(i, 1);
    if (this.imageLinks.length == 0) {
      this.mediaType = 'png';
    }
  }

  forID(_id: any) {
    this.router.navigate(['/retailer-deal-list'], { queryParams: { _id: _id } })
  }


  priceMsg:any
  discountCalforvalue(){
    this.priceMsg=''
    if(this.addDeals.value.actualprice < this.addDeals.value.mrp){
      this.addDeals.controls.mrp.setValue('')
       this.priceMsg = 'Please enter deal price less then actual price '+this.addDeals.value.actualprice
    }
   if(Number(this.addDeals.value.mrp)==0){
    this.addDeals.controls.mrp.setValue('')
    this.priceMsg = 'Please enter deal price between 0 to '+this.addDeals.value.actualprice
    }
    let DiscountPercentage = ((this.addDeals.value.actualprice - this.addDeals.value.mrp) / this.addDeals.value.actualprice) * 100
    let dsp = DiscountPercentage.toFixed(0)
    let fndsp = dsp
    console.log(fndsp)
    this.addDeals.controls.discount.setValue(fndsp)

  }
  calcMargin() {
    let itema = (Number(this.addDeals.value.discount) / 100) * Number(this.productlist.priceSizeDetails[0].price)
    let totalPrice = (this.productlist.priceSizeDetails[0].price - itema)
     this.addDeals.patchValue({
       mrp : totalPrice
     })
   }
  // calcMargin($event: any) {
  //   let myval = $event.target.value
  //   let urlData = (((myval / 100) * this.searchList[0]?.productlist?.priceSizeDetails[0]?.price))
  //   let totalPrice = (this.searchList[0]?.productlist?.priceSizeDetails[0]?.price - urlData)
  //   this.addDeals.patchValue({
  //     mrp: totalPrice
  //   })
  // }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 48 && !event.target.value) {
      return false
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {

      return false;
    }
    return true;

  }
}
