import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-customer-category-view-list',
  templateUrl: './customer-category-view-list.component.html',
  styleUrls: ['./customer-category-view-list.component.scss'],
})
export class CustomerCategoryViewListComponent implements OnInit {
  _id: any;
  currentPage = 1;
  pageSize = 15;
  serviceName: any;
  latitude: any;
  longitude: any;
  isLoggedIn: any;
  loggedInUserType: any;
  categoryName: any;
  headingName: any = 'CATEGORY'
  linkerRouteItems: any = [
    { name: 'Home', route: '/home' },
    { name: 'Categroy', route: '/customer-category-list' },
    { name: 'Category', route: '/customer-category-list-view' }
  ]
  constructor(
    private activatedroute: ActivatedRoute,
    public commonService: CommonService,
    private router: Router,
    public authService: AuthService
  ) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.categoryName = res.categoryName;
      if (this.categoryName) {
        this.linkerRouteItems[2] = { name: this.categoryName, route: '/customer-category-list-view' }
      }
    });

  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res: any) => {
      this.isLoggedIn = res;
    });
    this.isLoggedIn = this.authService.isLogin();
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res;
    });
    navigator.geolocation.getCurrentPosition(
      function (position) {
      },
      function () {
        alert(
          'You need to enable geolocation services in your browser to use this feature.'
        );
      }
    );
    this.getPosition().then((pos) => {
      localStorage.setItem('location', JSON.stringify(pos));
    });
    this.getLocation();

  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any;
  totalItems: any;

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.getProduct();
          }
        },
        (error) => console.log(error)
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }
  isLoading: boolean = false;
  getProduct() {
    if (this.isLoggedIn) {
      this.getServiceListwithlogin();
    } else {
      this.getServiceList();
    }
  }

  getServiceList() {
    let apiReqUrl: any = `product/listProductBySortion`;
    let apiReqData = {
      search: this.serviceName,
      page: String(this.currentPage),
      limit: String(this.pageSize),
      categoryId: this._id,
      userType: 'RETAILER',
      lng: this.longitude,
      lat: this.latitude,
    };
    this.commonService.removeEmptyKey(apiReqData);
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.productDetails = res.result.docs;
          if (!this.productDetails.length) {
            this.isLoading = true;
          } else {
            this.isLoading = false;
          }
          this.totalItems = res.result.count;
          this.imageLinks = res['result']['thumbnail'];
          this.commonService.hideSpinner();
        } else {
          this.isLoading = true;
          this.productDetails = [];
          this.commonService.hideSpinner();
          // this.commonService.errorToast(res.responseMessage)
        }
      },
      (err) => {
        this.isLoading = true;
        this.productDetails = [];
        this.commonService.hideSpinner();
        this.commonService.errorToast(err.responseMessage);
      }
    );
  }
  getServiceListwithlogin() {
    let apiReqUrl: any = `product/listProductBySortion`;
    let apiReqData = {
      search: this.serviceName,
      page: String(this.currentPage),
      limit: String(this.pageSize),
      categoryId: this._id,
      userType: 'RETAILER',
      lng: this.longitude,
      lat: this.latitude,
    };
    this.commonService.removeEmptyKey(apiReqData);
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.productDetails = res.result.docs;
          this.isLoading = false;
          this.linkerRouteItems = [
            { name: 'Home', route: '/home' },
            { name: 'Categroy ', route: '/customer-category-list' },
            { name: this.productDetails[0].categoryId.categoryName, route: '/customer-category-list-view' }
          ]
          this.headingName = this.productDetails[0].categoryId.categoryName

          // if (!this.productDetails.length) {
          //   this.isLoading = true;
          // } else {
          //   this.isLoading = false;
          // }
          this.totalItems = res.result.count;
          this.imageLinks = res['result']['thumbnail'];
          this.commonService.hideSpinner();
        } else {
          this.isLoading = true;
          this.productDetails = [];
          this.commonService.hideSpinner();
        }
      },
      (err) => {
        this.isLoading = true;
        this.productDetails = [];
        this.commonService.hideSpinner();
        this.commonService.errorToast(err.responseMessage);
      }
    );
  }
  navigateToViewProduct(_id) {
    this.router.navigate(['/customer-category-particular-view'], {
      queryParams: { _id: _id },
    });
  }
  pagination(event) {
    this.currentPage = event;
    this.getServiceList();
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ');
    return a.replace(/R/g, 'R ');
  }
  addWishlist(id) {
    if (!this.isLoggedIn) {
      return
    }
    let apiReqUrl = `user/addWishListProduct?productId=${id}`;
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          this.getServiceList();
          this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
          if (res.responseCode == 401) {
            this.router.navigate['/login'];
          }
        }
      },
      (err) => {
        this.commonService.hideSpinner();
        if (err.responseCode == 401) {
          this.router.navigate['/login'];
        }
      }
    );
  }

  showMessage() {
    return this.commonService.errorToast('Please Login');
  }
}
