<div style="margin-bottom: 4em;">
    <div class="container mt-2">
        <div id="carouselExampleControls" class="carousel slide reponseivDiv480px" data-ride="carousel">
            <!-- <ol class="carousel-indicators">
                <li *ngFor="let item of BannerListArray; let i=index;" data-target="#carouselExampleIndicators" data-slide-to="{{i}}" class="active"></li>
            </ol> -->
            <div class="carousel-inner main">
                <div *ngFor="let item of BannerListArray; let i=index;" class="carousel-item"
                    [ngClass]="{'active':i == 0}">
                    <img class="d-block w-100 tractor" [hidden]="isLoaded[i]" (click)="directPage(item?.description)"
                        (load)="onBannerLaod(i)" src="{{item?.bannerImage}}" alt="{{item?.bannerName}}">
                    <ngx-skeleton-loader *ngIf="!isLoaded[i]" [theme]="{width: '100%', height:'360px',
                    position: 'relative'}" count="1"></ngx-skeleton-loader>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>

        <div class="" style="max-width: 1440px;margin: auto;padding:50px 0px;" *ngIf="categoryListArray?.length">
            <div class="d-flex justify-content-between" style="align-items: center;text-align: center;">
                <h1 class="heading-text-cat">
                    <img src="assets/latest/left-arrow.svg" alt="">
                    SHOP BY CATEGORY
                    <img src="assets/latest/right-arrow.svg" alt="">
                </h1>
                <span routerLink="/customer-category-list" style="cursor: pointer;">
                    <p class="see-All">See All </p>
                </span>
            </div>
            <div class=" d-flex mt-4" style="overflow-y: hidden;">
                <div class="col-md-2 col-lg-2 col-sm-1 p-0" *ngFor="let item of categoryListArray">
                    <!-- <div class="uuu" style="cursor: pointer;"
                    (click)="navigateToViewProducts(item?._id,item?.categoryName)">
                    <img [src]="item?.categoryImage" alt="">
                    <p class="d text-center ml-2" style="margin-top: 5px; font-weight: 500;">{{item?.categoryName}}</p>
                </div> -->

                    <div class="uuu" *ngIf="item?.categoryImage" style="cursor: pointer;"
                        (click)="navigateToViewProducts(item?._id,item?.categoryName)">
                        <img [src]="item?.categoryImage" [hidden]="!item?.isLoaded" (load)="onImageLaod(item)" alt="">
                        <ngx-skeleton-loader *ngIf="!item?.isLoaded" [theme]="{height: '140px'}" count="1">
                        </ngx-skeleton-loader>
                        <p class="d text-center ml-2" style="margin-top: 5px;color: #000;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;">
                            {{item?.categoryName}}</p>
                        <!-- </div> -->
                    </div>
                    <div class="uuu" *ngIf="!item?.categoryImage">

                        <ngx-skeleton-loader *ngIf="!item?.isLoaded" [theme]="{height: '140px'}" count="1">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-3" *ngIf="serviceDetail?.length>0">


            <div class="d-flex justify-content-between" style="align-items: center;text-align: center;">
                <h1 class="heading-text-cat">
                    <img src="assets/latest/left-arrow.svg" alt="">
                    DEALS ON PRODUCTS
                    <img src="assets/latest/right-arrow.svg" alt="">
                </h1>
                <span routerLink="/customer-product-deal-list" style="cursor: pointer;">
                    <p class="see-All">See All</p>
                </span>
            </div>
            <div class="items mt-4">
                <div class="slick_slider">
                    <div class="deal_slider slider slider-customize">

                        <div *ngFor="let data of serviceDetail; let i = index">
                            <div *ngIf="data?.thumbnail">
                                <div class="bannerContent">
                                    <div class="card innerDiv" (click)="dealVIEw(data?._id)">
                                        <div class="image">
                                            <img class="img" [hidden]="!data?.isLoaded" (load)="onImageLaod(data)"
                                                [src]="data?.thumbnail" alt="categoryImage">
                                            <ngx-skeleton-loader *ngIf="!data?.isLoaded" [theme]="{    width: '227px',
                                            height: '329px',
                                            position: 'relative'}" count="1"></ngx-skeleton-loader>
                                        </div>
                                        <div class="mt-3">
                                            <p class="description">{{data?.description}}</p>
                                        </div>
                                        <div class="">
                                            <p class="dealName">Deal Name: {{data?.dealName}}</p>
                                        </div>
                                        <div style="margin-top: -8px;">

                                            <div class="row">
                                                <div class="col-12 p-0 m-0">
                                                    <p class="price"> <span style="text-decoration: line-through;">
                                                            {{data?.dealDetails[0]?.price
                                                            && getPrice(data?.dealDetails[0]?.price |
                                                            currency:"ZAR":"R")}}
                                                        </span>
                                                        <span class="price-color">{{data?.dealDetails[0]?.dealPrice
                                                            &&
                                                            getPrice(data?.dealDetails[0]?.dealPrice |
                                                            currency:"ZAR":"R")}}</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="row" style="margin-top: -8px;">
                                                <div class="col-12 p-0 m-0">
                                                    <p *ngIf="data?.dealDiscount>0" class="dealPrice">Deal Discount :
                                                        <span class="newn">
                                                            {{data?.dealDiscount}}% off
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="end-pro-time text-center mb-2">
                                            {{ countDown[i] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!data?.thumbnail">
                                <div class="bannerContent d-flex justify-content-between">
                                    <ngx-skeleton-loader *ngFor="let item of length(5)" [theme]="{    width: '227px',
                                    height: '329px',
                                    position: 'relative'}" count="1">
                                    </ngx-skeleton-loader>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pb-3" *ngIf="bannerData?.length>0">

            <div class="d-flex justify-content-between" style="align-items: center;text-align: center;">
                <h1 class="heading-text-cat">
                    <img src="assets/latest/left-arrow.svg" alt="">
                    PRODUCTS
                    <img src="assets/latest/right-arrow.svg" alt="">
                </h1>
                <span routerLink="/retailer-product-list" style="cursor: pointer;">
                    <p class="see-All">See All</p>
                </span>
            </div>
            <div class="items mt-4">
                <div class="slick_slider">
                    <div class="banner_slider slider slider-customize">
                        <div *ngFor="let data of bannerData; let i = index">
                            <div *ngIf="data?.thumbnail">
                                <div class="bannerContent">
                                    <div class="card innerDiv" (click)="navigateToViewProduct(data?._id)">
                                        <div class="image">
                                            <img class="img" [hidden]="!data?.isLoaded" (load)="onImageLaod(data)"
                                                [src]="data?.thumbnail" alt="categoryImage">
                                            <ngx-skeleton-loader *ngIf="!data?.isLoaded" [theme]="{    width: '227px',
                                            height: '329px',
                                            position: 'relative'}" count="1"></ngx-skeleton-loader>
                                        </div>
                                        <div class="mt-3">
                                            <p class="description">{{data?.productName}}</p>
                                        </div>
                                        <div style="margin-top: -8px;">
                                            <div class="row" *ngIf="data?.isDealActive == true">
                                                <div class="col-12 p-0 m-0">
                                                    <p class="price">
                                                        <span style="text-decoration: line-through;">
                                                            {{data?.priceSizeDetails[0]?.price
                                                            &&
                                                            getPrice(data?.priceSizeDetails[0]?.price |
                                                            currency:"ZAR":"R")}}
                                                        </span>
                                                        <span class="price-color ml-0">{{data?.dealPrice
                                                            &&
                                                            getPrice(data?.dealPrice |
                                                            currency:"ZAR":"R")}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="data?.isDealActive == false">
                                                <div class="col-12 p-0 m-0">
                                                    <p class="price">
                                                        <span>
                                                            {{data?.priceSizeDetails[0]?.price
                                                            &&
                                                            getPrice(data?.priceSizeDetails[0]?.price |
                                                            currency:"ZAR":"R")}}
                                                        </span>

                                                    </p>
                                                </div>
                                            </div>

                                            <div class="row" style="margin-top: -8px;"
                                                *ngIf="data?.isDealActive == true">
                                                <div class="col-12 p-0 m-0" *ngIf="data?.discount >= 0">
                                                    <p class="dealPrice"> Discount : <span class="newn">
                                                            {{data?.dealDiscount}}% off
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!data?.thumbnail">
                                <div class="bannerContent d-flex justify-content-between">
                                    <ngx-skeleton-loader *ngFor="let item of length(5)" [theme]="{    width: '227px',
                                    height: '329px',
                                    position: 'relative'}" count="1">
                                    </ngx-skeleton-loader>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="pb-3" *ngIf="serviceData?.length">

            <div class="d-flex  justify-content-between" style="align-items: center;text-align: center;">
                <h1 class="heading-text-cat">
                    <img src="assets/latest/left-arrow.svg" alt="">
                    BOOKING CATEGORY
                    <img src="assets/latest/right-arrow.svg" alt="">
                </h1>
                <span routerLink="/customer-service-list" style="cursor: pointer;">
                    <p class="see-All">See All</p>
                </span>
            </div>
            <p class="service-para">Render Our Services At Your Doorsteps</p>
            <div class="items mt-4">
                <div class="slick_slider">
                    <div class="service_slider slider slider-customize">

                        <div *ngFor="let data of serviceData; let i = index">
                            <div *ngIf="data?.categoryImage">
                                <div class="bannerContent">
                                    <div class="card innerDiv newService"
                                        (click)="navigateToViewService(data?._id,data?.categoryName,data?.description)">
                                        <div class="image">
                                            <img class="bannerSlideIamge" [hidden]="!data?.isLoaded"
                                                (load)="onImageLaod(data)" [src]="data?.categoryImage"
                                                alt="categoryImage">

                                            <ngx-skeleton-loader *ngIf="!data?.isLoaded" [theme]="{width: '100%',
                                            height: '402px',
                                            position: 'relative'}" count="1"></ngx-skeleton-loader>
                                        </div>
                                        <div class="mt-3">
                                            <p class="description m-0">{{data?.categoryName}}</p>
                                        </div>
                                        <div class="mt-1">
                                            <p class="description-text">{{data?.description | slice: 0:200}}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!data?.categoryImage">
                                <div class="bannerContent d-flex justify-content-between">
                                    <ngx-skeleton-loader *ngFor="let item of length(3)"
                                        [theme]="{width:'350px',height: '350px'}" count="1"></ngx-skeleton-loader>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- ------------------------------------------third section Let’s Get Started--------------------------- -->

        <!-- deal slider -->
        <div class="pb-3" *ngIf="serviceDetailDeals?.length">
            <div class="d-flex justify-content-between" style="align-items: center;text-align: center;">
                <h1 class="heading-text-cat">
                    <img src="assets/latest/left-arrow.svg" alt="">
                    DEALS ON BOOKINGS
                    <img src="assets/latest/right-arrow.svg" alt="">
                </h1>
                <span routerLink="/customer-service-deal-list" style="cursor: pointer;">
                    <p class="see-All">See All </p>
                </span>
            </div>
            <div class="items mt-4">
                <div class="slick_slider">
                    <div class="deal_slider_bookings slider slider-customize">
                        <!-- <div *ngFor="let data of serviceDetailDeals; let i = index">
                            <div *ngIf="data?.dealImage">
                                <deals-card [data]="data" [itemName]="data?.serviceId?.serviceName"
                                    [orignalPrice]="data?.serviceId?.price"
                                    [discountedPrice]="data?.dealPrice" [discountedPercentage]="getPercentage(data?.dealPrice,data?.serviceId?.price)"
                                    [countDown]="countDownNew[i]" (on_Click)="dealVIEwNew($event)"></deals-card>
                            </div>
                            <div *ngIf="!data?.dealImage">
                                <div class="bannerContent d-flex justify-content-between">
                                    <ngx-skeleton-loader *ngFor="let item of length(5)" [theme]="{    width: '227px',
                                    height: '329px',
                                    position: 'relative'}" count="1">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div> -->
                        <div *ngFor="let data of serviceDetailDeals; let i = index">
                            <div *ngIf="data?.dealImage">
                                <div class="bannerContent">
                                    <div class="card innerDiv" (click)="dealVIEwNew(data)">
                                        <div class="image">
                                            <img class="img" [hidden]="!data?.isLoaded" (load)="onImageLaod(data)"
                                                [src]="data?.dealImage[0]" alt="categoryImage">
                                            <ngx-skeleton-loader *ngIf="!data?.isLoaded" [theme]="{width: '227px',
                                        height: '329px',
                                        position: 'relative'}" count="1"></ngx-skeleton-loader>
                                        </div>
                                        <div class="mt-3">
                                            <p class="description">{{data?.serviceId?.serviceName}}</p>
                                        </div>
                                        <div style="margin-top: -8px;">

                                            <div class="row">
                                                <div class="col-12 p-0 m-0">
                                                    <p class="price"> <span style="text-decoration: line-through;">
                                                            {{data?.serviceId?.price
                                                            && getPrice(data?.serviceId?.price |
                                                            currency:"ZAR":"R")}}
                                                        </span>
                                                        <span class="price-color">{{data?.dealPrice
                                                            &&
                                                            getPrice(data?.dealPrice | currency:"ZAR":"R")}}</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="row" style="margin-top: -8px;">
                                                <div class="col-12 p-0 m-0">
                                                    <p class="dealPrice">Deal Discount : <span class="newn">
                                                            {{getPercentage(data?.dealPrice,data?.serviceId?.price)}}%
                                                            off
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="end-pro-time text-center mb-2" *ngIf="getCountDown(i)">
                                            {{ getCountDown(i) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!data?.dealImage">
                                <div class="bannerContent d-flex justify-content-between">
                                    <ngx-skeleton-loader *ngFor="let item of length(5)" [theme]="{width: '227px',
                                    height: '329px',
                                    position: 'relative'}" count="1"></ngx-skeleton-loader>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content ggg">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="d-flex justify-content-center mt-5 mb-4" id="book">
                <img class="for-img1" style="height: 100%;" src="assets/images/book.png" alt="login form"
                    class="img-fluid" />
            </div>
            <h2 class="modal-title d-flex justify-content-center" style="color:#EE3147;">“Thanks for connecting with
                us.”</h2>
        </div>
    </div>
</div>