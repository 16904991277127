<div class="mt-5">
    <article class="card">

        <div class="card-body">


            <div class="track">
                <div class="step" style="text-align: left;"
                    [ngClass]="{'active': orderDataDetials && orderDataDetials[0]?.orderStatus}">
                    <span class="icon text-center"> <i class="fa fa-check"></i> </span>
                    <span class="text">{{orderDataDetials && (orderDataDetials[0]?.orderType?
                        orderDataDetials[0].orderType : 'Order Received') }}</span>
                    <!-- <span class="desc">{{orderDataDetials && orderDataDetials
                        [0]?.orderDescription
                        }}</span> -->
                </div>
                <div class="step" [ngClass]="{'active': orderDataDetials && orderDataDetials[1]?.orderStatus}">
                    <span class="icon"> <i class="fa fa-archive"></i> </span>
                    <span class="text">{{orderDataDetials && (orderDataDetials[1]?.orderType ?
                        orderDataDetials[1].orderType : 'Order Processing')}}</span>
                    <!-- <span class="desc">{{orderDataDetials && orderDataDetials
                        [1]?.orderDescription
                        }}</span> -->
                </div>
                <div class="step" [ngClass]="{'active': orderDataDetials && orderDataDetials[2]?.orderStatus}">
                    <span class="icon"> <i class="fa fa-user"></i> </span>
                    <span class="text">{{orderDataDetials && (orderDataDetials[2]?.orderType ?
                        orderDataDetials[2].orderType : 'Packaging in Progress')}}</span>
                    <!-- <span class="desc">{{orderDataDetials && orderDataDetials
                        [2]?.orderDescription
                        }}</span> -->
                </div>
                <div class="step" [ngClass]="{'active': orderDataDetials && orderDataDetials[3]?.orderStatus}">
                    <span class="icon"> <i class="fa fa-truck"></i> </span>
                    <span class="text">{{orderDataDetials && (orderDataDetials[3]?.orderType ?
                        orderDataDetials[3].orderType : 'Delivery on the way')}}</span>
                    <!-- <span class="desc">{{orderDataDetials && orderDataDetials
                        [3]?.orderDescription
                        }}</span> -->
                </div>
                <div class="step" style="text-align: end;"
                    [ngClass]="{'active': orderDataDetials && orderDataDetials[4]?.orderStatus}">
                    <span class="icon text-center" style="left:5px"> <i class="fa fa-check-square-o"></i> </span>
                    <span class="text">{{orderDataDetials && (orderDataDetials[4]?.orderType ?
                        orderDataDetials[4].orderType : 'Delivered')}}</span>
                    <!-- <span class="desc">{{orderDataDetials && orderDataDetials
                        [4]?.orderDescription
                        }}</span> -->
                </div>
            </div>



        </div>
    </article>
</div>