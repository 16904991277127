import { Component, OnInit ,Input, Output} from '@angular/core';

@Component({
  selector: 'app-table-ui',
  templateUrl: './table-ui.component.html',
  styleUrls: ['./table-ui.component.scss']
})
export class TableUiComponent implements OnInit {

@Input() headerList :any;
  constructor() { }
  ngOnInit(): void {
  }

}
