<div class="container fw-b">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-5 wrapper-content">
  <div class="row justify-content-center searchBox">
    <div class="form-group has-search">
      <input type="text" class="form-control" [(ngModel)]="serviceName" placeholder="Search by category name"
        (input)="getServiceList(); currentPage = 1" />
      <span class="fa fa-search form-control-feedback"></span>
    </div>
  </div>
  <div class="row categoryBox row-cols-sm-3 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3">
    <ng-container *ngIf="productDetails?.length">
      <div class="col newCardWidth mb-3 newCardWidth1" *ngFor=" let item of productDetails| paginate: {
            itemsPerPage: pageSize,
            currentPage: currentPage,
            totalItems: totalItems};let i = index">
        <div class="innerDiv" (click)="navigateToViewProduct(item?._id, item?.categoryName)">
          <category-layout-home [data]="item"></category-layout-home>
        </div>
      </div>
    </ng-container>
    <!-- replaced flag with !isLoading -->
    <ng-container *ngIf="!productDetails?.length && !isLoading">
      <div class="col newCardWidth mb-3 newCardWidth1" *ngFor="let item of length(12)">
        <div class="innerDiv">
          <category-layout-home [data]="item"></category-layout-home>
        </div>
      </div>
    </ng-container>



  </div>
  <div *ngIf="isLoading" class="no-data">
    <p style="text-align: center; font-size: 16px; font-weight: 500">
      No Data Found
    </p>
  </div>
  <div class="custom-pagination mt-2 text-align-right" style="text-align: right" *ngIf="totalItems > 15">
    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
  </div>
</div>