import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-edit-upload-document-retailer',
  templateUrl: './edit-upload-document-retailer.component.html',
  styleUrls: ['./edit-upload-document-retailer.component.scss']
})
export class EditUploadDocumentRetailerComponent implements OnInit {

  constructor(public commonService: CommonService, private router: Router, public _sanitizer: DomSanitizer) { }

  imageUrl: any
  headingName:any ='EDIT BUSSINESS DOCUMENT'
  linkerRouteItems:any =[
    {name:'Home',route: '/home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Edit Bussiness Form',route:'/edit-business-form'}
  ]
  IncorporationForntUrl: any
  IncorporationBackUrl: any
  VatForntUrl: any
  VatBackUrl: any
  DirectorForntUrl: any
  DirectorBackUrl: any
  ConfirmationForntUrl: any
  ConfirmationBacktUrl: any
  DirectorIDDocumentUrl: any

  ngOnInit(): void {
    this.getProfile();
  }

  imgeUplode() {
    let data = {
      "businessDocumentUpload": {
        "cirtificationOfIncorporation": {
          "pdf": this.IncorporationForntUrl,
          // "backImage": "IncorporationBackUrl"
        },
        "directConsentForm": {
          "pdf": this.DirectorForntUrl,
          // "backImage": "DirectorBackUrl"
        },
        "directorId": {
          "pdf": this.DirectorIDDocumentUrl,

        },
        "VatRegConfirmationDocs": {
          "pdf": this.VatForntUrl,
          // "backImage": "VatBackUrl"
        },

        "bankConfirmationLetter": {
          "pdf": this.ConfirmationForntUrl,
          // "backImage": "ConfirmationBacktUrl"
        }

      },
      flag: 2,
      completeProfile: true,
    }

    
    this.commonService.showSpinner()
    this.commonService.putApi('user/updateProfile', data, 1).subscribe((res: any) => {
      
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner()
        this.router.navigate(['/customer-profile']);
      }
    }, err => {
      
      this.commonService.hideSpinner()
      this.commonService.errorToast(err.error['responseMessage'])
    })
  }

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-

  uploadImg(event: any, uplodeImage: any): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img, uplodeImage);
  }
  uploadImageFunc(img: any, uplodeImage: any) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('user/uploadDocument', fb, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        // this.imageUrl = res['result']['secure_url'];
        // this.imageUrl = res['result']

        if (uplodeImage == 'INCORPORATIONFORNT') {

          this.IncorporationForntUrl = res['result']['mediaUrl']
          // } else if (uplodeImage == 'INCORPORATIONBACK') {

          // this.IncorporationBackUrl = res['result']['mediaUrl']
        } else if (uplodeImage == 'VATFORNT') {
          this.VatForntUrl = res['result']['mediaUrl']

          // } else if (uplodeImage == 'VATBACK') {

          // this.VatBackUrl = res['result']['mediaUrl']
        } else if (uplodeImage == 'DIRECTORFORNT') {

          this.DirectorForntUrl = res['result']['mediaUrl']

          // } else if (uplodeImage == 'DIRECTORBACK') {
          // this.DirectorBackUrl = res['result']['mediaUrl']
        } else if (uplodeImage == 'DIRECTORDOCUMENT') {
          this.DirectorIDDocumentUrl = res['result']['mediaUrl']
        }
        else if (uplodeImage == 'CONFIRMATIONFORNT') {
          this.ConfirmationForntUrl = res['result']['mediaUrl']
        }
        else {
          // this.ConfirmationBacktUrl = res['result']['mediaUrl']

        }
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }

  arr: any = [];
  profileData: any
  getProfile() {
    this.commonService.showSpinner();

    this.commonService.getApi("user/myProfile", 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.profileData = res["result"]["businessDocumentUpload"];
        this.IncorporationForntUrl = 'cirtificationOfIncorporation' in this.profileData ? this.profileData.cirtificationOfIncorporation.pdf : '';
        this.ConfirmationForntUrl = 'bankConfirmationLetter' in this.profileData ? this.profileData.bankConfirmationLetter.pdf : '';
        this.VatForntUrl = 'VatRegConfirmationDocs' in this.profileData ? this.profileData.VatRegConfirmationDocs.pdf : '';
        this.commonService.hideSpinner();
        this.imageUrl = res.result.profilePic;
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }



}
