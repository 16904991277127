<div class="container mb-5 wrapper-content">
    <span (click)="commonService.backLocation()" class="circle-arrow text-start">
        <img class="arrow-image-add-new" src="assets/svg/ar.svg" alt="">

    </span>
    <h1 class="text-center mt-3">Payment Cancelled</h1>
    <div class="right-side" style="width: 100%;">
        <div class="row mt-3">
            <div class="top-success">
                <div class="row">
                    <div class="col-md-6">
                        <div class="datas">
                            <img class="right-img" src="assets/img/er.png" alt="">
                            <p class="new-text text-center">Your payment has failed. Please try again or contact customer support for assistance."
                                You have cancelled your payment</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="left-img" src="assets/img/cancelpayment.jpeg" alt="">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="text-align: center;" >
        <button class="add-cart" [routerLink]="['/addtoCart-customer']">My Cart</button>
    </div>


</div>