import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  @Input() title : string
  @Input() subTitile : string
  @Input() type : string
  @Input() sideImage : string
  @Input() isHiddenImage : boolean


  constructor() { }

  ngOnInit(): void {
  }

}
