<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container wrapper-content" style="max-width:95% ;">
    <div class="d-flex justify-content-between">
        <div class="left-container mt-4 scrol mb-3">

            <div class="ex1">
                <div id="accordion">
                    <menu-panel [categoryListArray]="categoryListArrayss" (onSubCategory)="getSubCategoryList($event)"
                        (onSelectSubCategody)="getSubCateGoryData($event)"></menu-panel>
                </div>
            </div>
        </div>
        <div class="right-container ">
            <div class="btn d-flex justify-content-end mt-2">
                <button type="button" class="btn btn6" routerLink="/retailer-add-deals" id="asd"><i class="fa fa-plus"
                        aria-hidden="true"></i>&nbsp; Add Deals</button>
            </div>
            <div class="d-flex flex-wrap" *ngIf="categoryListArray?.length > 0">
                <div class="card-container"
                    *ngFor="let item of categoryListArray | paginate:{itemsPerPage:itemPerPage, currentPage: currentPage, totalItems: totalItems};let j = last">
                    <deals-card [isImage]="true" width="105%" height="400px" [data]="item" (on_Click)="forID($event)"
                        (on_Click_Edit)="forEditDeals(item?._id)" [showEdit]="true"></deals-card>

                </div>
            </div>
            <div class="d-flex flex-wrap" *ngIf="!categoryListArray?.length && !isLoading">
                <div class="card-container" *ngFor="let item of length(16)">
                    <deals-card [isImage]="false" width="105%" height="400px" [data]="item"></deals-card>
                </div>
            </div>


            
            <div class="d-flex align-items-center  m-auto" *ngIf="!categoryListArray?.length && isLoading" style="width: 100%;
            height: 50vh;
            text-align: center;
            justify-content: center;">
                <p>No Data Found</p>
            </div>

        </div>

    </div>
    <div class="custom-pagination mt-2 pagin22" style="text-align-last: end;" *ngIf="totalItems > 20">
        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
    </div>
</div>