import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-service-provider-edit-deals',
  templateUrl: './service-provider-edit-deals.component.html',
  styleUrls: ['./service-provider-edit-deals.component.scss']
})
export class ServiceProviderEditDealsComponent implements OnInit {
  headingName:any ='EDIT DEAL'
  linkerRouteItems:any =[
    { name:'Home',route: '/service-order-detail'},
    {name:'EDIT DEAL',route:'/service-provider-edit-deals'}
  ]
  addDeals: FormGroup;
  addNewSubcategoryField: any = [];
  imageUrl: any;
  categoryListArray: any;
  totalItems: any;
  categoryId: any;
  subCategoryListArray: any;
  subCategoryId: any;
  currentPage = 1;
  itemPerPage = 10;
  searchListArray: any;
  dealName: any;
  aarayData: any;
  imageLinks: any = [];
  thumbnail: any;
  subid: any;
  _id: any = [];
  navcatId: any
  editId: any

  codeValue: any;
  today: any = new Date().toISOString().slice(0, 16);
  mediaType: any = 'media';
  imagThumb: any;

  constructor(
    private router: Router,
    public commonService: CommonService,
    public activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.editId = res._id
    })
  }

  ngOnInit(): void {
    // this.getSearch(),

    this.addDeals = new FormGroup({

      category: new FormControl('', Validators.required),
      imagee: new FormControl(''),

      subCategory: new FormControl('', [
        Validators.required,
      ]),

      description: new FormControl('', [
        Validators.required,
      ]),
      discountPercentage: new FormControl('', [
        Validators.required,
      ]),

      mrp: new FormControl('', [
        Validators.required,
        Validators.pattern('^([R0-9$.])*$'),
        // Validators.pattern("^[A-Z][a-zA-Z]*")
      ]),
      dealStartTime: new FormControl('', Validators.required),
      actual: new FormControl('', Validators.required),

      dealEndTime: new FormControl('', Validators.required),
    });
    // this.getSearchfirst()
    // this.categoryList();

    this.serviceList()
 
  }
  calculatePrice(e){
    if(e > 100){
      e = 99
    }
    if(e <= 0){
      e =1
    }
    let percentage = e
    let actualPrice = this.addDeals.value.actual

    let finalPrice = actualPrice - (actualPrice / 100) * percentage
    this.addDeals.patchValue({
      mrp : finalPrice.toFixed(2)
    })

  }
  calculatePercentage(e){
    let price = e
    let actualPrice = this.addDeals.value.actual

    let percentage = (actualPrice - price)/actualPrice * 100
    this.addDeals.patchValue({
      discountPercentage : percentage.toFixed(2)
    })
  }
  NumOnly(event: any) {
    let Numpattern = /^([R0-9$.%])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  deals: any
  viewDeals() {
    let url = `deal/viewDeal?dealId=${this.editId}`
    this.commonService.showSpinner()
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.deals = res.result;
        console.log(this.deals);
        
        this.cateId = this.deals.serviceId.categoryId._id
        this.subCategoryList()
       
        this.imageUrl = this.deals.dealImage[0]
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  editServiceDeals() {
    // let data = {
     
    //   dealImage: this.imageUrl,
    //   dealPrice: this.addDeals.value.mrp,
    //   dealStartTime: this.addDeals.value.dealStartTime,
    //   dealEndTime: this.addDeals.value.dealEndTime,
    //   dealsFor: "CUSTOMER",
    //   thumbnail: this.thumbnail,
    // };

    let data = {
      dealId: this.editId,
      dealImage: this.imageUrl,
      dealPrice: this.addDeals.value.mrp,
      serviceCategoryId: this.addDeals.value.category,
      serviceSubCategoryId: this.addDeals.value.subCategory,
      serviceId: this.addDeals.value.description,
      dealStartTime: this.addDeals.value.dealStartTime,
      dealEndTime: this.addDeals.value.dealEndTime,
      dealsFor: "CUSTOMER",
      thumbnail: this.thumbnail
    };
    let apiReqUrl: any = `deal/editDeal`;
    this.commonService.showSpinner();
    this.commonService.putApi(apiReqUrl, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.router.navigate(['/service-provider-available-deals'])
        this.commonService.backLocation()
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
        // this.router.navigate(['/list-deals']);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
    });
  }


  // category
  catList: any
  categoryList() {
    let apiReqUrl: any = `deal/v2/listCategoryOfServiceProvider`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.catList = res['result'];
        this.viewDeals();
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.error.responseCode)
    });
  }

  cateId: any
  thumb: any
  getId(event,subcatId) {
    this.cateId = event.target ? event.target.value : event;
    // this.thumb = event1.target.value;
    this.subCategoryList(subcatId);
  }

  subCatList: any
  subCategoryList(subcatId?) {
    let apiReqUrl: any = `deal/v2/listSubCategoryOfServiceProvider?categoryId=${this.cateId}`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.subCatList = res['result'];
        console.log(this.subCatList);
        
        if(subcatId){
          this.addDeals.patchValue({
            subCategory : subcatId
          })
        }
        else{
          this.addDeals.patchValue({
            category: this.deals.serviceId.categoryId._id,
            subCategory: this.deals.serviceSubCategoryId._id,
            description: this.deals.serviceId._id,
            mrp: this.deals.dealPrice,
            dealStartTime: new Date(this.deals.dealStartTime).toISOString().split(':')[0] + ':' + new Date(this.deals.dealStartTime).toISOString().split(':')[1],
            dealEndTime: new Date(this.deals.dealEndTime).toISOString().split(':')[0] + ':' + new Date(this.deals.dealEndTime).toISOString().split(':')[1],
          })
          this.getServiceId2(this.deals.serviceId._id)
        
        }
      
        
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.error.responseCode)
    });
  }
  getServiceId2(event) {
    this.serviceId = event

    let temp = this.services.find((ele)=>{
      return ele._id == event
    })
    this.addDeals.patchValue({
      actual : temp.price
    })
   
    this.calculatePercentage( this.deals.dealPrice)

  }
  subCatId: any
  getsubId(event) {
    this.subCatId = event.target.value;
    this.serviceList();
  }

  services: any
  serviceList() {
    let apiReqUrl: any = `v2/service/serviceListByUserId`;
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.services = res['result'];
       
        this.categoryList();
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.error.responseCode)
    });
  }
  serviceId: any
  getServiceId(event) {
    this.serviceId = event

    let temp = this.services.find((ele)=>{
      return ele._id == event
    })
    this.addDeals.patchValue({
      category : temp.categoryId._id,
      actual : temp.price
      // subCategory : temp.subCategoryId._id
    })
    this.imageUrl = temp.serviceImage[0]
    this.thumbnail = temp.thumbnail
    this.getId(temp.categoryId._id,temp.subCategoryId._id)
  }




  // @l

  // validation() {

  // }


  productId: any = [];
  // ==============================search====================
  searchList: any = [];
  catId: any;
  dataArry: any;
  attendeeArray: any = []
  getSearch(event: any) {
    if (!event.target.value) {
      // setTimeout(() => {
      this.catId = '';
      this.subid = '';
      this.searchList = [];
      this.imageLinks = [];
      this.addDeals.reset();
      return;
      // }, 10);
    }
    let data = {
      search: this.addDeals.value.title,
    };
    let apiReqUrl: any = `product/productOfOwn`;
    // this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.searchList = res['result']['docs'];
        this.addDeals.patchValue({
          description: this.searchList[0].description,
          category: this.searchList[0].categoryId.categoryName,
          subCategory: this.searchList[0].subCategoryId.subCategoryName,
          mrp: this.searchList[0]?.priceSizeDetails[0]?.price,
        });
        this.catId = this.searchList[0].categoryId._id;
        this.subid = this.searchList[0].subCategoryId._id;
        this.imageLinks = res['result']['docs'][0]['productImage'];
        this.dataArry = res['result']['docs'][0]['thumbnail'];
        this.productId = res['result']['docs'][0]['_id'];
        this.attendeeArray = this.searchList[0]?.priceSizeDetails
      } else if (res.responseCode == 404) {
        this.searchList = []
      }
      else {
        this.searchList = []
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.searchList = []
      }
    });
  }

  calcMargin($event: any) {
    let myval = $event.target.value
    let urlData = (((myval / 100) * this.searchList[0]?.priceSizeDetails[0]?.price))
    let totalPrice = (this.searchList[0]?.priceSizeDetails[0]?.price - urlData)
    this.addDeals.patchValue({
      mrp: totalPrice
    })
  }
  getSearchfirst() {
    let data = {


    }
    let apiReqUrl: any = `product/productOfOwn`;
    // this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.searchList = res['result']['docs'];
      } else if (res.responseCode == 404) {
        this.searchList = []
        this.imageLinks = [];

      }
      else {
        this.searchList = []
        this.imageLinks = [];

      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.searchList = []
        this.imageLinks = [];

      }
    });
  }

  // ====================================================save button================================


  uploadImg(event: any): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img: any) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('user/uploadFile', fb, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.imageUrl = res.result.mediaUrl
        this.thumbnail = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    })
  }
  // =========================

  postImage: any = [];
  imgSrc: any = [];

  inds: any;

  closeimage() {
    this.imageUrl = ''
  }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 48 && !event.target.value) {
      return false
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {

      return false;
    }
    return true;

  }

}
