<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-4 topfooter">
    <div class="row mt-3">
        <div class="table-responsive">
            <table class="table table-hover table-striped" aria-describedby="">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Product Image</th>
                        <th width="11%" scope="col">Product</th>
                        <th scope="col">Order Id</th>
                        <th scope="col">Quantity</th>
                        <th width="11%" scope="col">Order Date</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Order Status</th>
                        <th scope="col">Delivery Status</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of orderList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                        <td scope="row">{{10 * (currentPage - 1) + i+1}}</td>
                        <td scope="row">
                            <img class="imagtag" src="{{item?.productDetails[0]?.productId?.thumbnail}}"
                                 alt="">
                        </td>
                        <td>
                            {{item?.productDetails[0]?.productId?.productName}}
                        </td>
                        <td>{{item?.orderId}}</td>
                        <td>{{getNoOfquantity(item?.productDetails)}}</td>
                        <td>{{item?.createdAt | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        <td class="red-color">{{item?.orderPrice && getPrice(item?.orderPrice | currency:"ZAR":"R")}}</td>
                        <td>{{item?.orderStatus}}</td>
                        <td>{{item?.deliveryStatus}}</td>
                        <td  [ngClass]="{
                            'green-color': item?.paymentStatus=='COMPLETED',
                            'red-color': item?.paymentStatus!='COMPLETED'}">
                            {{item?.paymentStatus}}</td>
                        <td><em  class="fa fa-eye mpl10" aria-hidden="true" style="cursor: pointer;"
                                [queryParams]="{_id : item?._id, status: item?.orderStatus}"
                                [routerLink]="['/customer-order-history-view']"></em></td>
                    </tr>
                </tbody>
            </table>
            <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'80px'" ></app-table-loader>
            <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%;"
                (pageChange)="changePageNumber($event)"></pagination-controls>
        </div>
    </div>
</div>