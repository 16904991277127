<div class="container fw-b">
  <app-linker-route
    [headingName]="headingName"
    [linkerRouteItems]="linkerRouteItems"
  ></app-linker-route>
</div>
<div class="container mb-5 wrapper-content">
  <div class="row justify-content-center providerBox">
    <div class="form-group has-search">
      <span class="fa fa-search form-control-feedback"></span>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="serviceName"
        placeholder="Search for a booking provider"
        (input)="getServiceList()"
      />
    </div>
  </div>
  <sub-category-filter type="service" (onSelectCategory)="getServiceList($event)"></sub-category-filter>

 
  <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3">
    <ng-container *ngIf="productDetails?.length">
      <div class="col newCardWidth mb-3" *ngFor="let item of productDetails">
        <div class="innerDiv">
          <service-card
            [data]="item"
            [desc]="desc"
            [catName]="catName"
            (on_Click)="navigateToserviceAvailableAdd(item?._id)"
          ></service-card>
        </div>
        
      </div>
    </ng-container>

    <ng-container *ngIf="!productDetails?.length && !isLoading">
      <div class="col newCardWidth mb-3" *ngFor="let item of length(10)">
        <div class="innerDiv">
          <service-card
            [data]="{}"
            [desc]="''"
            [catName]="''"
           
          ></service-card>
        </div>
        
      </div>
    </ng-container>
    
    <div *ngIf="isLoading" class="no-data">
      <p style="text-align: center; font-size: 16px; font-weight: 500">
        No Data Found
      </p>
    </div>
  </div>
</div>
