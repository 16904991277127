import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnInit {
  @Input() dropDownData : any
  @Output() selectPayment = new EventEmitter
  constructor() { }

  ngOnInit(): void {
  }
  onSelect(e){
    let val = e.target.value
    if(val) this.selectPayment.emit(val)
  }
}
