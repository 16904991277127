import { CommonService } from 'src/app/provide/common.service';
import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuPanelComponent } from 'src/app/components/menu-panel/menu-panel.component';

@Component({
  selector: 'app-retailer-deal-list',
  templateUrl: './retailer-deal-list.component.html',
  styleUrls: ['./retailer-deal-list.component.scss']
})
export class RetailerDealListComponent implements OnInit {
  @ViewChild(MenuPanelComponent) menuController: MenuPanelComponent;
  panelOpenState = false;
  categoryListArray: any = [];
  itemPerPage = 20;
  currentPage = 1;
  totalItems: any;
  categoryId: any;
  images: any;
  categoryListArrayNew: any
  // userStatus: any = 'ACTIVE';
  headingName:any ='DEALS TO CUSTOMERS'
  linkerRouteItems:any =[
    { name:'Home',route: '/retailer-home'},
    {name:'Deals',route:'/retailer-deal-list'}
  ]

  // ================

  // categoryId: any;
  // categoryListArray: any = [];
  subCategoryListArray: any = [];
  subCategoryId: any;



  _id: any



  categoryListArrayss: any;
  isLoading: boolean =true;

  constructor(private activatedroute: ActivatedRoute, private _formBuilder: FormBuilder, private router: Router, public commonService: CommonService) {

    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })

  }


  parentElement: any;
  classList: any;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getCategoryList();

    this.getCategoryLists();
    this.getBannerDealsList();


  }
  tab: any
  select(tab: any) {
    this.tab = tab
  }
  showActivebutton(title: any) {
    // this.activeButton = title
  }





  reset() {
    this.subCategoryId = ''
    this.tab = ''
    this.getCategoryList()
  }
  // -------------------- get category list --------------------- //
  // -------------------- get category list --------------------- //
  getCategoryList() {
    let apiReqUrl: any = `deal/dealListOfParticular`
    this.commonService.showSpinner();
    let apiReqData: any = {
      page: String(this.currentPage),
      limit: String(this.itemPerPage),

    }
    // remove empty keys from request body

    this.categoryListArray = []
    this.totalItems = 0
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.categoryListArray = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        

        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);

      } else {
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.categoryListArray = []
        this.totalItems = 0
        // this.commonService.errorToast(err.responseMessage);

      }
    })
  }

  pagination(event: number) {
    
    this.currentPage = event;
    this.getCategoryList()
  }










  // ===========================================================================      ================================





  getCategoryLists() {
    let apiReqUrl: any = `admin/listCategory`
    // this.commonService.showSpinner();
    let apiReqData: any = {
      // page: String(this.currentPage),
      // limit: String(this.itemPerPage)
    }
    // remove empty keys from request body
    // apiReqData = this.commonService['removeEmptyKey'](apiReqData)
    apiReqData = this.commonService.removeEmptyKey(apiReqData)

    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      
      if (res && res.responseCode == 200) {
        this.categoryListArrayss = res.result.docs ? res.result.docs : '';
        console.log( this.categoryListArrayss," this.categoryListArrayss")
        this.totalItems = res.result.total
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.categoryListArrayss = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  getCategoryId(id: any) {
    // this.getSubCategoryList()
    this.categoryId = id.target.value
    

  }



  getSubCategoryList(id: any) {
    this.currentPage = id.currentPage
    let data: any = {

    }
    let apiReqUrl = `${'admin/subcategoryListWithCategory' + '?categoryId=' + id._id}`
    // this.commonService.showSpinner();

    // remove empty keys from request body
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.subCategoryListArray = res.result.subCategory ? res.result.subCategory : '';
        this.menuController.loadSubCategory( this.subCategoryListArray , id.index);

        this.totalItems = res.result
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    })
  }


  getSubCategoryId(id: any) {
    this.subCategoryId = id.target.value
  }



  // ==========================================

  expire: any
  productBySubCategory(id: any) {
    let apiReqUrl = `${'deal/dealListOfParticular'}`
    // this.commonService.showSpinner();
    let apiReqData: any = {
      page: String(this.currentPage),
      limit: String(this.itemPerPage),
      subCategoryId: id
    }
    this.isLoading =false
    // remove empty keys from request body
    this.categoryListArray = []
        this.totalItems = 0
       
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.categoryListArray = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
   
          this.isLoading =true
       

        

        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner();
      } else {
        this.categoryListArray = []
        this.isLoading =true
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }

    }, (err: any) => {
     
        // alert(1)
        this.categoryListArray = []
        this.totalItems = 0
        this.isLoading =true
        // this.commonService.errorToast(err.responseMessage);

      
    })



  }


  productBySubCategoryId(id: any) {
    this.subCategoryId = id.target.value
  }


  // =======================bannerApi=========================
  BannerListArray: any

  getBannerDealsList() {
    let apiReqUrl: any = `user/listBanner`
    let apiReqData: any = {
      // page: String(this.currentPage),
      limit: String(this.itemPerPage),
    }
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.BannerListArray = res.result.docs ? res.result.docs : '';
        // this.datalist = res.result.docs[0].thumbnail
        this.totalItems = res.result.total
        

        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.BannerListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.BannerListArray = []
        this.totalItems = 0
        // this.commonService.errorToast(err.responseMessage);

      }
    })
  }






  // =================


  forID(_id: any) {
    this.router.navigate(['/retailer-view-deals'], { queryParams: { _id: _id } })
  }

  forEditDeals(_id: any) {
    this.router.navigate(['/retailer-edit-deals'], { queryParams: { _id: _id } })
  }


  productDetails: any;
  imgurl: any



  getPrice(e: any) {
    
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }

  getSubCateGoryData(e){
    this.select(e.subCategoryName);
    this.productBySubCategory(e._id)
  }
  length(n:number){
    return new Array(n)
  }
}
