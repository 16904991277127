import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-order-history-customer',
  templateUrl: './order-history-customer.component.html',
  styleUrls: ['./order-history-customer.component.scss']
})
export class OrderHistoryCustomerComponent implements OnInit {
  btn: any = "Pending"
  headingName:any ='Your Orders History'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Order History',route:'/customer-order-history'}
  ]

  orderList: any =[]
  count: any
  catName: any
  currentPage: any = 1;
  pageSize: any = 10;
  serviceStatus: any = 'PENDING'
  isLoading: boolean = false;
  notFoundData:boolean =false
  constructor(public commonService: CommonService, public router: Router) { }

  ngOnInit(): void {
    this.serviceOrderList();
  }

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }

  serviceOrderList() {
    this.isLoading =true
    this.orderList =[];
    this.count= 0;
    const data = {
      page: this.currentPage,
      limit: this.pageSize,
      orderType: 'PRODUCT',
      // statusType: this.serviceStatus
    }
    this.commonService.postApi('order/orderListOfOwn', data, 1).subscribe((res: any) => {
      this.isLoading =false
      if (res['responseCode'] == 200 && res.result.docs.length>0) {
        this.commonService.hideSpinner()
        this.orderList = res.result.docs;
        this.count = res.result.total
        if(this.orderList.length>0){
          this.notFoundData =false
        }else{
          this.notFoundData =true
        }
      }else{
        this.commonService.hideSpinner()
        this.notFoundData =true
        this.commonService.errorToast(res.responseMessage)
      }

    }, (err: any) => {
      this.notFoundData =true
      this.isLoading =false
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  changePageNumber(page) {
    this.currentPage = page;
    this.serviceOrderList();
  }
  getNoOfquantity(prodArr){
    let noOfQuant = 0
    for (const item of prodArr) {
      noOfQuant += item.quantity
    }
    return noOfQuant
  }
}
