<auth-layout title="Verify Your Email ID" type="SIGNUP" subTitile="Please enter the 4-digit verification code that was sent to service provider email. The code
is valid
for 3
minute." sideImage="assets/latest/service-provider-signup.png">

    <form [formGroup]="otpForm">
        <div class="otp">
            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:4}"></ng-otp-input>
        </div>
        <strong style="position: relative;left: 36%;color: #c01e2f;">{{remainingTime}}</strong>
        <div class="vca mt-3">
            <span class="o-f"   *ngIf="!flag" (click)="navigateToResetPassword()">Verify</span>
            <span class="o-f op05" *ngIf="flag" (click)="navigateToResetPassword()">Verify</span>
        </div>
        <div class="mt-2 forcenter">
            <a class="res-otp" (click)="resendOtp()"  *ngIf="isExpire; else notExpired">Resend OTP</a>
            <ng-template #notExpired><a style="color: gray; cursor: none;">Resend OTP</a></ng-template>
            <!-- <a class="res-otp" (click)="resendOtp()" *ngIf="isExpire">Resend OTP</a>
            <a class="res-otp" *ngIf="!isExpire" style="cursor:no-drop ;">Resend OTP</a> -->
            <!-- <p class="mt-10">{{time == '1.59' || time == '0.59' ? '' : time }}</p> -->
        </div>
    </form>

</auth-layout>