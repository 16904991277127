  <!-- This is Only Table UI Sketlenton Loader Data -->
  <div *ngIf="isTable=='TABLE'">
    <table class="table table-hover" aria-describedby="">
      <tbody>
          <tr *ngIf="notFoundData">
              <td class="notfound" style="text-align: center;"  colspan="{{colspan}}">No Data Found</td>
          </tr>
      </tbody>
  </table>
  <!-- '80px' -->
  <ngx-skeleton-loader *ngIf="isLoading"
    count="6"
    animation="true"
    [theme]="{
      height: [isWidth]
    }"
  ></ngx-skeleton-loader>
  </div>


  <!-- This is address Sketlenton Loader Data -->
  <div *ngIf="isTable=='ADDRESS'">
    <table class="table table-hover" aria-describedby="">
      <tbody>
          <tr *ngIf="notFoundData">
              <td class="notfound" style="text-align: center;"  colspan="{{colspan}}">No Data Found</td>
          </tr>
      </tbody>
  </table>
    <div class="fb-item" *ngIf="isLoading">
      <div class="first-section-wrapper">
        <div class="gravatar-title">
          <div>
            <ngx-skeleton-loader
              [theme]="{
                width: '200px',
                'border-radius': '0',
                height: '25px',
                'margin-bottom': '10px'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader
              [theme]="{ width: '170px', 'border-radius': '0', height: '23px' }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div style="margin-top: 0px;" class="second-section-wrapper">
        <div class="wrapper">
          <ngx-skeleton-loader
            [theme]="{
              width: '50%',
              'border-radius': '0',
              'margin-left': '10px',
              height: '15px',
              'margin-bottom': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="wrapper">
          <ngx-skeleton-loader
            [theme]="{
              width: '40%',
              'border-radius': '0',
              'margin-left': '10px',
              height: '15px',
              'margin-bottom': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="wrapper">
          <ngx-skeleton-loader
            [theme]="{
              width: '30%',
              'border-radius': '0',
              'margin-left': '10px',
              height: '15px',
              'margin-bottom': '10px'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>


  <!-- <div *ngIf="ImageBOx">
    <ngx-skeleton-loader
      count="5"
      appearance="circle"
      [theme]="{
        width: '70px',
        height: '70px',
        'border-radius': '10px'
      }"
    >
    </ngx-skeleton-loader>
  </div> -->
  