import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss']
})
export class MenuPanelComponent implements OnInit ,OnDestroy{
  private scriptElement: HTMLScriptElement;


  @Input() categoryListArray : any
  @Output() onSubCategory = new EventEmitter
  @Output() onSelectSubCategody = new EventEmitter
  subCategoryListArray : any
  constructor() { }

  ngOnInit(): void {
    this.loadScript()
  }

  loadScript() {
    this.scriptElement = document.createElement('script');
    this.scriptElement.src = 'https://unpkg.com/carbon-components@10.0.0/scripts/carbon-components.min.js';
    document.body.appendChild(this.scriptElement);
  }
  clickedArray : any = []
  getSubCategoryList(e,i,t){
    if(this.clickedArray.includes(i)){
      return
    }
    this.clickedArray.push(i)
    this.onSubCategory.emit({_id : e, currentPage: 1,index: i})
  }
  loadSubCategory(subCategory,index){
    this.categoryListArray[index]['subCategoryListArray'] = subCategory
  }
  ngOnDestroy(): void {
    if (this.scriptElement) {
      document.body.removeChild(this.scriptElement);
      this.scriptElement = null;
    }
  }

  rawData(e,t){
    let obj = e
    obj.checked = t.target.checked
    this.onSelectSubCategody.emit(e)
  }

}
