<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-5 wrapper-content">
    <section id="maincontent" class="page-main container"> <a id="contentarea" tabindex="-1"></a>
        <div class="row">
            <div class="row column main p-0 col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <div class="clr"></div>
                <div class="cart-container col-md-12 p-0 uf_cartleftbox uf_cart_container border">
                    <div class="cart-products-toolbar cart-products-toolbar-top toolbar"
                        data-attribute="cart-products-toolbar-top"></div> <input type="hidden" id="uf_mmtdetailurl"
                        value="https://udesignapi.utsavfashion.com/api/v1/mmt/get.svc/getdetails"><input type="hidden"
                        id="totalshipamt" value="0">
                    <form action="https://www.utsavfashion.com/checkout/cart/updatePost/" method="post"
                        id="form-validate" class="form form-cart" novalidate="novalidate"><input name="form_key"
                            type="hidden" value="iqOHv1Upl97Gkn6q">
                        <div class="cart table-wrapper uf_cartwrapper">
                            <table id="shopping-cart-table" class="cart items data table">
                                <caption role="heading" aria-level="2" class="table-caption">Product Review
                                </caption>
                                <thead>
                                    <tr>
                                        <th class="col item" scope="col"><span>Product</span></th>
                                        <!-- <th class="col price" scope="col"><span>Unit Price</span></th> -->
                                        <!-- <th class="col subtotal" scope="col"><span>Subtotal</span></th> -->
                                    </tr>
                                </thead>
                                <tbody class="cart item uf_bagitem" data-sku="KMDF127_32" data-productid="3240488">
                                    <tr class="item-info" *ngFor="let item of orderResultDetail">
                                        <td data-th="Item" class="col item">
                                            <div class="uf_cartproductimg"><a
                                                    title="Embroidered Chanderi Silk Abaya Style Suit in Off White"
                                                    tabindex="-1" class="product-item-photo">
                                                    <span
                                                        class="product-image-container product-image-container-3240488"
                                                        style="width: 295px;"><span class="product-image-wrapper">

                                                            <img class="product-image-photo" [hidden]="!item?.productId?.thumbnail"
                                                                [src]="item?.productId?.thumbnail" loading="lazy"
                                                                width="295" height="427"
                                                            >
                                                            <ngx-skeleton-loader *ngIf="!item?.productId?.thumbnail" [theme]="{width: '100%', height:'155px',
                                                                           position: 'relative'}" count="1">
                                                            </ngx-skeleton-loader>




                                                        </span></span>
                                                </a></div>
                                            <div class="product-item-details position-relative">
                                                <div class="product-item-name">

                                                    <p class="productTitle m-0">{{item?.productId?.productName}}</p>


                                                    <span class="price-excluding-tax" data-label="Excl. Tax">
                                                        <span class="cart-price">
                                                            <span *ngIf="!item?.productId?.dealReferenceId" class="reg_price" style="margin: 0 12px 0 0;">
                                                                <span>
                                                                    {{item?.priceSizeDetails?.price && getPrice(item?.priceSizeDetails?.price |
                                                                    currency:"ZAR":"R")}}</span>
                                                            </span>
                                                            <span *ngIf="item?.productId?.dealReferenceId" class="reg_price" style="margin: 0 12px 0 0;">
                                                                <span style="text-decoration: line-through;">
                                                                    {{item?.priceSizeDetails?.price && getPrice(item?.priceSizeDetails?.price |
                                                                    currency:"ZAR":"R")}}</span>
                                                                <span style="color:#bf1e2e">
                                                                    {{item?.priceSizeDetails?.price && getPrice(item?.priceSizeDetails?.price - (item?.priceSizeDetails?.price * item?.productId?.dealReferenceId.dealDiscount / 100) |
                                                                    currency:"ZAR":"R")}}</span>
                                                                <span  style="color:#bf1e2e">
                                                                    {{ item?.productId?.dealReferenceId.dealDiscount}}% Off</span>
                                                            </span>

                                                        </span>
                                                    </span>

                                                </div>

                                                <ul class="item-options uf_bagitemoptions" style="padding: 2px;">
                                                    <li class="uf_bagoptionlist "><span class="uf_bagitemsize">
                                                            Quantity
                                                            : {{item?.quantity}}
                                                        </span>
                                                    </li>
                                                    <li class="uf_bagoptionlist "><span
                                                            class="uf_bagitemsize">Value/Size :
                                                            {{item?.priceSizeDetails?.value}}
                                                        {{item?.priceSizeDetails?.unit != 'other' ?item?.priceSizeDetails?.unit : '' }}
                                                        </span>
                                                    </li>





                                                </ul>
                                                <span class="uf_bagoptionlist lmp">

                                                    <input type="checkbox" class="form-control" (change)="getId($event.target.checked , item?._id)" style="width: 25px;"
                                                        id="">
                                                </span>
                                            </div>

                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </form>
                    <div class="cart-products-toolbar cart-products-toolbar-top toolbar"
                        data-attribute="cart-products-toolbar-top"></div>

                </div>
                <div class="col-md-12 text-center mt-3">
                   
                    <button class="btn btn-primary btn-theme" *ngIf="!loading" [disabled]="idsArray.length != pro_length"  (click)="load();openOtpModal()">Mark as Received</button>
                    <button class="btn btn-primary btn-theme op05" *ngIf="loading">Loading...</button>
                </div>
            </div>
        </div>
    </section>
</div>



<otp-modal *ngIf="showModal" custom="true" (closeModal)="showModal = false" (onResend)="openOtpModal()" (onSumit)="onSumbitOtp($event)" (onResend)="onResend()" customerType='retailer'></otp-modal>
<map-navigation *ngIf="navigateModal" [navDetail]="navDetail"><map-navigation>
