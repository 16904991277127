import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-otp-verification-retailer',
  templateUrl: './otp-verification-retailer.component.html',
  styleUrls: ['./otp-verification-retailer.component.scss']
})
export class OtpVerificationRetailerComponent implements  OnInit, OnDestroy {
  // otp: any;
  otpForm!: FormGroup
  otp: any;
  myEmail: any;
  countDowon: any = 2.59




  contDownTimer() {
    var a = window.setInterval(() => {
      if (this.countDowon - (parseInt(this.countDowon)) > 0.60) {

        this.countDowon = this.countDowon - 0.40
      }
      if (this.countDowon > 0) {
        this.countDowon = this.countDowon - 0.01
        this.getTime((this.countDowon).toFixed(2))
      }
      else {
        this.resend_otp_active = true
        clearInterval(a)
      }
    }, 1000)
  }

  resend_otp_active: boolean = false
  time!: string
  getTime(e: any) {
    this.time = String(e).replace('.', ':')
    this.time = String(e).replace('-', '')
  }
  constructor(private http: HttpClient, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.otpForm = new FormGroup({
      otp: new FormControl("", [
        Validators.required,

      ]),
    });

    this.myEmail = localStorage.getItem('email')

    let a  = Number(localStorage.getItem('remainingTime'))
    this.timer(a || 180);
    // this.resendOtp();
  }
  timerOn = true;

  onOtpChange(otp: any) {
    this.otp = otp;
  }

  navigateToResetPassword() {
    let userData = {
      email: this.myEmail,
      otp: this.otp,
      userType: 'RETAILER',
    }
    this.commonService.showSpinner()

    this.commonService.postApi('user/verifyOTP', userData, 0).subscribe((res: any) => {
      
      // if(res['']) 
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner()
        this.commonService.successToast(res['responseMessage'])
        this.router.navigate(['/reset-password-retailer'])
      }

    }, err => {
      this.commonService.hideSpinner()
      this.commonService.errorToast(err['responseMessage'])
      if (err['error']['responseCode'] == 400) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err['responseMessage'])
      }
      
    })

  }


  // resend otp 
  resendOtp() {
    this.commonService.showSpinner();
    this.myEmail = localStorage.getItem('email');
    var email = {
      'email': this.myEmail,
      userType: 'RETAILER',
    }
    this.commonService.postApi('user/resendOTP', email, 0).subscribe(res => {
      if (res['responseCode'] == 200) {
        this.timer(180);
        this.isExpire=false;
        this.commonService.hideSpinner()
        this.commonService.successToast(res['responseMessage'])
      }
      
    }, err => {
      this.commonService.hideSpinner()
      this.commonService.errorToast(err['responseMessage'])
      
    })
  }

  remainingTime: any
  isExpire: boolean = false
  timeOutId : any
  timer(remaining) {
    let m: any = Math.floor(remaining / 60);
    let s: any = remaining % 60;

    m = m < 10 ? '' + m : m;
    s = s < 10 ? '0' + s : s;
    this.remainingTime = m + ':' + s

    // document.getElementById('timer').innerHTML = m + ':' + s;
    remaining -= 1;
    let me = this
    localStorage.setItem('remainingTime',remaining)
    if (remaining >= 0 && this.timerOn) {
      this.timeOutId =  setTimeout(function () {
        me.timer(remaining);
      }, 1000);
      return;
    }

    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    localStorage.removeItem('remainingTime')
    this.isExpire = true
  }
  ngOnDestroy(): void {
    // This method will be called just before the component is destroyed
    this.timer(0)
    clearTimeout(this.timeOutId)
    localStorage.removeItem('remainingTime');
 }
}
