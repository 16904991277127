<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-5 wrapper-content">

    <mycart-layout *ngIf="cartListDetails?.length" (onUpdateCartValue)="updateCartValue($event)"
        [cartListDetails]="cartListDetails" [optionList]="optionList" [discount]="discount"
        [discountPercent]="discountPercent" (payOutEvent)="onProceed($event)" [payOutDetail]="payOutDetail" [sum]="sum" (navigateToaddress)="navigateToaddress($event)"
        (onRemoveItem)="getItemId($event)"></mycart-layout>

    <ngx-skeleton-loader *ngIf="!flag2" count="5" [theme]="{ 'border-radius': '0', height: '50px' }"></ngx-skeleton-loader>
    <div class="row mt-3" *ngIf="!cartListDetails?.length">
        <div class="col-md-10 m-auto">
            <div class="right-side">
                <div class="row" *ngIf="isLoading">
                    <img class="m-auto" src="assets/latest/empty.svg" alt="">
                </div>
                <div *ngIf="isLoading">
                    <h3 class="emptyHead">
                        Your Cart is Empty
                    </h3>
                    <p class="emptyPara">
                        Looks like you have not added anything to your cart
                        Go ahead & explore top categories.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="flag">
    <div class="container mt-5" *ngIf="serviceData?.length">
        <div class="d-flex justify-content-start">
            <h1 class="heading-text-cat" style="color: #000000 !important;font-size: 22px !important;">
                YOU MAY ALSO LIKE
            </h1>
        </div>
        <div class="items mt-4">
            <div class="slick_slider">
                <div class="service_slider_new slider slider-customize d-flex">
                    <ng-container *ngFor="let data of serviceData; let i = index">
                        <div class="slider-card">
                            <product-card [data]="data" (on_Click)="goToProduct(data?._id)"></product-card>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<div class="modal fade" id="modalDeleteConfirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content ggg" style="width: 77%;">
            <div class="d-flex justify-content-center mt-5 mb-4" id="book">
                <img class="for-img1" style="height: 100%;width: 18%;" src="assets/latest/trashicon.png"
                    alt="login form" class="img-fluid" />
            </div>
            <h2 class="modal-title d-flex justify-content-center okay" style="color:#263238;">
                Are you sure you want <br>to remove this item?
            </h2>

            <div class="modal-body d-flex justify-content-center mb-4">
                <button type="button" class="btn btntt1" data-dismiss="modal">
                    No
                </button>
                <button type="button" class="btn btntt ml-3" data-dismiss="modal" (click)="removeCartList()">
                    Yes
                </button>
            </div>
        </div>
    </div>
</div>