import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'sub-category-filter',
  templateUrl: './sub-category-filter.component.html',
  styleUrls: ['./sub-category-filter.component.scss']
})
export class SubCategoryFilterComponent implements OnInit {
  subcatList : any
  subcatId : any
  @Input() type 
  @Output() onSelectCategory = new EventEmitter
  isDragging: boolean = false;
  @ViewChild('containerRef', { static: false }) containerRef!: ElementRef;
  initialX: number = 0;
  scrollLeft: number = 0;

  startDrag(event: MouseEvent) {
    this.isDragging = true;
    this.initialX = event.clientX;
    this.scrollLeft = this.containerRef.nativeElement.scrollLeft;
    this.containerRef.nativeElement.style.cursor = 'grabbing';
  }

  drag(event: MouseEvent) {
    if (this.isDragging) {
      const deltaX = event.clientX - this.initialX;
      this.containerRef.nativeElement.scrollLeft = this.scrollLeft - deltaX;
    }
  }

  endDrag() {
    this.isDragging = false;
    this.containerRef.nativeElement.style.cursor = 'grab';
  }
  constructor(public commonService : CommonService, private activated : ActivatedRoute) { }

  ngOnInit(): void {
    this.activated.queryParams.subscribe((res)=>{
      if(this.type == 'service'){
        this.subServiceCategogyList(res._id)
      }
      else{
        this.subCategogyList(res._id)

      }
    })
  }
  subCategogyList(id) {
    let apiReqUrl = `admin/subcategoryListWithCategory?categoryId=${id}`
    const req = {
      
    }
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.subcatList = res.result.subCategory
        this.commonService.hideSpinner();
       
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
       
      }
    }, (err) => {
      this.commonService.hideSpinner()
      
    })
  }
  subServiceCategogyList(id) {
    let apiReqUrl = `admin/listSubCategoryService`
    const req = {
      categoryId : id
    }
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl,req, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.subcatList = res.result.docs.filter((ele)=>{return !String(ele.subCategoryName).toLowerCase()?.includes('exobe') })
        this.commonService.hideSpinner();
       
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
       
      }
    }, (err) => {
      this.commonService.hideSpinner()
      
    })
  }
  getSubCatId(id){
    this.subcatId = id
    this.onSelectCategory.emit(id)
  }

}
