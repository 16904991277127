<div class="container bg-danger fw-b">
    <hr>
</div>

<section class="vh-100 wrapper-content">
    <div class="container py-5 h-80 ">
        <div class="row d-flex justify-content-center align-items-center h-80">
            <div class="col col-xl-8">
                <div class="card shadow" style="border-radius: 1rem;">
                    <div class="row g-0">
                        <div class="col-md-6 col-lg-6 mt-0" id="for-img">
                            <img class="for-img1" style="height: 100%;" src="assets/img/Background.png" alt="login form"
                                class="img-fluid" />
                        </div>
                        <div class="col-md-6 col-lg-6 d-flex align-items-center">
                            <div class="card-body p-4 p-lg-5 text-black">

                                <form [formGroup]="changPassword">
                                    <div class="d-flex align-items-center mb-3 pb-1">
                                        <div class="row mt-4 mb-4">
                                            <span><i id="icon" class="fa fa-arrow-left titt"
                                                    style=" cursor: pointer;"></i></span>

                                        </div>
                                        <span class="h3 fw-bold">Change Password</span>

                                    </div>


                                    <div class="form-group2 col-md-12">
                                        <label for="">Old Password</label>
                                        <input [type]="changetype?'password':'text'" id="form-control"
                                            formControlName="oldpassword" maxlength="16" placeholder="Old password"
                                            (keypress)="preventSpace($event)">
                                        <span class="eyeicon" (click)="viewpass()"><i
                                                [ngClass]="visible?'fa fa-eye':'fa fa-eye-slash'"></i></span>

                                    </div>


                                    <div class="for-validation mt-2 mb-3 ml-3">
                                        <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('oldpassword')?.hasError('required') && changPassword.get('oldpassword')?.touched">
                                            *Old password
                                            required.</div>


                                        <!-- <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('oldpassword')?.hasError('pattern') && changPassword.get('oldpassword')?.dirty">
                                            Upper case character
                                            lower case character
                                            special character
                                            number
                                            and minimum of 8 digits.</div> -->


                                    </div>
                                    <!-- 
                                    <div class="form-outline mb-4">
                                        <label class="form-label Eadd">Old Password</label>
                                        <p class="para-input"> <input type="password" id="for-pass"
                                                formControlName="oldPassword" class="form-control" />
                                         
                                        </p>

                                    </div> -->
                                    <!-- <div class="for-valition ">
                                        <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('oldPassword')?.hasError('required') && changPassword.get('oldPassword')?.touched">
                                            *Please enter your Oldpassword.
                                        </div>
                                        <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('oldPassword')?.hasError('minlength') && changPassword.get('oldPassword')?.touched">
                                            *should be minimume 6 character.
                                        </div>
                                        <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('oldPassword')?.hasError('maxlength') && changPassword.get('oldPassword')?.dirty">
                                            *should be maxlength 16 character.
                                        </div>
                                    </div> -->

                                    <div class="form-group2 col-md-12">
                                        <label for="">New Password</label>
                                        <input [type]="changetypes?'password':'text'" id="form-control" placeholder="New password"
                                            formControlName="password" maxlength="16" (keypress)="preventSpace($event)">
                                        <span class="eyeicon" (click)="viewpas()"><i
                                                [ngClass]="visibles?'fa fa-eye':'fa fa-eye-slash'"></i></span>

                                    </div>


                                    <div class="for-validation  mt-2 mb-3 ml-3">
                                        <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('password')?.hasError('required') && changPassword.get('password')?.touched">
                                            *New Password is
                                            required.</div>


                                        <!-- <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('password')?.hasError('pattern') && changPassword.get('password')?.dirty">
                                            Upper case character
                                            lower case character
                                            special character
                                            number
                                            and minimum of 8 digits.</div> -->


                                    </div>


                                    <!-- <div class="form-outline mb-4">
                                        <label class="form-label Eadd">New Password</label>
                                        <p class="para-input"> <input type="password" id="for-pass"
                                                formControlName="newPassword" class="form-control" />
                                          
                                        </p>

                                    </div>
                                    <div class="for-valition ">
                                        <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('newPassword')?.hasError('required') && changPassword.get('newPassword')?.touched">
                                            *Please enter your newPassword.
                                        </div>
                                        <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('newPassword')?.hasError('minlength') && changPassword.get('newPassword')?.touched">
                                            *should be minimume 6 character.
                                        </div>
                                        <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('newPassword')?.hasError('maxlength') && changPassword.get('newPassword')?.dirty">
                                            *should be maxlength 16 character.
                                        </div>
                                    </div> -->


                                    <div class="form-group2 col-md-12">
                                        <label for="">confirm Password</label>
                                        <input [type]="cengesestype?'password':'text'" id="form-control" placeholder="Confirm password"
                                            formControlName="confirmPassword" maxlength="16">
                                        <span class="eyeicon" (click)="viewpassd()"><i
                                                [ngClass]="visibled?'fa fa-eye':'fa fa-eye-slash'"></i></span>

                                    </div>

                                    <div class="for-validation mt-2 ml-3">
                                        <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('confirmPassword')?.hasError('required') && changPassword.get('confirmPassword')?.touched">
                                            Please enter valid confirm password</div>
                                        <div class="for-validation text-danger"
                                            *ngIf="(changPassword.get('password')?.value != changPassword.get('confirmPassword')?.value) &&  changPassword.get('confirmPassword')?.dirty">
                                            Password and Confirm Password must be match.</div>

                                    </div>
                                    <!-- <div class="for-validation mt-2 ml-3">
                                        <div class="for-validation  text-danger"
                                            *ngIf="changPassword.get ('confirmPassword')?.hasError('required') && changPassword.get('confirmPassword')?.dirty">
                                            *Repeat Password is required.</div>


                                        <div class="for-validation  text-danger"
                                            *ngIf="changPassword.get('password')?.value !=changPassword.get('conformPassword')?.value && changPassword.get('conformPassword')?.dirty">
                                            *password and confirm Pssword must be match.
                                        </div>


                                    </div> -->

                                    <!-- <div class="form-outline mb-4">
                                        <label class="form-label Eadd">Confirm Password</label>
                                        <input type="password" id="for-email" class="form-control"
                                            formControlName="conformPassword" />
                                    </div>
                                    <div>
                                        <div class="for-validation text-danger"
                                            *ngIf="changPassword.get('conformPassword')?.hasError('required') && (changPassword.get('conformPassword')?.touched || changPassword.get('conformPassword')?.dirty)">
                                            *Please enter your password.
                                        </div>
                                        <div class="for-validation text-danger"
                                            *ngIf="(changPassword.get('newPassword')?.value != changPassword.get('conformPassword')?.value) && (changPassword.get('conformPassword')?.dirty)">
                                            *New Password and conformPassword must be match.

                                        </div>
                                    </div> -->


                                    <div class="pt-1 mt-4 text-center">
                                        <button class="btn btn-outline-success2 my-2 my-sm-0"
                                            (click)="navigateTochangePassword()" type="submit"
                                            [disabled]="!changPassword.valid">Change
                                            Password</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <app-footer></app-footer> -->
</section>