<div class="main-container">
  <div class="container d-flex">
    <div class="left-container" [ngClass]="type == 'SIGNUP' ? 'max-widdth' : ''"
    
    [ngClass]="isHiddenImage ? 'w-100 ffssf' : ''">
      <h3 class="title">{{title}}</h3>
      <p class="mb-4">{{subTitile}}</p>
      <ng-content></ng-content>
    </div>
    <div class="right-container" *ngIf="!isHiddenImage">
      <div class="image-container">
        <img [src]="sideImage || 'assets/svg/authImage.svg' " alt="Image" class="img-fluid">
      </div>
    </div>
  </div>
</div>