<div class="container mb-5 ">
    <app-linker-route [headingName]="headingName"  [linkerRouteItems]="linkerRouteItems"></app-linker-route>
  </div>
<div class="container wrapper-content">
    <body>
      <table>
        <thead>
          <tr>
            <th>S No.</th>
            <th>Customer Name</th>
            <th>Order ID</th>
            <th>Date & Time</th>
            <th>Total Receive Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
          let item of paymentData
            | paginate
              : {
                  itemsPerPage: pageSize,
                  currentPage: currentPage,
                  totalItems: totalItems
                };
          let i = index
        ">
            <td>{{pageSize * (currentPage - 1) + i+1}}</td>
            <td>{{item?.userDetails?.firstName}} {{item?.userDetails?.lastName}}</td>
            <td>{{item?.orderDetails?.orderId}}</td>
            <td >{{item?.createdAt | date: 'yyyy-MM-dd h:mm:ss a' }}</td>
            <td style="color: #0BB412;">{{item?.orderAmount ? getPrice(item?.orderAmount | currency:"ZAR":"R") :
                '--' }}</td>
          </tr>
       
        </tbody>
      </table>
      <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'45px'" ></app-table-loader>

      <div
      class="custom-pagination mt-2 text-align-right"
      style="text-align: right"
      *ngIf="totalItems > 10"
    >
      <pagination-controls
        (pageChange)="pagination($event)"
      ></pagination-controls>
    </div>
    </body>
    </div>