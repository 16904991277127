import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators'

@Injectable()
export class HttpResInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      filter(event => event instanceof HttpResponse),
      map((event: HttpResponse<any>) => event.clone({
        body: {
          responseCode: event.body.code || event.body.statusCode || event.body.responseCode,
          responseMessage: event.body.message || event.body.statusMessage || event.body.responseMessage,
          result: event.body.result || event.body
        }
      }))
    );
  }
}
