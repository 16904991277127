import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-customer-category-list',
  templateUrl: './customer-category-list.component.html',
  styleUrls: ['./customer-category-list.component.scss'],
})
export class CustomerCategoryListComponent implements OnInit {
  _id: any;
  currentPage = 1;
  pageSize = 15;
  serviceName: any;
  loggedInUserType: any;
  headingName: any = 'CATEGORY'
  linkerRouteItems: any = [
    { name: 'Home', route: '/home' },
    { name: 'Categroy', route: '/customer-category-list' }
  ]
  constructor(
    private activatedroute: ActivatedRoute,
    public commonService: CommonService,
    private router: Router,
    public authService: AuthService
  ) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    });
  }

  ngOnInit(): void {
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res;
    });
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn();
    this.getServiceList();
  }

  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any;
  totalItems: any;
  isLoading: boolean = false;
  // get retailer detail by id
  flag : boolean = true
  getServiceList() {
    let apiReqUrl: any = `admin/listCategory`;
    let apiReqData = {
      search: this.serviceName,
      page: String(this.currentPage),
      limit: String(this.pageSize),
    };
    this.commonService.removeEmptyKey(apiReqData);
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    if (!this.serviceName) {

      // this.commonService.showSpinner();
    }
    this.flag = true
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.productDetails = res.result.docs;
          if (!this.productDetails.length) {
            this.isLoading = true;
          } else {
            this.isLoading = false;
          }
          this.totalItems = res.result.totalDocs;
          this.imageLinks = res['result']['thumbnail'];
          this.commonService.hideSpinner();
        } else {
          this.isLoading = true;
          this.flag = false
          this.productDetails = [];
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },
      (err) => {
        this.isLoading = true;
        this.flag = false
        this.productDetails = [];
        this.commonService.hideSpinner();
        this.commonService.errorToast(err.responseMessage);
      }
    );
  }
  navigateToViewProduct(_id, categoryName) {
    if (this.loggedInUserType == 'RETAILER') {
      this.router.navigate(['/retailer-category-list-view'], {
        queryParams: { _id: _id, categoryName: categoryName },
      });
    } else {
      this.router.navigate(['/customer-category-list-view'], {
        queryParams: { _id: _id, categoryName: categoryName },
      });
    }
  }
  pagination(event) {
    this.currentPage = event;
    this.getServiceList();
  }
  length(n){
    return new Array(n)
  }
}
