import { CommonService } from 'src/app/provide/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuPanelComponent } from 'src/app/components/menu-panel/menu-panel.component';

@Component({
  selector: 'app-retailer-product-list',
  templateUrl: './retailer-product-list.component.html',
  styleUrls: ['./retailer-product-list.component.scss']
})
export class RetailerProductListComponent implements OnInit {
  @ViewChild(MenuPanelComponent) menuController: MenuPanelComponent;
  activeButton = 'product_wholesale'
  headingName:any ='PRODUCTS'
  linkerRouteItems:any =[
    { name:'Home',route: '/retailer-home'},
    {name:'Products',route:'/retailer-product-list'}
  ]
  panelOpenState = false;

  categoryListArray: any = [];
  itemPerPage = 20;
  currentPage = 1;
  totalItems: any;
  categoryId: any;
  images: any;
  subCategoryListArray: any = [];
  productBySubCategoryArray: any = [];
  subCategoryId: any;


  // parentElement: any;
  // classList: any;





  _id: any



  productIds: any

  constructor(private activatedroute: ActivatedRoute, private _formBuilder: FormBuilder, private router: Router, public commonService: CommonService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.productIds = res._id
    })
  }

  // ===========================

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getCategoryList();
    this.getCategoryLists();

    this.getBannerProductList();
    //  this.getSubCategoryList(event);
    // this.productBySubCategory(event);


  }

  showActivebutton(title: any) {
    this.activeButton = title
  }

  tab: any
  select(tab: any) {
    this.tab = tab
  }
  categoryListArrayNew: any

  datalist: any
  // -------------------- get category list --------------------- //
  // -------------------- get category list --------------------- //
  reset() {
    this.subCategoryId = ''
    this.tab = ''
    this.getCategoryList()
  }
  isLoading:boolean =false
  flag:boolean =true
  getCategoryList() {
    let apiReqUrl: any = `product/productOfOwn`
    // this.commonService.showSpinner();
    let apiReqData: any = {
      page: String(this.currentPage),
      limit: String(this.itemPerPage),

    }
    this.isLoading =true
    this.flag =true
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res && res.responseCode == 200 && res.result.docs.length>0) {
        this.categoryListArrayNew = res.result.docs ? res.result.docs : '';

    
          this.isLoading =false
          this.flag =true
   
        this.datalist = res.result.docs [0].thumbnail
        this.totalItems = res.result.total
        this.commonService.hideSpinner();

      } else {
        this.categoryListArrayNew = []
        this.isLoading =true
        this.flag =true
        this.totalItems = 0
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.isLoading =true
        this.flag =true
        this.categoryListArrayNew = []
        this.totalItems = 0
        // this.commonService.errorToast(err.responseMessage);

      }
    })
  }
  searchCategory(id: any) {
    let apiReqUrl: any = `product/productOfOwn`
    this.commonService.showSpinner();
    let apiReqData: any = {
      page: String(this.currentPage),
      limit: String(this.itemPerPage),
      categoryId: id

    }
    // remove empty keys from request body
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200 && res.result.docs.length>0) {
        this.categoryListArrayNew = res.result.docs ? res.result.docs : '';
        this.datalist = res.result.docs[0].thumbnail

        // this.totalItems = res.result.total
        
        

        // thumbnail

        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);

      } else {
        this.categoryListArrayNew = []
        // this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.categoryListArrayNew = []
        // this.totalItems = 0
        // this.commonService.errorToast(err.responseMessage);

      }
    })
  }



  pagination(event: number) {
    
    this.currentPage = event;
    this.getCategoryList()
  }










  // ===========================================================================      ================================





  getCategoryLists() {
    let apiReqUrl: any = `admin/listCategory`
    let apiReqData: any = {
      // page: String(this.currentPage),
      // limit: String(this.itemPerPage)
    }
    // remove empty keys from request body
    // apiReqData = this.commonService['removeEmptyKey'](apiReqData)
    apiReqData = this.commonService.removeEmptyKey(apiReqData)

    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.categoryListArray = res.result.docs ? res.result.docs : '';
        

        // this.totalItems = res.result.total
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.categoryListArray = []
        // this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

  getCategoryId(id: any) {
    this.categoryId = id.target.value
    
    // this.getSubCategoryList()

  }



  getSubCategoryList(id: any) {
    this.currentPage = id.currentPage
    let apiReqUrl = `${'admin/subcategoryListWithCategory' + '?categoryId=' + id._id}`

    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.subCategoryListArray = res.result.subCategory ? res.result.subCategory : '';
        this.menuController.loadSubCategory( this.subCategoryListArray , id.index);
        // this.totalItems = res.result
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.categoryListArray = []
        // this.totalItems = 0
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    })
  }


  getSubCategoryId(id: any) {
    this.subCategoryId = id.target.value
  }


  length(n:number){
    return new Array(n)
  }



  // ==========================================


  productBySubCategory(id: any, checked : boolean) {
    // let apiReqUrl = `${'product/productListByCategory'}`
    let apiReqUrl = `${'product/productOfOwn'}`
    let apiReqData: any = {
      page: String(this.currentPage),
      limit: String(this.itemPerPage),
      subCategoryId: checked ? id : ''
    }
    // remove empty keys from request body
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.categoryListArrayNew = res.result.docs ? res.result.docs : '';
        this.totalItems = res.result.total
        
        this.commonService.hideSpinner();

      } else {
        this.categoryListArrayNew = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        // this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.categoryListArrayNew = []
        this.totalItems = 0
        // this.commonService.errorToast(err.responseMessage);

      }
    })
  }


  productBySubCategoryId(id: any) {
    this.subCategoryId = id.target.value
  }

  productId: any;

  forID(_id: any) {
    this.router.navigate(['/retailer-view-product'], { queryParams: { _id: _id } })
  }

  forEdit(_id: any) {
    this.router.navigate(['/retailer-edit-product'], { queryParams: { _id: _id } })
  }


  productDetails: any;
  imgurl: any


  // =======================bannerApi=========================
  BannerListArray: any

  getBannerProductList() {
    let apiReqUrl: any = `user/listBanner`
    let apiReqData: any = {
      // page: String(this.currentPage),
      limit: String(this.itemPerPage),
    }
    apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.BannerListArray = res.result.docs ? res.result.docs : '';
        // this.datalist = res.result.docs[0].thumbnail
        this.totalItems = res.result.total
        

        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.BannerListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.BannerListArray = []
        this.totalItems = 0
        // this.commonService.errorToast(err.responseMessage);

      }
    })
  }


  getPrice(e: any) {
    
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }


  getSubCateGoryData(e){
    this.select(e.subCategoryName);
    this.productBySubCategory(e._id, e.checked)
  }

}
