import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './../../../provide/common.service';

@Component({
  selector: 'set-fee',
  templateUrl: './set-fee-configuration.component.html',
  styleUrls: ['./set-fee-configuration.component.scss']
})
export class SetFeeConfigurationComponent implements OnInit {

  @Input() getData : boolean = false
  @Output() feeData = new EventEmitter
  btn: any = "Pending"
  orderList: any
  pickupOrderList: any
  count: any
  catName: any
  currentPage: any = 1;
  pageSize: any = 10;
  serviceStatus: any = 'PENDING'
  serviceRole: string
  headingName: any = 'Your Service History'
  isLoading: boolean = false
  notFoundData: boolean = false
  linkerRouteItems: any = [
    { name: 'Home', route: '/service-order-detail' },
    { name: 'Account', route: '/customer-profile' },
    { name: 'Service History', route: '/service-order-detail' }
  ]
  userType: string;

  constructor(public commonService: CommonService, public router: Router) {
    this.serviceRole = localStorage.getItem('serviceRole')

    if(this.serviceRole == "PICKUP"){
      this.userType = "PICKUP_PARTNER"
    }
    else if(this.serviceRole == "DELIVERY"){
      this.userType = "DELIVERY_PARTNER"
    }
    else if(this.serviceRole == "FIELD"){
      this.userType = "FIELD_ENTITY"
    }
    else{
      this.userType = "SERVICE_PROVIDER"
    }
  }

  ngOnInit(): void {
    this.commonService.profileUpdateItem.next(true)
    if(this.getEndUrlSegment() == 'update-fee-pickup-partner'){
      if(this.serviceRole == "PICKUP"){
        this.getpickupOrderList()
      }
      else if(this.serviceRole == "DELIVERY"){
        this.getDeliveryOrderList()
      }
      else if(this.serviceRole == "FIELD"){
        this.getFieldOrderList()
      }
      else{
        this.userType = "SERVICE_PROVIDER"
      }
    }
    else{
      this.getpickupOrderList()
    }


   

  }

  selectBtn(status) {
    this.btn = status;
    this.serviceStatus = this.btn == 'Pending' ? 'PENDING' : 'ACCEPTED'
  

  }

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }


  getpickupOrderList() {
    this.isLoading = true
    this.pickupOrderList = []
    this.count = 0
    let url = 'pickupFee/listUserFee'
    if(this.getEndUrlSegment() == 'update-fee-pickup-partner'){
      url = 'pickupFee/listUserFee'
    }
    else{
      url = `user/listFeeAll?userType=${this.userType}`
    }

    this.commonService.getApi(url, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        this.pickupOrderList = res.result;

        if (res.result.length > 0) {
          this.notFoundData = false
        } else {
          this.notFoundData = true
        }
      }

    }, (err: any) => {
      this.isLoading = false
      this.notFoundData = true
      this.pickupOrderList = []
      if (err.responseCode == 409) {
        this.pickupOrderList = []
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }
  getFieldOrderList() {
    this.isLoading = true
    this.pickupOrderList = []
    this.count = 0
    let url = 'pickupFee/listUserFee'
    if(this.getEndUrlSegment() == 'update-fee-pickup-partner'){
      url = 'pickupFee/listUserStorageFee'
    }
    else{
      url = `user/listFeeAll?userType=${this.userType}`
    }

    this.commonService.getApi(url, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        this.pickupOrderList = res.result;

        if (res.result.length > 0) {
          this.notFoundData = false
        } else {
          this.notFoundData = true
        }
      }

    }, (err: any) => {
      this.isLoading = false
      this.notFoundData = true
      this.pickupOrderList = []
      if (err.responseCode == 409) {
        this.pickupOrderList = []
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  getDeliveryOrderList() {
    this.isLoading = true
    this.pickupOrderList = []
    this.count = 0
    let url = 'pickupFee/listUserFee'
    if(this.getEndUrlSegment() == 'update-fee-pickup-partner'){
      url = 'deliveryFee/listUserFeeByUserId'
    }
    else{
      url = `user/listFeeAll?userType=${this.userType}`
    }

    this.commonService.getApi(url, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        this.pickupOrderList = res.result;

        if (res.result.length > 0) {
          this.notFoundData = false
        } else {
          this.notFoundData = true
        }
      }

    }, (err: any) => {
      this.isLoading = false
      this.notFoundData = true
      this.pickupOrderList = []
      if (err.responseCode == 409) {
        this.pickupOrderList = []
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }
  onSubmit(_id, pickupFee) {
    let arr = []
    let data = {

    }
    if(this.getEndUrlSegment() == 'update-fee-pickup-partner'){
      
  
      data = {
        _id : _id,
        pickupFee : pickupFee
  
      }
    }
    else{

      
      for (const item of this.pickupOrderList) {
        let obj = {
          deliveryType : item.deliveryType,
          feeName : item.feeName,
          pickupFee : item.pickupFee
        }
        arr.push(obj)
      }
  
      data = {
        pickupFeeArray : arr,
  
      }
    }
    console.log(data);
    

    

    this.commonService.postApi('pickupFee/updateUserFeeDetail?userType=PICKUP_PARTNER',data, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        if(this.getEndUrlSegment() == 'update-fee-pickup-partner'){

        
        }
        else{
          this.router.navigate(['/login-service-provider'])
        }
       
      }

    }, (err: any) => {
    
     
      if (err.responseCode == 409) {
       
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })

  }

  onSubmitStorage(item) {
    let arr = []
    let data = {

    }

      
  
      data = {
        _id : item._id,
        minimumSize : item.sizeType.minimumSize,
        maximumSize : item.sizeType.maximumSize,
        daily : item.storageFee.daily,
        weekly : item.storageFee.weekly,
        monthly : item.storageFee.monthly,
        quarterly : item.storageFee.quarterly,
  
      }
  
    console.log(data);
    

    

    this.commonService.putApi('storageFee/updateStoragageFeeDetail',data, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        if(this.getEndUrlSegment() == 'update-fee-pickup-partner'){

        
        }
        else{
          this.router.navigate(['/login-service-provider'])
        }
       
      }

    }, (err: any) => {
    
     
      if (err.responseCode == 409) {
       
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })

  }
  onSubmitDelivery(item) {
    let arr = []
    let data = {

    }

      
  
      data = {
        _id : item._id,
        deliveryFee : String(item.deliveryFee),
        standardFee : String(item.standardFee)
  
      }
  
    console.log(data);
    

    

    this.commonService.putApi('deliveryFee/updateFeeDetail',data, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        if(this.getEndUrlSegment() == 'update-fee-pickup-partner'){

        
        }
        else{
          this.router.navigate(['/login-service-provider'])
        }
       
      }

    }, (err: any) => {
    
     
      if (err.responseCode == 409) {
       
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })

  }
  onDataGet(){
    let arr = []
    if(this.serviceRole == 'PICKUP'){
      for (const item of this.pickupOrderList) {
        let obj = {
          deliveryType : item.deliveryType,
          feeName : item.feeName,
          pickupFee : item.pickupFee
        }
        arr.push(obj)
      }
    }
    else if(this.serviceRole == 'DELIVERY'){
      for (const item of this.pickupOrderList) {
        let obj = {
          deliveryFee : item.deliveryFee,
          deliveryType : item.deliveryType,
          feeName : item.feeName,
          standardFee : item.standardFee,
          type : item.type
        }
        arr.push(obj)
      }
    }
    else if(this.serviceRole == 'FIELD'){
      for (const item of this.pickupOrderList) {
        let obj = {
          feeName : item.feeName,
          sizeType : item.sizeType,
          daily : item.storageFee.daily,
          monthly : item.storageFee.monthly,
          quarterly : item.storageFee.quarterly,
          weekly : item.storageFee.weekly
        }
        arr.push(obj)
      }
    }


      this.feeData.emit(arr)
  
     
  }

  changePageNumber(page) {
    this.currentPage = page;
    // this.serviceOrderList();
  }
  getEndUrlSegment(): string {
    const url = this.router.url;
    const segments = url.split('/');
    const lastSegmentWithQueryParamsAndFragments = segments[segments.length - 1];
    const endUrlSegment = lastSegmentWithQueryParamsAndFragments.split('?')[0].split('#')[0];
    return endUrlSegment;
  }

}


