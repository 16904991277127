import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.scss']
})
export class ProductReviewComponent implements OnInit {
  headingName: any = 'Process of Goods Collection'
  linkerRouteItems: any = [
    { name: 'Home', route: '/service-order-detail' },
    { name: 'Process of Goods Collection', route: '/service-order-detail-view' }
  ]
  showModal = false
  private _id: any;
  retailerId: any;
  pro_length: number = 0
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res) => {
      this._id = res._id,
        this.retailerId = res.retailerId
    })
  }

  ngOnInit(): void {
    this.getpickupOrderDeltail()
  }
  orderResultDetail: any
  getpickupOrderDeltail() {
    let url = `deliveryOrders/viewAssignedProduct?_id=${this._id}&retailerId=${this.retailerId}`
    this.commonService.showSpinner()
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.orderResultDetail = res.result.products
        this.pro_length = res.result.products.length
        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ');
  }
  openGoogleMapsWithRouting(lat, long) {
    var origin = "Your Current Location"; // You can replace this with your actual origin address or coordinates
    var destination = lat + "," + long;

    var mapsUrl = "https://www.google.com/maps/dir/?api=1&origin=" + encodeURIComponent(origin) + "&destination=" + encodeURIComponent(destination);

    window.location.href = mapsUrl;
  }
  openOtpModal() {
    // this.showModal = true
    let url = `deliveryOrders/sendOtp?_id=${this._id}&retailerId=${this.retailerId}`
    this.commonService.showSpinner()
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.showModal = true
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }
  navDetail : any
  navigateModal = false
  onSumbitOtp(e) {



    let url = `deliveryOrders/verifyOtp?_id=${this._id}&retailerId=${this.retailerId}`
    this.commonService.showSpinner()
    this.commonService.postApi(url,{otp : e} ,1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.showModal = false
        this.navigateModal = true
        let coordinates = res.result.storeLocation.coordinates
        this.navDetail = {
          text : 'Start navigate to fulfilment entity?',
          navLat : coordinates[1],
          navLong : coordinates[0],

        }

  
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
    
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      
    })
  }
  onResend() {

  }
  idsArray = []
  getId(isChecked, id) {

    if (isChecked) {
      if (this.idsArray.includes(id)) {
        return
      }
      this.idsArray.push(id)
    }
    else {
      let findIdx = this.idsArray.findIndex((ele) => { return ele == id })
      this.idsArray.splice(findIdx, 1)
    }

  }
  loading = false
  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;
  
    }, 60000); // 1 minute
  }
}
