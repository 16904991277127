<div class="main">
    <div class="row">
        <div class="col-lg-6 col-sm-12 text-center" *ngFor="let item of feedBackData;let i = index">
            <h3>{{item.title}}</h3>
            <fieldset class="rate">
                <input type="radio" [disabled]="isFeedbackDataSubmited" (change)="getSelectedRating($event.target.value,i)" [attr.id]="'rating10'+i"  [attr.name]="'rating'+i" value="10" /><label [attr.for]="'rating10'+i" title="5 stars"></label>
                <input type="radio" [disabled]="isFeedbackDataSubmited" (change)="getSelectedRating($event.target.value,i)"  [attr.id]="'rating9'+i" [attr.name]="'rating'+i" value="9" /><label class="half" [attr.for]="'rating9'+i" title="4 1/2 stars"></label>
                <input type="radio" [disabled]="isFeedbackDataSubmited" (change)="getSelectedRating($event.target.value,i)"  [attr.id]="'rating8'+i" [attr.name]="'rating'+i" value="8" /><label [attr.for]="'rating8'+i" title="4 stars"></label>
                <input type="radio" [disabled]="isFeedbackDataSubmited" (change)="getSelectedRating($event.target.value,i)"  [attr.id]="'rating7'+i" [attr.name]="'rating'+i" value="7" /><label class="half" [attr.for]="'rating7'+i" title="3 1/2 stars"></label>
                <input type="radio" [disabled]="isFeedbackDataSubmited" (change)="getSelectedRating($event.target.value,i)"  [attr.id]="'rating6'+i" [attr.name]="'rating'+i" value="6" /><label [attr.for]="'rating6'+i" title="3 stars"></label>
                <input type="radio" [disabled]="isFeedbackDataSubmited" (change)="getSelectedRating($event.target.value,i)"  [attr.id]="'rating5'+i" [attr.name]="'rating'+i" value="5" /><label class="half" [attr.for]="'rating5'+i" title="2 1/2 stars"></label>
                <input type="radio" [disabled]="isFeedbackDataSubmited" (change)="getSelectedRating($event.target.value,i)"  [attr.id]="'rating4'+i" [attr.name]="'rating'+i" value="4" /><label [attr.for]="'rating4'+i" title="2 stars"></label>
                <input type="radio" [disabled]="isFeedbackDataSubmited" (change)="getSelectedRating($event.target.value,i)"  [attr.id]="'rating3'+i" [attr.name]="'rating'+i" value="3" /><label class="half" [attr.for]="'rating3'+i" title="1 1/2 stars"></label>
                <input type="radio" [disabled]="isFeedbackDataSubmited" (change)="getSelectedRating($event.target.value,i)"  [attr.id]="'rating2'+i" [attr.name]="'rating'+i" value="2" /><label [attr.for]="'rating2'+i" title="1 star"></label>
                <input type="radio" [disabled]="isFeedbackDataSubmited" (change)="getSelectedRating($event.target.value,i)"  [attr.id]="'rating1'+i" [attr.name]="'rating'+i" value="1" /><label class="half" [attr.for]="'rating1'+i" title="1/2 star"></label>
            
            </fieldset>
            <textarea [(ngModel)]="review[i]" [readOnly]="isFeedbackDataSubmited" class="form-control" placeholder="Enter Review"></textarea>
        </div>
        <div class="col-12 text-center" style="margin-top: 40px;" *ngIf="!isFeedbackDataSubmited">
            <button class="btn btn-theme" (click)="submitFeedback()">Submit Feedback</button>
        </div>
    
    </div>
   
    
  
</div>

