<div class="container mb-5 ">
    <app-linker-route [headingName]="headingName"  [linkerRouteItems]="linkerRouteItems"></app-linker-route>
  </div>
<div class="container mb-5 wrapper-content">
        <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3" >
            <div class=" mb-3 col newCardWidth" *ngFor="let item of serviceDetail, let i = index" [routerLink]="['/service-provider-view-deals']" [queryParams]="{_id: item?._id}">
                <service-provider-deal-layout [data]="item" [countDown]="countDown[i]" (on_Click)="navigateToViewProduct(item?._id)"></service-provider-deal-layout>
             <!-- <deals-layout-service [data]="item" [countDown]="countDown[i]" (on_Click)="navigateToViewProduct()"> -->
        <!-- </deals-layout-service> -->
            </div>
        </div>
    <div *ngIf="isLoading" class="no-data">
        <p style="text-align: center; font-size: 16px; font-weight: 500;">No Data Found</p>
    </div>
</div>