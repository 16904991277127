import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  panelOpenState = false; //accordian open close question's and answer's
  //variable declare of api response
  // visible: boolean = false;
  // buttontype: boolean = true;
  pageName: any;
  result: any;


  // viewpass() {
  //   this.visible = !this.visible;
  //   this.buttontype = !this.buttontype;
  // }

  constructor(private fb: FormBuilder, http: HttpClient, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.faq()
  }


  /**
   * @private call get api of faq list data 
   */
  faq() {
    let url = "static/faqList"
    // this.commonService.showSpinner()
    this.commonService.getApi(url, 0).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.result = res["result"]
        // this.pageName = res["result"]
        this.pageName = String(this.pageName).split(/(?=[A-Z])/).join(" ")
        this.commonService.hideSpinner()
        // this.commonService.successToast(res.responseMessage);
        // this.service.successToast(res['responseMessage'])
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }
    )
  }

}
