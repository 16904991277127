<div id="sidebar">
    <h3>Shop by Category </h3>
    <ul data-accordion class="bx--accordion">
        <li data-accordion-item class="bx--accordion__item" *ngFor="let item of categoryListArray;let i = index">
            <button (click)="getSubCategoryList(item?._id,i);currentPage=1" class="bx--accordion__heading" aria-expanded="false" aria-controls="pane1">
                <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;"
                    xmlns="http://www.w3.org/2000/svg" class="bx--accordion__arrow" width="16" height="16"
                    viewBox="0 0 16 16" aria-hidden="true">
                    <path d="M11 8l-5 5-.7-.7L9.6 8 5.3 3.7 6 3z"></path>
                </svg>
                <div class="bx--accordion__title">{{item?.categoryName | titlecase}} 
                    <!-- <span class="clear">Clear</span> -->
                </div>
            </button>
            <div id="pane1" class="bx--accordion__content">
                <ul data-accordion class="categories">
                    <li>
                        <ul>
                           
                                <li class="subcat" *ngFor="let items of item?.subCategoryListArray;let j = index">
                                    <input type="radio" [attr.id]="'all'+j+''+i"
                                    (change)="rawData(items, $event)" name="subcat"
                                    >
                                    <label [attr.for]="'all'+j+''+i" style="white-space: pre;">{{items?.subCategoryName}}
                                    </label>
                                        <!-- <span class="count">123K</span> -->
                                </li>
                                <li *ngIf="!item?.subCategoryListArray?.length">
                                    <p>loading...</p>
                                </li>
                  
                          
                      
                        </ul>
                    </li>
                   
                </ul>
            </div>
        </li>
       
    </ul>
    
</div>



<!-- <li data-accordion-item class="bx--accordion__item">
    <button class="bx--accordion__heading" aria-expanded="false" aria-controls="pane1">
        <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;"
            xmlns="http://www.w3.org/2000/svg" class="bx--accordion__arrow" width="16" height="16"
            viewBox="0 0 16 16" aria-hidden="true">
            <path d="M11 8l-5 5-.7-.7L9.6 8 5.3 3.7 6 3z"></path>
        </svg>
        <div class="bx--accordion__title">Category 

        </div>
    </button>
    <div id="pane1" class="bx--accordion__content">
        <ul data-accordion class="categories">
            <li>Footwear<span class="count">123K</span>
                <ul>
                    <li class="subcat"><input type="checkbox" id="all"><label for="all">All
                            Footwear</label><span class="count">123K</span>
                    </li>
                    <li class="subcat"><input type="checkbox" id="low"><label for="low">Low-Top
                            Sneakers</label><span class="count">97K</span>
                    </li>
                    <li class="subcat"><input type="checkbox" id="high"><label for="high">High-Top
                            Sneakers</label><span class="count">93K</span>
                    </li>
                    <li class="subcat"><input type="checkbox" id="boots"><label for="boots">Boots</label><span
                            class="count">31K</span>
                    </li>
                    <li class="subcat"><input type="checkbox" id="casual"><label for="casual">Casual Leather
                            Shoes</label><span class="count">23K</span>
                    <li class="subcat"><input type="checkbox" id="formal"><label for="formal">Formal
                            Shoes</label><span class="count">18K</span>
                    <li class="subcat"><input type="checkbox" id="sand"><label for="sand">Sandals</label><span
                            class="count">2K</span>
                    <li class="subcat"><input type="checkbox" id="slip"><label for="slip">Slip Ons</label><span
                            class="count">456</span>
                    </li>
                </ul>
            </li>
            <li>Tops<span class="count">123K</span></li>
            <li>Bottoms<span class="count">123K</span></li>
            <li>Outerwear<span class="count">123K</span></li>
            <li>Tailoring<span class="count">123K</span></li>
            <li>Accessories<span class="count">13K</span></li>
        </ul>
    </div>
</li>
<li data-accordion-item class="bx--accordion__item">
    <button class="bx--accordion__heading" aria-expanded="false" aria-controls="pane1">
        <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;"
            xmlns="http://www.w3.org/2000/svg" class="bx--accordion__arrow" width="16" height="16"
            viewBox="0 0 16 16" aria-hidden="true">
            <path d="M11 8l-5 5-.7-.7L9.6 8 5.3 3.7 6 3z"></path>
        </svg>
        <div class="bx--accordion__title">Size</div>
    </button>

    <div id="pane1t5" class="bx--accordion__content">
        <div class="sizes">
            <div class="d-flex flex-wrap">
                <div class="item1">
                    <div id="ck-button">
                        <label>
                            <input type="checkbox" value="1"><span>XXS <span class="eu">40</span></span>

                        </label>
                    </div>
                </div>
                <div class="item2">
                    <div id="ck-button">
                        <label>
                            <input type="checkbox" value="1"><span>XS <span class="eu">42</span></span>
                        </label>
                    </div>
                </div>

                <div class="item3">
                    <div id="ck-button">
                        <label>
                            <input type="checkbox" value="1"><span>S<span class="eu">44-46</span></span>

                        </label>
                    </div>
                </div>

                <div class="item4">
                    <div id="ck-button">
                        <label>
                            <input type="checkbox" value="1"><span>M <span class="eu">48-50</span></span>

                        </label>
                    </div>
                </div>

                <div class="item5">
                    <div id="ck-button">
                        <label>
                            <input type="checkbox" value="1"><span>L <span class="eu">52-54</span></span>

                        </label>
                    </div>
                </div>

                <div class="item6">
                    <div id="ck-button">
                        <label>
                            <input type="checkbox" value="1"><span>XL <span class="eu">56</span></span>

                        </label>
                    </div>
                </div>

                <div class="item7">
                    <div id="ck-button">
                        <label>
                            <input type="checkbox" value="1"><span>XXL <span class="eu">58</span></span>

                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</li>
<li data-accordion-item class="bx--accordion__item">
    <button class="bx--accordion__heading" aria-expanded="false" aria-controls="pane2">
        <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;"
            xmlns="http://www.w3.org/2000/svg" class="bx--accordion__arrow" width="16" height="16"
            viewBox="0 0 16 16" aria-hidden="true">
            <path d="M11 8l-5 5-.7-.7L9.6 8 5.3 3.7 6 3z"></path>
        </svg>
        <div class="bx--accordion__title">Designer</div>
    </button>
    <div id="pane2" class="bx--accordion__content">

        <input class="dsearch" type="text" placeholder="Search Designers" name="search">

        <ul class="designers">
            <li class="subcat"><input type="checkbox" id="sup"><label for="sup">Supreme</label><span
                    class="count">123K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="bape"><label for="bape">Bape</label><span
                    class="count">97K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="gucci"><label for="gucci">Gucci</label><span
                    class="count">93K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="bal"><label for="bal">Balenciaga</label><span
                    class="count">31K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="prada"><label for="prada">Prada</label><span
                    class="count">23K</span></li>
            <li class="subcat"><input type="checkbox" id="formal"><label for="formal">Formal Shoes</label><span
                    class="count">18K</span></li>
        </ul>
    </div>
</li>
<li data-accordion-item class="bx--accordion__item">
    <button class="bx--accordion__heading" aria-expanded="false" aria-controls="pane3">
        <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;"
            xmlns="http://www.w3.org/2000/svg" class="bx--accordion__arrow" width="16" height="16"
            viewBox="0 0 16 16" aria-hidden="true">
            <path d="M11 8l-5 5-.7-.7L9.6 8 5.3 3.7 6 3z"></path>
        </svg>
        <div class="bx--accordion__title">Price</div>
    </button>
    <div id="pane3" class="bx--accordion__content">

     
        <ul class="designers">

            <li class="subcat"><input type="checkbox" id="50"><label for="50">Under $50</label><span
                    class="count">97K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="100"><label for="100">Under $100</label><span
                    class="count">93K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="250"><label for="250">Under $250</label><span
                    class="count">31K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="500"><label for="500">Under $500</label><span
                    class="count">23K</span></li>
            <li class="subcat"><input type="checkbox" id="1000"><label for="1000">Under $1,000</label><span
                    class="count">18K</span></li>
            <li class="subcat"><input type="checkbox" id="5000"><label for="5000">Under $5,000</label><span
                    class="count">18K</span></li>
            <li class="subcat"><input type="checkbox" id="5001"><label for="5001">$5,000 +</label><span
                    class="count">18K</span></li>
        </ul>
    </div>
</li>
<li data-accordion-item class="bx--accordion__item">
    <button class="bx--accordion__heading" aria-expanded="false" aria-controls="pane4">
        <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;"
            xmlns="http://www.w3.org/2000/svg" class="bx--accordion__arrow" width="16" height="16"
            viewBox="0 0 16 16" aria-hidden="true">
            <path d="M11 8l-5 5-.7-.7L9.6 8 5.3 3.7 6 3z"></path>
        </svg>
        <div class="bx--accordion__title">Condition</div>
    </button>
    <div id="pane4" class="bx--accordion__content">
        <ul class="designers">

            <li class="subcat"><input type="checkbox" id="new"><label for="new">New/Never Worn</label><span
                    class="count">97K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="gent"><label for="gent">Gently Used</label><span
                    class="count">93K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="used"><label for="used">Used</label><span
                    class="count">31K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="very"><label for="very">Very Worn</label><span
                    class="count">23K</span></li>

        </ul>
    </div>
</li> -->

<!-- <li data-accordion-item class="bx--accordion__item">
    <button class="bx--accordion__heading" aria-expanded="false" aria-controls="pane4">
        <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;"
            xmlns="http://www.w3.org/2000/svg" class="bx--accordion__arrow" width="16" height="16"
            viewBox="0 0 16 16" aria-hidden="true">
            <path d="M11 8l-5 5-.7-.7L9.6 8 5.3 3.7 6 3z"></path>
        </svg>
        <div class="bx--accordion__title">Seller Location</div>
    </button>
    <div id="pane4" class="bx--accordion__content">
        <ul class="designers">

            <li class="subcat"><input type="checkbox" id="us"><label for="us">United States</label><span
                    class="count">97K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="uk"><label for="uk">United Kingdom</label><span
                    class="count">93K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="eu"><label for="eu">Europe</label><span
                    class="count">31K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="asia"><label for="asia">Asia</label><span
                    class="count">23K</span></li>
            <li class="subcat"><input type="checkbox" id="anz"><label for="anz">Austraila/NZ</label><span
                    class="count">23K</span></li>
            <li class="subcat"><input type="checkbox" id="can"><label for="can">Canada</label><span
                    class="count">23K</span></li>
            <li class="subcat"><input type="checkbox" id="oth"><label for="oth">Other</label><span
                    class="count">23K</span></li>
        </ul>
    </div>
</li>
<li data-accordion-item class="bx--accordion__item">
    <button class="bx--accordion__heading" aria-expanded="false" aria-controls="pane3">
        <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;"
            xmlns="http://www.w3.org/2000/svg" class="bx--accordion__arrow" width="16" height="16"
            viewBox="0 0 16 16" aria-hidden="true">
            <path d="M11 8l-5 5-.7-.7L9.6 8 5.3 3.7 6 3z"></path>
        </svg>
        <div class="bx--accordion__title">Market</div>
    </button>
    <div id="pane3" class="bx--accordion__content">
        <ul class="designers">

            <li class="subcat"><input type="checkbox" id="grails"><label for="grails">Grails</label><span
                    class="count">97K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="hype"><label for="hype">Hype</label><span
                    class="count">93K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="core"><label for="core">Core</label><span
                    class="count">31K</span>
            </li>
            <li class="subcat"><input type="checkbox" id="sort"><label for="sort">Sortorial</label><span
                    class="count">23K</span></li>
        </ul>
    </div>
</li>
<li data-accordion-item class="bx--accordion__item">
    <button class="bx--accordion__heading" aria-expanded="false" aria-controls="pane4">
        <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;"
            xmlns="http://www.w3.org/2000/svg" class="bx--accordion__arrow" width="16" height="16"
            viewBox="0 0 16 16" aria-hidden="true">
            <path d="M11 8l-5 5-.7-.7L9.6 8 5.3 3.7 6 3z"></path>
        </svg>
        <div class="bx--accordion__title">Show Only</div>
    </button>
    <div id="pane4" class="bx--accordion__content">
        <ul class="designers">
            <li class="subcat"><input type="checkbox" id="staff"><label for="staff">Staff Picks</label><span
                    class="count">97K</span>
            </li>
        </ul>
    </div>
</li>
<li data-accordion-item class="bx--accordion__item">
    <button class="bx--accordion__heading" aria-expanded="false" aria-controls="pane4">
        <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;"
            xmlns="http://www.w3.org/2000/svg" class="bx--accordion__arrow" width="16" height="16"
            viewBox="0 0 16 16" aria-hidden="true">
            <path d="M11 8l-5 5-.7-.7L9.6 8 5.3 3.7 6 3z"></path>
        </svg>
        <div class="bx--accordion__title">My Saved Filters</div>
    </button>
    <div id="pane4" class="bx--accordion__content">
        <p>You have no saved filters.</p>
    </div>
</li> -->