import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
declare const gapi: any; 
@Component({
  selector: 'app-login-retailer',
  templateUrl: './login-retailer.component.html',
  styleUrls: ['./login-retailer.component.scss']
})
export class LoginRetailerComponent implements OnInit {

  loginForm!: FormGroup;
  user: any;



  // foricon eye
  visible: boolean = false;
  changetype: boolean = true;
  route: any;


  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }



  constructor(private fb: FormBuilder, private router: Router, private http: HttpClient, public commonService: CommonService, public authService: AuthService) { }

  ngOnInit(): void {


    window.scrollTo(0, 0);

    this.loginForm = this.fb.group({
      'email': new FormControl('', [
        Validators.required,
        // Validators.pattern('[a-z.-]{1,}@[a-z.-]{2,}[.]{1}[a-z]{2,5}')
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]
      ),
      'password': new FormControl('', [
        Validators.required,
        // Validators.pattern("(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$"),
      ]
      ),

      checkbox: new FormControl(false)

    })


    // remember me
    let checkbox = localStorage.getItem('retailerLogin') ? JSON.parse(localStorage.getItem('retailerLogin')!) : '';
    if (checkbox) {
      this.loginForm.patchValue({
        "email": atob(checkbox.email),
        "password": atob(checkbox.password),
        "checkbox": checkbox.checkbox
      })
    }

  }




  // ================================================



  /**
    * login api
    */
  login() {
    let apiReqData = {
      userType: 'RETAILER',
      emailNumberId: this.loginForm.value.email,
      password: this.loginForm.value.password,
    }
    this.commonService.showSpinner()
    this.commonService.postApi('user/usersLogin', apiReqData, 0).subscribe((res: any) => {
      
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner()
        if(!res.result.otpVerification){
          
        }
        // localStorage.setItem('token', res.result.token);
        localStorage.setItem('userType', 'RETAILER');
        localStorage.setItem('userId', res.result._id);
        localStorage.setItem('email', this.loginForm.value.email)
        if(res.result.primaryAddressId != 0){
          localStorage.setItem('primaryAddressId', res.result.primaryAddressId)
        }
        localStorage.setItem('completeProfile', res.result.completeProfile)
        localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
        this.authService.SharingData.next(res.result.completeProfile);
        this.authService.userRequestStatus.next(res.result.userRequestStatus);
   
        this.authService.loggedInUserType.next('RETAILER');
        if (res.result.completeProfile && res.result.userRequestStatus == 'APPROVED') {
          localStorage.setItem('token', res['result']['token'])
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          localStorage.setItem('myId', res['result']['_id'])
          this.router.navigate(['/retailer-home'])
          this.authService.isLoggedIn.next(true);
        }
  
        else if (res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 2) {
          this.commonService.errorToast('Thanks For Connecting With Us. We will verify your account and will send your approval soon.')
          this.authService.logout()
        }
        else if (!res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 0) {
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          localStorage.setItem('token-auth', res['result']['token'])
          this.router.navigate(['/business-details-retailer']);
        }
        else if (!res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 1) {
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          localStorage.setItem('token-auth', res['result']['token'])
          this.router.navigate(['/upload-document-retailer']);
        }

        else if (res.result.completeProfile && res.result.userRequestStatus == 'REJECTED') {
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          localStorage.setItem('token-auth', res['result']['token'])
          this.router.navigate(['/upload-document-retailer']);
          if (res.responseCode == 200) {
            this.commonService.errorToast(res.result.rejectReason)
          } else {
            this.commonService.errorToast('Your application has been rejected. Plesae complete the application and then proceed')
          }
        }
        else if (!res.result.completeProfile && res.result.userRequestStatus == 'REJECTED') {
          localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
          localStorage.setItem('token-auth', res['result']['token'])
          this.router.navigate(['/business-details-retailer']);
          if (res.responseCode == 200) {
            this.commonService.errorToast(res.result.rejectReason)
          } else {
            this.commonService.errorToast('Your application has been rejected. Plesae complete the application and then proceed')
          }
        } else if (res.result.userRequestStatus == 'PENDING') {
          this.commonService.errorToast("Thanks For Connecting With Us. We will verify your account and will send your approval soon.")
        }

        if (this.loginForm.value.checkbox == true) {
          let remData = {
            "email": btoa(this.loginForm.value.email),
            "password": btoa(this.loginForm.value.password),
            "checkbox": this.loginForm.value.checkbox
          }
          localStorage.setItem('retailerLogin', JSON.stringify(remData))


        } else {
          localStorage.removeItem('retailerLogin')
        }

      } else {
        this.commonService.hideSpinner()
        this.commonService.errorToast(res['responseMessage'])
      }
    }, (err: any) => {
      if (err.responseCode == 402) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
      else if (err.responseCode == 404) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }


  togglePasswordVisibility() {
    this.changetype = !this.changetype;
}


  preventSpace(event: any) {
    if ((event.charCode == 32) && !event.target.value) {
      event.preventDefault();
    }
  }
  changePasswordIcon(){
    this.changetype=!this.changetype
  }
  signInWithGoogle() {
    gapi.auth2.getAuthInstance().signIn();
  }
}
