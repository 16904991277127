import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-layout',
  templateUrl: './payment-layout.component.html',
  styleUrls: ['./payment-layout.component.scss']
})
export class PaymentLayoutComponent implements OnInit {
  headingName:any ='PAYMENT HISTORY'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    { name:'Account',route: '/customer-profile'},
    {name:'Address',route:'/customer-address-list'}
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
