<auth-layout title="Buisness Detail" type="SIGNUP" subTitile="">

    <form [formGroup]="businessform">
        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label" id="rr">First Name<span class="yy">*</span></label>
                <input type="text" class="form-control mt-2" formControlName="fName" maxlength="60"
                    placeholder="Enter First Name">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('fName')?.hasError('required') && isSubmitted">
                        Please enter first name.</div>

                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('fName')?.hasError('minlength') && businessform.get('fName')?.dirty ">
                        *Please enter minimum 2 character.</div>
                </div>

            </div>
            <div class="w-48">
                <label for="datepicker" class="for-label">Last Name<span class="yy">*</span></label>
                <input type="text" class="form-control mt-2 " formControlName="lName" (keypress)="preventSpace($event)"
                    maxlength="32" placeholder=" Enter Last Name">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('lName')?.hasError('required')  && isSubmitted ">
                        Please enter last name.</div>

                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('lName')?.hasError('minlength') && businessform.get('lName')?.dirty ">
                        *Please enter minimum 2 character.</div>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label">Email Address<span class="yy">*</span></label>
                <input type="text" class="form-control mt-2" formControlName="email" (keypress)="preventSpace($event)"
                    maxlength="250" placeholder="Enter Email Address">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('email')?.hasError('required')  && isSubmitted">
                        *Email is required.</div>
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('email')?.hasError('pattern') && businessform.get('email')?.dirty ">
                        *Please
                        enter a valid email</div>
                </div>
            </div>


            <div class="w-50">
                <label for="username">Phone Number (optional )</label>
                <div class="input-control d-flex">


                    <select id="browsers" class="form-select tel mt-2" formControlName="countryCode">

                        <option *ngFor="let code of products" value="{{code?.phonecode}}">{{code?.flag}}
                            {{code?.phonecode}}
                            {{code?.isoCode}} </option>

                    </select>
                    <input type="text" class="form-control tel mt-2" (keypress)="preventSpace($event)"
                        (keypress)="NumOnly($event)" placeholder="Enter Phone Number" formControlName="Phone"
                        maxlength="15">
                </div>




            </div>


        </div>
        <P class="owner mt-3">Product Details</P>


        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label" id="rr">Number Of Unique Products<span
                        class="yy">*</span></label>
                <input type="text" class="form-control mt-2" formControlName="numberproducts"
                    (keypress)="preventSpace($event);restrictChar($event);NumOnly($event)" maxlength="17"
                    placeholder="Enter number of unique products">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('numberproducts')?.hasError('required')  && isSubmitted">
                        Please Enter Number.</div>
                </div>

            </div>
            <!-- fdf -->
            <div class="w-50">
                <label for="datepicker" class="for-label" style="margin-bottom: 8px;">List of Brands or Products<span
                        class="yy">*</span></label>

                <div class="form-group row" *ngFor="let item of addNewSubcategoryField; let i = index"
                    style="flex-wrap: unset;">
                    <input type="text" class="form-control" placeholder="Enter list Of Brands "
                        [(ngModel)]="item.sub_Category_List" [ngModelOptions]="{standalone: true}"
                        (keypress)="preventSpace($event)">

                    <button *ngIf="addNewSubcategoryField?.length>1" type="button" class="btn8 col-md-2 ml-2"
                        (click)="removeBodySectionDynamic(i)"> <i class="fa fa-trash" aria-hidden="true"></i></button>




                </div>
                <!-- <div class="for-validation text-danger " style="margin-top: -15px;"
                *ngIf="businessform.get('productBrand')?.hasError('required')  && isSubmitted">
                Please Enter List.</div> -->
                <div class="d-flex justify-content-center">
                    <button class="btn9" (click)="addNewSubCategryDynamic()">
                        <i class="fa fa-plus-square-o plusiconSM" aria-hidden="true"></i>
                        Add</button>
                </div>

            </div>

        </div>
        <div class="d-flex justify-content-between">

            <div class="w-100">
                <div class="row">
                    <h6 class="textt">Do you keep stock?

                        <span class="yy">*</span>
                    </h6>

                </div>

                <div class="row">
                    <div class="col p-o">


                        <div class="form-group">
                            <!-- <label>Please select your gender</label> -->
                            <div class="row">
                                <label class="md-check">
                                    <input type="radio" value="true" name="" formControlName="keepStock">
                                    yes
                                </label>
                                <label class="md-check ml-2">
                                    <input type="radio" value="false" name="" formControlName="keepStock">
                                    No
                                </label>

                            </div>
                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('keepStock')?.hasError('required')&& isSubmitted ">
                                * Required.</div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="d-flex justify-content-between">

            <div class="col p-0">
                <div class="row">
                    <h6 class="textt">Do you have a physical store? ( Optional )</h6>

                </div>

                <div class="row">
                    <div class="col p-0">


                        <div class="form-group">
                            <!-- <label>Please select your gender</label> -->
                            <div class="row">
                                <label class="md-check">
                                    <input (click)="openesd()" type="radio" value="true" name=""
                                        formControlName="isPhysicalStore">
                                    Yes I have a store
                                </label>

                                <label class="md-check ml-2">
                                    <input (click)="closeesd()" type="radio" value="false" name=""
                                        formControlName="isPhysicalStore">
                                    No I sell through other channels
                                </label>
                            </div>
                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('isPhysicalStore')?.hasError('required')&& isSubmitted">
                                *Required.</div>
                        </div>




                        <div class="d-flex justify-content-between" *ngIf="showInputes">


                            <div class="w-30">
                                <input type="text" class="form-control" formControlName="storeess"
                                    (keypress)="preventSpace($event)" maxlength="15" placeholder="Store Name">
                                <div class="for-validation mb-4">
                                    <div class="for-validation text-danger"
                                        *ngIf="businessform.get('storeess')?.hasError('required')&& businessform.get('storeess')?.touched ">
                                        *Store Name is required.</div>
                                </div>
                            </div>



                            <div class="w-30">
                                <input type="text" class="form-control" formControlName="storeessed"
                                    (keypress)="preventSpace($event)" maxlength="15" placeholder="Store Address">
                                <div class="for-validation mb-4">
                                    <div class="for-validation text-danger"
                                        *ngIf="businessform.get('storeessed')?.hasError('required') && businessform.get('storeessed')?.touched ">
                                        *Address is required.</div>
                                </div>
                            </div>


                            <div class="w-30">
                                <input type="text" class="form-control" formControlName="storesgg"
                                    (keypress)="preventSpace($event)" maxlength="15" placeholder="Store Contact">
                                <div class="for-validation mb-4">
                                    <div class="for-validation text-danger"
                                        *ngIf="businessform.get('storesgg')?.hasError('required') && businessform.get('storesgg')?.touched ">
                                        *Contact is required.</div>
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </div>

        </div>





        <div class="d-flex justify-content-between mb-4">
            <div class="w-100">
                <label for="datepicker" class="for-label">List your preferred suppliers /
                    wholesalers for
                    stock replenishyment ( Optional )
                </label>


                <div class="form-group d-flex" style="flex-wrap: unset;">
                    <!-- <input list="id-car" class="form-control  mt-2" placeholder="List your preferred suppliers "
                        [(ngModel)]="srarchWholeSaler" [ngModelOptions]="{standalone: true}"
                        (keypress)="preventSpace($event)" (ngModelChange)="filter()">
                    <datalist id="id-car">
                        <option *ngFor="let car of wholeSalerList" [value]="car.firstName">{{car.firstName}}
                        </option>
                    </datalist> -->


                </div>
                <ng-multiselect-dropdown class="form-control  mt-2" [placeholder]="'List your preferred suppliers'"
                    [settings]="dropdownSettings" [data]="wholeSalerList" [(ngModel)]="wholesalerIdNew"  [ngModelOptions]="{standalone: true}" 
                    (onFilterChange)="searchWholeSaler($event)">
                </ng-multiselect-dropdown>
                <!-- <ng-select [items]="wholeSalerList" bindLabel="firstName" bindValue="id" [multiple]="true"
                    [(ngModel)]="srarchWholeSaler" [ngModelOptions]="{ standalone: true }" (ngModelChange)="filter()"
                    placeholder="List your preferred suppliers">
                    <ng-template ng-label-tmp let-item="item">
                        {{ item.firstName }}
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                        {{ item.firstName }}
                    </ng-template>
                </ng-select> -->

                <!-- <ng-select [items]="cities"
                bindLabel="name"
                bindValue="id"
                [multiple]="true"
                groupBy="selectedAllGroup"
                [selectableGroup]="true"
                placeholder="Select cities"
            >
                </ng-select> -->

                <!-- <ng-container *ngIf="selectedCarObjS?.length">
                    <div *ngFor="let hero of selectedCarObjS">
                        <div class="d-flex " style="padding-top: 5px;">
                            <input type="text" class="form-control mt-2" [value]="hero.firstName"
                                *ngIf="hero?.firstName" [(ngModel)]="hero.firstName"
                                [ngModelOptions]="{standalone: true}" readonly>
                            <button *ngIf="selectedCarObjS?.length>=1 && hero?.firstName" class="btn8 col-md-2 ml-2"
                                style="z-index: 999999;" (click)="removeSearchDynamic(1)"> <i class="fa fa-trash"
                                    aria-hidden="true"></i></button>
                        </div>
                    </div>
                </ng-container> -->




            </div>




        </div>
        <div class="d-flex justify-content-between">
            <div class="w-100">
                <div class="form-group row ">
                    <label for="datepicker" class="for-label">Additional Comment? ( optional )</label>
                    <textarea class="form-control mt-2" id="exampleFormControlTextarea1" formControlName="additional"
                        (keypress)="preventSpace($event)" placeholder=" Enter Additional Comment" rows="3"></textarea>


                </div>

            </div>
        </div>

        <p class="owner mt-4">Business Details</p>
        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label">Company Name<span class="yy">*</span></label>
                <input type="text" class="form-control mt-2" formControlName="company" (keypress)="preventSpace($event)"
                    placeholder=" Enter Company Name">

                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('company')?.hasError('required')  && isSubmitted">
                        *Company name is required.</div>
                </div>
            </div>
            <div class="w-50">
                <label for="datepicker" class="for-label">Business Registration Number<span class="yy">*</span></label>
                <input type="text" class="form-control mt-2" formControlName="businessRegNumber" maxlength="17"
                    (keypress)="preventSpace($event)" placeholder=" Enter Business Registration Number">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('businessRegNumber')?.hasError('required')  && isSubmitted">
                        *Registration number is required.</div>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-between">

            <div class="w-48">

                <label for="datepicker" class="for-label">Monthly Revenue<span class="yy">*</span></label>

                <select class="form-control mt-2" formControlName="monthlyRevenue">
                    <option value="">Select Monthly Revenue</option>
                    <option value="Less than R20k">Less than R20k</option>
                    <option value="R20k to R50k monthly">R20k - R50k</option>
                    <option value="R20k to R50k monthly">R20k - R50k</option>
                    <option value="More than R500k monthly">More than R500k</option>

                </select>

            </div>
            <div class="w-50">
                <label for="datepicker" class="for-label">Website’s URL ( Optional )
                </label>
                <input type="text" class="form-control mt-2" formControlName="url" (keypress)="preventSpace($event)"
                    placeholder=" Enter The Url">
                <div class="for-validation mb-4">

                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('url')?.hasError('pattern') && businessform.get('url')?.dirty">
                        * Please enter valid url required.
                    </div>
                </div>
            </div>

        </div>


        <div class="d-flex justify-content-between">
            <!-- <div class="col">
                            <label for="datepicker" class="for-label">Birthdate</label>
                            <input type="date" class="form-control" formControlName="birthdate" id="datepicker">
                        </div> -->
            <div class="w-100">
                <div class="row">
                    <p class="textt">Are you VAT registered?
                        <!-- <span class="yy">y</span>
                        ou VAT <span class="yy">r</span>
                        egistered? -->
                    </p>

                </div>



                <div class="row">
                    <div class="col">
                        <div class="form-check form-check-inline">
                            <input (click)="open()" class="form-check-input" type="radio" name="" id="" value="true"
                                placeholder="Enter VAT Number" formControlName="vatRegistered">
                            <label class="form-check-label" for="radios">Yes

                            </label>
                        </div>


                        <div class="form-check form-check-inline">
                            <input (click)="close()" class="form-check-input" type="radio" name="" id="" value="false"
                                placeholder="Enter VAT Number" formControlName="vatRegistered">
                            <label class="form-check-label">No</label>

                        </div>

                        <div class="hhh" *ngIf="showInput">
                            <input type="text" class="form-control" formControlName="vatNumberInput"
                                (keypress)="preventSpace($event)" maxlength="15" placeholder="Enter VAT Number">

                            <div class="for-validation mb-4">
                                <div class="for-validation text-danger"
                                    *ngIf="businessform.get('vatNumberInput')?.hasError('required')  && isSubmitted">
                                    *VAT registered number is required.</div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>



        </div>



        <p class="owner mt-3">Business Banking Details</p>


        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label">Bank Name<span class="yy">*</span></label>
                <input type="text" class="form-control mt-2" formControlName="bankname"
                    (keypress)="preventSpace($event)" placeholder="Enter Bank Name">

                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('bankname')?.hasError('required') && isSubmitted">
                        *Please enter bank name.</div>
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('bankname')?.hasError('pattern') && businessform.get('bankname')?.touched ">
                        *Invalid bank name.</div>
                </div>
            </div>
            <div class="w-50">
                <label for="datepicker" class="for-label">Branch Name<span class="yy">*</span></label>
                <input type="text" class="form-control mt-2" formControlName="branchname"
                    (keypress)="preventSpace($event)" placeholder="Enter Branch Name">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('branchname')?.hasError('required')  && isSubmitted ">
                        *Branch name is required.</div>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label">SWIFT Code<span class="yy">*</span></label>
                <input type="text" class="form-control mt-2" formControlName="swiftcode" maxlength="11"
                    (keypress)="preventSpace($event)" placeholder=" Enter Swift Code">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('swiftcode')?.hasError('required')  && isSubmitted ">
                        *Swiftcode required.</div>

                    <!-- <div class="for-validation text-danger"
                        *ngIf="businessform.get('swiftcode')?.hasError('pattern') && businessform.get('swiftcode')?.dirty">
                        *Swiftcode should be alphanumeric
                    </div> -->
                </div>
                <!-- <div class="for-validation text-danger"
                                *ngIf="businessform.get('swiftcode')?.hasError('required') && businessform.get('swiftcode')?.touched ">
                                *Swift code is required.</div> -->
            </div>
            <div class="w-50">
                <label for="datepicker" class="for-label">Account Type<span class="yy">*</span></label>
                <!-- <input type="text" class="form-control" formControlName="accountType"
                                (keypress)="preventSpace($event)" placeholder="  Enter account type"> -->

                <select class="form-control mt-2" formControlName="accountType" placeholder="  Enter account type">
                    <option value="">Account type</option>
                    <option value="Savings Account">Savings Account</option>
                    <option value="Current Account">Current Account</option>
                    <option value="Business Account">Business Account</option>
                    <option value="Cheque Account">Cheque Account</option>
                    <option value="Transmission Account">Transmission Account</option>

                </select>
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('accountType')?.hasError('required')  && isSubmitted ">
                        * Enter youre account type.</div>
                </div>
            </div>

        </div>
        <div class="d-flex justify-content-between">
            <div class="w-48">
                <label for="datepicker" class="for-label">Account Name<span class="yy">*</span></label>
                <input type="text" class="form-control mt-2" formControlName="accountName"
                    (keypress)="preventSpace($event)" placeholder="  Enter Account Name">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('accountName')?.hasError('required')  && isSubmitted">
                        * Enter youre account name.</div>
                </div>
            </div>



            <div class="w-50">
                <label for="datepicker" class="for-label">Account Number<span class="yy">*</span></label>
                <input type="text" class="form-control mt-2"
                    (keypress)="preventSpace($event);restrictChar($event);NumOnly($event)"
                    formControlName="accountNumber" placeholder="Enter Account Number" maxlength="17">
                <div class="for-validation mb-4">
                    <div class="for-validation text-danger"
                        *ngIf="businessform.get('accountNumber')?.hasError('required')  && isSubmitted">
                        * Enter youre account number.</div>
                </div>
            </div>

        </div>

        <!-- old line -->
    </form>
    <div class="btn d-flex justify-content-center mt-4">

        <button type="submit" class="btn btny" (click)="businessDetaile()">SUBMIT
            <!-- [disabled]="!businessform.valid" -->
        </button>
    </div>
</auth-layout>