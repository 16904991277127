import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() data: any
  @Input() imageCtageory: any
  @Input() showEdit: boolean
  @Input() isImage: boolean
  @Input() showHeart: boolean
  @Input() width: string
  @Input() height: string

  @Output() on_Click = new EventEmitter
  @Output() on_Click_Edit = new EventEmitter
  @Output() on_Add_Wishlist = new EventEmitter
  isLoaded: boolean = false;

theme : any
  constructor(public commonService: CommonService) { 
  }

  ngOnInit(): void {
    this.theme = { width: this.width || '227px', height:this.height || '300px', position: 'relative' }

  }
  navigateToViewProduct(e) {
    this.on_Click.emit(e)
  }
  // navigateTocategory(e,data){
  //   this.on_Click_Cat.emit({
  //     e:e,data:data
  //   })
  // }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  onEdit(e) {
    this.on_Click_Edit.emit(e)
  }
  onImageLoad() {
    this.isLoaded = true
  }
  onDealDiscountPrice() {

  }
  getDealPrice(price , percentage){
    return Number(price) -( Number(price) * Number(percentage) / 100)
  }
  addWishlist(e) {
    this.on_Add_Wishlist.emit(e)
  }
}
