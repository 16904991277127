<div >
    <div>
        <div class="bannerContent">
            <div class="card innerDiv" (click)="navigateToViewProduct(data?.productId?._id);">
            <!-- <div class="card innerDiv" (click)="navigateToViewProduct(data?._id);navigateTocategory(data?._id,data?.categoryName)"> -->
                <div class="image">
                    <img class="img" [hidden]="!isLoaded" (load)="onImageLoad()" [src]="data?.productId?.thumbnail" alt="categoryImage">
                    <ngx-skeleton-loader *ngIf="!isLoaded" [theme]="{width: '227px',
                    height: '329px',
                    position: 'relative'}" count="1"></ngx-skeleton-loader>

                </div>
                <div class="mt-1">
                    <p class="description">{{data?.productId?.productName}}</p>
                </div>
                <div style="margin-top: -8px;">
                    <div class="row">
                        <div class="col-12 p-0 m-0">
                            <p class="price" > 
                                <!-- <span style="text-decoration: line-through;">
                                    {{data?.productId?.priceSizeDetails[0]?.price &&
                                        getPrice(data?.productId?.priceSizeDetails[0]?.price | currency:"ZAR":"R")}}
                            </span> -->
                            <span class="price-color ml-0"
                                    >{{data?.productId?.priceSizeDetails[0]?.price &&
                                        getPrice(data?.productId?.priceSizeDetails[0]?.price | currency:"ZAR":"R")}}</span> </p>
                        </div>
                    </div>

                     <!-- <div class="row" style="margin-top: -8px;">
                        <div class="col-12 p-0 m-0">
                            <p class="dealPrice" style="color: #707070;font-size: 12px;"> Discount : <span class="newn">
                                {{data?.productId?.discount}}% off
                            </span>
                            </p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>