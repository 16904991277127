<div>
  <div class="postion-relative">
    <div class="bannerContent position-relative">
      <div class="card innerDiv" (click)="dealVIEw(data)">
        <div class="image" style="position: relative">
          <div class="end-pro-time1 text-center mb-2" *ngIf="countDown">
            {{ countDown }}
          </div>

          <img
            class="img"
            [src]="data?.dealImage[0]"
            (load)="onImageLoad()"
            [hidden]="!isLoaded"

          />
          <ngx-skeleton-loader
            *ngIf="!isLoaded"
            [theme]="{ width: '227px', height: '329px', position: 'relative' }"
            count="1"
          ></ngx-skeleton-loader>
        </div>
        <div class="mt-3">
          <p class="description">{{ data?.serviceId?.serviceName }}</p>
        </div>
        <div style="margin-top: -8px">
          <div class="row">
            <div class="col-12 p-0 m-0">
              <p class="price">
                <span style="text-decoration: line-through">
                  <ng-container>
                    {{
                      data?.serviceId?.price &&
                        getPrice(
                          data?.serviceId?.price | currency : "ZAR" : "R"
                        )
                    }}
                  </ng-container>
                </span>
                <span class="price-color">
                  <ng-container>
                    {{
                      data?.dealPrice &&
                        getPrice(data?.dealPrice | currency : "ZAR" : "R")
                    }}
                  </ng-container>
                </span>
              </p>
            </div>
          </div>

          <div class="row" style="margin-top: -8px">
            <div class="col-12 p-0 m-0">
              <p class="dealPrice">
                Deal Discount :
                <span class="newn">
                  {{
                    getPrice(
                      (data?.dealPrice / data?.serviceId?.price) * 100
                        | currency : "ZAR" : "R"
                    )
                  }}% off
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
